import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { AccountDeletionConfirmationView } from "./AccountDeletionConfirmationView";
import { AccountDeletionDisclaimerView } from "./AccountDeletionDisclaimerView";
import { AccountDeletionSurveyView } from "./AccountDeletionSurveyView";
import { client } from "api/client";
import { refreshToken, getUserInfo } from "store/user/thunks";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";

export const AccountDeletionPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const user = useSelector((state: RootState) => state.user);

    const [continued, setContinued] = useState<boolean>(false);

    const requestDeletion = useCallback(async () => {
        try {
            await client.deletionRequests_PostDeletionRequest(
                user.userInfo.UserId
            );
            await dispatch(refreshToken());
            await dispatch(getUserInfo());
        } catch {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "delete-account:deletion-request-failure"
                })
            );
        }
    }, [user.userInfo.UserId, dispatch]);

    if (!user.userInfo?.UserId) {
        return null;
    } else if (!user.userInfo.FeatureClaims.includes("deleted")) {
        return !continued ? (
            <AccountDeletionDisclaimerView
                toSurvey={() => setContinued(true)}
                user={user}
            />
        ) : (
            <AccountDeletionSurveyView
                back={() => setContinued(false)}
                requestDeletion={requestDeletion}
                onChange={() => {}}
            />
        );
    } else {
        return <AccountDeletionConfirmationView user={user} />;
    }
};
