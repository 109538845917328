import React, { useEffect, useState, useCallback } from "react";
import { client } from "api/client";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useHasAccess } from "hooks/useHasAccess";
import { DisabledFeature } from "components/disabled-feature/DisabledFeature";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExternalChannelSettingsForm } from "./ExternalChannelSettingsForm";
import { useParams } from "hooks/useParams";

export const AddExternalChannelSettingsPage: React.FC = () => {
    const { platformId, streamName, streamUrl, streamKey } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const hasAccess = useHasAccess();
    const [platform, setPlatform] = useState<StreamingProvider>();
    const [invalidProviderMessage, setInvalidProviderMessage] =
        useState<string>("");

    useEffect(() => {
        async function getProvider() {
            try {
                const platform =
                    await client.streamingProviders_GetStreamingProvider(
                        platformId
                    );
                setPlatform(platform);
            } catch (e) {
                setInvalidProviderMessage(
                    "This platform is currently unsupported by Switcher Studio."
                );
            }
        }

        getProvider();
    }, [platformId]);

    const confirm = useCallback(async () => {
        try {
            const existingSettings =
                await client.externalProviderSwitcherStreamSettings_GetExternalProviderSwitcherStreamSetting(
                    platformId
                );
            const update = {
                ...existingSettings,
                "channel-name": streamName,
                url: streamUrl,
                "rtmp-stream": streamKey,
                StreamingProviderId: platformId
            };

            await client.externalProviderSwitcherStreamSettings_PutExternalProviderSwitcherStreamSetting(
                platformId,
                update
            );

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:stream-is-synced",
                    messageOptions: { platformName: platform.Name }
                })
            );

            navigate("/platforms");
        } catch (err) {
            if (err.status === 404) {
                await client.externalProviderSwitcherStreamSettings_PostExternalProviderSwitcherStreamSetting(
                    platformId,
                    {
                        "audio-bit-rate": 128000,
                        "audio-channel-count": 2,
                        "audio-sample-rate": 48000,
                        "channel-name": streamName,
                        url: streamUrl,
                        "rtmp-stream": streamKey,
                        "video-bit-rate": 2000000,
                        "video-frame-height": 720,
                        "video-frame-width": 1280,
                        StreamingProviderId: platformId
                    }
                );

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:stream-is-synced",
                        messageOptions: { platformName: platform.Name },
                        fadeMilliseconds: 10000
                    })
                );

                navigate("/platforms");
            } else {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "messages:external-settings-save-error"
                    })
                );
            }
        }
    }, [
        dispatch,
        navigate,
        platformId,
        platform,
        streamName,
        streamUrl,
        streamKey
    ]);

    return (
        <>
            {!hasAccess ? (
                <DisabledFeature />
            ) : (
                <>
                    {invalidProviderMessage ? (
                        <div className="alert alert-danger" role="alert">
                            {invalidProviderMessage}
                        </div>
                    ) : (
                        <>
                            {platform && (
                                <>
                                    <h4>
                                        {t(
                                            "platforms:add-external-stream-settings"
                                        )}
                                    </h4>
                                    <ExternalChannelSettingsForm
                                        platformId={platformId}
                                        platformName={platform.Name}
                                        imgSrc={platform.LogoUrl}
                                        streamKey={streamKey}
                                        streamName={streamName}
                                        streamUrl={streamUrl}
                                        onClick={confirm}
                                        btnText={t("buttons:confirm")}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
