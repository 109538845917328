import React from "react";
import FacebookIcon from "assets/icons/facebook.svg?react";
import YouTubeIcon from "assets/icons//youtube.svg?react";
import PinIcon from "assets/icons/push-pin.svg?react";
import SolidPinIcon from "assets/icons/push-pin-solid.svg?react";
import styles from "./index.module.scss";

interface ProviderIconProps {
    provider: string;
    selected?: boolean;
}

export const ProviderIcon: React.FC<ProviderIconProps> = ({
    provider,
    selected
}: ProviderIconProps) => {
    return (
        <span className={styles["icon"]}>
            {(function () {
                switch (provider) {
                    case "Facebook":
                        return <FacebookIcon />;
                    case "YouTube":
                        return <YouTubeIcon />;
                    case "cloud":
                        if (selected) {
                            return <SolidPinIcon />;
                        } else {
                            return <PinIcon />;
                        }
                    default:
                        return null;
                }
            })()}
        </span>
    );
};
