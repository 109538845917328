import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    useRef
} from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { CatalogDetailsResponse } from "@switcherstudio/switcher-api-client";
import { Errors, useForm } from "hooks/useForm";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { Tabs } from "components/tabs/Tabs";
import { CustomizationTabs } from "store/view/types";
import { updateCustomizationActiveTab } from "store/view/slice";
import { DisplayTab } from "../CollectionPage/CollectionCustomizationPage/DisplayTab";
import { StylesTab } from "../CollectionPage/CollectionCustomizationPage/StylesTab";
import { Button } from "components/buttons/Button";
import { EmbedPreview } from "components/embed-preview";
import { useCatalogData } from "hooks/useCatalogData";
import {
    defaultInteractiveTabMap,
    embeddedDisplayMap
} from "helpers/playerApiMaps";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";

declare global {
    interface Window {
        switcherPlayerApp: any;
    }
}

export const CatalogCustomizationPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("customization-page");
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);

    const { customizationActiveTab } = useSelector(
        (state: RootState) => state.view
    );

    const projectId = user?.userInfo?.ProjectId;
    const [embedPreviewKey, setEmbedPreviewKey] = useState<number>(0);
    const [localCatalogResponse, setLocalCatalogResponse] =
        useState<CatalogDetailsResponse>(null);
    const [initCatalogResponse, setInitCatalogResponse] =
        useState<CatalogDetailsResponse>(null);
    const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
    const isMountedRef = useIsMountedRef();
    const formRef = useRef<HTMLFormElement>(null);

    const { loading: getVideoCatalogsLoading, embedCode } = useCatalogData({
        projectId: projectId,
        onSuccess: (data) => {
            setLocalCatalogResponse(data?.Details);
            setInitCatalogResponse(data?.Details);
        }
    });

    const { loading: updateCatalogLoading, dispatchApiRequest: updateCatalog } =
        useSwitcherClient((client) => client.catalog_PutCatalog, {
            requestImmediately: false,
            hideLoading: true,
            onSuccess: (data) => {
                setInitCatalogResponse(data.Details);

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message:
                            "customization-page:messages:catalog-update-success"
                    })
                );
            },
            onError: (error) => {
                rollbar.error("Error updating catalog appearance", error);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message:
                            "customization-page:messages:catalog-update-error"
                    })
                );
            }
        });

    const loading = useMemo(
        () => getVideoCatalogsLoading || updateCatalogLoading,
        [getVideoCatalogsLoading, updateCatalogLoading]
    );

    const submitAction = useCallback(async () => {
        /** Save catalog edits */
        await updateCatalog([
            localCatalogResponse?.Id,
            {
                // @ts-expect-error CatalogRepsonse matches Catalog.
                Catalog: {
                    ...localCatalogResponse
                }
            }
        ]);

        setEmbedPreviewKey((prev) => prev + 1);
    }, [localCatalogResponse, updateCatalog]);

    const handleCatalogSettingsChange = (key: string, value: any) => {
        let updatedProfile = { ...localCatalogResponse };
        updatedProfile = {
            ...updatedProfile,
            [key]: value === "" ? null : value
        };

        setLocalCatalogResponse(updatedProfile);
        customHandleChange(updatedProfile);
    };

    const {
        handleSubmit,
        /** only responsible for setting values internally */
        customHandleChange,
        errors
    } = useForm<CatalogDetailsResponse>(
        localCatalogResponse,
        submitAction,
        (values) => {
            let errors: Errors = {};
            // eslint-disable-next-line
            const hexRegExp = /^#([0-9a-f]{6})$/i;
            if (
                values?.ButtonBackgroundsLinksColor &&
                !hexRegExp.test(values?.ButtonBackgroundsLinksColor)
            ) {
                if (!errors.VideoPlayerSettings) {
                    errors.VideoPlayerSettings = {};
                }
                errors.VideoPlayerSettings.ButtonBackgroundsLinksColor =
                    t("errors:color-error");
            }
            if (
                values?.ButtonTextColor &&
                !hexRegExp.test(values?.ButtonTextColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.ButtonTextColor =
                    t("errors:color-error");
            }
            if (
                values?.UpcomingBackgroundColor &&
                !hexRegExp.test(values?.UpcomingBackgroundColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.UpcomingBackgroundColor =
                    t("errors:color-error");
            }
            if (
                values?.EmbedBackgroundColor &&
                !hexRegExp.test(values?.EmbedBackgroundColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.EmbedBackgroundColor =
                    t("errors:color-error");
            }
            if (
                values?.EmbedTextColor &&
                !hexRegExp.test(values?.EmbedTextColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.EmbedTextColor =
                    t("errors:color-error");
            }
            if (
                values?.InteractivePanelBackgroundColor &&
                !hexRegExp.test(values?.InteractivePanelBackgroundColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.InteractivePanelBackgroundColor =
                    t("errors:color-error");
            }
            if (
                values?.InteractivePanelTextColor &&
                !hexRegExp.test(values?.InteractivePanelTextColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.InteractivePanelTextColor =
                    t("errors:color-error");
            }
            if (
                values?.EnableMaxEmbedWidth &&
                values?.MaxEmbedWidth &&
                parseInt(values?.MaxEmbedWidth) < 320
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.MaxEmbedWidth = t(
                    "errors:max-embed-width"
                );
            }
            if (
                values?.EnableMaxEmbedWidth &&
                values?.MaxEmbedWidth &&
                parseInt(values?.MaxEmbedWidth) > 9999
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                // turn off max embed width
                () => handleCatalogSettingsChange("EnableMaxEmbedWidth", false);
            }

            return errors;
        },
        false
    );

    useEffect(() => {
        setHasPendingChanges(
            JSON.stringify(localCatalogResponse) !==
                JSON.stringify(initCatalogResponse)
        );
    }, [initCatalogResponse, localCatalogResponse, setHasPendingChanges]);

    /** Trigger form submission from outside form */
    const handleSaveButtonClick = () => {
        if (formRef.current) {
            handleSubmit();
        }
    };

    const handleRevertButtonClick = () => {
        if (formRef.current) {
            setLocalCatalogResponse(initCatalogResponse);
        }
    };

    usePageHeader({
        title: t("customization-page:catalog-customization"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("customization-page:customization")
        ],
        subTitle: (
            <Trans
                i18nKey={t("customization-page:catalog-customization-subtitle")}
            />
        ),
        backNavigationText: t("customization-page:collection-settings")
    });

    /** Send the updated video profile values to the player embed.
     * We are accessing a method written in the player project and
     * exposed by penpal. We are calling that method with the new values
     * and updating the player preview with those values. */
    useEffect(() => {
        try {
            if (isMountedRef.current) {
                const profileUpdate = {
                    ...localCatalogResponse,
                    ButtonBackgroundsLinksColor:
                        localCatalogResponse?.ButtonBackgroundsLinksColor?.toLocaleUpperCase() ??
                        "#000000",
                    ButtonTextColor:
                        localCatalogResponse?.ButtonTextColor?.toLocaleUpperCase() ??
                        "#FFFFFF",
                    UpcomingBackgroundColor:
                        localCatalogResponse?.UpcomingBackgroundColor?.toLocaleUpperCase() ??
                        "#000000",
                    PlayerControlsColor:
                        localCatalogResponse?.PlayerControlsColor?.toLocaleUpperCase() ??
                        "#FFFFFF",
                    EmbedBackgroundColor:
                        localCatalogResponse?.EmbedBackgroundColor?.toLocaleUpperCase() ??
                        "FFFFFF",
                    EmbedTextColor:
                        localCatalogResponse?.EmbedTextColor?.toLocaleUpperCase() ??
                        "000000",
                    InteractivePanelBackgroundColor:
                        localCatalogResponse?.InteractivePanelBackgroundColor?.toLocaleUpperCase() ??
                        "FFFFFF",
                    InteractivePanelTextColor:
                        localCatalogResponse?.InteractivePanelTextColor?.toLocaleUpperCase() ??
                        "000000",
                    UpcomingTextColor:
                        localCatalogResponse?.UpcomingTextColor?.toLocaleUpperCase() ??
                        "FFFFFF",
                    EmbeddedDisplay:
                        embeddedDisplayMap[
                            localCatalogResponse?.EmbeddedDisplay
                        ],
                    DefaultInteractiveTab:
                        defaultInteractiveTabMap[
                            localCatalogResponse?.DefaultInteractiveTab
                        ]
                };

                // vp expects camelCase keys
                const mappedProfileUpdate = Object.keys(profileUpdate).reduce(
                    (acc, key) => {
                        const newKey =
                            key.charAt(0).toLowerCase() + key.slice(1);
                        acc[newKey] = profileUpdate[key];
                        return acc;
                    },
                    {}
                );

                window.switcherPlayerApp?.playerCollection[
                    localCatalogResponse?.Id
                ]?.mainConnection?.setCatalogDetails(mappedProfileUpdate);
                window.switcherPlayerApp?.playerCollection[
                    localCatalogResponse?.Id
                ]?.auxConnection?.setCatalogDetails(mappedProfileUpdate);
            }
        } catch (e) {}
    }, [localCatalogResponse, isMountedRef]);

    useBeforeUnload(hasPendingChanges, null, true);

    return (
        <div className="row">
            <div className="col-lg-4">
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) =>
                        e.key === "Enter" ? handleSubmit(e) : null
                    }
                    ref={formRef}
                >
                    <Tabs<CustomizationTabs>
                        tabs={[
                            {
                                id: CustomizationTabs.Display,
                                label: t("customization-page:display"),
                                component: (
                                    <DisplayTab
                                        errors={errors}
                                        handleSettingsChange={
                                            handleCatalogSettingsChange
                                        }
                                        localCatalogResponse={
                                            localCatalogResponse
                                        }
                                    />
                                )
                            },
                            {
                                id: CustomizationTabs.Styles,
                                label: t("customization-page:styles"),
                                component: (
                                    <StylesTab
                                        errors={errors}
                                        handleSettingsChange={
                                            handleCatalogSettingsChange
                                        }
                                        catalogValues={localCatalogResponse}
                                    />
                                )
                            }
                        ]}
                        activeTab={customizationActiveTab}
                        setActiveTab={(id) =>
                            dispatch(updateCustomizationActiveTab(id))
                        }
                    />
                </form>
            </div>
            <div className={`col-lg-8 ${styles["sticky-container"]}`}>
                <div className={`${styles["button-container"]}`}>
                    <Button
                        onClick={handleRevertButtonClick}
                        disabled={loading || !hasPendingChanges}
                        type="outline"
                    >
                        {t("buttons:revert").toUpperCase()}
                    </Button>
                    <Button
                        onClick={handleSaveButtonClick}
                        disabled={loading || !hasPendingChanges}
                        type="primary"
                    >
                        {t("buttons:save-changes").toUpperCase()}
                    </Button>
                </div>
                <EmbedPreview
                    embedString={embedCode}
                    playerId={localCatalogResponse?.Id}
                    hasMaxHeight={true}
                    key={embedPreviewKey}
                />
            </div>
        </div>
    );
};
