import React from "react";
import { useTranslation } from "react-i18next";
import { ASPECT_RATIO_MAP } from "views/page-content/cloud/upload/upload-helpers/helpers";

interface AspectRatioSelectorProps {
    selectedAspectRatio: string;
    onChange: (value: string, remove?: boolean) => void;
}

export const AspectRatioSelector: React.FC<AspectRatioSelectorProps> = ({
    selectedAspectRatio,
    onChange
}) => {
    const aspectRatios = Object.keys(ASPECT_RATIO_MAP);
    const { t } = useTranslation();

    return (
        <>
            <div className="form-group">
                <label htmlFor="aspect-ratio-select">
                    {t("switcher-cloud:orientation")}
                </label>
                <select
                    name="aspect-ratio-select"
                    className="form-control"
                    value={selectedAspectRatio}
                    onChange={(e) => onChange(e.target.value)}
                    id="aspect-ratio-select"
                >
                    {aspectRatios.map((a, i) => {
                        return (
                            <option key={`ratio-${i}`} value={a}>
                                {t(`switcher-cloud:aspect-ratio-${a}`)}
                            </option>
                        );
                    })}
                </select>
            </div>
        </>
    );
};
