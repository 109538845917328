declare global {
    interface Window {
        Beacon: (
            command: HelpScoutCommand,
            options?: any,
            additionalOptions?: any
        ) => void;
    }
}

export type HelpScoutCommand =
    | "init"
    | "destroy"
    | "open"
    | "close"
    | "toggle"
    | "search"
    | "suggest"
    | "article"
    | "navigate"
    | "identify"
    | "prefill"
    | "reset"
    | "logout"
    | "config"
    | "on"
    | "once"
    | "off"
    | "event"
    | "session-data"
    | "show-message"
    | "info";

export class HelpScout {
    private static initialized = false;
    private static instance: HelpScout;

    private static enabled =
        import.meta.env.VITE_SUPPORT_SYSTEM === "helpscout";

    // Map commands from intercom to helpscout
    private static commandMapping = {
        showMessages: { command: "navigate", options: "/previous-messages/" },
        show: { command: "navigate", options: "/ask/" },
        shutdown: { command: "destroy" }
    };

    private static beaconId = "2e6188b0-e6a1-4c0e-9998-6f39afb333fd";

    static getInstance(): HelpScout {
        if (!HelpScout.instance) {
            HelpScout.instance = new HelpScout();
        }
        return HelpScout.instance;
    }

    init(): void {
        if (!HelpScout.initialized) {
            const writeScript = (e, t, n) => {
                function a() {
                    let e = t.getElementsByTagName("script")[0];
                    n = t.createElement("script");
                    n.type = "text/javascript";
                    n.async = !0;
                    n.src = "https://beacon-v2.helpscout.net";
                    e.parentNode.insertBefore(n, e);
                }
                e.Beacon = n = function (t, n, a) {
                    e.Beacon.readyQueue.push({
                        method: t,
                        options: n,
                        data: a
                    });
                };
                n.readyQueue = [];
                if ("complete" === t.readyState) return a();
                e.attachEvent
                    ? e.attachEvent("onload", a)
                    : e.addEventListener("load", a, !1);
            };
            writeScript(window, document, window.Beacon || function () {});

            HelpScout.initialized = true;
        }
    }

    boot(): void {
        if (!HelpScout.initialized) this.init();
        this.command("init", HelpScout.beaconId);
    }

    command(
        command: HelpScoutCommand,
        options?: any,
        additionalOptions?: any
    ): any {
        if (!HelpScout.initialized) {
            console.warn(
                "HelpScout not initialized, skipping command",
                command,
                options
            );
            return;
        }

        try {
            const commandMapping = HelpScout.commandMapping[command];
            const newCommand = commandMapping?.command ?? command;
            const newOptions = commandMapping?.options ?? options;
            if (newCommand === "navigate") {
                window.Beacon("open");
            }
            return window.Beacon(newCommand, newOptions, additionalOptions);
        } catch (err) {
            console.error(
                "Failed to execute HelpScout command",
                command,
                options,
                err
            );
        }
    }
}

export default HelpScout.getInstance;
