import React, { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";
import { useOnLocationChange } from "../hooks/useOnLocationChange";
import { resetNotifications } from "../store/notification/slice";
import { trackPageView } from "helpers/analyticsHelpers";

export const AppWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch();
    useOnLocationChange(() => {
        // clear all notifications on page change
        dispatch(resetNotifications());
        trackPageView(window.location.pathname);
    });

    return <>{children}</>;
};
