import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number) {
    const intervalId = useRef<NodeJS.Timeout>(null);
    const savedCallback = useRef<() => void>(null);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            intervalId.current = setInterval(tick, delay);
        }
        return () => clearInterval(intervalId.current);
    }, [delay]);

    return intervalId.current;
}
