import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { DateTimePicker } from "components/inputs/datepicker/DateTimePicker";
import { RootState } from "store/reducers";
import { updateGeneralForm, updateSelectedStream } from "store/platforms/slice";
import {
    format,
    compareMinutes,
    getMaxScheduleDateForSimulcast
} from "helpers/time";
import { useTranslation } from "react-i18next";
import { CoverPhoto } from "components/cover-photo/CoverPhoto";
import { Banner } from "components/banners/Banner";
import { PlatformId } from "views/page-content/platforms/types";
import { ScheduledOptions } from "views/page-content/platforms/platform/forms/types";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

interface ConditionalSchedulingProps {
    platformId: PlatformId;
    scheduleOptions: { text: string; value: ScheduledOptions }[];
    selectedOption: ScheduledOptions;
    minuteOffset?: number;
    existingStreams?: any[]; // come up with stream interface
    setStream?: (stream: any) => void;
    setSelectedOption: (option: ScheduledOptions) => void;
    isSimulcast?: boolean;
    // consider passing selectedStream & scheduledStartTime and update function
    // as args to eliminate remaining stateful aspects in this component.
}

export const ConditionalScheduling: React.FC<ConditionalSchedulingProps> = ({
    platformId,
    existingStreams,
    scheduleOptions,
    selectedOption,
    minuteOffset,
    // if this is a simulcast with a single social network, the platformId
    // will be the social network and isSimulcast will be true.
    isSimulcast = false,
    setSelectedOption
}: ConditionalSchedulingProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { general } = useSelector((state: RootState) => state.platforms);
    const { generalForm } = general;
    const navigate = useNavigate();

    const hasYtDests = useMemo(
        () =>
            generalForm.selectedSimulcastDestinations.filter(
                (d) => d.platform === PlatformId.Youtube
            ).length > 0,
        [generalForm.selectedSimulcastDestinations]
    );

    const hasTwitchDests = useMemo(
        () =>
            generalForm.selectedSimulcastDestinations.filter(
                (d) => d.platform === PlatformId.Twitch
            ).length > 0,
        [generalForm.selectedSimulcastDestinations]
    );

    const hasFbDests = useMemo(
        () =>
            generalForm.selectedSimulcastDestinations.filter(
                (d) => d.platform === PlatformId.Facebook
            ).length > 0,
        [generalForm.selectedSimulcastDestinations]
    );

    const setStream = (stream) => {
        if (typeof stream === "string")
            stream = existingStreams?.find((s) => s.id === stream);
        dispatch(
            updateSelectedStream({
                stream: stream,
                id: platformId
            })
        );
    };

    const renderOptions = (options) => {
        if (!options?.length)
            return (
                <option value="">{t("platforms:streams-unavailable")}</option>
            );

        let renderText = (o) => o.title;

        switch (platformId) {
            case PlatformId.Facebook:
                renderText = (o) =>
                    `${o?.title ? o.title : ""}${
                        o.planned_start_time
                            ? " - " +
                              format(
                                  new Date(o.planned_start_time),
                                  "MMM DD YYYY, h:mm a"
                              )
                            : ""
                    }`;
                break;
            case PlatformId.Youtube:
                renderText = (o) =>
                    `${o.snippet.title} - ${format(
                        new Date(o.snippet.scheduledStartTime),
                        "MMM DD YYYY, h:mm a"
                    )}`;
                break;
            default:
                break;
        }

        return options.map((o, i) => (
            <option value={o.id} key={`stream-${i}`}>
                {renderText(o)}
            </option>
        ));
    };

    const updateDate = useCallback(
        (val: Date) => {
            const formUpdate = { scheduledStartTime: val, isInvalid: false };
            const now = new Date();

            if (!!minuteOffset && compareMinutes(val, now, minuteOffset)) {
                formUpdate.isInvalid = true;
            } else if (val < now) {
                formUpdate.isInvalid = true;
            }

            dispatch(updateGeneralForm(formUpdate));
        },
        [dispatch, minuteOffset]
    );

    return (
        <>
            <div>
                <div className="form-group">
                    <label htmlFor="schedule-type-select">
                        {t("platforms:scheduling")}
                    </label>
                    <select
                        id="schedule-type-select"
                        className={`form-control`}
                        value={selectedOption}
                        onChange={(e) =>
                            setSelectedOption(
                                e.target.value as ScheduledOptions
                            )
                        }
                    >
                        {scheduleOptions.map(({ text, value }, idx) => (
                            <option
                                className={styles["selectedOption"]}
                                key={idx}
                                value={value}
                                disabled={
                                    value === "existing" &&
                                    existingStreams?.length <= 0
                                }
                            >
                                {text}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedOption === ScheduledOptions.Existing &&
                    platformId !== PlatformId.Simulcast && (
                        <div className="form-group">
                            <select
                                id="scheduled-stream-select"
                                className={`form-control`}
                                value={general?.selectedStreams[platformId]?.id}
                                onChange={(e) => setStream(e.target.value)}
                            >
                                {renderOptions(existingStreams)}
                            </select>
                        </div>
                    )}
                {selectedOption === ScheduledOptions.Later && (
                    <div className="form-group">
                        {!isSimulcast && (
                            <Banner
                                header={t("platforms:scheduled-streams-move")}
                                subheader={t(
                                    "platforms:scheduled-streams-move-notice"
                                )}
                                buttonProps={{
                                    label: t("simulcast:create-new"),
                                    onClick: () => {
                                        navigate(
                                            "/platforms/simulcast/destinations"
                                        );
                                    },
                                    type: "btn-primary"
                                }}
                            />
                        )}
                        {isSimulcast && (
                            <div className="form-group">
                                <DateTimePicker
                                    id="date-picker"
                                    error={
                                        generalForm.isInvalid
                                            ? !!minuteOffset
                                                ? t(
                                                      "errors:scheduled-time-custom-error",
                                                      {
                                                          minutes: minuteOffset
                                                      }
                                                  )
                                                : t(
                                                      "errors:scheduled-time-error"
                                                  )
                                            : ""
                                    }
                                    datePickerProps={{
                                        maxDate: getMaxScheduleDateForSimulcast(
                                            hasFbDests,
                                            hasYtDests,
                                            hasTwitchDests
                                        )
                                    }}
                                    value={generalForm.scheduledStartTime}
                                    onChange={updateDate}
                                />

                                <CoverPhoto platformId={platformId} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
