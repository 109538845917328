import React from "react";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { Link } from "react-router-dom";
import { PlatformIcon } from "components/icons/PlatformIcon";
import styles from "./index.module.scss";

interface RTMPChannelsTableProps {
    channels: SwitcherStreamSetting[];
    detailsText: string;
}

export const RTMPChannelsTable: React.FC<RTMPChannelsTableProps> = ({
    channels,
    detailsText
}: RTMPChannelsTableProps) => {
    return (
        <table className="table table-striped table-sm">
            <tbody>
                {channels.map((p) => {
                    return (
                        <tr
                            key={p["switcher-stream-setting-id"]}
                            className={styles["platform-row"]}
                        >
                            <td></td>
                            <td>
                                <Link
                                    className={styles["platform-link"]}
                                    to={`/platforms/custom-rtmp/${p["switcher-stream-setting-id"]}`}
                                >
                                    <PlatformIcon platformId={""} />
                                    {p["channel-name"]}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link
                                    className={"btn btn-outline-secondary"}
                                    to={`/platforms/custom-rtmp/${p["switcher-stream-setting-id"]}`}
                                >
                                    {detailsText}
                                </Link>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
