import { client } from "api/client";
import { Asset } from "@switcherstudio/switcher-api-client";
import { AspectRatio } from "views/page-content/cloud/upload/types";

export enum AssetType {
    image = "image",
    lowerthird = "lowerthird",
    video = "video",
    audio = "audio"
}

// pull unique tags and sort alphabetically
export const tagParser = function (assets: Asset[]) {
    const tags: string[] = [];

    assets.forEach((a) => {
        if (a.Tags.length > 0 && tags.indexOf(a.Tags[0]) === -1)
            tags.push(a.Tags[0]);
    });

    return tags.sort((a, b) => {
        const _a = a.toLowerCase();
        const _b = b.toLowerCase();

        return _a > _b ? 1 : _a === _b ? 0 : -1;
    });
};

export const getAssetsByTags = function (
    tags: string[],
    assets: Asset[]
): { tag: string; assets: Asset[] }[] {
    return tags.map((t) => {
        return {
            tag: t === "" ? "Main" : t,
            assets: assets.filter((a) => {
                if (a.Tags.length === 0 && t === "") return true;

                return a.Tags[0] === t;
            })
        };
    });
};

export const getAssetsByOrientation = function (
    assets: Asset[],
    orientation: string
) {
    return assets.filter(
        (a) =>
            a.Meta?.AspectRatioStrings?.indexOf(orientation) !== -1 ||
            a.Meta.isAudio ||
            (a.Meta?.AspectRatioStrings?.length === 0 &&
                orientation === AspectRatio.horizontal)
    );
};

export const getAssetsByTagAndOrientation = function (
    tags: string[],
    orientation: string,
    assets: Asset[]
) {
    const orientationAssets = getAssetsByOrientation(assets, orientation);

    return getAssetsByTags(tags, orientationAssets);
};

export const checkRawFilesForAudio = function (assets: Asset[]) {
    const rawAssets = assets.filter((a) => (a.Type as any) !== "Art");
    const artAssets = assets.filter((a) => (a.Type as any) === "Art");

    for (let i = 0; i < artAssets.length; i++) {
        const asset = artAssets[i];
        const raw: any = rawAssets.find(
            (r) => asset.RequiresAssetIds.indexOf(r.Id) !== -1
        );

        const isAudio =
            raw?.Type.toLowerCase() === AssetType.audio.toLowerCase() ||
            asset?.Meta?.PlayedSourceHasVideo === false;

        if (isAudio) {
            // This is added just in the front-end to help with filtering visible assets
            asset.Meta["isAudio"] = true;
        } else asset.Meta["isAudio"] = false;
    }

    return [...artAssets, ...rawAssets];
};

const mapRatioStringsToObjects = function (assets: Asset[]) {
    return assets.map((a) => {
        const final = { ...a };

        if (
            a.Meta.hasOwnProperty("SupportedAspectRatios") &&
            a.Meta.SupportedAspectRatios.length > 0
        ) {
            final["AspectRatios"] = a.Meta.SupportedAspectRatios.map(
                (ratio) => {
                    const splitValues = ratio.split(":");

                    return {
                        Width: parseInt(splitValues[0], 10),
                        Height: parseInt(splitValues[1], 10)
                    };
                }
            );
        }

        return final;
    });
};

export const getUserAssetsAndCheckForAudio = async function (userId: string) {
    const assets = await client.userAssets_GetUserAssets(userId);

    const mappedAssets = mapRatioStringsToObjects(assets); // remove once we no longer support "0:0" format

    return checkRawFilesForAudio(mappedAssets);
};
