import React, { useMemo } from "react";
import { SubscriptionWidgetProps } from "./types";
import BillingIcon from "assets/icons/billing.svg?react";
import { displayDate } from "helpers/time";
import { UpgradeButton } from "components/buttons/upgrade-button/UpgradeButton";
import { useTranslation } from "react-i18next";
import { differenceInCalendarDays } from "helpers/time";
import { useHasAccess } from "hooks/useHasAccess";
import { SilverSunnStripeSubscription } from "@switcherstudio/switcher-api-client";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { firstOrNull } from "utils/js-utils";
import { Widget } from "./Widget";

export const SubscriptionWidget: React.FC<SubscriptionWidgetProps> = ({
    customer
}: SubscriptionWidgetProps) => {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const isLapsedOrPaused = useHasAccess(
        (userInfo) =>
            userInfo?.Roles.includes("Paused") ||
            userInfo?.Roles.includes("Lapsed")
    );
    const { i18n, t } = useTranslation();
    const isPaused = useHasAccess((userInfo) =>
        userInfo.Roles.includes("Paused")
    );

    const subscription = useMemo<SilverSunnStripeSubscription>(() => {
        return firstOrNull(customer?.StripeSubscriptions);
    }, [customer]);

    const isTrial = useMemo<boolean>(() => {
        return (
            subscription?.PlanId === "Trial" || userInfo.Status === "trialing"
        );
    }, [subscription, userInfo.Status]);

    const isCanceled = useMemo<boolean>(
        () => subscription?.CancelAtPeriodEnd || !!subscription?.CanceledAt,
        [subscription]
    );

    const expireDate = useMemo<string>(() => {
        if (subscription) {
            if (isTrial) {
                const numDays =
                    differenceInCalendarDays(
                        new Date(subscription.ActiveUntil),
                        new Date()
                    ) || 0;
                return `${numDays} ${numDays === 1 ? t("widget:day-left") : t("widgets:days-left")}`;
            } else {
                return displayDate(
                    subscription.ActiveUntil || "",
                    i18n.language
                );
            }
        } else {
            return `0 ${t("widgets:days-left")}`;
        }
    }, [i18n.language, isTrial, subscription, t]);

    return (
        <Widget
            icon={<BillingIcon />}
            title={t("widgets:subscription").toUpperCase()}
            details={[
                {
                    title: t("widgets:your-plan"),
                    infoText: isPaused
                        ? t("pause-copy:paused")
                        : subscription?.PlanName
                },
                {
                    title: isTrial
                        ? t("widgets:days-remaining")
                        : isCanceled
                          ? t("widgets:cancel-date")
                          : t("widgets:renewal-date"),
                    infoText: expireDate.toString()
                }
            ]}
            footerSubtitle={
                isPaused
                    ? t("pause-copy:no-renewal-message")
                    : !isTrial
                      ? t("widgets:cancel-message")
                      : t("widgets:watermark-removal")
            }
            button={
                <UpgradeButton
                    needsUpgrade={isTrial}
                    isLapsedOrPaused={isLapsedOrPaused}
                    alternateText={t("widgets:manage-account").toUpperCase()}
                    alternateRoute="/subscription"
                    buttonStyle="btn-outline-secondary"
                />
            }
        />
    );
};
