import React, { useState, useEffect, useCallback } from "react";
import { Dropzone } from "./Dropzone";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { AspectRatio, AssetType, FileItem } from "./types";
import { AudioAsset } from "./AudioAsset";
import { VideoAsset } from "./VideoAsset";
import { ImageAsset } from "./ImageAsset";
import {
    getAssetType,
    fileReaderAsync,
    assemblePutOptions,
    uploadAssetToStorage,
    ASSET_TYPE_MAP,
    isAudioOrVideo,
    ASPECT_RATIO_MAP,
    getAspectRatioString
} from "./upload-helpers/helpers";
import { videoThumbnailAsync } from "./upload-helpers/thumbnail-helpers";
import { resetFileItems, addFileItems } from "store/cloud/slice";
import { AppDispatch } from "store/store";
import { client } from "api/client";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./UploadAssetPage.module.scss";
import { tagParser } from "helpers/assetHelper";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useNavigate } from "react-router-dom";

const MAX_FILE_SIZE = 104857600; //100MB

export const UploadAssetPage: React.FC = () => {
    const isMounted = useIsMountedRef();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const { fileItems } = useSelector((state: RootState) => state.cloud);
    const [error, setError] = useState<string>("");
    const [acceptedFileTypes, setAcceptedFileTypes] = useState<string[]>([
        ...ASSET_TYPE_MAP[AssetType.image],
        ...ASSET_TYPE_MAP[AssetType.video],
        ...ASSET_TYPE_MAP[AssetType.audio]
    ]);
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        async function run() {
            if (isMounted.current) {
                const assets = await client.userAssets_GetUserAssets(
                    user.userInfo?.UserId
                );

                const _tags = tagParser(assets);
                setTags(_tags);
            }
        }

        run();
    }, [isMounted, user.userInfo]);

    const onChange = useCallback(
        (input: HTMLInputElement | DataTransfer) => {
            const files = Array.from(input.files ? input.files : []);
            if (files.length) {
                const validRegex = new RegExp(acceptedFileTypes.join("|"));
                const validType = files.every((f) => validRegex.test(f.type));
                const validSize = files.every((f) => f.size <= MAX_FILE_SIZE);

                if (!validType) {
                    dispatch(resetFileItems());
                    return setError(
                        `${t(
                            "upload:unsupported-files"
                        )} ${acceptedFileTypes.join(", ")}.`
                    );
                }

                if (!validSize) {
                    dispatch(resetFileItems());
                    return setError(t("errors:file-size-error"));
                }

                dispatch(
                    addFileItems(
                        files.map((f) => {
                            const fileItem: FileItem = {
                                name: f.name,
                                file: f,
                                fileProps: {}
                            };

                            const type = getAssetType(fileItem.file.type);

                            if (isAudioOrVideo(type)) {
                                fileItem.fileProps.enableAudio = true;
                                fileItem.fileProps.endOnBlack = false;
                                fileItem.fileProps.thumbnailTag = f.name;
                            }

                            // default horizontal aspect ratio
                            fileItem.aspectRatios = [
                                ASPECT_RATIO_MAP[AspectRatio.horizontal]
                            ];

                            return fileItem;
                        })
                    )
                );
                setError("");
            }
        },
        [dispatch, acceptedFileTypes, t]
    );

    const cancel = () => {
        dispatch(resetFileItems());
    };

    const upload = useCallback(async () => {
        if (user.userInfo?.UserId) {
            const cloned: FileItem[] = fileItems.map((fi) => {
                return {
                    name: fi.name,
                    file: fi.file,
                    thumbnail: fi.thumbnail,
                    fileProps: fi.fileProps,
                    videoRef: fi.videoRef,
                    aspectRatios: fi.aspectRatios,
                    assetLayering: fi.assetLayering
                } as FileItem;
            });

            const withThumb = await Promise.all(
                cloned.map(async (fileItem) => {
                    const assetType = getAssetType(fileItem.file.type);

                    if (isAudioOrVideo(assetType)) {
                        const dataStr = await fileReaderAsync(fileItem.file);
                        if (dataStr) {
                            fileItem.fileProps = {
                                ...fileItem.fileProps,
                                dataStr: dataStr
                            };

                            if (
                                assetType === AssetType.video &&
                                !fileItem.fileProps.audioOnly
                            ) {
                                const thumbnail = await videoThumbnailAsync(
                                    fileItem.videoRef,
                                    960, // targetWidth
                                    fileItem.name,
                                    AspectRatio[
                                        getAspectRatioString(
                                            fileItem.aspectRatios
                                        )[0]
                                    ]
                                );
                                fileItem.thumbnail = thumbnail;
                            }

                            return fileItem;
                        }
                    }
                    return fileItem;
                })
            );

            const { filesWithMetadata, assetPutOptions } =
                await assemblePutOptions(withThumb);

            try {
                // upload to API
                const newAssets = await client.userAssets_PutUserAssets(
                    user.userInfo?.UserId,
                    assetPutOptions
                );

                // upload assets to blob storage
                await Promise.all(
                    newAssets.map(async (asset) => {
                        const file = filesWithMetadata.find(
                            (f) => f.imageMid === asset.Id || f.mid === asset.Id
                        );
                        if (file) {
                            await uploadAssetToStorage(file, asset);
                        }
                    })
                );

                navigate("/switcher-cloud");
            } catch (err) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:asset-add-error"
                    })
                );
            }
        }
    }, [user.userInfo, fileItems, navigate, dispatch]);

    useEffect(() => {
        if (user.userInfo?.Roles?.some((r) => r === "Admin")) {
            setAcceptedFileTypes((prev) => [...prev, "mmart"]);
        }

        return () => {
            dispatch(resetFileItems());
        };
    }, [dispatch, user.userInfo]);

    return (
        <>
            {fileItems.length === 0 && (
                <>
                    <div className={`row ${styles.header}`}>
                        <h4>{t("buttons:upload")}</h4>
                        <Link
                            className={`btn btn-grey md-btn ${styles["view-assets-btn"]}`}
                            to="/switcher-cloud"
                        >
                            {t("switcher-cloud:view-assets").toUpperCase()}
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Dropzone
                                accept={acceptedFileTypes.join(", ")}
                                fileCount={fileItems.length}
                                error={error}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </>
            )}
            {fileItems.length > 0 && (
                <>
                    <div className={`row ${styles.header}`}>
                        <h4>{t("buttons:upload")}</h4>
                        <div>
                            <button
                                className="btn btn-grey md-btn mr-2"
                                onClick={cancel}
                            >
                                {t("buttons:cancel").toUpperCase()}
                            </button>
                            <button
                                className="btn btn-primary md-btn"
                                onClick={upload}
                            >
                                {t("buttons:upload").toUpperCase()}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={`${styles["asset-group"]}`}>
                                {fileItems.map((f) => {
                                    const assetType = getAssetType(f.file.type);

                                    if (
                                        assetType === AssetType.video &&
                                        !f.fileProps.audioOnly
                                    ) {
                                        // video/audio component
                                        return (
                                            <VideoAsset
                                                key={f.file.name}
                                                fileItem={f}
                                                tags={tags}
                                            />
                                        );
                                    } else if (
                                        assetType === AssetType.audio ||
                                        (assetType === AssetType.video &&
                                            f.fileProps.audioOnly)
                                    ) {
                                        return (
                                            <AudioAsset
                                                key={f.file.name}
                                                fileItem={f}
                                                tags={tags}
                                            />
                                        );
                                    } else {
                                        // image component
                                        return (
                                            <ImageAsset
                                                key={f.file.name}
                                                fileItem={f}
                                                tags={tags}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
