import React from "react";
// import { useQs } from "hooks/useQs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { updateGeneralForm } from "store/platforms/slice";
import styles from "./SimulcastDestinationDisplay.module.scss";
import { SimulcastDestinationCard } from "../simulcast-destination-card/SimulcastDestinationCard";
import { PlatformId } from "views/page-content/platforms/types";
import { DestinationDescriptor } from "../../types";
import { useSimulcastFork } from "hooks/useSimulcastFork";
import { useClaimCheck } from "hooks/useClaimCheck";

interface SimulcastDestinationDisplayProps {
    isDark?: boolean;
    isInline?: boolean;
}

export const SimulcastDestinationDisplay: React.FC<
    SimulcastDestinationDisplayProps
> = ({ isDark, isInline = false }) => {
    const { t } = useSimulcastFork();
    const dispatch = useDispatch<AppDispatch>();
    // const { getParam, removeArrayItem } = useQs();
    const generalForm = useSelector(
        (s: RootState) => s.platforms.general.generalForm
    );
    const user = useSelector((state: RootState) => state.user);
    const userMaxDests =
        user.userInfo?.FeatureLimitClaims?.[
            "cloudflaresimulcasting:maxdestinations"
        ];
    const selectedDests = generalForm.selectedSimulcastDestinations;
    const hasCatalogClaim = useClaimCheck("catalog");

    const destClickHandler = (destination: DestinationDescriptor) => {
        dispatch(
            updateGeneralForm({
                selectedSimulcastDestinations: selectedDests.filter(
                    (d) => d.id !== destination.id
                )
            })
        );

        // removeArrayItem("destination[]", destination);
    };

    return (
        <>
            {!!selectedDests.length && (
                <>
                    <div
                        className={`${
                            styles["selected-destination-container"]
                        } ${isDark && styles["dark-bg"]} ${
                            isInline && styles["is-inline"]
                        }`}
                    >
                        {!isInline && (
                            <div>
                                <h3
                                    className={`${
                                        styles["selected-destination-header"]
                                    } ${isDark && styles["white-text"]}`}
                                >
                                    {t("selected-destinations")}{" "}
                                    {`(${
                                        generalForm.selectedSimulcastDestinations.filter(
                                            (d) =>
                                                d.platform !==
                                                PlatformId.VideoPlayer
                                        ).length
                                    } / ${userMaxDests})`}
                                </h3>
                            </div>
                        )}
                        <div className={styles["selected-destination-body"]}>
                            {selectedDests.map((d, i) => (
                                <SimulcastDestinationCard
                                    key={i}
                                    text={
                                        d.id !== PlatformId.VideoPlayer &&
                                        !hasCatalogClaim
                                            ? d.name
                                            : ""
                                    }
                                    destination={d}
                                    clickHandler={() => destClickHandler(d)}
                                    isDeletable={isInline ? false : true}
                                    isInline={isInline}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
