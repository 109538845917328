import { CustomSlugErrors } from "@switcherstudio/switcher-api-client";
import { Case } from "components/utility/Case";
import { Switch } from "components/utility/Switch";
import { useTranslation } from "react-i18next";

export interface CustomSlugInputErrorProps {
    error: CustomSlugErrors;
}

export const CustomSlugInputError = ({ error }: CustomSlugInputErrorProps) => {
    const { t } = useTranslation();

    return (
        <Switch test={error}>
            <Case value={CustomSlugErrors.Invalid}>
                <p>{t("errors:custom-slug-invalid")}</p>
            </Case>
            <Case value={CustomSlugErrors.Duplicate}>
                <p>{t("errors:custom-slug-duplicate")}</p>
            </Case>
        </Switch>
    );
};
