/**
 * Returns input percentage value into decimal
 * @param value
 * @returns value as decimal
 */
export const percent = (value: number) => value / 100;

export interface LimitOptions {
    /** min value */
    min?: number;
    /** max value */
    max?: number;
}

/**
 * Limits a given number between min, max or both
 * @param value Number to limit
 * @param options
 * @returns
 */
export const limit = (value: number, { min, max }: LimitOptions) => {
    if (min !== null && value < min) return min;
    if (max !== null && value > max) return max;
    return value;
};

export const formatNumericalInputToTwoDecimals = (
    newNumberStr: string,
    oldNumberStr: string
) => {
    const decimalIndex = newNumberStr.indexOf(".");
    const decimalPlaces = newNumberStr?.slice(decimalIndex + 1)?.length;
    const inputTooLong = decimalPlaces > 2;
    let formattedValue = newNumberStr;
    if (decimalIndex >= 0 && inputTooLong) {
        formattedValue = newNumberStr.slice(0, -1);
    }

    const oldNumberStrDecimalIndex = oldNumberStr?.indexOf(".");
    const oldNumberStrDecimalPlaces =
        oldNumberStrDecimalIndex === -1
            ? 0
            : oldNumberStr?.slice(oldNumberStrDecimalIndex + 1)?.length;
    const oldNumber = parseFloat(oldNumberStr);
    const newNumber = parseFloat(newNumberStr);
    const stepDifference =
        oldNumber > newNumber ? oldNumber - newNumber : newNumber - oldNumber;
    if (stepDifference === 1 || (stepDifference > 0.01 && stepDifference < 1)) {
        if (
            oldNumberStrDecimalIndex > -1 &&
            oldNumberStrDecimalPlaces === 1 &&
            oldNumber > newNumber
        ) {
            formattedValue = newNumberStr;
        } else {
            formattedValue =
                decimalIndex > -1 ? newNumberStr : `${newNumberStr}.00`;
        }
    }

    return formattedValue;
};

export const greater = (a: number, b: number) => (a > b ? a : b);
export const lesser = (a: number, b: number) => (a < b ? a : b);
export const clamp = (num: number, min: number, max: number) =>
    Math.min(Math.max(num, min), max);
