import { limitNegativeToZero } from "utils/js-utils";
import {
    StripeCoupon,
    ResellerInventoryItem,
    StripePrice,
    Product,
    SilverSunnStripeSubscription,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { TFunction } from "i18next";
import { first } from "lodash";
import { PlanPeriodOptions } from "components/cards/SelectPlanCardsContainer";

export const formatPlanName = (name: string, toUpperCase: boolean = false) => {
    const out =
        name?.replace(/\b(?:Monthly|Yearly|Switcher|...)\b/gi, "") ?? "";

    return toUpperCase ? out.toUpperCase() : out;
};

export interface DisplayAmountOptions {
    locale?: string;
    signed?: boolean;
    compact?: boolean;
    roundUp?: boolean;
    toFixed?: number;
    useGrouping?: boolean;
}

export const displayAmount = (
    amount: number,
    {
        locale = "en-US",
        signed = false,
        compact = true,
        roundUp = true,
        toFixed = 2,
        useGrouping = true
    }: DisplayAmountOptions = {}
) => {
    return Intl.NumberFormat(locale, {
        style: signed ? "currency" : "decimal",
        currency: "USD",
        notation: compact ? "compact" : "standard",
        minimumFractionDigits: compact ? undefined : toFixed,
        maximumFractionDigits: compact ? undefined : toFixed,
        useGrouping
    }).format(roundUp ? Math.ceil(amount / 100) : amount / 100);
};

export const calculateDiscountedAmount = (
    amount: number,
    coupon?: StripeCoupon,
    subscription?: SilverSunnStripeSubscription
): number => {
    const amountOff = coupon?.AmountOff || subscription?.AmountOff;
    const percentOff = coupon?.PercentOff || subscription?.PercentOff;

    if (amountOff) {
        return limitNegativeToZero(Math.ceil(amount - amountOff));
    } else if (percentOff) {
        return amount - amount * (percentOff / 100);
    }

    return amount;
};

export const calculateYearlyDifference = (
    plan: StripePrice,
    planPeriod: PlanPeriodOptions,
    subscriptionPlans: StripePrice[]
) => {
    if (planPeriod !== "year") return undefined;
    const monthlyPlanAmountPerYear =
        first(
            subscriptionPlans.filter(
                (p) =>
                    p.ProductId === plan.ProductId &&
                    p.RecurringInterval === "month"
            )
        ).Amount * 12;
    return monthlyPlanAmountPerYear - plan.Amount;
};

/**
 * Checks if a reseller inventory item can be used for a particular plan/price.
 * If coupon has no price id, then it can be used.
 * @param plan Price or price id to check
 * @param resellerInventoryItem coupon to check
 * @returns true if coupon applies, false if not
 */
export const couponAppliesToPlan = (
    plan: StripePrice | string,
    resellerInventoryItem: ResellerInventoryItem
) => {
    if (!resellerInventoryItem?.ResellerInventory?.PriceId) {
        return true;
    } else {
        if (typeof plan == "string") {
            return plan === resellerInventoryItem?.ResellerInventory.PriceId;
        } else {
            return (
                plan?.Id === resellerInventoryItem?.ResellerInventory.PriceId
            );
        }
    }
};

export interface SubTextOptions {
    addAfterTrial?: boolean;
    addPerInterval?: boolean;
}

export const displaySubText = (
    plan: StripePrice | StripePriceAnonymous,
    resellerInventoryItem: ResellerInventoryItem,
    t: TFunction,
    { addAfterTrial, addPerInterval }: SubTextOptions = {
        addAfterTrial: true,
        addPerInterval: true
    }
) => {
    const coupon = resellerInventoryItem?.ResellerInventory.Coupon;
    const recurringInterval = plan?.RecurringInterval;
    let subText = "";

    if (addPerInterval) {
        if (recurringInterval === "month")
            subText += t("subscription:per-month");
        if (recurringInterval === "year") subText += t("subscription:per-year");
    }

    if (couponAppliesToPlan(plan, resellerInventoryItem)) {
        if (coupon) {
            if (
                coupon.Duration === "repeating" &&
                recurringInterval === "month"
            )
                subText +=
                    " " +
                    t("subscription:months-pt1") +
                    coupon.DurationInMonths +
                    t("subscription:months-pt2");
            if (coupon.Duration === "once")
                subText += " " + t("subscription:once");
        }
        if (
            addAfterTrial &&
            resellerInventoryItem?.ResellerInventory.TrialDays > 0
        )
            subText += " " + t("subscription:after-trial");
    }

    return subText;
};

/* Currently only needed to find studio role for plan when it isn't the active plan for a user.*/
export const isStudioPlan = (plan: StripePrice | Product) =>
    plan?.Name?.toLowerCase().includes("studio");
/* Currently only needed to find business role for plan when it isn't the active plan for a user.*/
export const isBusinessPlan = (plan: StripePrice | Product) =>
    plan?.Name?.toLowerCase().includes("business");
