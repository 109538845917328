import React from "react";
import { useTranslation } from "react-i18next";

export interface SurveySidebarStepContentProps {
    isTrialing?: boolean;
}

export const SurveySidebarStepContent: React.FC<
    SurveySidebarStepContentProps
> = ({ isTrialing = true }) => {
    const { t } = useTranslation("getting-started");

    return (
        <p>
            <strong>{t("getting-started:survey-step:sidebar-content1")}</strong>{" "}
            {isTrialing
                ? t("getting-started:survey-step:sidebar-content2")
                : null}{" "}
            {t("getting-started:survey-step:sidebar-content3")}
        </p>
    );
};
