import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { resetVideoUploadSession } from "store/videoUploadSession/slice";

export const useResetUploadSession = () => {
    const { uploadSessions } = useSelector(
        (s: RootState) => s.videoUploadSession
    );

    const dispatch = useDispatch<AppDispatch>();

    const resetUploadSession = useCallback(
        ({ sessionId }: { sessionId: string }) => {
            const { modalDragAndDropInstance, entryPointDragAndDropInstance } =
                uploadSessions?.[sessionId] ?? {};
            // clean up Uppy instances
            modalDragAndDropInstance?.close();
            entryPointDragAndDropInstance?.close();

            dispatch(resetVideoUploadSession({ sessionId }));
        },
        [dispatch, uploadSessions]
    );

    return {
        resetUploadSession
    };
};
