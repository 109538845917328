import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import { TooltipBaseV2, TooltipProps } from "components/tooltips/TooltipBaseV2";

export interface SwitchProps {
    label?: string;
    on: boolean;
    onToggle: (state?: boolean) => void;
    disabled?: boolean;
    condensed?: boolean;
    isOnOn?: boolean;
    helpTooltip?: JSX.Element | TooltipProps;
    disabledTooltip?: JSX.Element | TooltipProps;
    reverseLayout?: boolean;
    className?: string;
}

/** A simple toggle component. */
export const Toggle: React.FC<SwitchProps> = ({
    label,
    on,
    onToggle,
    disabled = false,
    condensed = false,
    isOnOn = false,
    helpTooltip = null,
    disabledTooltip = null,
    reverseLayout = false,
    className = ""
}: SwitchProps) => {
    const _onKeyDown = useCallback(
        (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                onToggle();
            }
        },
        [onToggle]
    );

    const isTooltipProps = (
        tooltip: JSX.Element | TooltipProps
    ): tooltip is TooltipProps => {
        return (tooltip as TooltipProps)?.body !== undefined;
    };

    const helpTooltipComponent = useMemo(() => {
        if (!helpTooltip) return <></>;
        return isTooltipProps(helpTooltip) ? (
            <TooltipBaseV2
                show
                type={helpTooltip.type}
                body={helpTooltip.body}
                label={helpTooltip.label}
            />
        ) : (
            helpTooltip
        );
    }, [helpTooltip]);

    const disabledTooltipComponent = useMemo(() => {
        if (!disabledTooltip) return <></>;
        return isTooltipProps(disabledTooltip) ? (
            <TooltipBaseV2
                show
                type={disabledTooltip.type}
                body={disabledTooltip.body}
                label={disabledTooltip.label}
            />
        ) : (
            disabledTooltip
        );
    }, [disabledTooltip]);

    const switchComponent = useMemo(
        () => (
            <div
                className={`${styles["bar"]} ${
                    isOnOn || on ? styles["transform-bar"] : ""
                }`}
                onClick={() => onToggle(!on)}
                onKeyDown={_onKeyDown}
                tabIndex={0}
                role="button"
                aria-pressed={on}
            >
                <div
                    className={`${styles["switch"]} ${
                        isOnOn || on ? styles["switch-on"] : ""
                    } ${on ? styles["transform-switch"] : ""}`}
                ></div>
            </div>
        ),
        [_onKeyDown, isOnOn, on, onToggle]
    );

    const labelComponent = useMemo(
        () => <label className={`${styles["switch-label"]}`}>{label}</label>,
        [label]
    );

    const labelWithTooltipComponent = useMemo(() => {
        if (helpTooltip && !on && !disabled) {
            return React.cloneElement(helpTooltipComponent, {
                children: labelComponent
            });
        } else if (disabledTooltip && disabled) {
            return React.cloneElement(disabledTooltipComponent, {
                children: labelComponent
            });
        } else {
            return labelComponent;
        }
    }, [
        disabled,
        disabledTooltip,
        disabledTooltipComponent,
        helpTooltip,
        helpTooltipComponent,
        labelComponent,
        on
    ]);

    return (
        <>
            <div
                className={`switch-container ${
                    styles["switch-container"]
                } ${className} ${
                    disabled ? `disabled ${styles["disabled"]}` : ""
                } ${condensed ? styles["condensed"] : ""}
                 ${reverseLayout ? styles["reverse-layout"] : ""}`}
            >
                {reverseLayout ? (
                    <>
                        {switchComponent}
                        {labelWithTooltipComponent}
                    </>
                ) : (
                    <>
                        {labelWithTooltipComponent}
                        {switchComponent}
                    </>
                )}
            </div>
        </>
    );
};
