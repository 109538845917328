import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

export const TermsOfServicePrompt = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles["tos-prompt"]}>
                <span>
                    {t("create-account:terms-1")}{" "}
                    <Link
                        className={`text-center ${styles["login-links"]}`}
                        target="_blank"
                        to="https://www.switcherstudio.com/terms-of-use"
                    >
                        {t("create-account:terms-2").toUpperCase()}
                    </Link>
                    {". "}
                </span>
                <span>
                    {t("create-account:privacy-1")}{" "}
                    <Link
                        className={`text-center ${styles["login-links"]}`}
                        target="_blank"
                        to="https://www.switcherstudio.com/privacy-policy"
                    >
                        {t("create-account:privacy-2").toUpperCase()}
                    </Link>
                </span>
            </div>
        </>
    );
};
