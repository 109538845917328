import React, { useState, useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { CouponPrompt } from "components/inputs/text-input/CouponInput/CouponPrompt";
import { Button } from "components/buttons/Button";
import { displayAmount } from "helpers/stripe";
import { Spinner } from "react-bootstrap";

interface CouponCellProps {
    couponCode: string;
    discountAmount: number;
    type?: string;
    id?: string;
    addCoupon?: (value: string) => void;
    removeCoupon?: () => void;
    loading?: boolean;
    error?: string;
    hideAmount?: boolean;
}

export const CouponCell: React.FC<CouponCellProps> = ({
    couponCode,
    discountAmount,
    type = "coupon",
    id = "coupon",
    addCoupon,
    removeCoupon,
    loading = false,
    hideAmount = false,
    error
}: CouponCellProps) => {
    const { t } = useTranslation();
    const [showCouponInput, setShowCouponInput] = useState<boolean>(false);
    const [value, setValue] = useState<string>(couponCode || "");

    const showAndFocus = useCallback(() => {
        setShowCouponInput(true);
    }, []);

    useEffect(() => {
        setValue(couponCode);
        if (couponCode) {
            showAndFocus();
        }
    }, [couponCode, showAndFocus]);

    if (!showCouponInput) {
        return (
            <div>
                <CouponPrompt variant="cell" onClick={showAndFocus} />
            </div>
        );
    }

    return (
        <>
            <div className={styles["coupon-input"]}>
                <div className={styles["input-container"]}>
                    <>
                        <span>
                            {!couponCode ? (
                                <>
                                    <input
                                        type={type}
                                        id={id}
                                        value={value || ""}
                                        onChange={(e) =>
                                            setValue(e.target.value)
                                        }
                                        placeholder={t(
                                            "coupon-form:placeholder"
                                        )}
                                        readOnly={!!couponCode}
                                    />
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Button
                                            type="link"
                                            disabled={
                                                value === "" ||
                                                value === undefined
                                            }
                                            onClick={() => addCoupon(value)}
                                        >
                                            {t("buttons:apply")}
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <>
                                    {t("coupon-form:coupon-code")}{" "}
                                    <strong>{couponCode}</strong>
                                </>
                            )}
                        </span>
                        {error && (
                            <small className={styles["error"]}>{error}</small>
                        )}
                    </>
                </div>
                {couponCode && !hideAmount && (
                    <div>
                        {displayAmount(-discountAmount, {
                            compact: false,
                            signed: true
                        })}
                    </div>
                )}
            </div>
            {couponCode && (
                <Button onClick={removeCoupon} type="link">
                    {t("coupon-form:change-code")}
                </Button>
            )}
        </>
    );
};
