import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { IUserInfo } from "store/user/types";
import { useMemo } from "react";

export function useHasAccess(
    accessStatement?: (userInfo: IUserInfo) => boolean
) {
    const { userInfo } = useSelector((state: RootState) => state.user);

    const hasAccess = useMemo<boolean>(() => {
        if (accessStatement) {
            return accessStatement(userInfo);
        }
        return userInfo?.Roles?.indexOf("Lapsed") !== -1 ? false : true;
    }, [accessStatement, userInfo]);

    return hasAccess;
}
