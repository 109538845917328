import { PasswordValidation } from "hooks/useForm";
import styles from "./index.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipBase } from "../TooltipBase";

export interface PasswordRulesTooltipProps {
    display: boolean;
    passwordValidMap: PasswordValidation;
    direction?: "bottom" | "right";
}

export const PasswordRulesTooltip: React.FC<PasswordRulesTooltipProps> = ({
    display,
    passwordValidMap,
    direction
}) => {
    const { t } = useTranslation();

    return (
        <TooltipBase display={display} direction={direction}>
            <ul className={`${styles["password-list"]}`}>
                <li
                    className={
                        passwordValidMap.hasMinLength
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-min")}
                </li>
                <li
                    className={
                        passwordValidMap.hasLower
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-lower")}
                </li>
                <li
                    className={
                        passwordValidMap.hasUpper
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-upper")}
                </li>
                <li
                    className={
                        passwordValidMap.hasDigit
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-digit")}
                </li>
                <li
                    className={
                        passwordValidMap.hasSpecialChar
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-special")}
                </li>
            </ul>
        </TooltipBase>
    );
};
