import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

export interface PaginationNavProps {
    prev: () => void;
    next: () => void;
    jump?: (page: number) => void;
    currentPage?: number;
    maxPage?: number;
    prevPageToken?: string | null;
    nextPageToken?: string | null;
}

export const PagintationNav: React.FC<PaginationNavProps> = ({
    prev,
    next,
    currentPage,
    maxPage,
    prevPageToken,
    nextPageToken
}: PaginationNavProps) => {
    const { t } = useTranslation();
    /** TODO: implement jump */

    const isFirstPage = () => {
        if (prevPageToken !== undefined) {
            return prevPageToken === null;
        }

        return currentPage === 1;
    };

    const isLastPage = () => {
        if (nextPageToken !== undefined) {
            return nextPageToken === null;
        }

        return currentPage === maxPage;
    };

    return (
        <>
            <nav>
                <ul className="pagination justify-content-end">
                    <li
                        className={`page-item ${
                            isFirstPage() ? "disabled" : ""
                        }`}
                    >
                        <span
                            className={`page-link ${styles["link"]} ${styles["control-buttons"]}`}
                            onClick={() => prev()}
                        >
                            {t("misc:previous")}
                        </span>
                    </li>
                    <li
                        className={`page-item ${
                            isLastPage() ? "disabled" : ""
                        }`}
                    >
                        <span
                            className={`page-link ${styles["link"]} ${styles["control-buttons"]} `}
                            onClick={() => next()}
                        >
                            {t("misc:next")}
                        </span>
                    </li>
                </ul>
            </nav>
        </>
    );
};
