import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

interface PassEmptyStateProps {
    title: string;
    message: string;
    linkText: string;
    linkHref: string;
}

const PassEmptyState: React.FC<PassEmptyStateProps> = ({
    title,
    message,
    linkText,
    linkHref
}) => {
    return (
        <div className={styles["empty-pass-container"]}>
            <h5 className={styles["empty-pass-title"]}>{title}</h5>
            <p className={styles["empty-pass-description"]}>
                {message} <br />
                <Link
                    className={`${styles["empty-pass-link"]} btn btn-primary mb-2 `}
                    to={linkHref}
                >
                    {linkText}
                </Link>
            </p>
        </div>
    );
};

export default PassEmptyState;
