import { useEffect, useState } from "react";

import { client } from "api/client";
import { ResellerInventory } from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";

export function useGetResellerInventory(
    resellerId: string,
    inventoryId: string
): ResellerInventory {
    const [inventory, setInventory] = useState<ResellerInventory>();

    useEffect(() => {
        const getInventory = async () => {
            try {
                if (resellerId && inventoryId) {
                    setInventory(
                        await client.resellerInventories_GetResellerInventory(
                            resellerId,
                            inventoryId
                        )
                    );
                }
            } catch (e) {
                rollbar.error("Error getting reseller inventory", e);
            }
        };
        getInventory();
    }, [resellerId, inventoryId]);

    return inventory;
}
