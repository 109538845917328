import { useState, FormEvent, ChangeEvent, useCallback } from "react";
import { useIsMountedRef } from "./useIsMountedRef";
import rollbar from "helpers/rollbar";
import { PhoneNumberInputChangeEvent } from "components/inputs/text-input/PhoneNumberInput";

export interface Errors {
    [key: string]: any;
}

export interface PasswordValidation {
    hasDigit: boolean;
    hasLower: boolean;
    hasUpper: boolean;
    hasSpecialChar: boolean;
    hasMinLength: boolean;
}

export function useForm<T>(
    initialState: T,
    callback: (values: T) => Promise<void>,
    validate: (values: T, pvm?: PasswordValidation) => Errors,
    resetOnSubmit?: boolean
) {
    const [values, setValues] = useState<T>(initialState);
    const [errors, setErrors] = useState<Errors>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordValidMap, setPasswordValidMap] =
        useState<PasswordValidation>({
            hasDigit: false,
            hasLower: false,
            hasMinLength: false,
            hasSpecialChar: false,
            hasUpper: false
        });
    const isMountedRef = useIsMountedRef();
    const handleSubmit = useCallback(
        async (event?: FormEvent<HTMLFormElement>) => {
            if (event) event.preventDefault();
            const currentErrors = validate(values, passwordValidMap);
            setErrors(currentErrors);

            if (Object.keys(currentErrors).length === 0) {
                setIsSubmitting(true);
                try {
                    await callback(values);
                    if (isMountedRef.current) {
                        setIsSubmitting(false);
                        if (resetOnSubmit) {
                            setValues(initialState);
                        }
                    }
                } catch (e) {
                    let message = "";
                    if (e.response) {
                        const response = JSON.parse(e.response);
                        if (response.ModelState) {
                            for (let error in response.ModelState) {
                                if (
                                    typeof response.ModelState[error] !==
                                    "undefined"
                                ) {
                                    message = response.ModelState[error];
                                }
                            }
                        } else {
                            message = response.Message;
                        }
                    } else {
                        message = e.message;
                    }

                    let messageJSON: any;
                    try {
                        messageJSON = JSON.parse(message);
                    } catch (e) {
                        // eat the JSON.parse exception
                    }

                    if (
                        !!messageJSON &&
                        messageJSON.hasOwnProperty("error_description")
                    )
                        message =
                            messageJSON.error_description ||
                            "Form submission error";

                    rollbar.info(message);
                    if (isMountedRef.current) {
                        setErrors((e) => {
                            return { ...e, api: message, apiJSON: messageJSON };
                        });
                        setIsSubmitting(false);
                    }
                }
            }
        },
        [
            passwordValidMap,
            validate,
            values,
            callback,
            initialState,
            resetOnSubmit,
            isMountedRef
        ]
    );

    const handleChange = (
        event: ChangeEvent<HTMLInputElement> | PhoneNumberInputChangeEvent
    ) => {
        event.persist && event.persist();
        if (event.target.id === "password") {
            setPasswordValidMap({
                hasDigit: event.target.value.match(/\d/i) !== null,
                hasUpper: event.target.value.match(/[A-Z]/g) !== null,
                hasLower: event.target.value.match(/[a-z]/g) !== null,
                hasSpecialChar:
                    event.target.value.match(/[!@#$%^&*()_+=-]/i) !== null,
                hasMinLength: event.target.value.match(/.{8,}/i) !== null
            });
        }
        switch (event.target.type) {
            case "checkbox":
                return setValues((values) => ({
                    ...values,
                    [event.target.id]: event.target.checked
                }));
            default:
                return setValues((values) => ({
                    ...values,
                    [event.target.id]: event.target.value
                }));
        }
    };

    const hasErrors = () => Object.keys(errors).length > 0;

    // use this to set values directly in form if your data structure is more complicated than what the form permits
    const customHandleChange = (values: T) => {
        setValues(values);
    };

    return {
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        hasErrors,
        passwordValidMap,
        customHandleChange
    };
}
