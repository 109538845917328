export const getStyle = (element: HTMLElement, styleProp: string) => {
    if (element && window.getComputedStyle) {
        return document.defaultView
            .getComputedStyle(element, null)
            .getPropertyValue(styleProp);
    }
};

export const getZIndex = (element: HTMLElement) => {
    if (!element) return;

    let index = getStyle(element, "z-index");

    if (!index || index === "auto" || index === "inherit") {
        index = getZIndex(element.parentElement);
    }

    return index;
};
