import { Group } from "@visx/group";
import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { ParentSize } from "@visx/responsive";
import styles from "./index.module.scss";
import { Spinner } from "components/spinners/Spinner";
import dayjs from "dayjs";
import { AnalyticsLineGraphTooltip } from "./AnalyticsLineGraphTooltip";
import { AnalyticsUnitTypes } from "views/page-content/analytics/types";
import {
    MouseEventHandler,
    TouchEventHandler,
    useCallback,
    useMemo
} from "react";
import { useTranslation } from "react-i18next";
import { formatTimeDuration, getUTCDate } from "helpers/time";
import { TextProps } from "@visx/text";
import { scaleLinear, scaleTime } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { bisector, extent, max } from "d3-array";
import GridRows from "@visx/grid/lib/grids/GridRows";
import GridColumns from "@visx/grid/lib/grids/GridColumns";
import { Line, LinePath } from "@visx/shape";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { ScaleLinear } from "d3-scale";
import { curveCatmullRom } from "d3-shape";
import { MarkerCircle } from "@visx/marker";
import { clamp, lesser } from "helpers/numbers";
import { localPoint } from "@visx/event";
import { displayAmount } from "helpers/stripe";

const theme = {
    // colors
    backgroundColor: "#ffffff",
    colors: ["#234E5E"],

    // grid
    gridColor: "#B0B0B0",
    gridColorDark: "#B0B0B0",
    tickLength: 0
};

const textStyles: Partial<TextProps> = {
    fill: "#474747",
    fontSize: 12,
    fontFamily: "Montserrat"
};

const margin = {
    top: 20,
    bottom: 60,
    left: 55,
    right: 20
};

export interface AnalyticsLineGraphProps<T, K extends keyof T> {
    /** Summaries of dates to display */
    dateSummary: T[];
    /** Property of data to display */
    property: Exclude<keyof T, K>;
    /** Property that accesses the date */
    dateProperty: K;
    /** Unit to display data as */
    unit?: AnalyticsUnitTypes;
    /** Indicates showing loading state */
    loading?: boolean;
}

/** Generic implementation of a line graph for the Analytics page. Can be used for any property in the DateSummary of the Analytics Response. */
const AnalyticsLineGraphWrapper = <T, K extends keyof T>(
    props: AnalyticsLineGraphProps<T, K>
) => (
    <div className={styles["parent"]}>
        <ParentSize>
            {({ width, height }) => (
                <AnalyticsLineGraph width={width} height={height} {...props} />
            )}
        </ParentSize>
    </div>
);

const AnalyticsLineGraph = <T, K extends keyof T>({
    width,
    height,
    dateSummary,
    property,
    dateProperty,
    unit = "number",
    loading
}: AnalyticsLineGraphProps<T, K> & { width: number; height: number }) => {
    const { t, i18n } = useTranslation();

    const {
        tooltipData,
        tooltipOpen,
        tooltipLeft = 0,
        tooltipTop = 0,
        showTooltip,
        hideTooltip
    } = useTooltip<DateValue>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true
    });

    /** Width of grid */
    const innerWidth = useMemo<number>(
        () => width - margin.left - margin.right,
        [width]
    );

    /** Height of grid */
    const innerHeight = useMemo<number>(
        () => height - margin.top - margin.bottom,
        [height]
    );

    /** Mapped data */
    const data = useMemo<Array<DateValue>>(() => {
        return dateSummary?.map(
            (d) =>
                ({
                    date: getUTCDate(d[dateProperty] as string),
                    value: d[property]
                }) as DateValue
        );
    }, [dateProperty, dateSummary, property]);

    /** Is below a width where units are overlapping */
    const hasReachedCriticalMass = useMemo<boolean>(
        () => width / data?.length < 22,
        [data, width]
    );

    /** Accessor for data value */
    const getDate = useCallback((d: DateValue) => d.date, []);

    /** Accessor for value */
    const getValue = useCallback((d: DateValue) => d.value, []);

    /** Scale for X axis */
    const dateScale = useMemo(
        () =>
            scaleTime({
                range: [0, width - margin.left - margin.right],
                domain: extent(data ?? [], getDate)
            }),
        [data, getDate, width]
    );

    /** Scale for Y axis */
    const valueScale = useMemo<ScaleLinear<number, number>>(
        () =>
            scaleLinear<number>({
                range: [height - margin.top - margin.bottom, 0],
                domain: [
                    0,
                    max(data ?? [], getValue) > 0
                        ? max(data ?? [], getValue)
                        : 1
                ],
                nice: true
            }),
        [data, getValue, height]
    );

    /** Number of ticks (lines) across X axis */
    const xTicks = useMemo<number>(() => {
        if (!data) return null;
        return lesser(data.length, hasReachedCriticalMass ? 6 : 10);
    }, [data, hasReachedCriticalMass]);

    /** Number of ticks (lines) across Y axis */
    const yTicks = useMemo<number>(() => {
        if (!data) return null;
        switch (unit) {
            case "number":
            case "dollars":
                const max = Math.max(...data.map((d) => d.value));
                return clamp(max, 3, 8);
            case "time":
                return 6;
        }
    }, [data, unit]);

    const formatYTicks = useCallback(
        (t) => {
            const value = t.valueOf();

            switch (unit) {
                case "number":
                    if (!Number.isInteger(t)) return "";

                    return value >= 1000
                        ? Math.floor(value / 1000).toLocaleString() + "k"
                        : Math.floor(value).toLocaleString();
                case "time":
                    return formatTimeDuration(
                        value,
                        value > 60 * 60 ? "H:mm" : "m:ss"
                    );
                case "dollars":
                    if (!Number.isInteger(t)) return "";

                    return value >= 1000 * 100
                        ? displayAmount(value, {
                              locale: i18n.language,
                              signed: true,
                              roundUp: false
                          }) + "k"
                        : displayAmount(value, {
                              locale: i18n.language,
                              signed: true,
                              roundUp: false
                          });
            }
        },
        [i18n, unit]
    );

    const handleTooltip = useCallback<
        TouchEventHandler<SVGRectElement> & MouseEventHandler<SVGRectElement>
    >(
        (e) => {
            const bisectDate = bisector((d: DateValue) => d.date).left;
            const { x } = localPoint(e) || { x: 0 };

            if (x < margin.left || x > margin.left + width) {
                return;
            }

            const x0 = dateScale.invert(x - margin.left);

            // Retrieve index of data point at X
            const index = bisectDate(data, x0, 1);
            const d0 = data[index - 1];
            const d1 = data[index];
            let datum = d0;

            // Detects if cursor is on a halfway point between two data points
            if (d1 && getDate(d1)) {
                datum =
                    x0.valueOf() - getDate(d0).valueOf() >
                    getDate(d1).valueOf() - x0.valueOf()
                        ? d1
                        : d0;
            }

            showTooltip({
                tooltipData: datum,
                tooltipLeft: dateScale(getDate(datum)) + margin.left, // locks to X point
                tooltipTop: valueScale(getValue(datum)) + 16 // Lock to Y data point
            });
        },
        [data, dateScale, getDate, getValue, showTooltip, valueScale, width]
    );

    if (!data || loading)
        return <Spinner stroke={theme.colors[0]} size={height / 2} />;

    return (
        <svg width={width} height={height} ref={containerRef}>
            <rect
                x={0}
                y={0}
                width={width}
                height={height}
                fill={theme.backgroundColor}
            />

            <Group top={margin.top} left={margin.left}>
                <GridRows
                    numTicks={yTicks}
                    scale={valueScale}
                    width={innerWidth}
                />
                <GridColumns
                    numTicks={xTicks}
                    height={innerHeight}
                    scale={dateScale}
                />
                <AxisLeft
                    scale={valueScale}
                    tickFormat={formatYTicks}
                    hideAxisLine
                    hideTicks
                    numTicks={yTicks}
                    tickLabelProps={{
                        ...textStyles,
                        dx: "-0.5em"
                    }}
                />
                <AxisBottom
                    top={innerHeight}
                    scale={dateScale}
                    hideAxisLine
                    hideZero
                    hideTicks
                    numTicks={xTicks}
                    label={t("analytics-page:date")}
                    labelProps={{
                        ...textStyles,
                        dy: margin.bottom / 8
                    }}
                    tickFormat={(d) => {
                        const day = dayjs(d.valueOf());
                        return day.hour() === 0 ? day.format("M/D") : "";
                    }}
                    tickLabelProps={{
                        ...textStyles,
                        dy: "0.5em"
                    }}
                />
                {!hasReachedCriticalMass && (
                    <MarkerCircle
                        id="marker-circle"
                        stroke={theme.colors[0]}
                        fill={theme.colors[0]}
                        size={2}
                        refX={2.5}
                    />
                )}
                <LinePath
                    data={data}
                    width={innerWidth}
                    curve={curveCatmullRom}
                    x={(d) => dateScale(getDate(d)).valueOf()}
                    y={(d) => valueScale(getValue(d)).valueOf()}
                    stroke={theme.colors[0]}
                    strokeWidth={2}
                    markerMid="url(#marker-circle)"
                    markerStart="url(#marker-circle)"
                    markerEnd="url(#marker-circle)"
                />

                <rect
                    id="tooltip-interaction"
                    x={0}
                    y={0}
                    width={innerWidth}
                    height={innerHeight}
                    fill="transparent"
                    onTouchStart={handleTooltip}
                    onTouchMove={handleTooltip}
                    onMouseMove={handleTooltip}
                    onTouchEnd={hideTooltip}
                    onMouseLeave={hideTooltip}
                />

                {tooltipOpen && (
                    <>
                        <Line
                            from={{ x: tooltipLeft - margin.left, y: 0 }}
                            to={{
                                x: tooltipLeft - margin.left,
                                y: innerHeight
                            }}
                            stroke={theme.colors[0]}
                            strokeWidth={2}
                            pointerEvents="none"
                            strokeDasharray="4,2"
                        />
                        <TooltipInPortal
                            key={Math.random()}
                            style={{
                                ...defaultStyles,
                                minWidth: 60,
                                borderRadius: 0,
                                color: theme.backgroundColor,
                                backgroundColor: theme.colors[0],
                                opacity: 0.8
                            }}
                            top={tooltipTop}
                            left={tooltipLeft}
                        >
                            <AnalyticsLineGraphTooltip
                                tooltipData={tooltipData}
                                unit={unit}
                            />
                        </TooltipInPortal>
                    </>
                )}
            </Group>
        </svg>
    );
};

export { AnalyticsLineGraphWrapper as AnalyticsLineGraph };
