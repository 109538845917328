import React, { useCallback, useEffect, useState } from "react";
import { client } from "api/client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import styles from "./BrandProfileStyles.module.scss";
import { useTranslation } from "react-i18next";
import { Asset } from "@switcherstudio/switcher-api-client";
import { RootState } from "store/reducers";
import { AssetCardDelete } from "components/asset-card-delete/AssetCardDelete";
import { openConfirmation } from "store/confirmation/slice";
import rollbar from "helpers/rollbar";
import { setLoading } from "store/loading/slice";
import { useInterval } from "hooks/useInterval";

interface UploadedLogoProps {
    logos: Asset[];
    refreshLogos: () => Promise<Asset[]>;
    brandId: string;
}

export const UploadedLogo: React.FC<UploadedLogoProps> = ({
    logos,
    refreshLogos,
    brandId
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const [userId, setUserId] = useState<string>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!!userInfo && userId === null) setUserId(userInfo.UserId);
    }, [userInfo, userId]);

    // Poll for asset status updates
    const [delay, setDelay] = useState(3000);
    const [retryCount, setRetryCount] = useState(0);

    useInterval(async () => {
        // If all assets are uploaded and have thumbnails, stop polling
        const allAssetsUploaded = logos.every(
            (a) => (a.Status as any) === "Uploaded" && a.ThumbnailUrl !== null
        );

        // Set delay to null to clear this interval
        if (allAssetsUploaded) setDelay(null);

        // Refresh logos
        await refreshLogos();

        // Configure dynamic retry for polling
        const BACKOFF_MIN = 3;
        const BACKOFF_MAX = 12;
        const BACKOFF_EXPONENT = 1.5;

        if (retryCount > BACKOFF_MIN) {
            // After a certain number of retries, start increasing the delay between retries
            setDelay(delay * BACKOFF_EXPONENT);
        }

        if (retryCount > BACKOFF_MAX) {
            // Eventually, give up and notify the user
            setDelay(null);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "errors:logo-upload-timeout-error"
                })
            );
        }

        setRetryCount(retryCount + 1);
    }, delay);

    const deleteConfirmation = useCallback(
        async (asset) => {
            if (userId === null) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "user-info-load-error"
                    })
                );

                rollbar.error("UploadedLogo: userId is null");
                return;
            }

            dispatch(
                openConfirmation({
                    message: "messages:delete-logo-confirmation",
                    confirmText: "Remove",
                    onSuccess: async () => {
                        try {
                            dispatch(setLoading(1));

                            await client.brandLogos_DeleteUserBrandLogo(
                                userId,
                                brandId,
                                asset.Id
                            );

                            await refreshLogos();

                            dispatch(setLoading(-1));
                        } catch (err) {
                            rollbar.error(
                                "Error deleting user brand logo",
                                err
                            );
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:delete-logo-error"
                                })
                            );
                            dispatch(setLoading(-1));
                        }
                    }
                })
            );
        },
        [dispatch, refreshLogos, userId, brandId]
    );

    return (
        <>
            <div className={styles["logo-uploader"]}>
                <h4 className={styles["form-card-title"]}>
                    {t("brand-profile:brand-logo")}
                </h4>
                <div className={styles["logo-previews"]}>
                    {logos.map((l) => (
                        <div className={styles["logo-thumbnail"]} key={l.Id}>
                            <AssetCardDelete
                                asset={l}
                                onClick={() => deleteConfirmation(l)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
