import React, { useState, useCallback } from "react";
import { ModalBase } from "components/modal/ModalBase";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useTranslation } from "react-i18next";
import { validatePassword } from "helpers/utils";
import { IUserLogin } from "store/user/types";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "store/user/thunks";
import { RootState } from "store/reducers";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import CloseIcon from "assets/icons/close.svg?react";
import styles from "./index.module.scss";

export interface ReauthenticateModalProps {
    /** a boolean that determines if the modal should be opened */
    isOpen: boolean;
    /** the set function for the isOpen boolean */
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    /** a function that can be run after a user has re-entered their password. */
    onSuccess: () => any;
    /** Text to replace the text for the button that submits password and allows access */
    continueButtonText?: string;
    /** Text to replace the text for the back button */
    backButtonText?: string;
    /** a paragraph to be displayed in the modal */
    paragraph?: string;
}

/**
 * A modal that requires a user to re-enter their password in order do an action (as defined by onSuccess).
 */
export const ReauthenticateModal: React.FC<ReauthenticateModalProps> = ({
    isOpen,
    setIsOpen,
    continueButtonText,
    backButtonText,
    paragraph,
    onSuccess
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);

    const [password, setPassword] = useState<string>("");
    const [disableButton, setDisableButton] = useState<boolean>(true);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            setPassword(event.target.value);
            setDisableButton(!validatePassword(event.target.value));
        },
        []
    );

    const reauthenticate = useCallback(async () => {
        try {
            await dispatch(
                getToken({
                    username: user.userInfo.Email,
                    password
                } as IUserLogin)
            );
            if (onSuccess) {
                onSuccess();
            } else {
                setIsOpen(false);
            }
        } catch (e) {
            setPassword("");
            setDisableButton(true);
            if (JSON.parse(e.message).error === "invalid_grant") {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "messages:reauthenticate-failure"
                    })
                );
            }
        }
    }, [user.userInfo.Email, dispatch, onSuccess, password, setIsOpen]);

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            stylesOverride={{
                padding: "20px 16px",
                height: "auto",
                maxWidth: "700px"
            }}
        >
            <div className={styles["container"]}>
                <div className={styles["header"]}>
                    <h5>{t("profile:confirm-password")}</h5>
                    <button
                        type="button"
                        className="btn"
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <p>{paragraph ?? t("modals:reauthenticate-paragraph")}</p>
                <div>
                    <TextInput
                        id="password"
                        type="password"
                        placeholder={t("profile:password-placeholder")}
                        value={password}
                        onChange={handleChange}
                        className="modal-input"
                    />
                </div>
                <button
                    className={`btn btn-grey`}
                    onClick={() => setIsOpen(false)}
                >
                    {backButtonText ?? t("buttons:back").toUpperCase()}
                </button>
                <button
                    className={`btn btn-warn`}
                    onClick={reauthenticate}
                    disabled={disableButton}
                    style={{ marginLeft: 5 }}
                >
                    {continueButtonText ?? t("buttons:continue").toUpperCase()}
                </button>
            </div>
        </ModalBase>
    );
};
