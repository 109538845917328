import Bowser from "bowser";

const bowser = Bowser.getParser(window.navigator.userAgent);

export function isMobile() {
    const platformType = bowser.getPlatformType();
    return platformType === "mobile";
}

export function isTablet() {
    const platformType = bowser.getPlatformType();
    return platformType === "tablet";
}

export function isIOS() {
    return bowser.getOS().name === "iOS";
}

export function calcDiscount(total: number, due: number, proration: number) {
    const discount = total - due - proration;
    return discount > 0 ? discount : 0;
}

export function tryGetProp(props, key, _default = null) {
    try {
        return props.hasOwnProperty(key) ? props[key] : _default;
    } catch {
        return _default;
    }
}

export function titleCase(str: string) {
    return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
}

export function firstOrNull<T>(array: Array<T> | undefined): T | null {
    if (array && array.length) {
        return array[0];
    } else {
        return null;
    }
}

export function limitNegativeToZero(number: number) {
    return number >= 0 ? number : 0;
}
