import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportSystemState } from "./types";

export const supportSystem = createSlice({
    name: "supportSystem",
    initialState: {
        isBooted: false
    } as SupportSystemState,
    reducers: {
        setIsBooted: (state, { payload }: PayloadAction<boolean>) => {
            state.isBooted = payload;
        }
    }
});
export const { setIsBooted } = supportSystem.actions;

export default supportSystem.reducer;
