import React, { PropsWithChildren } from "react";
import styles from "./index.module.scss";

export interface TooltipBaseProps {
    display: boolean;
    /**
     * Location of arrow - default is "top"
     */
    direction?: "bottom" | "right";
}

export const TooltipBase: React.FC<PropsWithChildren<TooltipBaseProps>> = ({
    children,
    display,
    direction = "top"
}) => {
    return (
        <div style={{ position: "relative" }}>
            <span
                className={`
      ${styles["container"]} 
      ${display ? styles["is-open"] : ""} 
      ${styles[direction]}
    `}
            >
                {children}
            </span>
        </div>
    );
};
