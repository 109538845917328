import { createAsyncThunk } from "@reduxjs/toolkit";
import { addPending, moveToReady } from "./slice";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { VideoDownloadStatus } from "components/recording-card/RecordingCard/types";
import { download } from "helpers/download";
import { Client } from "api/client";
import { DownloadProps } from "hooks/useVideoDownload";

const NO_LOAD_client = new Client({ showLoading: false });

const POLLING_INTERVAL = 1500;
const DOWNLOAD_ON_READY = true;

export const startPollingDownload = createAsyncThunk<
    void,
    DownloadProps,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>(
    "downloads/startPollingDownload",
    async (downloadProps, { getState, dispatch }) => {
        const { pending } = getState().downloads;

        if (!pending.find((dl) => dl.videoId === downloadProps.videoId)) {
            dispatch(addPending(downloadProps));
        }

        dispatch(pollPendingDownloads());
    }
);

export const pollPendingDownloads = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("downloads/pollPendingDownloads", async (_, { getState, dispatch }) => {
    let { pending } = getState().downloads;

    if (pending.length > 0) {
        const statuses =
            await NO_LOAD_client.broadcasts_GetVideoDownloadStatuses(
                pending.map((dl) => dl.videoId)
            );

        for (const { VideoId, Response } of statuses) {
            if (Response.result.default.status === VideoDownloadStatus.Ready) {
                const downloadProps = pending.find(
                    (dl) => dl.videoId === VideoId
                );
                dispatch(moveToReady(downloadProps));

                if (DOWNLOAD_ON_READY)
                    download(Response.result.default.url, downloadProps.title);
            }
        }

        setTimeout(() => dispatch(pollPendingDownloads()), POLLING_INTERVAL);
    }
});
