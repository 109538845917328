import { slugify } from "./slugify";

/**
 * Triggers the browser to download a file - creates anchor tag in order to prevent popup blocking on asynchronous calls
 * @param href url of item to download
 * @param filename name of file
 */
export const download = (href: string, filename: string) => {
    // Create a new link

    const anchor = document.createElement("a");
    anchor.href = `${href}?filename=${slugify(filename, {
        toLowerCase: false
    })}`;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    try {
        // Remove element from DOM
        document.body.removeChild(anchor);
    } catch (e) {
        console.log(e);
    }
};

export const jsonToCsv = (json: object[]): string => {
    const header = Object.keys(json[0]);
    const headerString = header.join(","); // handle null or undefined values here
    const replacer = (_, value: string): string =>
        value.replace('"', '""') ?? "";
    const rowItems = json.map((row): string =>
        header
            .map((fieldName): string =>
                JSON.stringify(row[fieldName], replacer)
            )
            .join(",")
    ); // join header and body, and break into separate lines
    return [headerString, ...rowItems].join("\r\n");
};
