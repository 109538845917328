import React, { PropsWithChildren } from "react";
import styles from "./index.module.scss";

export const CardGrid: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <div className={styles["card-container"]}>{children}</div>
        </>
    );
};
