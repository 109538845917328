import {
    Client as SwitcherClient,
    ClientOptions
} from "@switcherstudio/switcher-api-client";
import store from "store/store";
import { setLoading } from "store/loading/slice";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

export class Client extends SwitcherClient {
    _http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };

    constructor(
        options: ClientOptions = new ClientOptions(),
        http: {
            fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
        } = {
            fetch: (
                url: RequestInfo,
                init?: RequestInit | undefined
            ): Promise<Response> => {
                const state = store.getState();
                if (init && init.headers && state.user.ticket) {
                    (init.headers as { [key: string]: string })[
                        "Authorization"
                    ] = "bearer " + state.user.ticket!.access_token;
                }

                const request = fetch(url, init);

                request.catch(() => {
                    store.dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "login:server-error",
                            messageOptions: { allowDuplicates: false }
                        })
                    );

                    return request;
                });

                if (options.showLoading) {
                    store.dispatch(setLoading(1));
                    request.finally(() => store.dispatch(setLoading(-1)));
                }

                return request;
            }
        }
    ) {
        super(
            options,
            import.meta.env.VITE_API_URL ||
                "https://silversunnapi-develop.azurewebsites.net",
            http
        );
        this._http = http;
    }

    hubspot_GetFormData(id: string): Promise<any> {
        let url_ = `https://switcher-hubspot-forms.azurewebsites.net/api/read?code=fPQOBMBee714AgisdT9JRUYNDaNl5K2E8BY0NwLgUmauYGQjFUSR5w==&id=${id}`;
        if (id === undefined || id === null)
            throw new Error(
                "The parameter 'id' must be defined and cannot be null."
            );
        url_ = url_.replace(/[?&]$/, "");

        let options_ = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        } as RequestInit;

        return this._http.fetch(url_, options_).then((_response: Response) => {
            return this.transformResult(url_, _response, () =>
                Promise.resolve()
            );
        });
    }
}

// Just export this so we don't have to construct new ones all over the place
// unless custom options are required.
export const client = new Client();
