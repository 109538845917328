import { useCallback } from "react";
import { client } from "api/client";
import { CreateSubscriptionResponse } from "@switcherstudio/switcher-api-client";
import { trackEvent, getMeanLTV } from "helpers/analyticsHelpers";

export interface UseMakeSubscriptionArgs {
    clientId: string | undefined;
    resellerInventoryItemId: string | undefined;
    planId: string;
    isTrialing: boolean;
}

export const useMakeSubscription = ({
    clientId,
    resellerInventoryItemId,
    planId,
    isTrialing
}: UseMakeSubscriptionArgs) => {
    return useCallback(
        async (userId: string, retryStatus?: string) => {
            if (!retryStatus) {
                const res: CreateSubscriptionResponse =
                    await client.userSubscriptions_CreateSubscription(userId, {
                        ResellerInventoryItemId: resellerInventoryItemId,
                        Plan: planId,
                        Source: clientId,
                        TrialDays: isTrialing ? null : 0
                    });

                try {
                    const plans = await client.metrics_GetPlansLtv();
                    let planInfo = plans.filter((p) => p.PlanId === planId)[0];

                    if (!planInfo) {
                        planInfo = {
                            Ltv: await getMeanLTV(),
                            PlanId: planId
                        };
                    }

                    if (!isTrialing) {
                        // "Subscribed event is deprecated, "Trial Started" event is handled in trackConversion helper func
                        trackEvent(
                            "Subscribed",
                            {
                                currency: "USD",
                                predicted_ltv: planInfo.Ltv,
                                value: planInfo.Ltv,
                                planId: planInfo.PlanId,
                                category: "Billing",
                                label: planInfo.PlanId
                            },
                            {
                                integrations: {
                                    Intercom: false,
                                    HelpScout: false
                                }
                            }
                        );
                    }
                } catch {
                    // We don't want payment to fail because of a metrics error
                }
                return res;
            }
        },
        [resellerInventoryItemId, planId, clientId, isTrialing]
    );
};
