import React from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { Link } from "react-router-dom";
import { PlatformIcon } from "components/icons/PlatformIcon";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";
import styles from "./index.module.scss";
import { PlatformId } from "views/page-content/platforms/types";

export interface PlatformsTableStreamProvider extends StreamingProvider {
    CanManage?: boolean | undefined;
}

interface PlatformsTableProps {
    platforms: PlatformsTableStreamProvider[];
    connectText: string;
    detailsText: string;
    disconnectText: string;
    disconnectAction: (platformId: PlatformId) => void;
}

export const PlatformsTable: React.FC<PlatformsTableProps> = ({
    platforms,
    connectText,
    detailsText,
    disconnectText,
    disconnectAction
}: PlatformsTableProps) => {
    return (
        <table className="table table-striped table-sm">
            <tbody>
                {platforms
                    .filter(
                        (p) => p.CanManage || p.Id !== PlatformId.VideoPlayer
                    ) // Can't connect/disconnect the video player
                    .map((p) => {
                        return (
                            <tr key={p.Id} className={styles["platform-row"]}>
                                <td>
                                    {p.IsLinked && (
                                        <CheckmarkIcon
                                            className={`${
                                                styles["check-icon"]
                                            } ${
                                                styles[
                                                    p.IsLinked
                                                        ? "enabled"
                                                        : "disabled"
                                                ]
                                            }`}
                                        />
                                    )}
                                </td>
                                <td>
                                    {p.CanManage || !p.IsLinked ? (
                                        <Link
                                            className={styles["platform-link"]}
                                            to={
                                                p.IsLinked
                                                    ? p.DashboardUrl
                                                    : `/platforms/connect/${p.Id}`
                                            }
                                        >
                                            <PlatformIcon
                                                platformId={p.Id || ""}
                                            />
                                            {p.Name}
                                        </Link>
                                    ) : (
                                        <a
                                            className={styles["platform-link"]}
                                            onClick={async () => {
                                                await disconnectAction(
                                                    p.Id as PlatformId
                                                );
                                            }}
                                        >
                                            <PlatformIcon
                                                platformId={p.Id || ""}
                                            />
                                            {p.Name}
                                        </a>
                                    )}
                                </td>
                                <td className="text-right">
                                    {p.IsLinked ? (
                                        p.CanManage ? (
                                            <Link
                                                className={
                                                    "btn btn-outline-secondary"
                                                }
                                                to={p.DashboardUrl}
                                            >
                                                {detailsText}
                                            </Link>
                                        ) : (
                                            <button
                                                className="btn btn-secondary"
                                                onClick={async () => {
                                                    await disconnectAction(
                                                        p.Id as PlatformId
                                                    );
                                                }}
                                            >
                                                {disconnectText}
                                            </button>
                                        )
                                    ) : (
                                        <Link
                                            className={"btn btn-secondary"}
                                            to={`/platforms/connect/${p.Id}`}
                                        >
                                            {connectText}
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};
