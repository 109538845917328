import { ReactElement } from "react";
import { CaseProps } from "./Case";

export interface SwitchProps<T> {
    /** The value to test */
    test: T;
    children: ReactElement<CaseProps<T>>[];
}

/**
 * A React-friendly implementation of switch/case logic. This component is tightly coupled with the Case component, which is to be used as a child that contains the elements needed to render.
 * An example of this in use can be found in `ViewsTab.tsx`.
 */
export const Switch = <T,>({ test, children }: SwitchProps<T>) => {
    const defaultResult = children.find((child) => child.props.default) || null;
    const result = children.find((child) => child.props.value === test);

    return result || defaultResult;
};
