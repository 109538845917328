import React from "react";

import { ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { ItemCard } from "./ItemCard";

interface ItemGridProps {
    displayedItems: ResellerInventoryItem[];
    isResellerAdmin?: boolean;
    copyToClipboard: (toBeCopied: string) => void;
}

export const ItemGrid: React.FC<ItemGridProps> = ({
    displayedItems,
    isResellerAdmin,
    copyToClipboard
}) => {
    const { t } = useTranslation();
    return (
        <>
            {displayedItems.length === 0 ? (
                <div className="alert alert-info" role="alert">
                    {t("messages:no-inventory")}
                </div>
            ) : (
                <div>
                    {displayedItems.map((item, key) => (
                        <ItemCard
                            item={item}
                            isResellerAdmin={isResellerAdmin}
                            copyToClipboard={copyToClipboard}
                            key={key}
                        />
                    ))}
                </div>
            )}
        </>
    );
};
