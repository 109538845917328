import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import styles from "./WorkspacesPage.module.scss";
import { client } from "api/client";
import { setLoading } from "store/loading/slice";
import {
    getDefaultUserOrg,
    getUserInfo,
    refreshToken
} from "store/user/thunks";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";
import { AppDispatch } from "store/store";

export const WorkspacesPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const isMountedRef = useIsMountedRef();
    const [userOrgs] = useState(user.orgs);
    const [defaultOrg, setDefaultOrg] = useState(undefined);

    useEffect(() => {
        async function fetchDefault() {
            dispatch(setLoading(1));
            setDefaultOrg(user.defaultOrg);
            dispatch(setLoading(-1));
        }

        if (isMountedRef.current) {
            fetchDefault();
        }
    }, [isMountedRef, user, dispatch]);

    const buttonClick = useCallback(
        async (orgID?: any) => {
            dispatch(setLoading(1));
            const userId = user.userInfo.UserId;

            try {
                await client.userOrganizations_DeleteDefault(userId);
                if (orgID)
                    await client.userOrganizations_PostDefault(userId, orgID);

                await dispatch(getDefaultUserOrg());
                await dispatch(refreshToken());
                await dispatch(getUserInfo());
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:workspace-change-success",
                        messageOptions: {}
                    })
                );

                dispatch(setLoading(-1));
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:workspace-change-error",
                        messageOptions: {}
                    })
                );

                dispatch(setLoading(-1));
            }
        },
        [user.userInfo.UserId, dispatch]
    );

    return (
        <section>
            <div className="row">
                <div className={`col-lg-12 ${styles["workspace-container"]}`}>
                    <h3>
                        {userOrgs.length > 1
                            ? t("misc:team-workspace_plural")
                            : t("misc:team-workspace")}
                    </h3>
                    <ul className={styles["workspace-list"]}>
                        {userOrgs.map((u, i) => (
                            <li
                                onClick={() => buttonClick(u.Id)}
                                key={`org-${i}`}
                            >
                                <button className="btn btn-grey">
                                    {!!defaultOrg &&
                                    defaultOrg.OrganizationUserId === u.Id ? (
                                        <CheckmarkIcon />
                                    ) : null}
                                    {u.Organization.Name} {t("misc:workspace")}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={`col-lg-12 ${styles["workspace-container"]}`}>
                    <h3>{t("misc:personal-workspace")}</h3>
                    <ul className={styles["workspace-list"]}>
                        <li onClick={() => buttonClick()}>
                            <button className="btn btn-grey">
                                {defaultOrg === null ? <CheckmarkIcon /> : null}
                                {user.userInfo.Email} {t("misc:workspace")}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};
