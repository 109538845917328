import React, { useState, useMemo, useEffect } from "react";
import styles from "./index.module.scss";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "store/store";
import { v4 as uuidv4 } from "uuid";
import { useClaimCheck } from "hooks/useClaimCheck";
import {
    BroadcastStatus,
    VideoPlayerCloudflareResponse
} from "@switcherstudio/switcher-api-client";
import {
    BroadcastDetails,
    BroadcastDetailsProps
} from "../entity-details/BroadcastDetails";
import { useGenericMultiSelect } from "components/generic-multiselect/useGenericMultiSelect";
import { ComponentItem } from "components/generic-multiselect/types";
import { useUserStatus } from "hooks/useUserStatus";
import { useAddVideosActions } from "./useAddVideoActions";
import { AddVideosContent } from "./AddVideosContent";
import { AddVideosProps } from "./types";
import { useVideoUpload } from "hooks/useVideoUpload";
import { VideoUploadStatus } from "store/uploads/types";

export const AddVideos = ({
    variant,
    previouslySelectedBroadcastIds,
    setIsOpen,
    isMultiple = true,
    buttonText,
    onSelect,
    playerId,
    onClose,
    showUnpublished = true,
    setHeaderData
}: AddVideosProps) => {
    const isVideoLibrary = variant === "video-library";
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [uploadSessionId, setUploadSessionId] = useState<string>(uuidv4());
    const [allBroadcasts, setAllBroadcasts] = useState<
        VideoPlayerCloudflareResponse[]
    >([]);
    const [uploadModalKey, setUploadModalKey] = useState<number>(0);

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const hasCatalogClaim = useClaimCheck("catalog");
    const { userHasNoSubscription } = useUserStatus();
    const { pollUpload } = useVideoUpload();

    /* Data fetching and handling */
    const { dispatchApiRequest: refetchVideos, loading: fetchLoading } =
        useSwitcherClient(
            (client) => client.cloudRecordings_GetVideosForUserV2,
            {
                requestImmediately: true,
                hideLoading: true,
                fetchPolicy: isVideoLibrary ? undefined : "cache-and-network",
                preFetch: () => {
                    setUploadModalKey((prev) => prev + 1);
                },
                onSuccess: (data) => {
                    if (!showUnpublished) {
                        data = data?.filter((video) => {
                            // filter out unpublished videos
                            return (
                                video?.broadcast?.BroadcastStatus !==
                                    BroadcastStatus._5 && video?.videos?.length
                            );
                        });
                    }
                    setAllBroadcasts(data);
                    setIsFirstLoad(false);
                },
                onError: () => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("errors:recording-retrieval-error")
                        })
                    );
                }
            }
        );

    const loading = useMemo(
        () => fetchLoading && isFirstLoad,
        [fetchLoading, isFirstLoad]
    );

    /** If a broadcast has a video in a pending state, add it to a list of broadcasts to poll. */
    useEffect(() => {
        if (!allBroadcasts.length) return;
        allBroadcasts?.forEach((item) => {
            const video = item?.videos[0];
            if (
                video?.status?.state === VideoUploadStatus.Processing ||
                video?.status?.state === VideoUploadStatus.Queued
            ) {
                pollUpload({
                    broadcastId: item?.broadcast?.Id
                });
            }
        });
    }, [allBroadcasts, pollUpload]);

    useEffect(() => {
        setHeaderData &&
            isVideoLibrary &&
            setHeaderData({
                autoSave: loading && allBroadcasts.length > 0,
                autoSaveText: t("video-library:loading")
            });
    }, [loading, allBroadcasts, setHeaderData, isVideoLibrary, t]);

    const { handleDelete, actionsBarOptions, searchSortOptions } =
        useAddVideosActions({
            refetchVideos,
            isVideoLibrary
        });

    const broadcastListItemComponentItems: ComponentItem<VideoPlayerCloudflareResponse>[] =
        useMemo(() => {
            if (!allBroadcasts.length) return [];

            return allBroadcasts.map((broadcast) => {
                const entitlements = [
                    ...broadcast.playlistBroadcastEntitlements,
                    ...broadcast.playerEntitlements
                ];

                return {
                    id: broadcast.broadcast.Id,
                    component: (
                        <BroadcastDetails
                            broadcast={broadcast.broadcast}
                            video={broadcast.videos?.[0]}
                            metrics={broadcast.entitySummary}
                            badges
                            players={broadcast.players}
                            entitlements={entitlements}
                            showPricing
                            showEdit={isVideoLibrary}
                            showDownload
                            allowVideoPlaybackOnThumbnailClick
                            location="video-library"
                            handleDeleteBroadcast={() => {
                                handleDelete(broadcast.broadcast, entitlements);
                            }}
                            onBroadcastUpdate={() => {
                                refetchVideos();
                            }}
                            disabled={broadcast.videos?.some((v) => !v)}
                        />
                    ),
                    baseObject: broadcast
                };
            });
        }, [allBroadcasts, refetchVideos, isVideoLibrary, handleDelete]);

    const {
        GenericMultiSelectComponent,
        handleSelectOrDeselectAll,
        handleSubmit,
        anyNewSelected,
        visibleItems,
        anyVisibleSelected
    } = useGenericMultiSelect<
        VideoPlayerCloudflareResponse,
        BroadcastDetailsProps
    >({
        isMultiple: isMultiple,
        checkBoxLocation: isVideoLibrary ? "left" : "right",
        allowItemClick: !isVideoLibrary,
        items: broadcastListItemComponentItems,
        onSubmit: (ids) => {
            if (onSelect && setIsOpen) {
                onSelect(
                    ids.filter(
                        (id) => !previouslySelectedBroadcastIds.includes(id)
                    )
                );
                setIsOpen(false);
            }
        },
        previouslySelectedIds: previouslySelectedBroadcastIds,
        actionsBarOptions,
        searchSortOptions,
        loading
    });

    const AddVideosContentComponent = useMemo(
        () => (
            <AddVideosContent
                isVideoLibrary={isVideoLibrary}
                hasVideos={allBroadcasts.length !== 0}
                refetchVideos={refetchVideos}
                loading={loading}
                GenericMultiSelectComponent={GenericMultiSelectComponent}
                t={t}
                userHasNoSubscription={userHasNoSubscription}
                videoUploadSessionId={uploadSessionId}
                setVideoUploadSessionId={setUploadSessionId}
                playerId={!isVideoLibrary ? playerId : undefined}
                onClose={!isVideoLibrary ? onClose : undefined}
                uploadModalKey={uploadModalKey}
            />
        ),
        [
            isVideoLibrary,
            allBroadcasts,
            refetchVideos,
            loading,
            GenericMultiSelectComponent,
            t,
            userHasNoSubscription,
            uploadSessionId,
            setUploadSessionId,
            playerId,
            onClose,
            uploadModalKey
        ]
    );

    const footer = useMemo(
        () => (
            <div className={styles["footer"]}>
                <button
                    type="button"
                    className="btn"
                    onClick={() => setIsOpen(false)}
                >
                    {t("buttons:cancel")}
                </button>
                {isMultiple && (
                    <button
                        type="button"
                        className="btn"
                        onClick={() => handleSelectOrDeselectAll()}
                        disabled={!visibleItems?.length}
                    >
                        {anyVisibleSelected && anyNewSelected
                            ? t("buttons:deselect-all")
                            : t("buttons:select-all")}
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={!anyNewSelected}
                >
                    {!!buttonText
                        ? t(buttonText)
                        : hasCatalogClaim
                        ? t("video-player-page:add-to-collection")
                        : t("video-player-page:add-to-playlist")}
                </button>
            </div>
        ),
        [
            setIsOpen,
            t,
            isMultiple,
            handleSelectOrDeselectAll,
            anyNewSelected,
            handleSubmit,
            buttonText,
            hasCatalogClaim,
            visibleItems?.length,
            anyVisibleSelected
        ]
    );

    return (
        <>
            {isVideoLibrary ? (
                AddVideosContentComponent
            ) : (
                <>
                    <div className={styles["content"]}>
                        {AddVideosContentComponent}
                    </div>
                    <hr />
                    {footer}
                </>
            )}
        </>
    );
};
