import { isTablet } from "utils/js-utils";
import { useMediaQuery } from "./useMediaQuery";

/** Returns if the viewport matches mobile breakpoints. Does not detect device type */
export const useIsTablet = () => {
    const isLandscapeTablet = useMediaQuery({
        maxWidth: 1300,
        minWidth: 769,
        orientation: "landscape"
    });
    const isPortraitTablet = useMediaQuery({
        maxWidth: 1300,
        minWidth: 769,
        orientation: "portrait"
    });

    return {
        isTablet: isTablet() || isLandscapeTablet || isPortraitTablet,
        isLandscapeTablet,
        isPortraitTablet
    };
};
