import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProfileForms.module.scss";
import ProfileIcon from "assets/icons/username.svg?react";
import { UserState } from "store/user/types";
import { AccountDeletionConfirmationView } from "../account-deletion/AccountDeletionConfirmationView";
import { useNavigate } from "react-router-dom";

interface AccountDeletionRequestFormProps {
    user: UserState;
}

/**
 * Request account deletion form
 */
export const AccountDeletionRequestForm: React.FC<
    AccountDeletionRequestFormProps
> = ({ user }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const toAccountDeletionPage = useCallback(() => {
        navigate("account-deletion");
    }, [navigate]);

    return (
        <>
            <div className={styles["form-card"]}>
                <span className={styles["icon"]}>
                    <ProfileIcon />
                </span>
                <div className={styles["form-card-body"]}>
                    {!user.userInfo.FeatureClaims.includes("deleted") ? (
                        <>
                            <h4 className={styles["form-card-title"]}>
                                {t("misc:request-deletion")}
                            </h4>{" "}
                            <p>{t("misc:request-deletion-body")}</p>
                            <a
                                href="/account-deletion"
                                className={`${styles["submit-link"]}`}
                                onClick={toAccountDeletionPage}
                            >
                                {t(
                                    "buttons:deletion-request-confirm"
                                ).toUpperCase()}
                            </a>
                        </>
                    ) : (
                        <AccountDeletionConfirmationView user={user} />
                    )}
                </div>
            </div>
        </>
    );
};
