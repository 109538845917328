import React, { PropsWithChildren } from "react";
import BillingIcon from "assets/icons/billing.svg?react";
import styles from "./SubscriptionFormWrapper.module.scss";

interface SubscriptionFormWrapperProps extends PropsWithChildren {
    showIcon?: boolean;
}
export const SubscriptionFormWrapper: React.FC<
    SubscriptionFormWrapperProps
> = ({ children, showIcon }) => {
    return (
        <div className={styles["form-card"]}>
            {showIcon && (
                <span className={styles["icon"]}>
                    <BillingIcon />
                </span>
            )}
            <div className={styles["form-card-body"]}>{children}</div>
        </div>
    );
};
