import { VideoPlayerAspectRatio } from "@switcherstudio/switcher-api-client";

export const displayAspectRatio = (
    ratio: VideoPlayerAspectRatio
): "16:9" | "9:16" => {
    switch (ratio) {
        case VideoPlayerAspectRatio.SixteenByNine:
            return "16:9";
        case VideoPlayerAspectRatio.NineBySixteen:
            return "9:16";
    }
};
