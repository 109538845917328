import { useTranslation } from "react-i18next";
import CalendarIcon from "assets/icons/calendar.svg?react";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { setActiveModal } from "store/modal/slice";
import { BookingModal } from "components/modal/BookingModal";
import { Modals } from "store/modal/types";
import { useCallback } from "react";

export const BookingButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
        dispatch(
            setActiveModal({
                id: "booking",
                type: Modals.Booking,
                component: <BookingModal />
            })
        );
    }, [dispatch]);

    return (
        <button type="button" className={styles["card"]} onClick={handleClick}>
            <CalendarIcon />
            <div>
                <p>{t("support:calendly-cta")}</p>
                <strong>{t("support:calendly-cta-strong")}</strong>
            </div>
        </button>
    );
};
