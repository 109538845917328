import useIntercom from "hooks/useIntercom";
import useHelpScout from "hooks/useHelpScout";
import { useCallback, useMemo } from "react";
import { HelpScoutCommand } from "helpers/helpscout";
import { IntercomCommand } from "helpers/intercom";
import { exists } from "helpers/booleans";

export const useSupportSystem = (boot: boolean = false) => {
    const intercom = useIntercom(boot);
    const helpscout = useHelpScout(boot);

    const supportSystemCommand = useCallback(
        (
            command: IntercomCommand | HelpScoutCommand,
            options?: any,
            additionalOptions?: any
        ) => {
            intercom && intercom(command as IntercomCommand, options);
            helpscout &&
                helpscout(
                    command as HelpScoutCommand,
                    options,
                    additionalOptions
                );
        },
        [intercom, helpscout]
    );

    const isSupportAvailable = useMemo(() => {
        return exists(intercom) || exists(helpscout);
    }, [intercom, helpscout]);

    return { isSupportAvailable, supportSystemCommand };
};
