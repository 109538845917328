import { BaseThumbnail } from "../BaseThumbnail";
import styles from "./index.module.scss";

export const EmptyThumbnail = () => {
    return (
        <BaseThumbnail>
            <div className={styles["empty-thumbnail"]}></div>
        </BaseThumbnail>
    );
};
