import { useEffect, useRef } from "react";

export interface RunOnceReturn {
    /** Resets the run count */
    reset: () => void;
}

/**
 * Runs the callback function once when the condition is met
 * @param callback function to be executed once
 * @param when if true the callback will be executed. Defaults to true to run callback on mount.
 */
export const useRunOnce = (
    callback: () => void,
    when: boolean = true
): RunOnceReturn => {
    const ran = useRef<boolean>(false);

    useEffect(() => {
        if (when && !ran.current) {
            callback();
            ran.current = true;
        }
    }, [callback, when]);

    const reset = () => {
        ran.current = false;
    };

    return { reset };
};
