import React from "react";
import CloseIcon from "assets/icons/close.svg?react";
import styles from "./index.module.scss";
import { MessageContentState } from "../types";
import { exists } from "helpers/booleans";
import { BannerContent } from "./BannerContent";

export interface BannerButtonProps {
    label: string;
    onClick: () => void;
    type:
        | "btn-primary"
        | "btn-outline-primary"
        | "btn-link"
        | "btn-danger"
        | "btn-outline-danger";
    className?: string;
}

export interface BannerProps {
    header?: string;
    hide?: boolean;
    setHide?: (state: boolean) => void;
    subheader?: string;
    canClose?: boolean;
    cssClass?: string;
    icon?: React.ReactNode;
    /** This seems to be only used for one specific use case. Leaving in but considering it deprecated */
    bannerContentMessage?: MessageContentState;
    buttonProps?: BannerButtonProps;
}

export const Banner: React.FC<BannerProps> = ({
    header,
    subheader,
    hide,
    setHide,
    canClose,
    cssClass,
    icon = <></>,
    bannerContentMessage,
    buttonProps
}) =>
    !hide && (
        <div
            className={`${styles["banner-container"]} ${
                cssClass ? styles[`banner-${cssClass}`] : ""
            }`}
        >
            <div
                className={`${styles["banner"]} ${
                    !canClose && buttonProps ? styles["wrap"] : ""
                }`}
            >
                <div className={styles["icon-text-container"]}>
                    {icon}
                    {exists(bannerContentMessage) ? (
                        <BannerContent messageProps={bannerContentMessage} />
                    ) : (
                        <span className={styles["message"]}>
                            {header} <br></br>
                            <small>{subheader ? subheader : ""}</small>
                        </span>
                    )}
                </div>
                {buttonProps && (
                    <button
                        className={`btn ${buttonProps.type} ${
                            styles[buttonProps.className] ?? ""
                        }`}
                        type="button"
                        onClick={buttonProps.onClick}
                    >
                        {buttonProps.label}
                    </button>
                )}
                {canClose && (
                    <button
                        className={styles["close-btn"]}
                        onClick={() => setHide(true)}
                    >
                        <CloseIcon />
                    </button>
                )}
            </div>
        </div>
    );
