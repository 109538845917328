export const getResultsFromPromiseAllSettled = <T>(
    results: PromiseSettledResult<T>[]
) => {
    return results.reduce(
        (memo, currentResult) => {
            return {
                fulfilled:
                    currentResult.status === "fulfilled"
                        ? [...memo.fulfilled, currentResult]
                        : memo.fulfilled,
                rejected:
                    currentResult.status === "rejected"
                        ? [...memo.rejected, currentResult]
                        : memo.rejected
            };
        },
        {
            fulfilled: [] as PromiseFulfilledResult<T>[],
            rejected: [] as PromiseRejectedResult[]
        }
    );
};

/**
 * Moves an item in an array and returns a modified clone of the array with new order
 * @param array Array to rearrange
 * @param from index of item to move
 * @param to index of new position
 * @returns modified clone of original array
 */
export const move = <T>(array: T[], from: number, to: number) => {
    const clone = [...array];
    clone.splice(to, 0, clone.splice(from, 1)[0]);
    return clone;
};
