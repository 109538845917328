import React from "react";
import { AssetCard } from "./AssetCard";
import { Asset } from "@switcherstudio/switcher-api-client";
import styles from "./AssetCardList.module.scss";

interface AssetCardListtProps {
    assets: Asset[];
    selectedAssets?: Asset[];
    selectMode?: boolean;
    size?: string;
    onClick: (asset: Asset) => void;
}

export const AssetCardList: React.FC<AssetCardListtProps> = ({
    assets,
    selectedAssets,
    selectMode,
    size,
    onClick
}: AssetCardListtProps) => {
    return (
        <>
            <div
                className={`${styles["asset-card-container"]} ${
                    size ? styles[size] : ""
                }`}
            >
                {assets?.map((a) => {
                    const selected = selectedAssets?.some(
                        (sa) => sa.Id === a.Id
                    );
                    return (
                        <AssetCard
                            key={a.Id}
                            asset={a}
                            selected={selected}
                            selectMode={selectMode}
                            onClick={onClick}
                            size={size}
                        />
                    );
                })}
            </div>
        </>
    );
};
