import { exists } from "./booleans";

export interface CreateUrlOptions {
    pathname?: string;
    searchParams?: { [key: string]: string | number } | undefined[];
    /** Flag to allow null or undefined values to be passed into search params */
    allowNull?: boolean;
}

/**
 * A wrapper to easily construct a URL object - see https://developer.mozilla.org/en-US/docs/Web/API/URL
 * @param base origin for new url - ex. "https://github.com"
 * @param options
 */
export const createUrl = (
    base: string,
    { pathname, searchParams, allowNull }: CreateUrlOptions = {
        pathname: "/",
        searchParams: [],
        allowNull: false
    }
): URL => {
    let url = new URL(base);

    url.pathname = pathname.startsWith("/") ? pathname : "/" + pathname;

    if (exists(searchParams)) {
        for (const [key, value] of Object.entries(searchParams)) {
            if (value) {
                url.searchParams.append(key, value.toString());
            } else if (allowNull) {
                url.searchParams.append(key, "null");
            }
        }
    }

    return url;
};

export const removeQueryParams = (
    pathname: string = window.location.pathname
) => {
    window.history.replaceState(null, "", pathname);
};
