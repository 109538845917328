import React from "react";
import styles from "./index.module.scss";

interface ColorPickerProps {
    label?: string;
    id: string;
    value: string | number | string[] | undefined;
    allowText?: boolean;
    error?: string;
    help?: string;
    horizontal?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /** this style variation is congruent with the new styles, but it's
     * disabled on the brand profile/kit until that's reworked! */
    variation?: boolean;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
    label,
    id,
    value,
    allowText,
    error,
    help,
    horizontal,
    readonly,
    disabled = false,
    onChange,
    variation = true
}: ColorPickerProps) => {
    return (
        <>
            <div className={`form-group ${horizontal ? "row" : ""}`}>
                <label
                    className={`${horizontal ? "col-xl-2 col-form-label" : ""} ${!variation ? styles["label"] : ""}`}
                    htmlFor={id}
                >
                    {label}
                </label>
                {!variation && <hr className={styles["hr"]} />}
                <div className={`${horizontal ? "col-xl-10" : ""}`}>
                    <div className={`${styles["input-holder"]}`}>
                        <input
                            type="color"
                            className={`form-control ${styles["color-input"]}`}
                            id={id}
                            value={value || ""}
                            onChange={onChange}
                            disabled={disabled}
                            readOnly={readonly}
                        />
                        {allowText ? (
                            <input
                                type="text"
                                className={`form-control ${styles["text-input"]}`}
                                id={id}
                                value={value || ""}
                                onChange={onChange}
                                disabled={disabled}
                                readOnly={readonly}
                            />
                        ) : null}
                    </div>
                    <small
                        id={`${id}-help`}
                        aria-describedby={id}
                        className={`form-text ${
                            error ? "text-danger" : "text-muted"
                        }`}
                    >
                        {error ?? help}
                    </small>
                </div>
            </div>
        </>
    );
};
