import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { useClaimCheck } from "hooks/useClaimCheck";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";
import { PasswordGatingToggle } from "components/inputs/toggle/PasswordGatingToggle";
import { useCatalogData } from "hooks/useCatalogData";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
    Catalog,
    CatalogRequest,
    CatalogResponse
} from "@switcherstudio/switcher-api-client";
import { useCatalogSubscriptionPass } from "views/page-content/CatalogPage/CatalogSubscriptionPage/useCatalogSubscriptionPass";
import { StripeConnectCard } from "components/cards/StripeConnectButton";
import { RecurringPassField } from "components/modal/GatedContentModal/RecurringPassField";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { Toggle } from "components/inputs/toggle/Toggle";

export const CatalogSubscriptionPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("subscription-page");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");
    const hasPasswordProtectedContentClaim = useClaimCheck(
        "gatedcontent.password"
    );
    const hasEmailProtectedContentClaim = useClaimCheck("gatedcontent.email");

    const { userInfo } = useSelector((state: RootState) => state.user);

    const {
        catalogData: catalog,
        updateCatalog,
        loading
    } = useCatalogData({
        projectId: userInfo?.ProjectId
    });

    const { isEmailGatingEnabled, isPasswordGatingEnabled, password } =
        useMemo(() => {
            if (!catalog?.Details) return {};

            return {
                isEmailGatingEnabled: catalog?.Details?.IsEmailGatingEnabled,
                isPasswordGatingEnabled:
                    catalog?.Details?.IsPasswordGatingEnabled,
                password: catalog?.Details?.Password ?? ""
            };
        }, [catalog?.Details]);

    const [isEmailGatingEnabledLocal, setIsEmailGatingEnabledLocal] =
        useState(null);
    const [isPasswordGatingEnabledLocal, setIsPasswordGatingEnabledLocal] =
        useState(null);
    const [passwordLocal, setPasswordLocal] = useState(null);
    const [hasPasswordValidationError, setHasPasswordValidationError] =
        useState(false);

    useEffect(() => {
        if (!catalog?.Details) return;

        setIsEmailGatingEnabledLocal(
            catalog?.Details?.IsEmailGatingEnabled ?? false
        );
        setIsPasswordGatingEnabledLocal(
            catalog?.Details?.IsPasswordGatingEnabled ?? false
        );
        setPasswordLocal(catalog?.Details?.Password ?? "");
    }, [catalog?.Details]);

    const {
        loading: subscriptionLoading,
        details,
        gatedContentStatus,
        isSetup,
        values,
        priceErrors,
        createOrUpdate,
        handlePriceChange,
        recurringPassHasNoChanges,
        entitlementAndPrices,
        handleSubmit,
        catalogHasPrice
    } = useCatalogSubscriptionPass({ catalog });

    const validateFields = useCallback(() => {
        const length = passwordLocal?.length;
        // Set validation error if password is empty or over 256 characters
        if (isPasswordGatingEnabledLocal && (!length || length > 256)) {
            setHasPasswordValidationError(true);
            return false;
        }

        setHasPasswordValidationError(false);
        return true;
    }, [isPasswordGatingEnabledLocal, passwordLocal]);

    const createCatalogRequest = useCallback(
        (catalog: CatalogResponse) => {
            const fieldsAreValid = validateFields();
            if (!fieldsAreValid) return;

            const details = catalog?.Details as unknown as Catalog;
            const apiObject: CatalogRequest = {
                Catalog: {
                    ...details,
                    IsEmailGatingEnabled: isEmailGatingEnabledLocal,
                    IsPasswordGatingEnabled: isPasswordGatingEnabledLocal,
                    Password: passwordLocal
                }
            };

            return apiObject;
        },
        [
            isEmailGatingEnabledLocal,
            isPasswordGatingEnabledLocal,
            passwordLocal,
            validateFields
        ]
    );

    const onSubmit = useCallback(() => {
        const catalogRequest = createCatalogRequest(catalog);

        updateCatalog([catalog?.Details?.Id, catalogRequest]);

        if (!recurringPassHasNoChanges) handleSubmit();
    }, [
        catalog,
        createCatalogRequest,
        updateCatalog,
        handleSubmit,
        recurringPassHasNoChanges
    ]);

    const onToggleEmail = useCallback(
        (state: boolean | ((prevState: boolean) => boolean)) => {
            setIsEmailGatingEnabledLocal(state);
        },
        [setIsEmailGatingEnabledLocal]
    );

    const subtitleKey = useMemo(() => {
        if (hasPasswordProtectedContentClaim && hasEmailProtectedContentClaim) {
            return t("subscription-page:subscription-options-all");
        } else if (hasPasswordProtectedContentClaim) {
            return t("subscription-page:subscription-options-password");
        } else if (hasEmailProtectedContentClaim) {
            return t("subscription-page:subscription-options-email");
        } else {
            return t("subscription-page:subscription-options-subtitle");
        }
    }, [hasPasswordProtectedContentClaim, hasEmailProtectedContentClaim, t]);

    usePageHeader({
        title:
            hasEmailProtectedContentClaim || hasPasswordProtectedContentClaim
                ? t("subscription-page:gating-options")
                : t("subscription-page:subscription-options"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("subscription-page:subscription-options")
        ],
        subTitle: <Trans i18nKey={t(subtitleKey)} />
    });

    const submitDisabled = useMemo(() => {
        if (!catalog?.Details) return true;

        const emailGatingHasChanged =
            isEmailGatingEnabledLocal !== isEmailGatingEnabled;
        const passwordGatingHasChanged =
            isPasswordGatingEnabledLocal !== isPasswordGatingEnabled;
        const passwordHasChanged = passwordLocal !== password;

        return (
            !emailGatingHasChanged &&
            !passwordGatingHasChanged &&
            !passwordHasChanged
        );
    }, [
        catalog?.Details,
        isEmailGatingEnabledLocal,
        isEmailGatingEnabled,
        isPasswordGatingEnabledLocal,
        isPasswordGatingEnabled,
        passwordLocal,
        password
    ]);

    const shouldShowStripeSection = useMemo(
        () => !subscriptionLoading && !loading && (!isSetup || values),
        [subscriptionLoading, loading, isSetup, values]
    );

    useBeforeUnload(
        (isSetup && !recurringPassHasNoChanges) || !submitDisabled,
        null,
        true
    );

    const saveButton = useMemo(() => {
        return (
            <Button
                type="primary"
                isSubmit
                onClick={onSubmit}
                disabled={recurringPassHasNoChanges && submitDisabled}
            >
                {t("buttons:save-changes")}
            </Button>
        );
    }, [recurringPassHasNoChanges, submitDisabled, onSubmit, t]);

    if (loading) return;

    return (
        <>
            {hasGatedContentAccess && catalog ? (
                <>
                    <div className={styles["subscription-options"]}>
                        {hasPasswordProtectedContentClaim && (
                            <PasswordGatingToggle
                                isEnabled={isPasswordGatingEnabledLocal}
                                setIsEnabled={setIsPasswordGatingEnabledLocal}
                                password={passwordLocal}
                                setPassword={setPasswordLocal}
                                hasEmailOrPurchaseGating={
                                    isEmailGatingEnabledLocal || catalogHasPrice
                                }
                                hasValidationError={hasPasswordValidationError}
                            />
                        )}
                        {hasEmailProtectedContentClaim && (
                            <div className={styles["email-section"]}>
                                <Toggle
                                    on={
                                        catalogHasPrice ||
                                        isEmailGatingEnabledLocal
                                    }
                                    label={t(
                                        "catalog-subscription-page:email-section.email-gating-label"
                                    )}
                                    reverseLayout
                                    onToggle={onToggleEmail}
                                    disabled={catalogHasPrice}
                                />
                            </div>
                        )}
                    </div>

                    {!isSetup &&
                        (hasEmailProtectedContentClaim ||
                            hasPasswordProtectedContentClaim) &&
                        saveButton}

                    {shouldShowStripeSection && (
                        <div className={styles["stripe-container"]}>
                            {!isSetup && (
                                <StripeConnectCard
                                    details={details}
                                    gatedContentStatus={gatedContentStatus}
                                    variant="Catalog"
                                />
                            )}
                            {isSetup && values && (
                                <RecurringPassField
                                    errors={priceErrors}
                                    type={createOrUpdate}
                                    values={values}
                                    variant="catalog"
                                    onChange={handlePriceChange}
                                    creatorProductId={
                                        entitlementAndPrices
                                            ?.ProductEntitlements?.[0]
                                            ?.ProductId
                                    }
                                />
                            )}
                        </div>
                    )}

                    {isSetup && saveButton}
                </>
            ) : (
                <StudioUpgradeCta />
            )}
        </>
    );
};
