import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { useInterval } from "hooks/useInterval";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useNavigate } from "react-router-dom";
import {
    AssetsUsage,
    Asset,
    AssetType
} from "@switcherstudio/switcher-api-client";
import { Client, client } from "api/client";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { AssetCardList } from "./AssetCardList";
import FilterIcon from "assets/icons/filter.svg?react";
import DownIcon from "assets/icons/down-arrow.svg?react";
import UpIcon from "assets/icons/up-arrow.svg?react";
import AddIcon from "assets/icons/add.svg?react";
import TrashIcon from "assets/icons/trash.svg?react";
import natsort from "natsort";
import styles from "./CloudPage.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import rollbar from "helpers/rollbar";
import { DisabledFeature } from "components/disabled-feature/DisabledFeature";
import { useHasAccess } from "hooks/useHasAccess";
import { openConfirmation } from "store/confirmation/slice";
import {
    tagParser,
    getAssetsByTagAndOrientation,
    getUserAssetsAndCheckForAudio
} from "helpers/assetHelper";
import { getAspectRatioString } from "./upload/upload-helpers/helpers";
import { AspectRatio } from "./upload/types";
import { PillSlideSelector } from "components/pill-slide-selector/PillSlideSelector";
import { ScrollToTop } from "components/scroll-to-top/ScrollToTop";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import { ContactSalesModal } from "components/modal/ContactSalesModal";
import { Modals } from "store/modal/types";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Link } from "react-router-dom";

const BACKOFF_MIN = 3;
const BACKOFF_MAX = 12;
const BACKOFF_EXPONENT = 1.5;

type FilterKey = "All" | "Image" | "Video";
type SortKey = "Name" | "InsertedAt" | "Size";

export const CloudPage: React.FC = () => {
    const _client = new Client({ showLoading: false });
    const isStudioUser = useHasPlanRole("Studio");
    const isGrowUser = useHasPlanRole("Grow");

    const { t } = useTranslation();

    const [filterOpts] = useState([
        { name: t("switcher-cloud:all"), value: "All" },
        { name: t("switcher-cloud:images"), value: "Image" },
        { name: t("switcher-cloud:videos"), value: "Video" }
    ]);
    const [sortOpts] = useState([
        { name: t("switcher-cloud:name"), value: "Name" },
        { name: t("switcher-cloud:date"), value: "InsertedAt" },
        { name: t("switcher-cloud:size"), value: "Size" }
    ]);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const isMounted = useIsMountedRef();
    const { userInfo } = useSelector((state: RootState) => state.user);
    const [assets, setAssets] = useState<Asset[]>([]);
    const [assetsUsage, setAssetsUsage] = useState<AssetsUsage>();
    const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
    const [artwork, setArtwork] = useState<{ tag: string; assets: Asset[] }[]>(
        []
    );
    const [displayedRawFiles, setDisplayedRawFiles] = useState<Asset[]>([]);
    const [rawFilesFilterKey, setRawFilesFilterKey] =
        useState<FilterKey>("All");
    const [rawFilesSortKey, setRawFilesSortKey] = useState<SortKey>("Name");
    const [artworkSortKey, setArtworkSortKey] = useState<SortKey>("Name");
    const [rawFilesSortByDesc, setRawFilesSortByDesc] =
        useState<boolean>(false);
    const [artworkSortByDesc, setArtworkSortByDesc] = useState<boolean>(false);
    const [selectMode, setSelectMode] = useState<boolean>(false);
    const [retryCount, setRetryCount] = useState<number>(0);
    const [tags, setTags] = useState<string[]>([]);
    const [delay, setDelay] = useState<number>(3000);
    const [orientation, setOrientation] = useState<string>(
        AspectRatio.horizontal
    );
    const hasAccess = useHasAccess(
        (userInfo) => userInfo.FeatureClaims?.indexOf("cloudassets") !== -1
    );

    const maxAssets = userInfo?.FeatureLimitClaims?.["cloudassets:maxcount"];

    // Poll for updates if any assets do not have an "Uploaded" status,
    // and do not have a ThumbnailUrl set.
    const interval = useInterval(async () => {
        if (assets.length && hasAccess) {
            const assetsNotUploaded = assets.filter(
                (a) =>
                    !(
                        (a.Status as any) === "Uploaded" &&
                        a.ThumbnailUrl !== null
                    )
            );

            if (assetsNotUploaded?.length > 0) {
                const assetPromises = assetsNotUploaded.map(
                    async (assetNotUploaded) => {
                        return await _client.userAssets_GetUserAsset(
                            userInfo?.UserId,
                            assetNotUploaded?.Id
                        );
                    }
                );

                const assets = await Promise.all(assetPromises);

                if (isMounted.current) {
                    setAssets((prevState) => {
                        // Only re-render the changed assets.
                        return prevState.map((ps) => {
                            const newAsset = assets.find((a) => a.Id === ps.Id);
                            if (
                                (ps.Status as any) === "Uploaded" &&
                                ps.ThumbnailUrl !== null
                            ) {
                                return ps;
                            }

                            return {
                                ...ps,
                                ...newAsset,
                                Meta: {
                                    ...ps.Meta,
                                    AspectRatioStrings: getAspectRatioString(
                                        ps.AspectRatios
                                    )
                                }
                            };
                        });
                    });

                    setRetryCount((r) => (r += 1));

                    if (retryCount >= BACKOFF_MIN) {
                        setDelay((d) => (d *= BACKOFF_EXPONENT));
                    }

                    if (retryCount >= BACKOFF_MAX) {
                        clearInterval(interval);
                    }
                }
            }
        }
    }, delay);

    // If all assets have been uploaded, clear the setInterval.
    useEffect(() => {
        const allAssetsUploaded = assets.every(
            (a) => (a.Status as any) === "Uploaded" && a.ThumbnailUrl !== null
        );

        if (!hasAccess || (assets.length && allAssetsUploaded)) {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [assets, interval, hasAccess]);

    const refreshAssets = useCallback(async () => {
        try {
            if (userInfo?.UserId) {
                const assets = await getUserAssetsAndCheckForAudio(
                    userInfo?.UserId
                );

                // DEVNOTE: This is a potentially temporary removal to help with assets performance
                // const assetsUsage =
                //     await client.userAssetsUsage_GetUserAssetsUsage(
                //         userInfo?.UserId
                //     );

                if (isMounted.current) {
                    setAssets(
                        assets.map((a) => {
                            return {
                                ...a,
                                Meta: {
                                    ...a.Meta,
                                    AspectRatioStrings: getAspectRatioString(
                                        a.AspectRatios
                                    )
                                }
                            };
                        })
                    );
                    setAssetsUsage({
                        TotalAssets: assets.filter((asset) => {
                            if (asset.Type != AssetType._2)
                                return !(asset.RequiredByAssetIds?.length > 0);
                            else return true;
                        }).length,
                        MaxAssets: maxAssets
                    });
                    setTags(["", ...tagParser(assets)]);
                }
            }
        } catch (e) {
            rollbar.error("Error refreshing assets", e);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "errors:asset-error"
                })
            );
        }
    }, [dispatch, isMounted, userInfo, maxAssets]);

    useEffect(() => {
        if (isMounted.current && hasAccess) {
            refreshAssets();
        }
    }, [refreshAssets, isMounted, hasAccess]);

    useEffect(() => {
        const filtered: Asset[] = assets.filter(
            (a) => (a.Type as any) === "Art"
        );
        let sorter = natsort({ desc: artworkSortByDesc, insensitive: true });
        filtered.sort((a, b) =>
            sorter(a[artworkSortKey] || "", b[artworkSortKey] || "")
        );
        setArtwork(getAssetsByTagAndOrientation(tags, orientation, filtered));
    }, [assets, artworkSortKey, artworkSortByDesc, tags, orientation]);

    useEffect(() => {
        const filtered = assets
            .filter((a) => (a.Type as any) !== "Art")
            .filter((a) => {
                if (rawFilesFilterKey === "All") return a;
                return (a.Type as any) === rawFilesFilterKey;
            });

        let sorter = natsort({ desc: rawFilesSortByDesc, insensitive: true });
        filtered.sort((a, b) =>
            sorter(a[rawFilesSortKey] || "", b[rawFilesSortKey] || "")
        );
        setDisplayedRawFiles(filtered);
    }, [assets, rawFilesFilterKey, rawFilesSortKey, rawFilesSortByDesc, tags]);

    const toggleSelectMode = useCallback(() => {
        if (selectMode === false) {
            setSelectedAssets([]);
        }

        setSelectMode(!selectMode);
    }, [selectMode]);

    const onClickAsset = useCallback(
        (asset: Asset) => {
            if (selectMode) {
                if ((asset?.RequiredByAssetIds?.length || 0) > 0) {
                    return;
                }

                const inSelectedAssets = selectedAssets?.find(
                    (sa) => sa.Id === asset.Id
                );
                if (inSelectedAssets) {
                    setSelectedAssets((prevState) =>
                        prevState?.filter((sa) => sa.Id !== asset.Id)
                    );
                } else {
                    setSelectedAssets(selectedAssets?.concat(asset));
                }
            } else {
                if ((asset.Type as any) === "Art") {
                    navigate(
                        `/switcher-cloud/asset/${encodeURIComponent(asset.Id)}`
                    );
                } else {
                    navigate(
                        `/switcher-cloud/raw-file/${encodeURIComponent(
                            asset.Id
                        )}`
                    );
                }
            }
        },
        [navigate, selectMode, selectedAssets]
    );

    const deleteBatch = useCallback(async () => {
        if (selectedAssets.length > 0) {
            dispatch(
                openConfirmation({
                    message: "messages:delete-confirmation",
                    messageOptions: { number: selectedAssets.length },
                    onSuccess: async () => {
                        try {
                            const assetsRemoved =
                                await client.userAssets_DeleteUserAssetsAll(
                                    userInfo?.UserId || "",
                                    selectedAssets
                                );

                            // DEVNOTE: These are potentially temporary removals to help with assets performance

                            // const assets =
                            //     await client.userAssets_GetUserAssets(
                            //         userInfo?.UserId || ""
                            //     );

                            // const assetsUsage =
                            //     await client.userAssetsUsage_GetUserAssetsUsage(
                            //         userInfo?.UserId || ""
                            //     );

                            if (isMounted.current) {
                                setAssets(
                                    assets
                                        .filter((a) => {
                                            return !assetsRemoved.some(
                                                (ar) => ar.Id === a.Id
                                            );
                                        })
                                        .map((a) => {
                                            return {
                                                ...a,
                                                Meta: {
                                                    ...a.Meta,
                                                    AspectRatioStrings:
                                                        getAspectRatioString(
                                                            a.AspectRatios
                                                        )
                                                }
                                            };
                                        })
                                );
                                setAssetsUsage({
                                    TotalAssets: assets.filter((asset) => {
                                        if (asset.Type != AssetType._2)
                                            return !(
                                                asset.RequiredByAssetIds
                                                    ?.length > 0
                                            );
                                        else return true;
                                    }).length,
                                    MaxAssets: maxAssets
                                });
                                setTags(["", ...tagParser(assets)]);
                                setSelectMode(false);
                            }
                        } catch (err) {
                            rollbar.error("Error deleting assets batch", err);
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:delete-error"
                                })
                            );
                        }
                    }
                })
            );
        }
    }, [dispatch, userInfo, isMounted, selectedAssets, maxAssets, assets]);

    const goToUpload = () => {
        navigate(`/switcher-cloud/upload`);
    };

    const onFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRawFilesFilterKey(e.target.value as FilterKey);
    };

    const onRawFilesSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRawFilesSortKey(e.target.value as SortKey);
    };

    const onArtworkSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setArtworkSortKey(e.target.value as SortKey);
    };

    const toggleArtworkSortDirection = useCallback(() => {
        setArtworkSortByDesc(!artworkSortByDesc);
    }, [artworkSortByDesc]);

    const toggleRawFilesSortDirection = useCallback(() => {
        setRawFilesSortByDesc(!rawFilesSortByDesc);
    }, [rawFilesSortByDesc]);

    const cloudFull = assetsUsage?.TotalAssets >= assetsUsage?.MaxAssets;
    const cloudTotal = assetsUsage?.TotalAssets || 0;
    const cloudMax = assetsUsage?.MaxAssets || 0;

    const assetLimitReachedText = useMemo(() => {
        return isStudioUser || isGrowUser
            ? t("switcher-cloud:asset-limit-reached-cloud-studio")
            : t("switcher-cloud:asset-limit-reached-cloud");
    }, [isStudioUser, t, isGrowUser]);

    const assetLimitApproachingText = useMemo(() => {
        return isStudioUser || isGrowUser
            ? t("switcher-cloud:asset-limit-approaching-cloud-studio")
            : t("switcher-cloud:asset-limit-approaching-cloud");
    }, [isStudioUser, t, isGrowUser]);

    const i18nKey = useMemo(() => {
        if (cloudFull) {
            return assetLimitReachedText;
        }

        if (cloudTotal / cloudMax >= 0.8) {
            return assetLimitApproachingText;
        }

        return "";
    }, [
        cloudFull,
        cloudTotal,
        cloudMax,
        assetLimitReachedText,
        assetLimitApproachingText
    ]);

    const translationsWithLinks = (
        <Trans
            i18nKey={i18nKey}
            components={{
                link1: (
                    <Link
                        to={"/subscription?#openModal"}
                        onClick={() => dispatch(closeCurrentModal())}
                        className={styles["link"]}
                    />
                ),
                link2: (
                    <span
                        onClick={() => {
                            dispatch(
                                setActiveModal({
                                    id: Modals.ContactSalesModal,
                                    type: Modals.ContactSalesModal,
                                    component: <ContactSalesModal />
                                })
                            );
                        }}
                        className={styles["link"]}
                    />
                )
            }}
        />
    );

    if (!hasAccess) return <DisabledFeature />;

    return (
        <>
            <div className="row">
                <div className="col-lg">
                    <div className={styles["control-bar"]}>
                        <div
                            className={`${styles["assets-usage-bar"]} mr-2 mb-2`}
                        >
                            <div>{translationsWithLinks}</div>
                            <ProgressBar
                                hideCount
                                maxValue={assetsUsage?.MaxAssets}
                                currentValue={assetsUsage?.TotalAssets}
                                variant={"cloud-assets"}
                            />
                            <div className="text-center">
                                {(assetsUsage?.MaxAssets || 0) -
                                    (assetsUsage?.TotalAssets || 0)}{" "}
                                {t("switcher-cloud:assets-remaining")}
                            </div>
                        </div>
                        <div className={`${styles["control-buttons"]} mb-2`}>
                            <button
                                className={`${styles["action-button"]} ${
                                    styles["trash-button"]
                                } btn btn-outline-default mr-1 ${
                                    selectMode ? styles["visible"] : ""
                                }`}
                                onClick={deleteBatch}
                            >
                                <span>
                                    <TrashIcon />
                                </span>
                            </button>
                            <button
                                title={t("switcher-cloud:upload-new-asset")}
                                className={`${styles["action-button"]} btn btn-outline-default mr-1`}
                                onClick={goToUpload}
                            >
                                <span>
                                    <AddIcon />
                                </span>
                            </button>
                            <button
                                className={`${styles["select-button"]} btn btn-grey md-btn`}
                                onClick={toggleSelectMode}
                            >
                                {selectMode
                                    ? t("buttons:cancel").toUpperCase()
                                    : t("buttons:select").toUpperCase()}
                            </button>
                        </div>
                    </div>
                    <h4>{t("switcher-cloud:my-assets")}</h4>
                    <div className={styles["control-bar"]}>
                        <div
                            className={`${styles["control-bar-controls"]} mb-2`}
                        >
                            <div
                                className={`${styles["control-selects"]} mb-2`}
                            >
                                <div
                                    className={`input-group mr-1 ${styles["select-group"]}`}
                                >
                                    <div
                                        className="input-group-prepend"
                                        onClick={() =>
                                            toggleArtworkSortDirection()
                                        }
                                    >
                                        <span
                                            className={
                                                styles["direction-button"]
                                            }
                                            id="icon"
                                        >
                                            {/* TODO: Change this icon */}
                                            {artworkSortByDesc ? (
                                                <DownIcon />
                                            ) : (
                                                <UpIcon />
                                            )}
                                        </span>
                                    </div>
                                    <select
                                        id="sort-select"
                                        className={styles.select}
                                        value={artworkSortKey}
                                        onChange={onArtworkSortChange}
                                    >
                                        {sortOpts.map(
                                            ({ name, value }, idx) => {
                                                return (
                                                    <option
                                                        key={idx}
                                                        value={value}
                                                    >
                                                        {name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                                <div
                                    className={`input-group mr-1 ${styles["select-group"]}`}
                                >
                                    <PillSlideSelector
                                        pills={[
                                            {
                                                text: t(
                                                    "switcher-cloud:aspect-ratio-horizontal"
                                                ),
                                                value: "horizontal"
                                            },
                                            {
                                                text: t(
                                                    "switcher-cloud:aspect-ratio-vertical"
                                                ),
                                                value: "vertical"
                                            }
                                        ]}
                                        selected={orientation}
                                        onChange={(value) =>
                                            setOrientation(value.toLowerCase())
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {artwork.map((a, i) => {
                        return (
                            <div key={"art" + i}>
                                {a.assets.length > 0 && <h5>{a.tag}</h5>}
                                <AssetCardList
                                    assets={a.assets}
                                    selectMode={selectMode}
                                    selectedAssets={selectedAssets}
                                    onClick={onClickAsset}
                                />
                            </div>
                        );
                    })}
                    <h4>{t("switcher-cloud:raw-files")}</h4>
                    <div className={`${styles["control-bar-controls"]} mb-2`}>
                        <div className={`${styles["control-selects"]} mb-2`}>
                            <div
                                className={`input-group mr-3 ${styles["select-group"]}`}
                            >
                                <div className="input-group-prepend">
                                    <span
                                        className={`${styles["direction-button"]} ${styles["filter-icon"]}`}
                                        id={"icon"}
                                    >
                                        <FilterIcon />
                                    </span>
                                </div>
                                <select
                                    id="filter-select"
                                    className={styles.select}
                                    value={rawFilesFilterKey}
                                    onChange={onFilterChange}
                                >
                                    {filterOpts.map(({ name, value }, idx) => {
                                        return (
                                            <option key={idx} value={value}>
                                                {name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div
                                className={`input-group mr-1 ${styles["select-group"]}`}
                            >
                                <div
                                    className="input-group-prepend"
                                    onClick={() =>
                                        toggleRawFilesSortDirection()
                                    }
                                >
                                    <span
                                        className={styles["direction-button"]}
                                        id="icon"
                                    >
                                        {/* TODO: Change this icon */}
                                        {rawFilesSortByDesc ? (
                                            <DownIcon />
                                        ) : (
                                            <UpIcon />
                                        )}
                                    </span>
                                </div>
                                <select
                                    id="sort-select"
                                    className={styles.select}
                                    value={rawFilesSortKey}
                                    onChange={onRawFilesSortChange}
                                >
                                    {sortOpts.map(({ name, value }, idx) => {
                                        return (
                                            <option key={idx} value={value}>
                                                {name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    {
                        <AssetCardList
                            assets={displayedRawFiles}
                            selectMode={selectMode}
                            selectedAssets={selectedAssets}
                            onClick={onClickAsset}
                        />
                    }
                </div>
            </div>
            <ScrollToTop />
        </>
    );
};
