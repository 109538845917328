import React, { useState, useEffect, useCallback, useRef } from "react";
import { formatBytes } from "../utils";
import { FileItem, AssetType } from "./types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { updateFileItem } from "store/cloud/slice";
import { audioThumbnailAsync } from "./upload-helpers/thumbnail-helpers";
import { setLoading } from "store/loading/slice";
import { useDebounce } from "hooks/useDebounce";
import { Checkbox } from "components/inputs/checkbox/Checkbox";
import { getAssetType } from "./upload-helpers/helpers";
import styles from "./AudioAsset.module.scss";
import commonStyles from "../AssetCard.module.scss";
import { AssetTag } from "components/asset-tag/AssetTag";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useTranslation } from "react-i18next";

interface AudioAssetProps {
    fileItem: FileItem;
    tags: string[];
}

export const AudioAsset: React.FC<AudioAssetProps> = ({ fileItem, tags }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [audioObjectUrl, setAudioObjectUrl] = useState<string>("");
    const audioRef = useRef<HTMLAudioElement>(null);
    const debouncedTag = useDebounce(fileItem.fileProps?.thumbnailTag, 500);
    const { t } = useTranslation();

    useEffect(() => {
        async function getVideoObjectUrl() {
            const url = URL.createObjectURL(fileItem.file);
            setAudioObjectUrl(url);
        }

        getVideoObjectUrl();
    }, [dispatch, fileItem.file]);

    useEffect(() => {
        async function updateThumbnail() {
            if (audioObjectUrl && audioRef.current) {
                dispatch(setLoading(1));
                const thumbnail = await audioThumbnailAsync(debouncedTag, 960);
                dispatch(
                    updateFileItem({
                        name: fileItem.name,
                        thumbnail: thumbnail
                    })
                );
                dispatch(setLoading(-1));
            }
        }

        updateThumbnail();
    }, [dispatch, fileItem.name, debouncedTag, audioObjectUrl]);

    const updateTag = useCallback(
        (tag: string) => {
            dispatch(
                updateFileItem({
                    ...fileItem,
                    fileProps: {
                        ...fileItem.fileProps,
                        tag
                    }
                })
            );
        },
        [dispatch, fileItem]
    );

    const toggle = useCallback(() => {
        dispatch(
            updateFileItem({
                name: fileItem.name,
                fileProps: {
                    ...fileItem.fileProps,
                    audioOnly: !fileItem.fileProps?.audioOnly
                }
            })
        );
    }, [dispatch, fileItem]);

    return (
        <>
            {fileItem && (
                <>
                    <div className={`${styles["asset-card"]} mb-2`}>
                        <div className={`${styles["asset-image"]}`}>
                            {fileItem.thumbnail && (
                                <img
                                    src={fileItem.thumbnail.dataURL}
                                    alt="Asset"
                                />
                            )}
                        </div>
                        <div className={`${styles["asset-video"]}`}>
                            {audioObjectUrl && (
                                <>
                                    <audio ref={audioRef}>
                                        <source
                                            src={audioObjectUrl}
                                            id={`${fileItem.name}-audio-source`}
                                        />
                                    </audio>
                                </>
                            )}
                        </div>
                        <div
                            className={`card-body ${commonStyles["asset-card-body"]}`}
                        >
                            <p
                                className={`${styles["asset-card-title"]} text-center mb-2`}
                            >
                                <strong>{fileItem.file.name}</strong>
                                <br></br>
                                {formatBytes(fileItem.file.size || 0)}
                            </p>
                            <TextInput
                                label={t("cloud:thumbnail-tag")}
                                id={`${fileItem.file.name}-tag`}
                                type="text"
                                value={fileItem.fileProps?.thumbnailTag}
                                onChange={(e) =>
                                    dispatch(
                                        updateFileItem({
                                            ...fileItem,
                                            fileProps: {
                                                ...fileItem.fileProps,
                                                thumbnailTag: e.target.value
                                            }
                                        })
                                    )
                                }
                            />
                            {getAssetType(fileItem.file.type) ===
                                AssetType.video && (
                                <Checkbox
                                    label="Audio Only"
                                    id={`${fileItem.file.name}-audio-only`}
                                    checked={fileItem.fileProps?.audioOnly}
                                    onChange={() => toggle()}
                                    customClass="mb-1"
                                />
                            )}
                            <AssetTag
                                tags={tags}
                                onChange={updateTag}
                                selectedTag={fileItem.fileProps?.tag}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
