import React from "react";
import {
    StripePaymentElementOptions,
    StripePaymentElementChangeEvent
} from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import styles from "./index.module.scss";

const PAYMENT_ELEMENT_OPTIONS: StripePaymentElementOptions = {};

export interface CardInputProps {
    onChange?: (event: StripePaymentElementChangeEvent) => void;
}

/**
 * An input element for a card. A wrapper for Stripe's CardElement without a submit or confirm button.
 * To use other forms of payment in the future, we might want to change to a PaymentElement - https://stripe.com/docs/payments/payment-element
 */
export const PaymentMethodInput: React.FC<CardInputProps> = ({ onChange }) => (
    <div className={`form-group ${styles["container"]}`}>
        <PaymentElement
            id="card-element"
            options={PAYMENT_ELEMENT_OPTIONS}
            onChange={onChange}
        />
    </div>
);
