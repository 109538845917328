import React from "react";
import { PlatformId } from "views/page-content/platforms/types";

interface NoAccessProps {
    platformId: PlatformId;
}

export const NoAccess: React.FC<NoAccessProps> = ({ platformId }) => {
    switch (platformId) {
        default:
            return <></>;
    }
};
