import styles from "./index.module.scss";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import classnames from "classnames/bind";
import { ComponentItem } from "./types";
const cx = classnames.bind(styles);

export interface ItemsListProps<T> {
    items: ComponentItem<T>[];
    allowItemClick?: boolean;
    checkBoxLocation?: "left" | "right";
    isMultiple?: boolean;
    handleSelectLocal?: (id: string, isSelected: boolean) => void;
    selectedIdSet?: Set<string>;
    previouslySelectedIds?: string[];
    allowUnselect?: boolean;
}

export function ItemsList<T>({
    items,
    allowItemClick = true,
    checkBoxLocation = "right",
    isMultiple = false,
    handleSelectLocal = () => {},
    previouslySelectedIds = [],
    selectedIdSet = new Set<string>(previouslySelectedIds),
    allowUnselect = true
}: ItemsListProps<T>) {
    return (
        <>
            {items
                ?.map(({ id, component, hideSelector = false }): JSX.Element => {
                    if (!id || !component) return null;
                    const wasPreviouslySelected =
                        !allowUnselect &&
                        isMultiple &&
                        previouslySelectedIds.includes(id);
                    if (wasPreviouslySelected) return null;

                    const isSelected = selectedIdSet.has(id);

                    return (
                        <div
                            className={cx("ms-card", {
                                "previously-selected": wasPreviouslySelected,
                                "checkbox-left": checkBoxLocation === "left",
                                "allow-item-click": allowItemClick
                            })}
                            key={id}
                            onClick={() =>
                                allowItemClick &&
                                handleSelectLocal(id, isSelected)
                            }
                        >
                            <div className={cx("ms-card-component")}>
                                {component}
                            </div>
                            {!hideSelector && (
                                <StyledCheckboxOrRadio
                                    type={isMultiple ? "checkbox" : "radio"}
                                    checked={isSelected}
                                    onChange={() =>
                                        handleSelectLocal(id, isSelected)
                                    }
                                    disabled={wasPreviouslySelected}
                                />
                            )}
                        </div>
                    );
                })}
        </>
    );
}
