import { ITicket, IUserLogin, GetUserOrgsPayload } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { OrganizationUserDefault } from "@switcherstudio/switcher-api-client";

const apiBaseUrl =
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net";

export const getToken = createAsyncThunk(
    "users/getToken",
    async ({ username, password }: IUserLogin) => {
        const formData = new URLSearchParams();

        formData.append("grant_type", "password");
        formData.append("username", username);
        formData.append("password", password);
        formData.append("client_id", "SilverSunnDashboard");

        let tokenRes: Response;
        let text: string;
        try {
            // Fetch the token
            tokenRes = await fetch(apiBaseUrl + "/Token", {
                method: "POST",
                body: formData
            });

            text = await tokenRes.text();
        } catch (err) {
            // If the fetch fails, throw a server error
            let error = new Error("Could not connect to server");
            error.name = "ServerError";
            throw error;
        }

        if (!tokenRes.ok) {
            let error = new Error(text);

            // Differentiate between server errors (5xx) and bad requests (4xx) in thrown errors
            if (tokenRes.status >= 500) {
                error.name = "ServerError";
            } else {
                error.name = "BadRequest";
            }

            throw error;
        }

        try {
            // Parse and typecast the response
            const ticket = JSON.parse(text) as ITicket;
            return ticket;
        } catch (err) {
            // If the response is not JSON, throw a server error
            let error = new Error("Could not parse response");
            error.name = "ServerError";
            throw error;
        }
    }
);

export const exchangeCode = createAsyncThunk(
    "user/exchangeCode",
    async (code: string) => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "authorization_code");
        formData.append("client_id", "SilverSunnDashboard");
        formData.append("code", code);

        const res = await fetch(`${apiBaseUrl}/token`, {
            method: "POST",
            body: formData
        });
        const text = await res.text();
        if (!res.ok) {
            throw new Error(text);
        }

        const ticket = JSON.parse(text) as ITicket;
        return ticket;
    }
);

export const getUserInfo = createAsyncThunk("users/getUserInfo", async () => {
    const { client } = await import("api/client");
    const userInfo = await client.account_GetUserInfo();

    return userInfo;
});

export const getUserOrgs = createAsyncThunk<
    GetUserOrgsPayload,
    void,
    {
        state: RootState;
    }
>("users/getUserOrgs", async (_, { getState }) => {
    const { client } = await import("api/client");
    const state = getState();
    const orgs = await client.userOrganizations_Index(
        state.user.ticket?.userId || ""
    );
    const payload: GetUserOrgsPayload = { orgs };

    if (state.user.userInfo?.OrganizationId) {
        const org = await client.organizations_GetOrganization(
            state.user.userInfo?.OrganizationId
        );
        payload.maximumDestinations = org.MaximumDestinations || 3;
        payload.maximumUsers = org.MaximumUsers || 10;
        payload.name = org.Name || "";
    }

    return payload;
});

export const refreshToken = createAsyncThunk<
    ITicket,
    void,
    {
        state: RootState;
    }
>("users/refreshToken", async (_, { getState }) => {
    const tokenUrl = apiBaseUrl + "/Token";
    const state = getState();
    const formData = new URLSearchParams();

    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", state.user.ticket.refresh_token);
    formData.append("client_id", "SilverSunnDashboard");

    const tokenRes = await fetch(tokenUrl, {
        method: "POST",
        body: formData
    });

    const text = await tokenRes.text();
    if (!tokenRes.ok) {
        throw new Error(text);
    }

    const ticket = JSON.parse(text) as ITicket;
    return ticket;
});

export const getDefaultUserOrg = createAsyncThunk<
    OrganizationUserDefault,
    void,
    {
        state: RootState;
    }
>("users/getDefaultUserOrg", async (_, { getState }) => {
    const { client } = await import("api/client");
    const state = getState();

    const defaultOrg = await client.userOrganizations_GetDefault(
        state.user.userInfo.UserId
    );

    return defaultOrg;
});
