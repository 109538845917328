import React from "react";
import styles from "../GatedContentTable.module.scss";
import { PricingModalTypes } from "../../../../../components/modal/GatedContentModal";
import { useTranslation } from "react-i18next";
import {
    CreatorProduct,
    StripeAccountsDetails
} from "@switcherstudio/switcher-api-client";
import { GatedContentDisabledTooltip } from "components/tooltips/GatedContentDisabledTooltip";
import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { GatedContentTableCell } from "./GatedContentTableCell";
import { sortByDate } from "helpers/time";
import { StripeConnectCard } from "components/cards/StripeConnectButton";

export interface GatedContentModalCallbacks {
    updateModal?: (
        arg0: boolean,
        arg1: PricingModalTypes,
        arg2?: CreatorProduct
    ) => void;
    updateDeleteModal?: (arg0: boolean, arg1: CreatorProduct) => void;
}

interface PricingTableProps extends GatedContentModalCallbacks {
    products: CreatorProduct[] | undefined;
    productSubscribers: number[];
    details: StripeAccountsDetails;
}

export const GatedContentTable: React.FC<PricingTableProps> = ({
    products,
    productSubscribers,
    updateModal,
    updateDeleteModal,
    details
}) => {
    const { t } = useTranslation();
    const { gatedContentStatus, loading } = useStripeAccountInfo();

    return (
        <div>
            <div className={styles["card-container"]}>
                <StripeConnectCard
                    details={details}
                    gatedContentStatus={gatedContentStatus}
                />
                {!loading && (
                    <GatedContentDisabledTooltip
                        gatedContentStatus={gatedContentStatus}
                        labelOrientation="bottom"
                    >
                        <button
                            className={`btn btn-primary ${styles["pass-btn"]}`}
                            type="button"
                            onClick={() =>
                                updateModal(true, PricingModalTypes.Create)
                            }
                            disabled={
                                gatedContentStatus !== GatedContentStatus.READY
                            }
                        >
                            {t("gated-content-page:create-btn")}
                        </button>
                    </GatedContentDisabledTooltip>
                )}
            </div>

            <div className="table-responsive">
                <table className={`${styles["price-table"]} table`}>
                    <thead className={styles["small"]}>
                        <tr>
                            <th>{t("gated-content-page:name")}</th>
                            <th>{t("gated-content-page:type")}</th>
                            <th>{t("gated-content-page:price")}</th>
                            <th>{t("gated-content-page:assignments")}</th>
                            <th>{t("gated-content-page:purchases")}</th>
                            <th>{t("gated-content-page:active-subs")}</th>
                            <th>{t("gated-content-page:created")}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {products
                            ?.sort((a, b) =>
                                sortByDate(a.CreatedAt, b.CreatedAt, {
                                    descending: true
                                })
                            )
                            .map((product, key) => (
                                <GatedContentTableCell
                                    product={product}
                                    subscribers={productSubscribers[product.Id]}
                                    updateModal={updateModal}
                                    updateDeleteModal={updateDeleteModal}
                                    key={key}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
