import React, { useState, useEffect, useCallback } from "react";

import { RootState } from "store/reducers";

import styles from "./ManageInventoryItemsPage.module.scss";

import { InventoryHeader } from "./InventoryHeader";
import { ItemTable } from "./ItemTable";
import { ItemGrid } from "./ItemGrid";
//import { SortDropdown } from "./SortDropdown";
import { TextInput } from "components/inputs/text-input/TextInput";
import { Select } from "components/inputs/select/Select";

import { useSelector, useDispatch } from "react-redux";
import { useIsResellerAdmin } from "hooks/useIsResellerAdmin";
import { useIsInfluencer } from "hooks/useIsInfluencer";
import { useGetResellerByUser } from "hooks/useGetResellerByUser";
import { useGetResellerInventory } from "hooks/useGetResellerInventory";
import { useGetResellerInventoryItems } from "hooks/useGetResellerInventoryItems";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { useTranslation } from "react-i18next";
import { ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { isMobile } from "utils/js-utils";
import { AppDispatch } from "store/store";
import { useParams } from "hooks/useParams";

export const ManageInventoryItemsPage: React.FC = () => {
    const { resellerInventoryId } = useParams();

    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const reseller = useGetResellerByUser(user);
    const inventory = useGetResellerInventory(
        reseller?.Id,
        resellerInventoryId
    );
    const inventoryItems = useGetResellerInventoryItems(
        reseller?.Id,
        inventory?.Id
    );
    const isResellerAdmin = useIsResellerAdmin(user);
    const isInfluencer = useIsInfluencer(user);

    useRedirectIfDisallowed(() => isResellerAdmin || isInfluencer);

    const [statusOptions] = useState<{ text: string; value: number }[]>(
        t("manage-inventories:status-options", { returnObjects: true })
    );
    const [searchValue, setSearchValue] = useState<string>("");
    const [filterByStatus, setFilterByStatus] = useState<number>(-1);
    const [displayedItems, setDisplayedItems] =
        useState<ResellerInventoryItem[]>();

    useEffect(() => {
        // filter displayedItems with filterByStatus and searchValue
        const filtered = inventoryItems?.filter((item) => {
            const matchesStatus =
                item.Status === filterByStatus || filterByStatus === -1;
            const matchesSearch = !searchValue.length
                ? true
                : item.RedeemedByUserEmail?.toLowerCase().indexOf(
                      searchValue.toLowerCase()
                  ) >= 0;

            return matchesStatus && matchesSearch && item.IsUnlimited === false;
        });

        setDisplayedItems(filtered);
    }, [filterByStatus, inventoryItems, searchValue]);

    const copyToClipboard = useCallback(
        async (toBeCopied: string) => {
            await navigator.clipboard.writeText(toBeCopied);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-success"
                })
            );
        },
        [dispatch]
    );

    return (
        <>
            {displayedItems && (
                <>
                    <InventoryHeader inventory={inventory} />
                    <hr />
                    <div className={`row ${styles["filter-bar"]}`}>
                        <div className="col-md-6">
                            <Select
                                label={t("manage-inventories:filter-by")}
                                id="inventory-status-select"
                                options={statusOptions}
                                optionLabel="text"
                                optionKey="value"
                                selected={filterByStatus}
                                onChange={(e) =>
                                    setFilterByStatus(parseInt(e.target.value))
                                }
                            />
                        </div>
                        <div className="col-md-6">
                            {isResellerAdmin && (
                                <TextInput
                                    id="searchEmail"
                                    type="text"
                                    value={searchValue}
                                    label={t(
                                        "manage-inventories:search-by-email"
                                    )}
                                    placeholder={t("manage-inventories:email")}
                                    readonly={false}
                                    onChange={(e) =>
                                        setSearchValue(e.target.value)
                                    }
                                />
                            )}
                        </div>
                    </div>

                    {isMobile() ? (
                        <>
                            {/* <SortDropdown
                isResellerAdmin={isResellerAdmin}
                sortByColumn={sortByColumn}
              /> */}
                            <ItemGrid
                                displayedItems={displayedItems}
                                isResellerAdmin={isResellerAdmin}
                                copyToClipboard={copyToClipboard}
                            />
                        </>
                    ) : (
                        <ItemTable
                            displayedItems={displayedItems}
                            isResellerAdmin={isResellerAdmin}
                            copyToClipboard={copyToClipboard}
                        />
                    )}
                </>
            )}
        </>
    );
};
