import { useMemo } from "react";
import { Card } from "../Card";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { displayAmount } from "helpers/stripe";
import { formatTimeDuration } from "helpers/time";
import { AnalyticsUnitTypes } from "views/page-content/analytics/types";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

export interface GraphCardProps {
    /** header text of card */
    title: string;
    /** Total to be displayed */
    total: number;
    /** the component containing the graph */
    graph: JSX.Element;
    /** Available actions */
    actions?: JSX.Element[];
    /** A component to handle reordering */
    handle?: JSX.Element;
    /** Provides props for a drag handle */
    dragHandleProps?: DraggableProvidedDragHandleProps;
    /** Unit to display total as */
    unit?: AnalyticsUnitTypes;
    /** Sets loading state */
    loading?: boolean;
}

/**
 * A card for displaying analytics graphs.
 */
export const GraphCard: React.FC<GraphCardProps> = ({
    title,
    total,
    graph,
    actions = [],
    handle = <></>,
    dragHandleProps,
    unit = "number",
    loading = false
}) => {
    const { i18n } = useTranslation();

    const formattedTotal = useMemo(() => {
        switch (unit) {
            case "time":
                return formatTimeDuration(total);
            case "dollars":
                return displayAmount(total, {
                    locale: i18n.language,
                    signed: true,
                    roundUp: false
                });
            case "number":
            default:
                return Intl.NumberFormat(i18n.language).format(total);
        }
    }, [i18n.language, total, unit]);

    return (
        <Card position="foreground" className={styles["container"]}>
            <div className={styles["card"]}>
                <div className={styles["side"]} {...dragHandleProps}>
                    <div className={styles["side-top"]}>
                        <p>{title}</p>
                        <h2>{total >= 0 && !loading ? formattedTotal : ""}</h2>
                        <div
                            className={`${styles["actions"]} ${styles["actions-desktop"]}`}
                        >
                            {actions.map((a) => (
                                <div key={a.key}>{a}</div>
                            ))}
                        </div>
                    </div>
                    {handle && (
                        <span className={styles["handle"]}>{handle}</span>
                    )}
                </div>
                <div className={styles["graph"]}>{graph}</div>
                <div
                    className={`${styles["actions"]} ${styles["actions-mobile"]}`}
                >
                    {actions.map((a) => (
                        <div key={a.key}>{a}</div>
                    ))}
                </div>
            </div>
        </Card>
    );
};
