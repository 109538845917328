import React, { useCallback, useEffect, useRef, useState } from "react";
import { client } from "api/client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import styles from "./BrandProfileStyles.module.scss";
import { useTranslation } from "react-i18next";
import {
    AspectRatio,
    AssetLayering,
    AssetType,
    FileItem
} from "../cloud/upload/types";
import { RootState } from "store/reducers";
import {
    assemblePutOptions,
    fileReaderAsync,
    uploadAssetToStorage
} from "../cloud/upload/upload-helpers/helpers";
import { imageThumbnailAsync } from "../cloud/upload/upload-helpers/thumbnail-helpers";
import rollbar from "helpers/rollbar";
import AddIcon from "assets/icons/add.svg?react";
import { Asset } from "@switcherstudio/switcher-api-client";
import { Spinner } from "components/spinners/Spinner";

interface LogoUploaderProps {
    afterSave: (result) => void;
    brandId: string;
}

export const LogoUploader: React.FC<LogoUploaderProps> = ({
    afterSave,
    brandId
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const [userId, setUserId] = useState<string>(null);
    const [fileAttemptingUpload, setFileAttemptingUpload] =
        useState<boolean>(false);
    const { t } = useTranslation();
    const inputElement = useRef(null);

    useEffect(() => {
        if (!!userInfo && userId === null) setUserId(userInfo.UserId);
    }, [userInfo, userId]);

    const onChange = useCallback(
        async (newVal: HTMLInputElement | DataTransfer) => {
            try {
                // Confirm that the user is logged in
                if (userId === null) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "user-info-load-error"
                        })
                    );

                    rollbar.error("LogoUploader: userId is null");
                    return;
                }

                // Confirm that the user has uploaded one file
                if (!newVal.files || !newVal.files.length) return;
                if (newVal.files.length > 1)
                    throw new Error(t("errors:logo-upload-limit-error"));
                const file = newVal.files[0];

                // Confirm that the file is an image
                if (file.type !== "image/jpeg" && file.type !== "image/png")
                    throw new Error(t("errors:logo-upload-type-error"));

                // Create a thumbnail for the image
                const dataStr = await fileReaderAsync(file);
                const thumbnail = await imageThumbnailAsync(
                    AssetLayering.FullScreen,
                    AssetType.image,
                    dataStr,
                    480,
                    AspectRatio.horizontal
                );

                // Create a FileItem for uploading
                const fileItems: FileItem[] = [
                    {
                        file,
                        thumbnail,
                        name: file.name,
                        fileProps: {},
                        assetLayering: AssetLayering.FullScreen,
                        assetType: AssetType.image
                    }
                ];

                // Prepare the file for uploading
                const { filesWithMetadata, assetPutOptions } =
                    await assemblePutOptions(fileItems);
                const newLogoAssets = await client.brandLogos_PutUserBrandLogos(
                    userId,
                    brandId,
                    assetPutOptions
                );

                try {
                    await Promise.all(
                        newLogoAssets.map(async (logoAsset) => {
                            // Find the file that matches the logoAsset and upload it to blob storage
                            const file = filesWithMetadata.find(
                                (f) =>
                                    f.imageMid === logoAsset.Id ||
                                    f.mid === logoAsset.Id
                            );
                            if (file) {
                                setFileAttemptingUpload(true);
                                await uploadAssetToStorage(file, logoAsset);
                            }
                        })
                    );
                } catch (e) {
                    // Rollback the logo data save if the upload fails
                    const asset: Asset = {
                        Id:
                            filesWithMetadata[0].imageMid ||
                            filesWithMetadata[0].mid
                    };
                    await client.brandLogos_DeleteUserBrandLogos(
                        userId,
                        brandId,
                        [asset]
                    );

                    throw e;
                }

                setFileAttemptingUpload(false);
                afterSave(
                    newLogoAssets.filter((a) => (a.Type as any) === "Art")
                );

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("brand-profile:brand-logo-success")
                    })
                );
            } catch (e) {
                setFileAttemptingUpload(false);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        },
        [dispatch, t, userId, brandId, afterSave]
    );

    return (
        <>
            <div className={styles["logo-uploader"]}>
                <h4 className={styles["form-card-title"]}>
                    {t("brand-profile:brand-logo")}
                </h4>
                <div className={styles["logo-previews"]}>
                    <div className={styles["input-holder"]}>
                        {fileAttemptingUpload ? (
                            <Spinner />
                        ) : (
                            <>
                                <label
                                    className={`btn btn-primary ${styles["upload-btn"]}`}
                                    htmlFor="fileInput"
                                    tabIndex={0}
                                    role="button"
                                >
                                    <AddIcon />
                                    <span className="sr-only">
                                        {t("buttons:upload")}
                                    </span>
                                </label>
                                <input
                                    id="fileInput"
                                    alt="input for brand logo upload"
                                    className={styles["upload-input"]}
                                    ref={inputElement}
                                    type="file"
                                    name="logo-upload"
                                    accept="image/jpeg,image/png"
                                    onChange={(e) => onChange(e.target)}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
