import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useIsMountedRef } from "hooks/useIsMountedRef";

interface PillObject {
    text: string;
    value: string;
}

interface PillSlideSelectorProps {
    pills: Array<string> | Array<PillObject> | any;
    selected: string;
    onChange: (value: string) => void;
}

export const PillSlideSelector: React.FC<PillSlideSelectorProps> = ({
    pills,
    selected,
    onChange
}) => {
    const isMounted = useIsMountedRef();
    const [_pills, setPills] = useState<Array<PillObject>>([]);

    useEffect(() => {
        if (isMounted.current && pills.length > 0) {
            if (typeof pills[0] === "string") {
                setPills(
                    pills.map((p) => {
                        return {
                            text: p,
                            value: p
                        };
                    })
                );
            } else setPills(pills as any);
        }
    }, [isMounted, pills]);

    return (
        <>
            <div className={styles["pill-slider"]}>
                {_pills.map((p, i) => {
                    return (
                        <div
                            key={`pill-selector-${i}`}
                            className={`${
                                styles[
                                    p.value.toLowerCase() ===
                                    selected.toLowerCase()
                                        ? "selected"
                                        : ""
                                ]
                            } ${styles["slide-pill"]}`}
                            onClick={() => onChange(p.value)}
                        >
                            {p.text.toUpperCase()}
                        </div>
                    );
                })}
            </div>
        </>
    );
};
