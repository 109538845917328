import { useState, useCallback, useEffect } from "react";
import { Asset } from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { setLoading } from "store/loading/slice";
import rollbar from "helpers/rollbar";
import { useTranslation } from "react-i18next";
import { openConfirmation } from "store/confirmation/slice";
import { AppDispatch } from "store/store";

export function useAssetDetails(assetId: string) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { userInfo } = useSelector((s: RootState) => s.user);
    const [asset, setAsset] = useState<Asset>();
    const [rawFiles, setRawFiles] = useState<Asset[]>([]);
    const [otherAssets, setOtherAssets] = useState<Asset[]>([]);
    const [deleteRawFiles, setDeleteRawFiles] = useState<boolean>(false);
    const [disableDeleteRawFiles, setDisableDeleteRawFiles] =
        useState<boolean>(false);

    useEffect(() => {
        async function run() {
            try {
                dispatch(setLoading(1));
                const asset = await client.userAssets_GetUserAsset(
                    userInfo?.UserId || "",
                    assetId
                );

                if (asset.RequiresAssetIds) {
                    let rawFiles = [];
                    let otherAssets = [];

                    for (const rawFileId of asset.RequiresAssetIds) {
                        const rawFile = await client.userAssets_GetUserAsset(
                            userInfo?.UserId || "",
                            rawFileId
                        );

                        if (rawFile.RequiredByAssetIds?.length) {
                            const otherAssetIds =
                                rawFile.RequiredByAssetIds.filter(
                                    (id) => id !== asset.Id
                                );

                            for (const id of otherAssetIds) {
                                const otherAsset =
                                    await client.userAssets_GetUserAsset(
                                        userInfo?.UserId || "",
                                        id
                                    );
                                otherAssets.push(otherAsset);
                            }
                        }

                        rawFiles.push(rawFile);
                    }

                    let disable = false;

                    const rawFilesIds = rawFiles.map((rf) => rf.Id);

                    for (let oa of otherAssets) {
                        const usesOtherFiles = oa.RequiresAssetIds?.some(
                            (id) => !rawFilesIds.includes(id)
                        );
                        if (usesOtherFiles) {
                            disable = true;
                        }
                    }

                    if (disable) {
                        setDeleteRawFiles(false);
                        setDisableDeleteRawFiles(disable);
                    } else {
                        setDeleteRawFiles(true);
                        setDisableDeleteRawFiles(disable);
                    }

                    setRawFiles(rawFiles);
                    setOtherAssets(otherAssets);
                }
                setAsset(asset);
                dispatch(setLoading(-1));
            } catch (e) {
                rollbar.error("Error retrieving asset", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:asset-retrieval-error"
                    })
                );
                dispatch(setLoading(-1));
            }
        }

        run();
    }, [dispatch, assetId, userInfo, t]);

    const remove = useCallback(async () => {
        if (asset) {
            dispatch(
                openConfirmation({
                    htmlMessage: deleteRawFiles,
                    message: deleteRawFiles
                        ? `
          <p>${t("asset-hook:confirm-1")}</p>
          
          <p>${t("asset-hook:confirm-2")}</p>
          <p>    ${rawFiles.map((a) => a.Name).join("<br/>    ")}</p>
          
          <p>${t("asset-hook:confirm-3")}</p>
          <p>    ${otherAssets.map((a) => a.Name).join("<br/>    ")}</p>
          
          <p>${t("asset-hook:confirm-4")}</p>
                    `
                        : "messages:delete-asset-confirmation",
                    onSuccess: async () => {
                        try {
                            let payload = [asset];
                            if (deleteRawFiles)
                                payload = [
                                    ...payload,
                                    ...rawFiles,
                                    ...otherAssets
                                ];

                            dispatch(setLoading(1));
                            await client.userAssets_DeleteUserAssetsAll(
                                userInfo?.UserId || "",
                                payload
                            );
                            dispatch(setLoading(-1));
                            navigate("/switcher-cloud");
                        } catch (e) {
                            rollbar.error("Error removing asset", e);
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:asset-removal-error"
                                })
                            );
                            dispatch(setLoading(-1));
                        }
                    }
                })
            );
        }
    }, [
        dispatch,
        asset,
        navigate,
        rawFiles,
        otherAssets,
        deleteRawFiles,
        userInfo,
        t
    ]);

    const toggleDeleteRawFiles = useCallback(() => {
        setDeleteRawFiles(!deleteRawFiles);
    }, [deleteRawFiles]);

    return {
        asset,
        rawFiles,
        otherAssets,
        deleteRawFiles,
        disableDeleteRawFiles,
        remove,
        toggleDeleteRawFiles
    };
}
