import { UploadProps } from "hooks/useVideoUpload";

export interface UploadsState {
    processingOrQueued: UploadProps[];
    ready: UploadProps[];
}

export enum VideoUploadStatus {
    Processing = "inprogress",
    Success = "ready",
    Queued = "queued"
}
