import React, { useState, useEffect } from "react";
import styles from "./Dropzone.module.scss";
import { useTranslation } from "react-i18next";

interface DropzoneProps {
    accept: string;
    error: string;
    fileCount: number;
    onChange: (dataTransfer: HTMLInputElement | DataTransfer) => void;
}

export const Dropzone: React.FC<DropzoneProps> = ({
    accept,
    error,
    fileCount,
    onChange
}) => {
    const { t } = useTranslation();
    const [labelText, setLabelText] = useState<string>("");
    const [dragged, setDragged] = useState<boolean>(false);

    const dragIn = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragged(true);
    };

    const dragOut = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragged(false);
    };

    useEffect(() => {
        if (error) {
            setLabelText(error);
            return;
        }

        if (fileCount) {
            setLabelText(`${fileCount} files selected.`);
        } else {
            setLabelText(t("upload:drag-and-drop"));
        }
    }, [error, fileCount, t]);

    return (
        <>
            <div
                className={`${styles["box"]} ${
                    dragged ? styles["dragged"] : ""
                } ${error ? styles["error"] : ""}`}
                onDragOver={dragIn}
                onDragEnter={dragIn}
                onDragLeave={dragOut}
                onDragEnd={dragOut}
                onDrop={function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(e.dataTransfer);
                    setDragged(false);
                }}
            >
                <div className={styles["box__input"]}>
                    <input
                        id="fileInput"
                        className={styles["box__file"]}
                        type="file"
                        name="asset-upload"
                        data-multiple-caption={`${fileCount} files selected`}
                        accept={accept}
                        multiple
                        onChange={(e) => onChange(e.target)}
                    />
                    <label className={styles["box-label"]} htmlFor="fileInput">
                        {error ? (
                            <svg
                                className={styles["box__icon"]}
                                viewBox="0 0 1000 1000"
                            >
                                <g>
                                    <g>
                                        <path d="M500,10C229.4,10,10,229.4,10,500c0,270.7,219.4,490,490,490c270.6,0,490-219.4,490-490C989.9,229.4,770.5,10,500,10z M500,887.8c-214.2,0-387.9-173.6-387.9-387.9C112.1,285.7,285.7,112,500,112c214.2,0,387.9,173.6,387.9,387.9C887.9,714.2,714.2,887.8,500,887.8z M627.6,487.2c42.3,0,76.6-40,76.6-89.3s-34.3-89.3-76.6-89.3c-42.3,0-76.6,40-76.6,89.3S585.3,487.2,627.6,487.2z M372.4,487.2c42.3,0,76.6-40,76.6-89.3s-34.3-89.3-76.6-89.3s-76.6,40-76.6,89.3S330.1,487.2,372.4,487.2z M500.1,563.8c-174.8,0-235.9,118.2-238.5,123.2c-9.5,18.9-1.8,41.9,17.1,51.4c5.5,2.7,11.3,4,17.1,4c14,0,27.6-7.7,34.3-21.1c0.4-0.9,42-80.9,170-80.9c125.7,0,168.3,77.9,169.9,80.9c9.4,18.9,32.4,26.7,51.3,17.1c18.9-9.4,26.6-32.5,17.1-51.3C735.9,682,674.8,563.8,500.1,563.8L500.1,563.8z" />
                                    </g>
                                </g>
                            </svg>
                        ) : (
                            <svg
                                className={styles["box__icon"]}
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="43"
                                viewBox="0 0 50 43"
                            >
                                <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
                            </svg>
                        )}
                        <span id="dropzone-label-text">{labelText}</span>
                    </label>
                </div>
            </div>
        </>
    );
};
