import { useInterval } from "hooks/useInterval";
import { useCallback, useMemo, useState } from "react";
import { useGetUploadSession } from "./useGetUploadSession";

export const useGetAggregateUploadProgress = ({
    sessionId
}: {
    sessionId: string;
}) => {
    const { files } = useGetUploadSession({ sessionId });
    const filesArr = useMemo(
        () => (!!files ? Object.values(files) : []),
        [files]
    );
    const [aggregateUploadPercentage, setAggregateUploadPercentage] =
        useState<number>(0);
    const [totalInProgressUploads, setTotalInProgressUploads] =
        useState<number>(filesArr?.length);

    const getAggregateUploadPercentage = useCallback(() => {
        return filesArr.reduce(
            (memo, currentFile, currentIndex, thisArray) => {
                const currentRawFileProgress = currentFile.isCanceled
                    ? 100
                    : currentFile.uppyInstance.getState()?.totalProgress;
                // bump progress down to 99% if is not marked as completed
                const currentFileProgress =
                    currentRawFileProgress === 100 && currentFile.isUploading
                        ? 99
                        : currentRawFileProgress;

                const filesUploading = currentFile?.isUploading
                    ? memo.filesUploading + 1
                    : memo.filesUploading;

                const newTotalPercentage =
                    memo.totalPercentage + currentFileProgress;
                if (currentIndex === thisArray.length - 1) {
                    return {
                        totalPercentage: Math.floor(
                            newTotalPercentage / thisArray.length
                        ),
                        filesUploading
                    };
                }

                return {
                    totalPercentage: Math.floor(newTotalPercentage),
                    filesUploading
                };
            },
            { totalPercentage: 0, filesUploading: 0 }
        );
    }, [filesArr]);

    useInterval(() => {
        const results = getAggregateUploadPercentage();
        setAggregateUploadPercentage(results.totalPercentage);
        setTotalInProgressUploads(results.filesUploading);
    }, 150);

    return {
        aggregateUploadPercentage,
        totalInProgressUploads
    };
};
