import SortIcon from "assets/icons/sort.svg?react";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { Spinner } from "components/spinners/Spinner";
const cx = classnames.bind(styles);

export interface TableColumn<Value> {
    label: string;
    sort?: (
        a: Value,
        b: Value,
        direction: "ascending" | "descending"
    ) => number;
    formatter?: (value: Value) => string | number;
}
export interface TableColumns {
    [key: string]: TableColumn<any>;
}

export type TablePoints<Columns extends TableColumns> = {
    [K in keyof Columns]: Columns[K] extends TableColumn<infer V> ? V : never;
};

export interface TableProps<Columns extends TableColumns> {
    variant?: "sortable-table";
    columns: Columns;
    sortedBy: keyof Columns;
    points: TablePoints<Columns>[];
    /** Indicates showing loading state */
    loading?: boolean;
}

export interface PointsSorted<Columns extends TableColumns> {
    sortedBy: keyof Columns;
    direction: "ascending" | "descending";
    points: TablePoints<Columns>[];
}

export const Table = <Columns extends TableColumns>({
    variant = "sortable-table",
    columns,
    sortedBy,
    points,
    loading
}: TableProps<Columns>) => {
    const [sorted, setSorted] = useState<PointsSorted<Columns>>({
        sortedBy,
        direction: "ascending",
        points
    });

    const handleSort = <Key extends keyof Columns>(key: Key) => {
        setSorted((prev) => {
            const direction =
                key === prev.sortedBy
                    ? prev.direction === "ascending"
                        ? "descending"
                        : "ascending"
                    : "ascending";

            return {
                direction,
                sortedBy: key,
                points: prev.points.sort((a, b) =>
                    columns[key].sort
                        ? columns[key].sort(a[key], b[key], direction)
                        : 0
                )
            };
        });
    };

    useEffect(() => {
        // When a new dataset comes in, set the data in state and then sort it.
        setSorted((prev) => {
            return {
                ...prev,
                points
            };
        });
        handleSort(sorted.sortedBy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points]);

    if (!points || loading) return <Spinner size={100} />;

    return (
        <table className={cx(variant)}>
            <thead>
                <tr>
                    {Object.keys(columns).map((key) => (
                        <th key={key} onClick={() => handleSort(key)}>
                            {columns[key].label}
                            {columns[key].sort && (
                                <SortIcon
                                    className={cx(
                                        sorted.sortedBy === key
                                            ? sorted.direction
                                            : ""
                                    )}
                                />
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {sorted.points.map((point, index) => (
                    <tr key={index}>
                        {Object.keys(columns).map((key) => (
                            <td key={key}>
                                {columns[key].formatter
                                    ? columns[key].formatter(point[key])
                                    : (point[key] as
                                          | string
                                          | number
                                          | boolean
                                          | React.ReactNode)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
