export interface Step<T> {
    /** Unique id for step */
    id: T;
    /** Component to be displayed when step is active */
    component: JSX.Element;
}

export interface WizardProps<T> {
    /** Available tabs */
    steps: Step<T>[];
    /** Currently displayed tab */
    activeStep: T;
}

/**
 * A component that displays UI steps in sequence.
 */
export const Wizard = <T extends string>({
    steps,
    activeStep
}: WizardProps<T>) => {
    return (
        <>
            {steps.map(
                ({ id, component }) =>
                    activeStep === id && (
                        <span key={id as string}>{component}</span>
                    )
            )}
        </>
    );
};
