import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { postEvents } from "store/events/thunks";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { trackEvent } from "helpers/analyticsHelpers";

const micrositeUrl = import.meta.env.VITE_SWITCHER_PLAYER_URL;

/**
 * A hook to get or copy the embed code.
 * @param variant Whether the id is from collection or catalog.
 * @param entityId The catalog Id or collection Id.
 * @returns Promise to copy embed code or the embed code itself.
 */
export function useEmbedCode(
    variant: "catalog" | "collection",
    entityId: string
): { copyEmbedCode: () => Promise<void>; embedCode: string } {
    const clipboardExists = !!navigator.clipboard;
    const dispatch = useDispatch<AppDispatch>();
    const isCatalog = variant === "catalog";

    const embedCode = useMemo(
        () =>
            `<div
            class="dff402f7-5be0-4890-b831-95c5b63ddb42"
            data-hostname="${micrositeUrl}"
            data-path="/embed"
            ${
                isCatalog
                    ? `data-catalogid="${entityId}"`
                    : `data-videoPlayerId="${entityId}"`
            }
            data-location="iframe"
            ></div>
            <script src="${micrositeUrl}/embed.js"></script>`,
        [entityId, isCatalog]
    );

    const copyEmbedCode = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(embedCode);

            dispatch(postEvents({ "copied-code": true }));
            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-embed-code"
                })
            );
        }
        trackEvent("Copied Embed Code", { variant });
    }, [clipboardExists, embedCode, dispatch, variant]);

    return {
        copyEmbedCode,
        embedCode
    };
}
