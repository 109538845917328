import { useState, useEffect } from "react";

export enum ScriptAsyncStatus {
    Idle = "idle",
    Loading = "loading",
    Ready = "ready",
    Error = "error"
}

// Dynamically load a script element.
// Adapted from https://usehooks.com/useScript/
export function useScript(
    src: string,
    attributes: { qualifiedName: string; value: string }[] = []
) {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState<ScriptAsyncStatus>(
        src ? ScriptAsyncStatus.Loading : ScriptAsyncStatus.Idle
    );
    useEffect(() => {
        // Allow falsy src value if waiting on other data needed for
        // constructing the script URL passed to this hook.
        if (!src) {
            setStatus(ScriptAsyncStatus.Idle);
            return;
        }
        // Fetch existing script element by src
        // It may have been added by another intance of this hook
        let script = document.querySelector<HTMLScriptElement>(
            `script[src="${src}"]`
        );
        if (!script) {
            // Create script
            script = document.createElement("script");
            script.referrerPolicy = "strict-origin-when-cross-origin";
            script.async = true;
            script.src = src;
            script.setAttribute("data-status", "loading");

            attributes.forEach((attribute) => {
                script.setAttribute(attribute.qualifiedName, attribute.value);
            });

            // Add script to document head
            document.head.appendChild(script);

            // Store status in attribute on script
            // This can be read by other instances of this hook
            const setAttributeFromEvent = (event) => {
                script.setAttribute(
                    "data-status",
                    event.type === "load"
                        ? ScriptAsyncStatus.Ready
                        : ScriptAsyncStatus.Error
                );
            };
            script.addEventListener("load", setAttributeFromEvent);
            script.addEventListener("error", setAttributeFromEvent);
        } else {
            // Grab existing script status from attribute and set to state.
            setStatus(script.getAttribute("data-status") as ScriptAsyncStatus);
        }
        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event) => {
            setStatus(
                event.type === "load"
                    ? ScriptAsyncStatus.Ready
                    : ScriptAsyncStatus.Error
            );
        };

        // Add event listeners
        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);

        // Remove event listeners on cleanup
        return () => {
            if (script) {
                script.removeEventListener("load", setStateFromEvent);
                script.removeEventListener("error", setStateFromEvent);
            }
        };
    }, [src, attributes]);
    return status;
}
