import React, { PropsWithChildren, useState } from "react";
import styles from "./index.module.scss";

interface FormCardProps extends PropsWithChildren {
    children: React.ReactNode;
    icon?: React.ReactNode;
    pageTitle?: string;
}

export const FormCard: React.FC<FormCardProps> = ({
    children,
    icon,
    pageTitle
}) => {
    const [multiForm] = useState<boolean>(
        Array.isArray(children) && children.length > 1 ? true : false
    );

    return (
        <>
            <div className={`row ${styles["form-card"]}`}>
                <span className={styles["icon"]}>{!!icon ? icon : null}</span>
                <div
                    className={`col-md-10 ${styles["form-card-body"]} ${
                        multiForm ? styles["multi-form"] : ""
                    }`}
                >
                    {!!pageTitle ? (
                        <h4 className={styles["form-card-title"]}>
                            {pageTitle}
                        </h4>
                    ) : null}
                    {children}
                </div>
            </div>
        </>
    );
};
