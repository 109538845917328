import store from "store/store";
import { FacebookResponse, IFbArgs } from "./types";
import { setLoading } from "store/loading/slice";

const fbApiVersion = "v17.0";
const baseUrl = `${
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net"
}/api/streamingproviders/facebook/proxy/`; // TODO: config var for url

// this function should actually return a promise..
export default async function fbFetch(options: IFbArgs) {
    const state = store.getState();
    const { enableLoading = true } = options;
    const url = `${baseUrl}${options.fbApiVersion || fbApiVersion}/${
        options.edge
    }${
        options.token ? "?access_token=" + options.token + "&" : "?"
    }${parseFields(options)}`;

    try {
        if (enableLoading) {
            store.dispatch(setLoading(1));
        }
        const response = await fetch(url, {
            method: options.method || "GET",
            body: options.stringify
                ? JSON.stringify(options.body)
                : options.body || null,
            headers: {
                ...(options.headers || {}),
                Authorization: `bearer ${state.user.ticket!.access_token}`
            }
        });

        const body = await handleResponse(response);

        if (response.status >= 400) {
            throw new Error(body?.error?.message);
        }

        return handlePagination(body, options);
    } catch (e) {
        throw e;
    } finally {
        if (enableLoading) {
            store.dispatch(setLoading(-1));
        }
    }
}

async function handleResponse(response) {
    const text = await response.text();
    try {
        let result = null;
        result = text === "" ? null : JSON.parse(text);
        return result;
    } catch (e) {
        throw new Error("Invalid JSON");
    }
}

export async function createFacebookStream() {}

function handlePagination(response: FacebookResponse, options: IFbArgs) {
    const customRes: any = { ...response };
    if (response && response.paging) {
        if (typeof response.paging.next !== "undefined") {
            if (options.fields && options.fields.before)
                delete options.fields.before;
            customRes.getNextPage = {
                edge: options.edge,
                fields: {
                    ...options.fields,
                    after: response.paging.cursors.after
                }
            };
        }
        if (typeof response.paging.previous !== "undefined") {
            if (options.fields && options.fields.after)
                delete options.fields.after;
            customRes.getPreviousPage = {
                edge: options.edge,
                fields: {
                    ...options.fields,
                    before: response.paging.cursors.before
                }
            };
        }
    }

    return customRes;
}

function parseFields(options: IFbArgs) {
    let fieldsArray: string[] = [];
    for (let i in options.fields) {
        fieldsArray.push(`${i}=${encodeURIComponent(options.fields[i])}`);
    }
    return fieldsArray.join("&");
}

export function parseRtmpString(createVideoResponse: {
    secure_stream_url: string;
    stream_url: string;
}): { streamUrl: string; streamKey: string } {
    const streamUrlResponse = createVideoResponse.secure_stream_url;
    const streamUrlOffset = streamUrlResponse.search("/rtmp/");

    return {
        streamUrl: streamUrlResponse.substring(0, streamUrlOffset + 6),
        streamKey: streamUrlResponse.substring(streamUrlOffset + 6)
    };
}
