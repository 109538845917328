import { useClaimCheck } from "hooks/useClaimCheck";
import { useTranslation } from "react-i18next";

export const useSimulcastFork = () => {
    const enableVLH = useClaimCheck("vlh");
    const enableLegacySimulcast = useClaimCheck("legacysimulcast");

    // Once VLH (Video Library as App Hub) is released
    // Simulcast will be viewed as Livestreaming (simulcastvlh) in the browser
    // and in modern app versions, older app versions will continue to see
    // Multistreaming (simulcast)
    const simulcastType =
        enableVLH && !enableLegacySimulcast ? "simulcastvlh" : "simulcast";
    const { t } = useTranslation(simulcastType);
    return { simulcastType, t };
};
