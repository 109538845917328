import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import CouponIcon from "assets/icons/coupon.svg?react";
import { CouponResponse } from "@switcherstudio/switcher-api-client";

export interface CouponAppliedProps {
    coupon: CouponResponse;
    onRemove?: () => void;
    hideRemoveButton?: boolean;
}

export const CouponApplied: React.FC<CouponAppliedProps> = ({
    coupon,
    onRemove,
    hideRemoveButton
}) => {
    const { t } = useTranslation();

    if (!coupon) return;

    if (coupon?.ResellerInventoryItem?.ResellerInventory?.IsSilent) return;

    return (
        <div className={`alert ${styles["alert-container"]}`} role="alert">
            <div className={styles["alert-content"]}>
                <CouponIcon className={styles["icon"]} />
                <div className={styles["coupon-details"]}>
                    <p>
                        {`${t("coupon-form:coupon-code")} `}
                        <strong>
                            {coupon?.ResellerInventoryItem?.CouponCode}
                        </strong>
                        {` ${t("misc:applied")}`}
                    </p>
                    <small>
                        {
                            coupon?.ResellerInventoryItem?.ResellerInventory
                                ?.Description
                        }
                    </small>
                </div>
            </div>

            {!hideRemoveButton && (
                <button
                    className="btn btn-link"
                    onClick={!!onRemove ? onRemove : () => {}}
                >
                    {t("button:remove")}
                </button>
            )}
        </div>
    );
};
