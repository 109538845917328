import styles from "./index.module.scss";
import PlayButtonIcon from "assets/icons/play.svg?react";
import { BaseThumbnail } from "../BaseThumbnail";
import { EmptyThumbnail } from "components/thumbnails/EmptyThumbnail";
import { useMemo } from "react";
import { DisabledVariant } from "components/entity-details/BroadcastDetails";
import CountdownThumbnail from "../CountdownThumbnail";
import { useTranslation } from "react-i18next";

interface BroadcastThumbnailProps {
    thumbnailImageURL: string;
    allowVideoPlaybackOnThumbnailClick: boolean;
    handlePlayVideo: (...args: any) => any;
    isScheduledUpload: boolean;
    isScheduledLive: boolean;
    scheduledSecondsRemaining?: number;
    disabled: boolean;
    disabledVariant: DisabledVariant;
}

export const BroadcastThumbnail = ({
    thumbnailImageURL,
    allowVideoPlaybackOnThumbnailClick,
    handlePlayVideo,
    isScheduledUpload,
    isScheduledLive,
    scheduledSecondsRemaining,
    disabled,
    disabledVariant
}: BroadcastThumbnailProps) => {
    const { t } = useTranslation();
    const countdownOrThumbnail = useMemo(() => {
        const shouldShowThumbnailImage =
            thumbnailImageURL && !isScheduledUpload && !isScheduledLive;

        const isPremiereTimerExpired =
            isScheduledUpload && scheduledSecondsRemaining <= 0;

        const isLiveTimerExpired =
            isScheduledLive && scheduledSecondsRemaining <= 0;

        const scheduledLiveHeaderText = !isLiveTimerExpired
            ? t("broadcast-details:live-in")
            : "";

        const headerText = isScheduledLive
            ? scheduledLiveHeaderText
            : t("broadcast-details:premiering-in");

        return shouldShowThumbnailImage || isPremiereTimerExpired ? (
            <img
                className={`video-thumbnail-image`}
                src={thumbnailImageURL}
                alt="video-thumbnail-image"
            />
        ) : (
            <CountdownThumbnail
                timeRemaining={scheduledSecondsRemaining}
                headerText={headerText}
            />
        );
    }, [
        thumbnailImageURL,
        isScheduledUpload,
        isScheduledLive,
        scheduledSecondsRemaining,
        t
    ]);

    const displayedThumbnail = useMemo(() => {
        if (disabled && disabledVariant === DisabledVariant.Disabled) {
            return (
                <BaseThumbnail>
                    <EmptyThumbnail />
                </BaseThumbnail>
            );
        }

        if (disabled && disabledVariant === DisabledVariant.Processing) {
            return (
                <BaseThumbnail>
                    <div className={styles["processing-wrapper"]}>
                        <span key="processing" className={styles["processing"]}>
                            {t("video-upload:video-processing-ellipsis")}
                        </span>
                    </div>
                </BaseThumbnail>
            );
        }

        if (allowVideoPlaybackOnThumbnailClick && !isScheduledLive) {
            return (
                <button
                    className={`video-preview ${styles["video-preview"]}`}
                    onClick={handlePlayVideo}
                >
                    <BaseThumbnail>
                        {countdownOrThumbnail}
                        <div className={styles["play-button-wrapper"]}>
                            <PlayButtonIcon
                                className={styles["play-button-icon"]}
                            />
                        </div>
                    </BaseThumbnail>
                </button>
            );
        }
        return <BaseThumbnail>{countdownOrThumbnail}</BaseThumbnail>;
    }, [
        disabled,
        disabledVariant,
        allowVideoPlaybackOnThumbnailClick,
        isScheduledLive,
        countdownOrThumbnail,
        handlePlayVideo,
        t
    ]);

    return displayedThumbnail;
};
