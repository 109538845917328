import { SetupIntent } from "@stripe/stripe-js";
import { client } from "api/client";
import { useEffect, useState } from "react";
import {
    RedirectCallbackArguments,
    useBillingRedirect
} from "./useBillingRedirect";
export interface UseSetupIntentOptions {
    /** Called when redirect is successful */
    onRedirect?: (
        setupIntent: SetupIntent,
        args: RedirectCallbackArguments
    ) => void;
}

/**
 * A hooks to retrieve and validate a Setup Intent from Stripe
 */
export const useSetupIntent = ({ onRedirect }: UseSetupIntentOptions = {}) => {
    const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);

    const { setupIntent: intentFromRedirect } = useBillingRedirect({
        onSetupIntent: onRedirect
    });

    useEffect(() => {
        const getSetupIntent = async () => {
            const setupIntent =
                intentFromRedirect ??
                ((await client.stripeIntents_GetSetupIntent()) as SetupIntent);

            setSetupIntent(setupIntent);
        };
        getSetupIntent();
    }, [intentFromRedirect]);

    return { setupIntent };
};
