import { PropsWithChildren } from "react";
import styles from "./index.module.scss";

export interface RowProps extends PropsWithChildren {
    className?: string;
    header?: string;
    /** Special styles for a special row. If row has fewer than 2 widgets, it's special. */
}

export const Row: React.FC<RowProps> = ({ children, header, className }) => (
    <div className={`row ${className}`}>
        <div className="col-lg-12">
            {header && (
                <div className={styles["account-overview-header"]}>
                    <h4>{header}</h4>
                </div>
            )}
            <div className={`${styles["widget-row"]}`}>{children}</div>
        </div>
    </div>
);
