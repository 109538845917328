import React from "react";
import { Entitlement } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { Widget } from "components/widgets/Widget";
import { Button } from "components/buttons/Button";

interface EntitlementGridProps {
    entitlements: Entitlement[] | undefined;
    openManagePlanModal: () => void;
}

export const NewEntitlementGridV2: React.FC<EntitlementGridProps> = ({
    entitlements,
    openManagePlanModal
}) => {
    const { t } = useTranslation();

    if (entitlements) {
        return (
            <div>
                {entitlements?.map((entitlement) => (
                    <Widget
                        key={entitlement.Id}
                        title={t(
                            "subscription-page:plan-details"
                        ).toUpperCase()}
                        details={[
                            {
                                title: entitlement.LatestProduct.Name
                            }
                        ]}
                        button={
                            <Button
                                type="primary"
                                onClick={openManagePlanModal}
                            >
                                {t("subscription-page:select-plan")}
                            </Button>
                        }
                        variant="billing"
                    />
                ))}
            </div>
        );
    }
};
