import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import { client } from "api/client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { SilverSunnStripeSubscription } from "@switcherstudio/switcher-api-client";
import dayjs from "dayjs";
import rollbar from "helpers/rollbar";
import { useTranslation } from "react-i18next";
import { SubscriptionFormWrapper } from "../SubscriptionFormWrapper";
import commonStyles from "../SubscriptionCommonStyles.module.scss";
import { useNavigate } from "react-router-dom";
import { useParams } from "hooks/useParams";

export const RenewSubscriptionPage: React.FC = () => {
    const { subscriptionId } = useParams();
    const { t } = useTranslation();
    const isMounted = useIsMountedRef();
    const navigate = useNavigate();

    const [subscription, setSubscription] =
        useState<SilverSunnStripeSubscription>();
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        async function run() {
            const customer = await client.stripe_GetCustomer();

            if (isMounted.current) {
                const subscription = customer?.StripeSubscriptions?.find(
                    (s) => s.SilverSunnStripeSubscriptionId === subscriptionId
                );

                if (subscription) {
                    setSubscription(subscription);
                } else {
                    setError(t("errors:something-wrong"));
                }
            }
        }

        run();
    }, [isMounted, subscriptionId, t]);

    const back = () => {
        navigate("/subscription");
    };

    const renew = async () => {
        setError("");
        setSuccess("");
        setIsSubmitting(true);
        try {
            await client.stripe_RenewSubscription(subscriptionId);
            setSuccess(t("renew-subscription-page:subscription-renewed"));
        } catch (e) {
            rollbar.error("Error renewing stripe subscription", e);
            setError(e.message || e.Message || e);
        } finally {
            setIsSubmitting(false);
        }
    };

    const displayDate = (date?: string) => {
        return dayjs(date).format("ddd, MMM D, YYYY");
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <p>
                        <button
                            type="button"
                            className={`btn btn-outline-primary ${commonStyles["header-btn"]}`}
                            onClick={back}
                        >
                            <ChevronLeft />{" "}
                            {t("cancel-subscription:subscriptions")}
                        </button>
                    </p>

                    {!success && subscription && (
                        <SubscriptionFormWrapper>
                            <p>
                                {t(
                                    "renew-subscription-page:subscription-will-cancel"
                                ) + " "}
                                <strong>
                                    {displayDate(subscription.ActiveUntil)}
                                </strong>
                                .
                            </p>
                            <p className="lead">
                                {t("renew-subscription-page:want-renew")}
                            </p>
                            {error && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            )}
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={renew}
                                disabled={isSubmitting}
                            >
                                {t("renew-subscription-page:renew")}
                            </button>
                        </SubscriptionFormWrapper>
                    )}
                    {success && (
                        <SubscriptionFormWrapper>
                            <p>
                                <div
                                    className="alert alert-success"
                                    role="alert"
                                >
                                    {success}
                                </div>
                                <Link to={"/subscription"}>
                                    {t("cancel-subscription:return")}
                                </Link>
                            </p>
                        </SubscriptionFormWrapper>
                    )}
                </div>
            </div>
        </>
    );
};
