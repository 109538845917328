import React, { useCallback } from "react";
import { useForm, Errors, PasswordValidation } from "hooks/useForm";
import { Link } from "react-router-dom";
import { LoginInput } from "components/inputs/text-input/LoginInput";
import { client } from "api/client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./LoginPage/index.module.scss";
import { PasswordCriteria } from "components/password-criteria/PasswordCriteria";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { useParams } from "hooks/useParams";
interface ResetPasswordPageProps {
    isInvitation?: boolean;
}
export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({
    isInvitation
}) => {
    const { email, code } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const onSubmit = useCallback(
        async (values): Promise<void> => {
            const { password, confirmPassword } = values;
            await client.account_ResetPassword({
                Email: email,
                Code: code,
                Password: password,
                ConfirmPassword: confirmPassword
            });

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "profile:success-message"
                })
            );

            if (isInvitation) {
                navigate("/login");
            }
        },
        [code, dispatch, email, isInvitation, navigate]
    );

    const {
        values,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
        passwordValidMap
    } = useForm<{
        password: string;
        confirmPassword: string;
    }>(
        {
            password: "",
            confirmPassword: ""
        },
        onSubmit,
        (values, pvm?: PasswordValidation) => {
            let errors: Errors = {};

            if (!values.password) {
                errors.password = t("errors:password-required-error");
            } else if (!pvm.hasMinLength) {
                errors.password = t("errors:password-length-error");
            } else if (!pvm.hasLower) {
                errors.password = t("errors:password-lower-error");
            } else if (!pvm.hasUpper) {
                errors.password = t("errors:password-upper-error");
            } else if (!pvm.hasDigit) {
                errors.password = t("errors:password-digit-error");
            } else if (!pvm.hasSpecialChar) {
                errors.password = t("errors:password-special-char-error");
            }

            if (!values.confirmPassword) {
                errors.confirmPassword = t("errors:password-required-error");
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = t("errors:password-match-error");
            }

            return errors;
        }
    );

    return (
        <>
            <form onSubmit={handleSubmit}>
                <LoginInput
                    label={t("profile:new-password-placeholder")}
                    type="password"
                    id="password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                />
                <LoginInput
                    label={t("profile:confirm-new-password")}
                    type="password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    error={errors.confirmPassword}
                    onChange={handleChange}
                />
                {errors.api && (
                    <div className="alert alert-danger" role="alert">
                        {errors.api}
                    </div>
                )}
                <PasswordCriteria passwordValidMap={passwordValidMap} />
                <button
                    type="submit"
                    className="btn btn-white md-btn btn-block mb-4"
                    disabled={isSubmitting}
                >
                    {isInvitation
                        ? t("buttons:get-started")
                        : t("page-titles:reset-password")}
                </button>
            </form>
            {!isInvitation && (
                <div className="text-center">
                    <Link
                        className={`text-center ${styles["login-links"]}`}
                        to="/login"
                    >
                        {t("login:back-to-login").toUpperCase()}
                    </Link>
                </div>
            )}
        </>
    );
};
