import React, { useCallback } from "react";
import { ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { displayDate } from "helpers/time";
import styles from "./ManageInventoryItemsPage.module.scss";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";

interface ItemRowProps {
    item: ResellerInventoryItem;
    isResellerAdmin?: boolean;
    copyToClipboard: (code: string) => void;
}

export const ItemRow: React.FC<ItemRowProps> = ({
    item,
    isResellerAdmin,
    copyToClipboard
}) => {
    const { t, i18n } = useTranslation();

    const parseStatus = useCallback(
        (status: number): string => {
            switch (status) {
                case 0:
                    return t("manage-inventories:available");
                case 1:
                    return t("manage-inventories:sent");
                case 2:
                    return t("manage-inventories:redeemed");
                case 3:
                    return t("manage-inventories:invalidated");
                case 4:
                    return t("manage-inventories:claimed");
                default:
                    return "";
            }
        },
        [t]
    );

    return (
        <tr>
            <td className={styles["first-column"]}>
                <span>{parseStatus(item.Status)}</span>
                <span className={styles["checkmark-icon"]}>
                    {item.Status !== 0 && <CheckmarkIcon />}
                </span>
            </td>
            <td>
                <span>{item.CouponCode}</span>
            </td>
            {isResellerAdmin && (
                <td className={styles["email"]}>
                    <span>{item.RedeemedByUserEmail}</span>
                </td>
            )}
            <td>
                <span>
                    {item.Status !== 0 &&
                        displayDate(item.UpdatedAt, i18n.language)}
                </span>
            </td>
            <td>
                <button
                    className="btn btn-outline-primary"
                    disabled={
                        item.Status !== 0 && !isResellerAdmin ? true : false
                    }
                    onClick={() =>
                        copyToClipboard(
                            item.Status === 0
                                ? item.CouponCode
                                : item.RedeemedByUserEmail
                        )
                    }
                >
                    {item.Status === 0 || !isResellerAdmin
                        ? t("buttons:copy-code")
                        : t("buttons:copy-email")}
                </button>
            </td>
        </tr>
    );
};
