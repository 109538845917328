import React, { useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import SwitcherEmbedIcon from "assets/icons/switcher-player-embed.svg?react";
import FaceBookRoundedIcon from "assets/icons/facebook-rounded.svg?react";
import YoutubeRoundedIcon from "assets/icons/youtube-rounded.svg?react";
import TwitchRoundedIcon from "assets/icons/twitch-rounded.svg?react";
import MonetizationIcon from "assets/icons/monetization.svg?react";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useOnboardingSurveyResults } from "hooks/useOnboardingSurveyResults";
import { useTranslation } from "react-i18next";
import {
    SurveyCardOptionsType,
    SurveyMonetizeOptionsType,
    SurveyStreamOptionsType,
    SurveyVideoOptionsType,
    SurveySiteOptionsType,
    SurveyProductSalesOptionsType
} from "./types";
import { Checkbox } from "components/inputs/checkbox/Checkbox";
import { CreateAccountInput } from "components/inputs/text-input/CreateAccountInput";
import { useLandingPageRedirectABTest } from "hooks/useLandingPageRedirectABTest";
export interface SurveyStepProps {
    nextStep: () => void;
}
export const SurveyStep: React.FC<SurveyStepProps> = ({ nextStep }) => {
    const { t } = useTranslation("getting-started");
    const [shouldSkipOnUnload, setShouldSkipOnUnload] = useState(true);
    const { postSurveyResults } = useOnboardingSurveyResults();
    const [selectedCards, setSelectedCards] = useState<
        Set<SurveyCardOptionsType>
    >(new Set());
    const [videoOptions, setVideoOptions] = useState<
        Set<SurveyVideoOptionsType>
    >(new Set());

    const [streamOptions, setStreamOptions] = useState<
        Set<SurveyStreamOptionsType>
    >(new Set());

    const [monetizeOptions, setMonetizeOptions] = useState<
        Set<SurveyMonetizeOptionsType>
    >(new Set());

    const [siteOptions, setSiteOptions] = useState<Set<SurveySiteOptionsType>>(
        new Set()
    );

    const [productSalesOptions, setProductSalesOptions] = useState<
        Set<SurveyProductSalesOptionsType>
    >(new Set());

    const [siteURL, setSiteURL] = useState<string>(null);

    const [surveyStep, setSurveyStep] = useState<number>(1);

    const { redirectToLandingPageWithABTest } = useLandingPageRedirectABTest();

    const toggleCardSelection = (card: SurveyCardOptionsType) => {
        const updatedSelectedCards = new Set(selectedCards);
        if (updatedSelectedCards.has(card)) {
            updatedSelectedCards.delete(card);
        } else {
            updatedSelectedCards.add(card);
        }
        setSelectedCards(updatedSelectedCards);
    };
    const toggleStreamOptions = (option: SurveyStreamOptionsType) => {
        const updatedStreamOptions = new Set(streamOptions);
        if (updatedStreamOptions.has(option)) {
            updatedStreamOptions.delete(option);
        } else {
            updatedStreamOptions.add(option);
        }
        setStreamOptions(updatedStreamOptions);
    };
    const toggleMonetizeOptions = (option: SurveyMonetizeOptionsType) => {
        const updatedMonetizeOptions = new Set(monetizeOptions);
        if (updatedMonetizeOptions.has(option)) {
            updatedMonetizeOptions.delete(option);
        } else {
            updatedMonetizeOptions.add(option);
        }
        setMonetizeOptions(updatedMonetizeOptions);
    };

    const toggleVideoOptions = (option: SurveyVideoOptionsType) => {
        const updatedVideoOptions = new Set(videoOptions);
        if (updatedVideoOptions.has(option)) {
            updatedVideoOptions.delete(option);
        } else {
            updatedVideoOptions.add(option);
        }
        setVideoOptions(updatedVideoOptions);
    };

    const toggleSiteOptions = (option: SurveySiteOptionsType) => {
        const updatedSiteOptions = new Set(siteOptions);
        if (updatedSiteOptions.has(option)) {
            updatedSiteOptions.delete(option);
        } else {
            if (option === "has-site" && updatedSiteOptions.has("no-site")) {
                updatedSiteOptions.delete("no-site");
            }
            if (option === "no-site" && updatedSiteOptions.has("has-site")) {
                updatedSiteOptions.delete("has-site");
            }
            updatedSiteOptions.add(option);
        }
        setSiteOptions(updatedSiteOptions);
    };

    const toggleProductSalesOptions = (
        option: SurveyProductSalesOptionsType
    ) => {
        const updatedProductSalesOptions = new Set(productSalesOptions);
        if (updatedProductSalesOptions.has(option)) {
            updatedProductSalesOptions.delete(option);
        } else {
            updatedProductSalesOptions.add(option);
        }
        setProductSalesOptions(updatedProductSalesOptions);
    };

    const isCardSelected = (card: SurveyCardOptionsType) =>
        selectedCards.has(card);

    const handleContinue = () => {
        if (surveyStep === 1) {
            setSurveyStep(2);
            nextStep();
        }
        if (surveyStep === 2) {
            setShouldSkipOnUnload(false);
            postSurveyResults({
                selectedCards,
                selectedStreamOptions: streamOptions,
                selectedMonetizeOptions: monetizeOptions,
                selectedVideoOptions: videoOptions,
                selectedSiteOptions: siteOptions,
                selectedProductSalesOptions: productSalesOptions,
                siteUrl: siteURL
            });

            if (
                videoOptions.has("ready-to-upload") &&
                !videoOptions.has("creating-with-switcher")
            ) {
                redirectToLandingPageWithABTest();
            } else {
                nextStep();
            }
        }
    };

    const handleSkip = useCallback(() => {
        postSurveyResults({
            // capture selected cards if they had already submitted them before the skip
            selectedCards: surveyStep === 2 ? selectedCards : null,
            selectedStreamOptions: null,
            selectedMonetizeOptions: null,
            selectedVideoOptions: null,
            selectedSiteOptions: null,
            selectedProductSalesOptions: null,
            siteUrl: null,
            skippedSurveyStep: surveyStep
        });

        redirectToLandingPageWithABTest();
    }, [
        postSurveyResults,
        surveyStep,
        selectedCards,
        redirectToLandingPageWithABTest
    ]);

    useBeforeUnload(shouldSkipOnUnload, handleSkip);

    const isContinueButtonDisabled = useMemo(() => {
        if (surveyStep === 1) {
            return selectedCards.size === 0;
        } else if (surveyStep === 2) {
            if (selectedCards.has("embed")) {
                if (videoOptions.size === 0 || siteOptions.size === 0) {
                    return true;
                }
            }
            if (selectedCards.has("stream")) {
                if (streamOptions.size === 0) {
                    return true;
                }
            }
            if (selectedCards.has("monetize")) {
                if (monetizeOptions.size === 0) {
                    return true;
                }
            }
        }
        return false;
    }, [
        surveyStep,
        selectedCards,
        videoOptions.size,
        streamOptions.size,
        siteOptions.size,
        monetizeOptions.size
    ]);

    const videosQuestion = useMemo(() => {
        if (selectedCards.has("embed") && selectedCards.has("monetize")) {
            return t("getting-started:survey-step:embed-and-monetize-question");
        }
        if (selectedCards.has("embed")) {
            return t("getting-started:survey-step:embed-question");
        }
        return t("getting-started:survey-step:monetize-source-question");
    }, [t, selectedCards]);

    return (
        <div className={styles["survey-container"]}>
            <h2>
                {t("getting-started:survey-step:title1")}
                <strong>{t("getting-started:survey-step:title2")}</strong>
            </h2>
            {surveyStep === 1 && (
                <>
                    <small className={`${styles["sub-heading"]}`}>
                        {t("getting-started:survey-step:card-sub-heading1")}
                        <strong>
                            {t("getting-started:survey-step:card-sub-heading2")}
                        </strong>
                    </small>
                    <div
                        className={`row ${styles["survey-question-container"]}`}
                    >
                        <button
                            className={
                                isCardSelected("embed")
                                    ? styles["survey-card-selected"]
                                    : styles["survey-card"]
                            }
                            onClick={() => toggleCardSelection("embed")}
                        >
                            <div>
                                <SwitcherEmbedIcon fill="#515251" />
                            </div>
                            <h6>
                                {t("getting-started:survey-step:card-embed")}
                            </h6>
                        </button>
                        <button
                            className={
                                isCardSelected("stream")
                                    ? styles["survey-card-selected"]
                                    : styles["survey-card"]
                            }
                            onClick={() => toggleCardSelection("stream")}
                        >
                            <div className={styles["icon-container"]}>
                                <FaceBookRoundedIcon />
                                <YoutubeRoundedIcon />
                                <TwitchRoundedIcon />
                            </div>
                            <h6>
                                {t("getting-started:survey-step:card-stream")}
                            </h6>
                        </button>
                        <button
                            className={
                                isCardSelected("monetize")
                                    ? styles["survey-card-selected"]
                                    : styles["survey-card"]
                            }
                            onClick={() => toggleCardSelection("monetize")}
                        >
                            <div>
                                <MonetizationIcon />
                            </div>
                            <h6>
                                {t("getting-started:survey-step:card-monetize")}
                            </h6>
                        </button>
                    </div>
                </>
            )}
            {surveyStep === 2 && (
                <>
                    <small className={`${styles["sub-heading"]}`}>
                        {t("getting-started:survey-step:question-sub-heading1")}
                    </small>
                    <div
                        className={`col-12 ${styles["survey-question-container"]}`}
                    >
                        {selectedCards.has("embed") && (
                            <div
                                className={`col-12 ${styles["question-container"]}`}
                            >
                                <p className="text-center">
                                    <strong>
                                        {t(
                                            "getting-started:survey-step:website-question"
                                        )}
                                    </strong>
                                </p>
                                <div
                                    className={`form-check form-check-inline ${styles["multi-select"]}`}
                                >
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:yes"
                                            )}
                                            id={"has-site"}
                                            checked={siteOptions.has(
                                                "has-site"
                                            )}
                                            onChange={() =>
                                                toggleSiteOptions("has-site")
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:no"
                                            )}
                                            id={"no-site"}
                                            checked={siteOptions.has("no-site")}
                                            onChange={() =>
                                                toggleSiteOptions("no-site")
                                            }
                                        ></Checkbox>
                                    </div>
                                </div>
                                <p className="text-center">
                                    <strong>
                                        {t(
                                            "getting-started:survey-step:site-url"
                                        )}
                                    </strong>
                                </p>
                                <div className={`form-check`}>
                                    <div className={styles["option"]}>
                                        <CreateAccountInput
                                            type="text"
                                            id="site-url"
                                            value={siteURL}
                                            onChange={(url) =>
                                                setSiteURL(url.target.value)
                                            }
                                            label={t(
                                                "getting-started:survey-step:site-url-label"
                                            )}
                                        />
                                    </div>
                                </div>

                                <p className="text-center">
                                    <strong>{videosQuestion}</strong>
                                </p>

                                <div
                                    className={`form-check form-check-inline ${styles["multi-select"]}`}
                                >
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:ready-to-upload"
                                            )}
                                            id={"ready-to-upload"}
                                            checked={videoOptions.has(
                                                "ready-to-upload"
                                            )}
                                            onChange={() =>
                                                toggleVideoOptions(
                                                    "ready-to-upload"
                                                )
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:creating-with-switcher"
                                            )}
                                            id={"creating-with-switcher"}
                                            checked={videoOptions.has(
                                                "creating-with-switcher"
                                            )}
                                            onChange={() =>
                                                toggleVideoOptions(
                                                    "creating-with-switcher"
                                                )
                                            }
                                        ></Checkbox>
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedCards.has("stream") && (
                            <div
                                className={`col-12 ${styles["question-container"]}`}
                            >
                                <p className="text-center">
                                    <strong>
                                        {t(
                                            "getting-started:survey-step:stream-question"
                                        )}
                                    </strong>
                                </p>
                                <div
                                    className={`form-check form-check-inline ${styles["multi-select"]}`}
                                >
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:facebook"
                                            )}
                                            id={"facebook"}
                                            checked={streamOptions.has(
                                                "facebook"
                                            )}
                                            onChange={() =>
                                                toggleStreamOptions("facebook")
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:youtube"
                                            )}
                                            id={"youtube"}
                                            checked={streamOptions.has(
                                                "youtube"
                                            )}
                                            onChange={() =>
                                                toggleStreamOptions("youtube")
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:twitch"
                                            )}
                                            id={"twitch"}
                                            checked={streamOptions.has(
                                                "twitch"
                                            )}
                                            onChange={() =>
                                                toggleStreamOptions("twitch")
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:website"
                                            )}
                                            id={"website"}
                                            checked={streamOptions.has(
                                                "website"
                                            )}
                                            onChange={() =>
                                                toggleStreamOptions("website")
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:other"
                                            )}
                                            id={"other"}
                                            checked={streamOptions.has("other")}
                                            onChange={() =>
                                                toggleStreamOptions("other")
                                            }
                                        ></Checkbox>
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedCards.has("monetize") && (
                            <div
                                className={`col-12 ${styles["question-container"]}`}
                            >
                                {selectedCards.has("monetize") &&
                                    !selectedCards.has("embed") && (
                                        <>
                                            <p className="text-center">
                                                <strong>
                                                    {videosQuestion}
                                                </strong>
                                            </p>

                                            <div
                                                className={`form-check form-check-inline ${styles["multi-select"]}`}
                                            >
                                                <div
                                                    className={styles["option"]}
                                                >
                                                    <Checkbox
                                                        label={t(
                                                            "getting-started:survey-step:ready-to-upload"
                                                        )}
                                                        id={"ready-to-upload"}
                                                        checked={videoOptions.has(
                                                            "ready-to-upload"
                                                        )}
                                                        onChange={() =>
                                                            toggleVideoOptions(
                                                                "ready-to-upload"
                                                            )
                                                        }
                                                    ></Checkbox>
                                                </div>
                                                <div
                                                    className={styles["option"]}
                                                >
                                                    <Checkbox
                                                        label={t(
                                                            "getting-started:survey-step:creating-with-switcher"
                                                        )}
                                                        id={
                                                            "creating-with-switcher"
                                                        }
                                                        checked={videoOptions.has(
                                                            "creating-with-switcher"
                                                        )}
                                                        onChange={() =>
                                                            toggleVideoOptions(
                                                                "creating-with-switcher"
                                                            )
                                                        }
                                                    ></Checkbox>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                <p className="text-center">
                                    <strong>
                                        {t(
                                            "getting-started:survey-step:monetize-question"
                                        )}
                                    </strong>
                                </p>
                                <div
                                    className={`form-check form-check-inline ${styles["multi-select"]}`}
                                >
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:on-demand"
                                            )}
                                            id={"On-Demand"}
                                            checked={monetizeOptions.has(
                                                "on-demand"
                                            )}
                                            onChange={() =>
                                                toggleMonetizeOptions(
                                                    "on-demand"
                                                )
                                            }
                                        ></Checkbox>
                                    </div>
                                    <div className={styles["option"]}>
                                        <Checkbox
                                            label={t(
                                                "getting-started:survey-step:subscription"
                                            )}
                                            id={"Subscriptions"}
                                            checked={monetizeOptions.has(
                                                "subscriptions"
                                            )}
                                            onChange={() =>
                                                toggleMonetizeOptions(
                                                    "subscriptions"
                                                )
                                            }
                                        ></Checkbox>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className={`col-12 ${styles["question-container"]}`}
                        >
                            <p className="text-center">
                                <strong>
                                    {t(
                                        "getting-started:survey-step:product-sales-question"
                                    )}
                                </strong>
                            </p>
                            <div
                                className={`form-check form-check-inline ${styles["multi-select"]}`}
                            >
                                <div className={styles["option"]}>
                                    <Checkbox
                                        label={t(
                                            "getting-started:survey-step:in-store"
                                        )}
                                        id={"in-store"}
                                        checked={productSalesOptions.has(
                                            "in-store"
                                        )}
                                        onChange={() =>
                                            toggleProductSalesOptions(
                                                "in-store"
                                            )
                                        }
                                    ></Checkbox>
                                </div>
                                <div className={styles["option"]}>
                                    <Checkbox
                                        label={t(
                                            "getting-started:survey-step:online"
                                        )}
                                        id={"online"}
                                        checked={productSalesOptions.has(
                                            "online"
                                        )}
                                        onChange={() =>
                                            toggleProductSalesOptions("online")
                                        }
                                    ></Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className={styles["button-container"]}>
                <button
                    className={`btn btn-primary ${styles["continue-button"]}`}
                    disabled={isContinueButtonDisabled}
                    onClick={handleContinue}
                >
                    {t("getting-started:survey-step:continue").toUpperCase()}
                </button>
                <button
                    className={styles["skip"]}
                    onClick={handleSkip}
                    onKeyDown={(
                        event: React.KeyboardEvent<HTMLButtonElement>
                    ) => event.key === "Enter" && handleSkip}
                >
                    {t("getting-started:survey-step:skip")}
                </button>
            </div>
        </div>
    );
};
