import React from "react";

import { ResellerInventory } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { isInPast, displayDate } from "helpers/time";

import styles from "./ManageInventoryItemsPage.module.scss";

interface InventoryHeaderProps {
    inventory: ResellerInventory;
}

export const InventoryHeader: React.FC<InventoryHeaderProps> = ({
    inventory
}) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles["header"]}>
            {inventory && (
                <>
                    <div>
                        {inventory?.Price ? (
                            <h4 className="card-title">
                                {inventory?.Price.Name}
                            </h4>
                        ) : (
                            <h4 className="card-title">
                                {t("manage-inventories:any-plan")}
                            </h4>
                        )}
                        {inventory?.ValidUntil ? (
                            <p
                                className={`card-text lead mb-2 ${
                                    isInPast(inventory?.ValidUntil)
                                        ? "border-danger text-danger"
                                        : ""
                                }`}
                            >
                                <strong>
                                    {!isInPast(inventory?.ValidUntil)
                                        ? t("misc:expires")
                                        : t("misc:expired")}
                                </strong>
                                {": "}
                                {displayDate(
                                    inventory?.ValidUntil,
                                    i18n.language
                                )}
                            </p>
                        ) : (
                            <p className="card-text lead mb-2">
                                <strong>{t("misc:non-expiring")}</strong>
                            </p>
                        )}
                    </div>
                    <div>
                        <h5 className="text-muted">{inventory?.Description}</h5>
                        {inventory?.IsUnlimited ? (
                            <p>
                                <strong>
                                    {inventory?.ResellerInventoryRedeemedCount}
                                </strong>
                                {` ${t(
                                    "manage-inventories:redeemed"
                                ).toLowerCase()}`}
                            </p>
                        ) : (
                            <p>
                                <strong>
                                    {inventory?.ResellerInventoryRedeemedCount}
                                </strong>
                                {` ${t("manage-inventories:out-of")} `}
                                <strong>
                                    {inventory?.ResellerInventoryTotalCount}
                                </strong>
                                {` ${t(
                                    "manage-inventories:redeemed"
                                ).toLowerCase()}`}
                            </p>
                        )}
                    </div>
                    <span>
                        <ul>
                            {inventory?.Features.map((feature, key) => (
                                <li key={key}>{feature.Description}</li>
                            ))}
                        </ul>
                    </span>
                </>
            )}
        </div>
    );
};
