import { createRoot } from "react-dom/client";
import { register } from "./serviceWorker";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

register();

serviceWorker.unregister();
