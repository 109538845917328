import React from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useForm, Errors } from "hooks/useForm";
import { ChangePasswordFormValues } from "./types";
import { useTranslation } from "react-i18next";
import styles from "./ProfileForms.module.scss";
import ProfileIcon from "assets/icons/username.svg?react";
import { PasswordCriteria } from "components/password-criteria/PasswordCriteria";

interface ChangePasswordFormProps {
    /** Handler for password update form submission. */
    onSubmit: (payload: ChangePasswordFormValues) => Promise<void>;
}

/**
 * Password change form.
 */
export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
    onSubmit
}: ChangePasswordFormProps) => {
    const { t } = useTranslation();
    const {
        values,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
        passwordValidMap
    } = useForm<ChangePasswordFormValues>(
        {
            oldPassword: "",
            password: "",
            confirmPassword: ""
        },
        onSubmit,
        (values: ChangePasswordFormValues) => {
            let errors: Errors = {};
            if (!values.oldPassword) {
                errors.oldPassword = t("errors:username-error");
            }

            if (!values.password) {
                errors.password = t("errors:password-error");
            }

            if (!values.confirmPassword) {
                errors.confirmPassword = t("errors:password-confirm-error");
            }

            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = t("errors:password-match-error");
            }

            return errors;
        },
        true
    );

    return (
        <>
            <div className={styles["form-card"]}>
                <span className={styles["icon"]}>
                    <ProfileIcon />
                </span>
                <div className={styles["form-card-body"]}>
                    <h4 className={styles["form-card-title"]}>
                        {t("misc:change-password")}
                    </h4>
                    <form onSubmit={handleSubmit}>
                        <TextInput
                            id="oldPassword"
                            type="password"
                            label={t("profile:old-password")}
                            placeholder={t("profile:old-password-placeholder")}
                            value={values.oldPassword}
                            error={errors.oldPassword}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="password"
                            type="password"
                            label={t("profile:new-password")}
                            placeholder={t("profile:new-password-placeholder")}
                            value={values.password}
                            error={errors.password}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="confirmPassword"
                            type="password"
                            label={t("profile:confirm-new-password")}
                            placeholder={t("profile:new-password-placeholder")}
                            value={values.confirmPassword}
                            error={errors.confirmPassword}
                            onChange={handleChange}
                        />
                        {errors.api && (
                            <div className="alert alert-danger" role="alert">
                                {errors.api}
                            </div>
                        )}
                        <PasswordCriteria passwordValidMap={passwordValidMap} />
                        <button
                            type="submit"
                            className={`btn btn-primary ${styles["submit-btn"]}`}
                            disabled={isSubmitting}
                        >
                            {t("misc:change-password").toUpperCase()}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};
