import { exists } from "helpers/booleans";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    BroadcastCreateRequest,
    WebLinkRequest,
    WebLinkRequestType
} from "@switcherstudio/switcher-api-client";
import {
    setAddToPlayers,
    setIsBroadcastCreated,
    setSelectedProduct,
    removeUploadsFromPendingBroadcasts
} from "store/videoUploadSession/slice";
import rollbar from "helpers/rollbar";
import { trackEvent } from "helpers/analyticsHelpers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useCallback, useEffect, useMemo } from "react";
import { UploadedFile } from "store/videoUploadSession/types";
import { AppDispatch } from "store/store";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { useGetUploadSession } from "./useGetUploadSession";

/**
 * A hook that listens for new uploads that are added to the
 * video upload session state after they have been uploaded by Uppy but before they
 * have had a broadcast created and assigned to the uploaded video. The general behavior
 * is similar to processing a queue but without the defined behavior of first-in-first-out ordering.
 * Instead, the broadcasts are just handled as batches to process together.
 *
 * This hook can be used in multiple places and needs to be present anytime an upload is happening
 * (whether in an active modal or in the background as a minimized modal tab) in order to sucessfully
 * finish and connect to Uppy --> Cloudlfare upload process with our internal API's broadcast table.
 */
export const useCreateBroadcastForUpload = ({
    sessionId
}: {
    sessionId: string;
}) => {
    const { userInfo } = useSelector((s: RootState) => s.user);

    const { pendingBroadcasts, files, categories, webLinks, onUploadComplete } =
        useGetUploadSession({ sessionId });

    const { dispatchApiRequest: uploadBroadcast } = useSwitcherClient(
        (client) => client.broadcasts_UploadBroadcast,
        { requestImmediately: false, hideLoading: true }
    );
    const { dispatchApiRequest: postWebLinks } = useSwitcherClient(
        (client) => client.webLink_Post,
        { requestImmediately: false, hideLoading: true }
    );

    const dispatch = useDispatch<AppDispatch>();
    const filesArray = useMemo(
        () => (!!files ? Object.values(files) : []),
        [files]
    );
    const isSingleUpload = useMemo(() => filesArray.length === 1, [filesArray]);

    const { handleUploadThumbnail } = useThumbnailUpload(
        filesArray?.[0]?.thumbnailFile,
        filesArray?.[0]?.thumbnailImageURL
    );

    const createBroadcast = useCallback(
        async (uploadedFile: UploadedFile) => {
            if (uploadedFile.isBroadcastCreated) return;

            const currentDateTime = new Date().toUTCString();
            try {
                // we don't do thumbnails for bulk uploads
                const assetID = isSingleUpload
                    ? (await handleUploadThumbnail())?.Id
                    : null;
                const request: BroadcastCreateRequest = {
                    Broadcast: {
                        Title: uploadedFile.title,
                        Description: uploadedFile.description,
                        ProjectId: userInfo.ProjectId,
                        EnableRecording: true,
                        EnableSwitcherPlayer: true,
                        VideoId: uploadedFile.streamMediaId,
                        CreatedAt: currentDateTime,
                        UpdatedAt: currentDateTime,
                        ActiveAt: currentDateTime,
                        EndedAt: currentDateTime,
                        StartsAt: uploadedFile.startsAt ?? undefined,
                        IsProtected: true,
                        BroadcastStatus: uploadedFile.broadcastStatus ?? 3,
                        ThumbnailAssetId: assetID,
                        Categories: categories,
                        ShowInCatalog: uploadedFile.showInCatalog
                    },
                    AddToPlayers: uploadedFile.addToPlayers,
                    CreatorProductId: uploadedFile.selectedProduct?.Id
                };

                const broadcast = await uploadBroadcast([request]);
                const updatedWebLinks = webLinks.map((link) => ({
                    ...link,
                    BroadcastId: broadcast.Id
                }));
                // Handle weblinks creation
                if (updatedWebLinks?.length > 0) {
                    await postWebLinks([
                        {
                            Id: broadcast.Id,
                            webLinks: updatedWebLinks,
                            Type: WebLinkRequestType.Broadcast
                        } as WebLinkRequest
                    ]);
                }

                // cleanup file session data
                dispatch(
                    setIsBroadcastCreated({
                        sessionId,
                        fileId: uploadedFile.fileId,
                        isBroadcastCreated: true
                    })
                );
                dispatch(
                    setAddToPlayers({
                        sessionId,
                        fileId: uploadedFile.fileId,
                        addToPlayers: []
                    })
                );
                dispatch(
                    setSelectedProduct({
                        sessionId,
                        fileId: uploadedFile.fileId,
                        selectedProduct: undefined
                    })
                );

                return broadcast;
            } catch (e) {
                rollbar.error("Error creating broadcast for upload", e);
            }
            trackEvent("Video Upload", {
                title: uploadedFile.title,
                includeInPlaylist: uploadedFile.addToPlayers.length > 0,
                videoId: uploadedFile.streamMediaId
            });
        },
        [
            isSingleUpload,
            handleUploadThumbnail,
            userInfo?.ProjectId,
            categories,
            uploadBroadcast,
            postWebLinks,
            webLinks,
            dispatch,
            sessionId
        ]
    );

    // create broadcasts for successful uploads as they come in
    useEffect(() => {
        const broadcastsToCreate = Object.values(pendingBroadcasts).filter(
            (pb) => exists(pb)
        );
        if (!broadcastsToCreate?.length) return;

        dispatch(
            removeUploadsFromPendingBroadcasts({
                sessionId,
                files: broadcastsToCreate.map((bcc) => bcc.file)
            })
        );

        broadcastsToCreate.forEach(async (btc) => {
            const broadcast = await createBroadcast(btc.file);
            onUploadComplete(broadcast?.Id);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingBroadcasts]);
};
