import * as postRobot from "post-robot";
import { switcherSdk } from "./switcher-sdk";

interface FetchOptions {
    headers?: { [name: string]: string };
    method?: "GET" | "POST" | "PUT" | "DELETE";
}

export type ApiMethod = "GET" | "POST" | "PUT" | "DELETE";
export interface ApiRequest {
    method: ApiMethod;
    path: string | string[];
    data?: any;
}

export interface UiRequest {
    method:
        | "dismiss"
        | "hideCover"
        | "openShareActivity"
        | "setLoadingText"
        | "setTitle"
        | "showCover"
        | "terminateBCProfileCreation"
        | "setItem"
        | "getItem";
    params: any;
}

const GUID =
    "({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}";
const PROVIDER = new RegExp(`^/api/StreamingProviders/${GUID}/?$`, "i");
const CHANNEL = new RegExp(
    `^/api/StreamingProviders/${GUID}/SwitcherStreamSettings/?$`,
    "i"
);

const ALLOWED = [
    { method: "GET", pattern: PROVIDER },
    { method: "GET", pattern: CHANNEL },
    { method: "POST", pattern: CHANNEL },
    { method: "PUT", pattern: CHANNEL }
];

export function setup(
    endpoint: string,
    access_token: string,
    providerId: string
) {
    const apiListener = postRobot.on<ApiRequest>("api", function (event) {
        return api_handler(endpoint, access_token, event.data);
    });
    const uiListener = postRobot.on<UiRequest>("ui", function (event) {
        return ui_handler(event.data, providerId);
    });

    return { apiListener, uiListener };
}

function api_handler(
    endpoint: string,
    access_token: string,
    event: ApiRequest
) {
    function api_whitelist(method: ApiMethod, path: string): boolean {
        return ALLOWED.some(function (x) {
            return x.method === method && x.pattern.test(path);
        });
    }

    function api_options(event: ApiRequest) {
        let options = { method: event.method || "GET" };

        if (event.method === "POST" || event.method === "PUT") {
            options["body"] = JSON.stringify(event.data);
        }

        return options;
    }

    function request(
        endpoint: string,
        access_token: string,
        path: string,
        options?: FetchOptions
    ) {
        const fullPath = endpoint + encodeURI(path);
        const defaults: FetchOptions = {
            method: "GET",
            headers: {
                Authorization: "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        };
        const config = Object.assign({}, defaults, options);
        return fetch(fullPath, config);
    }

    let path = Array.isArray(event.path) ? event.path.join("/") : event.path;

    if (!api_whitelist(event.method, path)) {
        throw new Error("Disallowed method/path");
    }

    let options = api_options(event);
    return request(endpoint, access_token, path, options).then((res) =>
        res.json()
    );
}

function ui_handler(request: UiRequest, providerId: string) {
    switch (request.method) {
        case "dismiss":
            switcherSdk.dismiss(null);
            break;
        case "hideCover":
            return new Promise<void>((resolve) =>
                switcherSdk.hideCover(null, resolve)
            );
        case "openShareActivity":
            if (!("text" in request.params))
                throw new Error("Missing param: text");
            if (!("url" in request.params))
                throw new Error("Missing param: url");

            switcherSdk.openShareActivity(
                request.params.text,
                request.params.url
            );
            break;
        case "setLoadingText":
            if (!("text" in request.params))
                throw new Error("Missing param: text");

            switcherSdk.setLoadingText(request.params.text);
            break;
        case "setTitle":
            if (!("title" in request.params))
                throw new Error("Missing param: title");

            switcherSdk.setTitle(request.params.title);
            break;
        case "showCover":
            switcherSdk.showCover();
            break;
        case "terminateBCProfileCreation":
            if (!("profile" in request.params))
                throw new Error("Missing param: profile");

            switcherSdk.terminateBCProfileCreation(request.params.profile);
            break;
        case "setItem":
            if (!("key" in request.params))
                throw new Error("Missing param: key");
            if (!("value" in request.params))
                throw new Error("Missing param: value");

            return Promise.resolve(
                window.localStorage.setItem(
                    `${providerId}-${request.params.key}`,
                    request.params.value
                )
            );
        case "getItem":
            if (!("key" in request.params))
                throw new Error("Missing param: key");

            return Promise.resolve(
                window.localStorage.getItem(
                    `${providerId}-${request.params.key}`
                )
            );
        default:
            throw new Error("Unrecognized UI method");
    }
}
