import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageState } from "./types";

const initialState = {
    activeBanner: null
} as PageState;

export const page = createSlice({
    name: "page",
    initialState: initialState,
    reducers: {
        setActiveBanner: (state, { payload }: PayloadAction<JSX.Element>) => {
            state.activeBanner = payload;
        }
    }
});

export const { setActiveBanner } = page.actions;

export default page.reducer;
