import React, { useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { Link } from "react-router-dom";
import { LoginForm } from "../LoginForm";
import {
    getToken,
    getUserOrgs,
    getUserInfo,
    getDefaultUserOrg,
    exchangeCode
} from "store/user/thunks";
import { identify } from "store/user/slice";
import { IUserLogin } from "store/user/types";
import { useTranslation } from "react-i18next";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { configureRollbar } from "store/rollbar/slice";
import rollbar from "helpers/rollbar";
import { useNavigate } from "react-router-dom";
import { useParams } from "hooks/useParams";

export const LoginPage: React.FC = () => {
    const { redirect, code } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const login = useCallback(async () => {
        const userInfo = await dispatch(getUserInfo()).unwrap();
        const userOrgs = await dispatch(getUserOrgs()).unwrap();

        await dispatch(getDefaultUserOrg());
        dispatch(identify({}));
        dispatch(
            configureRollbar({
                UserId: userInfo.UserId
            })
        );

        let destination = "/";

        if (redirect) {
            destination = redirect;
        } else if (userOrgs?.orgs.length > 0) {
            destination = "/workspaces";
        }

        navigate(destination);
    }, [dispatch, navigate, redirect]);

    useEffect(() => {
        // If an authorization code is provided in the params,
        // try to log in immediately.
        const getToken = async () => {
            if (!code) {
                return;
            }

            try {
                await dispatch(exchangeCode(code));
                await login();
            } catch (err) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message:
                            "The code provided is invalid or has expired, please login using your username and password."
                    })
                );
            }
        };
        getToken();
    }, [code, dispatch, login]);

    const onSubmit = useCallback(
        async (form: IUserLogin) => {
            try {
                // Get the token and then log in
                await dispatch(getToken(form));
                await login();
            } catch (err) {
                if (err.name === "ServerError") {
                    // if the API response status code was 5xx
                    // Issue a rollbar warning
                    rollbar.warning("Server error during login.", err);

                    // Show a generic server error notification to the user
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "login:server-error"
                        })
                    );
                } else throw err; // otherwise, pass the error through
            }
        },
        [dispatch, login]
    );
    const accountProjectLink =
        import.meta.env.VITE_ACCOUNT_PROJECT_URL || "/getting-started";
    return (
        <>
            <LoginForm onSubmit={onSubmit} />
            <div className="text-center">
                <Link to="/forgot-password" className={styles["login-links"]}>
                    {t("login:forgot-password").toUpperCase()}
                </Link>
                <p className={styles["create-account"]}>
                    {t("login:dont-have-account")}{" "}
                    <Link
                        to={accountProjectLink}
                        className={styles["login-links"]}
                    >
                        {t("login:create-account").toUpperCase()}
                    </Link>
                </p>
            </div>
        </>
    );
};
