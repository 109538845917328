import styles from "./index.module.scss";
import NoRecordingsIcon from "assets/icons/recording-file.svg?react";
import { useTranslation } from "react-i18next";

export interface CloudRecordingsEmptyStateProps {
    uploadVideoButton: React.ReactNode;
}

export const CloudRecordingsEmptyState: React.FC<
    CloudRecordingsEmptyStateProps
> = ({ uploadVideoButton }) => {
    const { t } = useTranslation();

    return (
        <div className={styles["container"]}>
            <NoRecordingsIcon />
            <strong>{t("cloud:no-recordings-1")}</strong>
            <p>{t("cloud:no-recordings-2")}</p>
            {uploadVideoButton}
        </div>
    );
};
