import React, { useCallback, useEffect, useState } from "react";
import { client } from "api/client";
import {
    ChangePasswordFormValues,
    EditProfileFormValues,
    Profile
} from "./types";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { EditProfileForm } from "./EditProfileForm";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { refreshToken, getUserInfo } from "store/user/thunks";
import rollbar from "helpers/rollbar";
import { AccountDeletionRequestForm } from "./AccountDeletionRequestForm";
import { RootState } from "store/reducers";

export const ProfilePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const isMountedRef = useIsMountedRef();
    const [profile, setProfile] = useState(null);
    const user = useSelector((state: RootState) => state.user);

    useEffect(() => {
        const init = async function () {
            try {
                const _profile = await client.account_GetProfile();
                setProfile(_profile);
            } catch (e) {
                rollbar.error("Error getting account profile", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        };

        if (isMountedRef.current) {
            init();
        }
    }, [isMountedRef, dispatch]);

    const onChangePasswordSubmit = useCallback(
        async (form: ChangePasswordFormValues) => {
            try {
                await client.account_ChangePassword({
                    OldPassword: form.oldPassword,
                    NewPassword: form.password,
                    ConfirmPassword: form.confirmPassword
                });

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:password-updated"
                    })
                );
            } catch (e) {
                rollbar.error("Error updating password", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        },
        [dispatch]
    );

    const onEditProfileSubmit = useCallback(
        async (form: EditProfileFormValues) => {
            try {
                const body: Profile = {
                    Company: form.company,
                    Email: form.email,
                    FirstName: form.firstName,
                    LastName: form.lastName,
                    PhoneNumber: form.phoneNumber,
                    PrimaryColor: "",
                    ProfileImageUrl: "",
                    Title: form.title,
                    WebsiteUrl: form.website
                };
                await client.account_PutProfile(body);
                await dispatch(refreshToken());
                await dispatch(getUserInfo());

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "profile:success-message-profile"
                    })
                );
            } catch (e) {
                rollbar.error("Error updating user profile", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        },
        [dispatch]
    );

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <ChangePasswordForm onSubmit={onChangePasswordSubmit} />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    {!!profile && (
                        <EditProfileForm
                            onSubmit={onEditProfileSubmit}
                            profile={profile}
                        />
                    )}
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    <AccountDeletionRequestForm user={user} />
                </div>
            </div>
        </>
    );
};
