import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { useGetCloudUsageForUploadSessions } from "./useGetCloudUsageForUploadSessions";
import { useGetUploadSession } from "./useGetUploadSession";
import { useResetUploadSession } from "./useResetUploadSession";

export const useHandleUploadModalAccess = ({
    sessionId,
    showNotifications = true
}: {
    sessionId: string;
    showNotifications?: boolean;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { addedVideosExceedCloudUsage } = useGetCloudUsageForUploadSessions();
    const { activeModal } = useSelector((s: RootState) => s.modal);
    const { files } = useGetUploadSession({ sessionId });
    const filesArray = useMemo(
        () => (!!files ? Object.values(files) : []),
        [files]
    );
    const { resetUploadSession } = useResetUploadSession();

    // only consider videos that haven't started uploading yet
    const videosAwaitingUpload = useMemo(
        () => filesArray.filter((file) => file.isAwaitingUpload),
        [filesArray]
    );
    const hasAddedFiles = useMemo(
        () => videosAwaitingUpload?.length,
        [videosAwaitingUpload?.length]
    );

    /**
     * A hook that manages the process of opening the upload modal after files are added to the entry point
     * drag and drop component. This hook decides whether to proceed to opening up the modal, or to deny access to the
     * modal and clear the session.
     *
     * If added video files count would exceed cloud storage limits, we abandon upload session and go back to drag and drop step
     * Else, we proceed to open the upload modal to view file(s)
     */
    useEffect(() => {
        if (addedVideosExceedCloudUsage) {
            resetUploadSession({ sessionId });
            if (showNotifications) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        // just taking the first sentence here since it is a notification alert
                        message: t("video-player-page:attention-action").split(
                            "."
                        )?.[0]
                    })
                );
            }
        } else if (hasAddedFiles && activeModal.id !== sessionId) {
            dispatch(
                setActiveModal({
                    id: sessionId,
                    type: Modals.TriggeredVideoUploadModal
                })
            );
        }
    }, [
        activeModal,
        addedVideosExceedCloudUsage,
        showNotifications,
        dispatch,
        hasAddedFiles,
        resetUploadSession,
        sessionId
    ]);
};
