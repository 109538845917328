import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PreferencesState, SetPreferencePayload } from "./types";

export const preferences = createSlice({
    name: "preferences",
    initialState: {
        hideVideoTutorialsWidget: {}
    } as PreferencesState,
    reducers: {
        setPreference: (
            state,
            { payload }: PayloadAction<SetPreferencePayload>
        ) => {
            const { preference, user, value } = payload;
            state[preference][user] = value;
        }
    }
});
export const { setPreference } = preferences.actions;

export default preferences.reducer;
