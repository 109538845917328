import { InlineButton } from "components/buttons/InlineButton";
import { trackEvent } from "helpers/analyticsHelpers";
import styles from "./index.module.scss";
import SwitcherShareIcon from "assets/icons/share.svg?react";
import { useTranslation } from "react-i18next";
import { Button } from "components/buttons/Button";
import { useCallback } from "react";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { EditCustomSlugModal } from "components/modal/EditCustomSlugModal";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";

interface ShareLinkProps {
    /** the catalog's or collection's microsite link */
    shareLink: string;
    /** whether catalog or collection */
    variant: "catalog" | "collection";
    /** function that handles updating slug */
    updateCustomSlug?: (customSlug: string) => void;
    currentSlug: string;
}

const iconColor = {
    backgroundColor: "#0C4F60"
};

export const ShareLink = ({
    shareLink,
    variant,
    currentSlug,
    updateCustomSlug
}: ShareLinkProps) => {
    const { t } = useTranslation();
    const clipboardExists = !!navigator.clipboard;
    const dispatch = useDispatch<AppDispatch>();
    const isCatalog = variant === "catalog";

    const copyLink = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(shareLink);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-share-link"
                })
            );
        }
        trackEvent("Copied Share Link", { variant });
    }, [dispatch, shareLink, clipboardExists, variant]);

    const openEditLinkModal = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.EditBroadcastModal,
                type: Modals.EditBroadcastModal,
                component: (
                    <EditCustomSlugModal
                        currentSlug={currentSlug}
                        onSave={updateCustomSlug}
                    />
                )
            })
        );
    }, [dispatch, currentSlug, updateCustomSlug]);

    return (
        <div className={`${styles["share-link"]}`}>
            <div className={styles["share-link-title"]}>
                <div className={styles["icon"]} style={iconColor}>
                    <SwitcherShareIcon />
                </div>
                <div className={`${styles["title-text"]}`}>
                    {t("catalog-page:share-title")}
                </div>
            </div>

            <p className={`text-small ${styles["text-color"]}`}>
                {isCatalog
                    ? t("catalog-page:share-subhead")
                    : t("collection-page:share-subhead")}
            </p>
            <div className={`${styles["share-link-field-wrapper"]}`}>
                <span className={styles["share-link-field"]}>{shareLink}</span>
                <InlineButton variant="tertiary" onClick={openEditLinkModal}>
                    {t("catalog-page:edit-link")}
                </InlineButton>
            </div>

            <div className={styles["share-link-footer"]}>
                <div className={styles["share-link-button-container"]}>
                    <Button type="outline" onClick={copyLink} title={shareLink}>
                        {t("catalog-page:share-action-copy")}
                    </Button>
                </div>
            </div>
        </div>
    );
};
