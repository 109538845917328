import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import styles from "./Loading.module.scss";
import LoadingAnimation from "./LoadingAnimation";

interface LoadingProps {
    /** A value which determines if the loading element is visible. */
    isLoading?: boolean;
    offset?: boolean;
}

/** A loading display element. */
const Loading: React.FC<LoadingProps> = ({
    isLoading,
    offset = false
}: LoadingProps) => {
    const { loading, isFullscreen } = useSelector(
        (state: RootState) => state.loading
    );

    return (
        <div
            className={`${styles["loading-container"]} ${
                isLoading || loading > 0 ? styles["active"] : ""
            } ${isFullscreen ? styles["fullscreen"] : ""} ${
                offset ? styles["offset"] : ""
            }`}
        >
            <LoadingAnimation size={"lg"} />
        </div>
    );
};

export default Loading;
