import store from "store/store";
import Rollbar from "rollbar";

function typeName(x) {
    let name = typeof x;
    if (name !== "object") {
        return name;
    }
    if (!x) {
        return "null";
    }
    if (x instanceof Error) {
        return "error";
    }
    return {}.toString
        .call(x)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
}

const prepareArgs = (
    name: string,
    error: any,
    customData: any
): Rollbar.LogResult[] => {
    const isError = typeName(error) === "error";
    const args = isError
        ? [name, error, { data: customData }]
        : [name, { error, data: customData }];
    return args;
};

export default {
    error: function (
        name: string,
        error?: any,
        customData?: any
    ): Rollbar.LogResult {
        return store
            .getState()
            .rollbar.instance.error(...prepareArgs(name, error, customData));
    },
    info: function (name: string, customData?: any): Rollbar.LogResult {
        return store
            .getState()
            .rollbar.instance.info(...prepareArgs(name, null, customData));
    },
    warning: function (
        name: string,
        error?: any,
        customData?: any
    ): Rollbar.LogResult {
        return store
            .getState()
            .rollbar.instance.warning(...prepareArgs(name, error, customData));
    }
};
