import React from "react";
import { PlatformId } from "views/page-content/platforms/types";
import { SimulcastFacebookDestinationSelector } from "./SimulcastFacebookDestinationSelector";
import { SimulcastYoutubeDestinationSelector } from "./SimulcastYoutubeDestinationSelector";
import { SimulcastRtmpDestinationSelector } from "./SimulcastRtmpDestinationSelector";
import { SimulcastTwitchDestinationSelector } from "./SimulcastTwitchDestinationSelector";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import styles from "./SimulcastDestinationSelector.module.scss";
import { useNavigate } from "react-router-dom";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useTranslation } from "react-i18next";
import { SimulcastPlayerDestinationSelector } from "./SimulcastPlayerDestinationSelector";
import { SimulcastGenericDestinationSelector } from "./SimulcastGenericDestinationSelector";
import { useParams } from "hooks/useParams";

export const SimulcastPlatformDestinationSelector: React.FC = () => {
    const { platformId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isInBrowser } = useSwitcherSdk(true);
    const user = useSelector((state: RootState) => state.user);
    const userMaxDests =
        user.userInfo?.FeatureLimitClaims?.[
            "cloudflaresimulcasting:maxdestinations"
        ];
    const generalForm = useSelector(
        (s: RootState) => s.platforms.general.generalForm
    );
    const isAtDestLimit =
        userMaxDests ===
        generalForm.selectedSimulcastDestinations.filter(
            (d) => d.platform !== PlatformId.VideoPlayer
        ).length;

    return (
        <>
            {!isInBrowser && (
                <div className={styles["header"]}>
                    <button
                        className="btn btn-outline-primary"
                        onClick={() =>
                            navigate(`/platforms/simulcast/destinations`)
                        }
                    >
                        <ChevronLeft /> {t("buttons:back")}
                    </button>
                </div>
            )}
            {
                // based on the platform render the proper 'container component'
                (function () {
                    switch (platformId) {
                        case PlatformId.Facebook:
                            return (
                                <SimulcastFacebookDestinationSelector
                                    disableAll={isAtDestLimit}
                                />
                            );
                        case PlatformId.Youtube:
                            return (
                                <SimulcastYoutubeDestinationSelector
                                    disableAll={isAtDestLimit}
                                />
                            );
                        case PlatformId.Twitch:
                            return (
                                <SimulcastTwitchDestinationSelector
                                    disableAll={isAtDestLimit}
                                />
                            );
                        case PlatformId.VideoPlayer:
                            return <SimulcastPlayerDestinationSelector />;
                        case "custom":
                            return (
                                <SimulcastRtmpDestinationSelector
                                    disableAll={isAtDestLimit}
                                />
                            );
                        default:
                            return (
                                <SimulcastGenericDestinationSelector
                                    platformId={platformId as PlatformId}
                                />
                            );
                    }
                })()
            }
        </>
    );
};
