import React from "react";
import styles from "./BrandProfileStyles.module.scss";
import { Errors } from "hooks/useForm";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/inputs/text-input/TextInput";

interface SocialProfileLinksFormProps {
    facebookLink: string;
    linkedinLink: string;
    twitchLink: string;
    youtubeLink: string;
    instagramLink: string;
    snapchatLink: string;
    tiktokLink: string;
    twitterLink: string;
    onChange: (values: string, key: string) => void;
    errors: Errors;
}

export const SocialProfileLinksForm = (props: SocialProfileLinksFormProps) => {
    const { t } = useTranslation();

    return (
        <>
            <h4 className={styles["form-card-title"]}>
                {t("brand-profile:social-profile-links")}
            </h4>
            <div className="row">
                <div className="col-lg-6">
                    <TextInput
                        label="Facebook"
                        type="text"
                        id="facebook"
                        placeholder={t("brand-profile:facebook-placeholder")}
                        value={props.facebookLink}
                        error={props.errors.facebook}
                        onChange={(e) => {
                            props.onChange(e.target.value, "facebookLink");
                        }}
                    />
                    <TextInput
                        label="Instagram"
                        type="text"
                        id="instagram"
                        placeholder={`@${t("brand-profile:user")}`}
                        value={props.instagramLink}
                        error={props.errors.instagram}
                        onChange={(e) => {
                            props.onChange(e.target.value, "instagramLink");
                        }}
                    />
                    <TextInput
                        label="LinkedIn"
                        type="text"
                        id="linkedin"
                        placeholder={t("brand-profile:username")}
                        value={props.linkedinLink}
                        error={props.errors.linkedin}
                        onChange={(e) => {
                            props.onChange(e.target.value, "linkedinLink");
                        }}
                    />
                    <TextInput
                        label="Snapchat"
                        type="text"
                        id="snapchat"
                        placeholder={t("brand-profile:username")}
                        value={props.snapchatLink}
                        error={props.errors.snapchat}
                        onChange={(e) => {
                            props.onChange(e.target.value, "snapchatLink");
                        }}
                    />
                </div>
                <div className="col-lg-6">
                    <TextInput
                        label="TikTok"
                        type="text"
                        id="tiktok"
                        placeholder={`@${t("brand-profile:user")}`}
                        value={props.tiktokLink}
                        error={props.errors.tiktok}
                        onChange={(e) => {
                            props.onChange(e.target.value, "tiktokLink");
                        }}
                    />
                    <TextInput
                        label="Twitch"
                        type="text"
                        id="twitch"
                        placeholder={t("brand-profile:username")}
                        value={props.twitchLink}
                        error={props.errors.twitch}
                        onChange={(e) => {
                            props.onChange(e.target.value, "twitchLink");
                        }}
                    />
                    <TextInput
                        label="X"
                        type="text"
                        id="twitter"
                        placeholder={`@${t("brand-profile:user")}`}
                        value={props.twitterLink}
                        error={props.errors.twitter}
                        onChange={(e) => {
                            props.onChange(e.target.value, "twitterLink");
                        }}
                    />
                    <TextInput
                        label="YouTube"
                        type="text"
                        id="youtube"
                        placeholder={t("brand-profile:channel-name")}
                        value={props.youtubeLink}
                        error={props.errors.youtube}
                        onChange={(e) => {
                            props.onChange(e.target.value, "youtubeLink");
                        }}
                    />
                </div>
            </div>
            {props.errors.api && (
                <div className="alert alert-danger" role="alert">
                    {props.errors.api}
                </div>
            )}
        </>
    );
};
