export class SwitcherApiResponse<T> {
    public response: T;
    public methodStr: string;
    public argsStr: string;
    public lastRequested: Date;

    constructor(normalizedRequestStr: string, response: T) {
        const [methodStr, argsStr] = normalizedRequestStr?.split("____");
        this.response = response;
        this.methodStr = methodStr;
        this.argsStr = argsStr;
        this.lastRequested = new Date();
    }
}

export interface ApiState {
    // group all responses by method name
    [clientMethod: string]: {
        // cache method responses separately by args provided
        [args: string]: SwitcherApiResponse<any>;
    };
}

export interface SetResponsePayload {
    normalizedKey: string;
    response: SwitcherApiResponse<any>;
}
