import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiState, SwitcherApiResponse } from "./types";

const initialState = {} as ApiState;

export const api = createSlice({
    name: "api",
    initialState: initialState,
    reducers: {
        resetApiCache: () => initialState,
        setApiResponse: <T>(
            state,
            { payload }: PayloadAction<SwitcherApiResponse<T>>
        ) => {
            state[payload.methodStr] = {
                ...(state[payload.methodStr] && {
                    ...state[payload.methodStr]
                }),
                [payload.argsStr]: payload
            };
        }
    }
});

export const { resetApiCache, setApiResponse } = api.actions;

export default api.reducer;
