import { DownloadStep } from "./DownloadStep";
import { SelectPlanStep } from "./SelectPlanStep";
import { CreateAccountStep } from "./CreateAccountStep";
import { SurveyStep } from "./SurveyStep";

const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

const SubscribeFlow = ({
    couponHook,
    stepsHook,
    onSelectPlanButtonClicked,
    selectedPlan,
    clientId,
    redirectStatus,
    defaultToSubscribeFlow,
    isTrialing,
    tag
}) => {
    const {
        resellerInventoryItem,
        handleCouponCode,
        removeCoupon,
        showCouponInput
    } = couponHook;

    const { step, nextStep } = stepsHook;

    switch (step) {
        case 1:
            return (
                <SelectPlanStep
                    resellerInventoryItem={resellerInventoryItem}
                    handleCouponCode={handleCouponCode}
                    removeCoupon={removeCoupon}
                    showCouponInput={showCouponInput}
                    onSelectPlanButtonClicked={onSelectPlanButtonClicked}
                />
            );
        case 2:
            if (!selectedPlan) return <></>;
            return (
                <CreateAccountStep
                    nextStep={nextStep}
                    clientId={clientId}
                    redirectStatus={redirectStatus}
                    defaultToSubscribeFlow={defaultToSubscribeFlow}
                    resellerInventoryItem={resellerInventoryItem}
                    isTrialing={isTrialing}
                    tag={tag}
                    selectedPlan={selectedPlan}
                    handleCouponCode={handleCouponCode}
                    removeCoupon={removeCoupon}
                    showCouponInput={showCouponInput}
                />
            );
        case 3:
        case 4:
            return <SurveyStep nextStep={nextStep} />;
        case 5:
            if (skipDownloadStep) return <></>;
            return <DownloadStep />;
        default:
            return <></>;
    }
};

export default SubscribeFlow;
