import React, { useState } from "react";
import styles from "../CreateOrUpdateSwitcherPlayerModal/index.module.scss";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { GatedContentPassFormProps } from ".";
import { Errors } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";

export const OneTimePassField = ({
    values: { oneTimePrice },
    errors,
    onChange
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (key: string, val: any) => void;
}) => {
    const { t } = useTranslation();

    const [oneTimePriceDebounced, setOneTimePriceDebounced] =
        useState<string>(oneTimePrice);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e?.currentTarget?.value;

        setOneTimePriceDebounced(newValue);
    };

    useDebounce(oneTimePriceDebounced, 500, {
        onUpdate: (price) => handleParentChange("oneTimePrice", price)
    });

    const handleParentChange = (key: string, value: any) => {
        onChange(key, value);
    };

    return (
        <>
            <div className={`${mpstyles["pricing-form-expanded-content"]}`}>
                <div className={styles["label-row"]}>
                    <label htmlFor="price">{"Price"}</label>
                </div>
                <div className={mpstyles["price-row"]}>
                    <span className={mpstyles["price-currency"]}>US$</span>
                    <input
                        id="price"
                        type="number"
                        min={import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE}
                        className={`form-control ${mpstyles["price-input"]}`}
                        value={oneTimePriceDebounced}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        onChange={handleChange}
                    />
                </div>
                {errors.oneTimePrice && (
                    <div className={mpstyles["sub-error"]}>
                        {errors.oneTimePrice}
                    </div>
                )}
            </div>
        </>
    );
};
