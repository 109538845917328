import { client } from "api/client";
import { twitchClient } from "api/twitch/twitch-client";
import {
    FacebookDestination,
    TwitchCategory,
    FacebookProfile,
    FacebookContentTag,
    GetTwitchUserAndChannelPayload
} from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LiveStream } from "api/youtube/types";
import { youtube } from "api/youtube/youtube-client";
import { facebook } from "api/facebook/facebook-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { IChannelUpdateOptions } from "api/twitch/types";
import { switcherSdk } from "utils/switcher-sdk";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";
import {
    FaceBookSwitcherStreamSetting,
    SwitcherStreamSetting,
    YouTubeSwitcherStreamSetting,
    TwitchSwitcherStreamSetting,
    VideoPlayerSwitcherStreamSetting,
    Broadcast,
    BroadcastStatus,
    CreatorProductEntitlementsBindingModelDiscriminator
} from "@switcherstudio/switcher-api-client";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { userHasClaim } from "helpers/userHelper";
import rollbar from "helpers/rollbar";

/* Facebook */
export const getFacebookUserProfile = createAsyncThunk(
    "platforms/getFacebookUserProfile",
    async () => {
        const info: FacebookProfile = await facebook.userProfileInfo.get();
        return info;
    }
);

export const selectFacebookDestination = createAsyncThunk(
    "platforms/selectFacebookDestination",
    async (destination: FacebookDestination) => {
        return destination;
    }
);

export const searchFBTags = createAsyncThunk(
    "platforms/searchFBTags",
    async (searchTerm: string) => {
        const result = await facebook.contentTags.get(searchTerm);
        const res: FacebookContentTag[] = result.data.map((d) => {
            return {
                ...d,
                selected: false
            };
        });
        return res;
    }
);

export const createFacebookStream = createAsyncThunk<
    SwitcherStreamSetting,
    | {
          broadcastId: string;
          selectedDest?: FacebookProfile;
          showNotification: boolean;
          isSimulcast: boolean;
      }
    | undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("platforms/createFacebookStream", async (args, { getState, dispatch }) => {
    const state = getState();
    const { selectedDestination, facebookForms } = state.platforms.facebook;
    const dest = args?.selectedDest || selectedDestination;
    const facebookForm = facebookForms[dest.id];
    try {
        const result: SwitcherStreamSetting =
            await facebook.helpers.handleCreation(
                dest,
                facebookForm,
                args?.broadcastId,
                args?.isSimulcast
            );
        await client.streamDestinations_PostStreamDestination(
            {
                Name: dest.name,
                StreamDestinationId: dest.id,
                StreamDestinationType: dest.edge,
                IsDefault: true
            },
            state.user.userInfo?.UserId
        );

        if (args?.showNotification === undefined || args?.showNotification) {
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:stream-is-synced",
                    messageOptions: { platformName: "Facebook" }
                })
            );
        }

        if (
            !args?.isSimulcast &&
            switcherSdk.clientId !== "Generic" &&
            switcherSdk.api.versionMajor >= 2
        ) {
            switcherSdk.terminateBCProfileCreation(result);
        }
        return result;
    } catch (e) {
        throw e;
    }
});

export const updateFacebookStream = createAsyncThunk<
    FaceBookSwitcherStreamSetting,
    | {
          selectedDest?: FacebookProfile;
          isSimulcast: boolean;
      }
    | undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>(
    "platforms/updateFacebookStream",
    async (args = { isSimulcast: false }, { getState, dispatch }) => {
        const state = getState();
        const {
            general,
            facebook: { selectedDestination }
        } = state.platforms;
        const {
            selectedStreams: { facebook: selectedStream },
            generalForm
        } = general;
        const dest = args.selectedDest || selectedDestination;

        try {
            const result: FaceBookSwitcherStreamSetting =
                await facebook.helpers.updateStream(
                    selectedStream,
                    generalForm,
                    dest
                );

            await client.streamDestinations_PostStreamDestination(
                {
                    Name: dest.name,
                    StreamDestinationId: dest.id,
                    StreamDestinationType: dest.edge,
                    IsDefault: true
                },
                state.user.userInfo?.UserId
            );

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:stream-is-synced",
                    messageOptions: { platformName: "Facebook" }
                })
            );

            return result;
        } catch (e) {
            throw e;
        }
    }
);

/* Twitch */
export const getTwitchUserAndChannel = createAsyncThunk(
    "platforms/getTwitchUserAndChannel",
    async () => {
        const user = await twitchClient.getUser();
        const { id } = user;
        const channelInfo = await twitchClient.getChannelInfo(id);

        return {
            channelInfo,
            user
        } as GetTwitchUserAndChannelPayload;
    }
);

export const getTwitchIngestServers = createAsyncThunk(
    "platforms/getTwitchIngestServers",
    async () => {
        const response = await client.twitch_GetIngestServers();
        // filter out the default server, deprecated server, and servers that are not available
        const filterServers = (i) =>
            !i.name.includes("DEPRECATED") || i.available !== 1 || !i.default;
        const sortServers = (a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        };
        const defaultServer = response.ingests[0];
        defaultServer.name = `Default - ${defaultServer.name}`;
        defaultServer.default = true;
        const ingestServers = [
            defaultServer,
            ...response.ingests.filter(filterServers).sort(sortServers)
        ];

        return ingestServers;
    }
);

export const searchTwitchCategories = createAsyncThunk(
    "platforms/searchTwitchCategories",
    async (searchTerm: string) => {
        let games = [] as Array<TwitchCategory>;

        if (searchTerm.length >= 2) {
            try {
                const data = await twitchClient.searchCategories(searchTerm);
                games = !!data ? data : [];
            } catch (e) {
                // error notification about failing to search
            }
        }

        return games;
    }
);

export const updateTwitchChannel = createAsyncThunk<
    TwitchSwitcherStreamSetting,
    | { broadcastId?: any; isSimulcast?: boolean; showNotification?: boolean }
    | undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("platforms/updateTwitchChannel", async (args, { getState, dispatch }) => {
    const state = getState();
    const { selectedQuality, title } = state.platforms.general.generalForm;
    const { channelInfo, user, twitchForm } = state.platforms.twitch;

    // update channel info
    const body: IChannelUpdateOptions = {
        title,
        game_id: twitchForm.selectedGame
    };
    const isTwitchPartner = user.broadcaster_type === "partner";
    if (isTwitchPartner) {
        body["delay"] = twitchForm.delay.toString();
    }

    try {
        const { stream_key } = await twitchClient.getStreamKey(
            channelInfo.broadcaster_id
        );
        await twitchClient.modifyChannelInfo(channelInfo.broadcaster_id, body);

        // update Switcher settings
        const setting = {
            ...selectedQuality.setting,
            broadcastId: args?.broadcastId,
            name: "Twitch",
            url:
                twitchForm.ingestServer?.default === false
                    ? twitchForm.ingestServer.url_template.replace(
                          /\/{stream_key}/,
                          ""
                      )
                    : "rtmp://jfk.contribute.live-video.net/app/",
            "rtmp-stream": stream_key,
            "channel-name": title,
            "permalink-url":
                "https://www.twitch.tv/" + channelInfo.broadcaster_name
        };

        let data;
        if (args?.isSimulcast) {
            data = await client.twitch_PostTwitchSwitcherStreamSetting(setting);
        } else {
            data = await client.twitch_PutTwitchSwitcherStreamSetting(setting);
        }

        if (args?.showNotification) {
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:stream-is-synced",
                    messageOptions: { platformName: "Twitch" }
                })
            );
        }

        if (
            !args.isSimulcast &&
            switcherSdk.clientId !== "Generic" &&
            switcherSdk.api.versionMajor >= 2
        ) {
            switcherSdk.terminateBCProfileCreation(data);
        }
        return data;
    } catch (e) {
        throw e;
    }
});

/* YouTube */
export async function getYouTubeSwitcherStreamSetting() {
    const data = await client.youTube_GetSwitcherStudioSetting();

    return data;
}

export async function updateSwitcherYouTubeStreamSettings({
    broadcastId,
    streamInfo,
    qualityInfo,
    title,
    isSimulcast,
    swiBroadcastId = undefined
}: {
    isSimulcast: boolean;
    swiBroadcastId: string;
    broadcastId: string;
    streamInfo: LiveStream;
    qualityInfo: ChannelSetting;
    title: string;
}) {
    const setting = {
        ...qualityInfo,
        "rtmp-stream": streamInfo.cdn.ingestionInfo.streamName,
        url: streamInfo.cdn.ingestionInfo.ingestionAddress,
        "permalink-url": `https://www.youtube.com/watch?v=${broadcastId}`,
        "youtube-stream-id": streamInfo.id,
        "youtube-broadcast-id": broadcastId,
        "user-editable": false,
        "channel-name": title,
        "is-deleted": false,
        broadcastId: swiBroadcastId,
        version: "1.0"
    };

    if (isSimulcast) {
        const result =
            await client.youTube_PostYouTubeSwitcherStreamSetting(setting);
        return result;
    }
    const result =
        await client.youTube_PutYouTubeSwitcherStreamSetting(setting);

    if (
        switcherSdk.clientId !== "Generic" &&
        switcherSdk.api.versionMajor >= 2
    ) {
        switcherSdk.terminateBCProfileCreation(result);
    }
    return result;
}

export const createYouTubeEvent = createAsyncThunk<
    YouTubeSwitcherStreamSetting,
    | {
          showNotification: boolean;
          swiBroadcastId: string;
          isSimulcast: boolean;
      }
    | undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("platforms/createYouTubeEvent", async (args, { getState, dispatch }) => {
    const state = getState();
    const { selectedQuality, title, description, scheduledStartTime } =
        state.platforms.general.generalForm;
    const { isForKids, allowEmbedding, selectedPrivacy } =
        state.platforms.youtube.youtubeForm;
    try {
        const { id } = await youtube.liveBroadcasts.insert(
            {
                part: "id,snippet,contentDetails,status",
                alt: "json"
            },
            {
                snippet: {
                    title,
                    description,
                    scheduledStartTime
                },
                contentDetails: {
                    monitorStream: {
                        enableMonitorStream: false,
                        broadcastStreamDelayMs: 60000
                    },
                    enableDvr: true,
                    enableEmbed: allowEmbedding,
                    enableContentEncryption: false,
                    enableLowLatency: false,
                    recordFromStart: true,
                    startWithSlate: false,
                    enableAutoStart: true,
                    enableAutoStop: false
                },
                status: {
                    privacyStatus: selectedPrivacy.value,
                    selfDeclaredMadeForKids: isForKids
                }
            }
        );
        const { streamInfo } = await youtube.helpers.createAndBindLiveStream(
            id,
            selectedQuality.name
        );
        const data = await updateSwitcherYouTubeStreamSettings({
            broadcastId: id,
            streamInfo,
            qualityInfo: selectedQuality.setting,
            title,
            swiBroadcastId: args?.swiBroadcastId,
            isSimulcast: args?.isSimulcast
        });
        if (args?.showNotification === undefined || args?.showNotification) {
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:stream-is-synced",
                    messageOptions: { platformName: "YouTube" }
                })
            );
        }

        if (
            switcherSdk.clientId !== "Generic" &&
            switcherSdk.api.versionMajor >= 2
        ) {
            if (!args?.isSimulcast) {
                switcherSdk.terminateBCProfileCreation(data);
            }
        }
        return data;
    } catch (e) {
        throw e;
    }
});

export const updateYoutubeEvent = createAsyncThunk<
    YouTubeSwitcherStreamSetting,
    | {
          swiBroadcastId?: string;
          isSimulcast: boolean;
      }
    | undefined,
    {
        state: RootState;
    }
>(
    "platforms/updateYoutubeEvent",
    async (args = { isSimulcast: false }, { getState }) => {
        const state = getState();
        const {
            generalForm: {
                title,
                description,
                selectedQuality,
                scheduledStartTime
            },
            selectedStreams: { youtube: selectedStream }
        } = state.platforms.general;

        try {
            await youtube.liveBroadcasts.update(
                {
                    part: "id,snippet,contentDetails,status",
                    alt: "json"
                },
                {
                    ...selectedStream,
                    snippet: {
                        title,
                        description,
                        scheduledStartTime
                    }
                }
            );

            const { streamInfo } =
                await youtube.helpers.createAndBindLiveStream(
                    selectedStream.id,
                    selectedQuality.name
                );
            const data = await updateSwitcherYouTubeStreamSettings({
                broadcastId: selectedStream.id,
                streamInfo,
                qualityInfo: selectedQuality.setting,
                title,
                swiBroadcastId: args.swiBroadcastId,
                isSimulcast: args.isSimulcast
            });

            if (
                switcherSdk.clientId !== "Generic" &&
                switcherSdk.api.versionMajor >= 2
            ) {
                if (!args.isSimulcast) {
                    switcherSdk.terminateBCProfileCreation(data);
                }
            }
            return data;
        } catch (e) {
            throw e;
        }
    }
);

/* Swticher Player */
export const updateVideoPlayerStream = createAsyncThunk<
    VideoPlayerSwitcherStreamSetting,
    | {
          broadcast: Broadcast;
          isSimulcast?: boolean;
          showNotification?: boolean;
      }
    | undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("platforms/updateVideoPlayerStream", async (args, { getState, dispatch }) => {
    const state = getState();
    const { selectedQuality, title } = state.platforms.general.generalForm;
    const { enableLiveShopping, playlistIds, creatorProductId } =
        state.platforms.videoplayer.videoPlayerForm;
    const enablePayPerView = userHasClaim("gatedcontent");
    try {
        // Create default video player switcher settings
        const setting: VideoPlayerSwitcherStreamSetting = {
            ...selectedQuality.setting,
            broadcastId: args?.broadcast.Id,
            "channel-name": title
        };

        let data;
        if (args?.isSimulcast) {
            // Create a new stream setting for the simulcast
            data =
                await client.videoPlayer_PostVideoPlayerStreamSetting(setting);
        } else {
            // Create the input for this specific video player broadcast
            const input = await client.broadcasts_CreateInput(
                args?.broadcast.Id
            );
            setting.url = input.rtmps?.url;
            setting["rtmp-stream"] = input.rtmps?.streamKey;

            // Replace the existing stream setting with new stream setting
            data =
                await client.videoPlayer_PutVideoPlayerStreamSetting(setting);

            // Set additional broadcast settings only if this is a single platform use
            args.broadcast.InputId = input.uid;
            args.broadcast.EnableSwitcherPlayer = true;
            args.broadcast.EnableRecording = true;
            args.broadcast.BroadcastStatus = BroadcastStatus._1;
            args.broadcast.EnableLiveShopping = enableLiveShopping;
            await client.broadcasts_PutBroadcast(
                args?.broadcast.Id,
                args?.broadcast,
                false
            );

            if (playlistIds?.length > 0) {
                const playlistPromises = playlistIds.map(async (playlistId) => {
                    const playlistBroadcastResponse =
                        await client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcastById(
                            playlistId,
                            args.broadcast.Id
                        );

                    if (enablePayPerView && !!creatorProductId) {
                        await client.creatorProductEntitlements_Create({
                            ProductEntitlements: [
                                {
                                    ProductId: creatorProductId,
                                    VideoPlayerPlaylistBroadcastId:
                                        playlistBroadcastResponse
                                            ?.PlaylistBroadcast?.Id,
                                    Discriminator:
                                        CreatorProductEntitlementsBindingModelDiscriminator._2
                                }
                            ]
                        });
                    }
                });

                let responses = await Promise.allSettled(playlistPromises);
                for (const resp of responses) {
                    if (resp.status === "rejected") {
                        rollbar.error(
                            "Error updating video player stream",
                            resp
                        );
                    }
                }
            } else {
                rollbar.warning("Switch Player Event No Playlist", {
                    broadcastId: args.broadcast.Id
                });
            }
        }

        if (args?.showNotification) {
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:stream-is-synced",
                    messageOptions: { platformName: "Switcher Player" }
                })
            );
        }

        if (
            !args.isSimulcast &&
            switcherSdk.clientId !== "Generic" &&
            switcherSdk.api.versionMajor >= 2
        ) {
            switcherSdk.terminateBCProfileCreation(data);
        }
        return data;
    } catch (e) {
        throw e;
    }
});
