import React, { useCallback } from "react";
import { client } from "api/client";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExternalChannelSettingsForm } from "./ExternalChannelSettingsForm";
import { switcherSdk } from "utils/switcher-sdk";

interface ConfigureExternalChannelSettingsContainerProps {
    platform: StreamingProvider;
}

export const ConfigureExternalChannelSettingsContainer: React.FC<
    ConfigureExternalChannelSettingsContainerProps
> = ({ platform }) => {
    const { t } = useTranslation("platforms");
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const save = useCallback(
        async (quality) => {
            try {
                const existingSettings =
                    await client.externalProviderSwitcherStreamSettings_GetExternalProviderSwitcherStreamSetting(
                        platform.Id
                    );
                const update = {
                    ...existingSettings,
                    "video-frame-width": quality.width,
                    "video-frame-height": quality.height,
                    "video-bit-rate": quality.bitrate
                };

                await client.externalProviderSwitcherStreamSettings_PutExternalProviderSwitcherStreamSetting(
                    platform.Id,
                    update
                );

                if (
                    switcherSdk.clientId !== "Generic" &&
                    switcherSdk.api.versionMajor >= 2
                ) {
                    switcherSdk.terminateBCProfileCreation(update);
                } else {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: "messages:stream-is-synced",
                            messageOptions: { platformName: platform.Name }
                        })
                    );
                    navigate("/platforms");
                }
            } catch (err) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "messages:external-settings-save-error"
                    })
                );
            }
        },
        [dispatch, navigate, platform]
    );

    return (
        <>
            {platform && (
                <ExternalChannelSettingsForm
                    platformId={platform.Id}
                    platformName={platform.Name}
                    imgSrc={platform.LogoUrl}
                    streamKey={platform.ChannelSetting?.["rtmp-stream"]}
                    streamName={platform.ChannelSetting["channel-name"]}
                    streamUrl={platform.ChannelSetting.url}
                    qualityHeight={
                        platform.ChannelSetting["video-frame-height"]
                    }
                    onClick={save}
                    btnText={t("buttons:save")}
                />
            )}
        </>
    );
};
