import { SilverSunnStripeCreditCard } from "@switcherstudio/switcher-api-client";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { PaymentBrandIcon } from "components/icons/PaymentBrandIcon";

interface PaymentMethodCardV2Props {
    card: SilverSunnStripeCreditCard;
    /** Requests card removal from the api. Takes the userId and the cardId*/
    removeCard?: (cardId: string) => void;
    removeCardLoading?: boolean;
    variant?: "default" | "compact";
}

export const PaymentMethodCardV2: React.FC<PaymentMethodCardV2Props> = ({
    card,
    removeCard,
    removeCardLoading,
    variant = "default"
}) => {
    const { t } = useTranslation();
    const brandIcon = <PaymentBrandIcon brandSlug={card.Brand} />;

    const isCompact = useMemo<boolean>(() => variant === "compact", [variant]);

    return (
        <div
            className={`${styles["payment-method-card"]} ${
                card?.Default ? styles["primary"] : ""
            }`}
        >
            <div className={styles["card-details"]}>
                {!isCompact && card?.Default && (
                    <div className={styles["primary-card-tag"]}>
                        {t("misc:primary")}
                    </div>
                )}

                <span className={styles["card-number"]}>
                    {brandIcon} {card.Brand} {t("misc:ending-in")}{" "}
                    {`${card.LastFour}`}
                </span>
                {!isCompact && (
                    <>
                        {card.Expired ? (
                            <small className={styles["card-expired"]}>
                                {t("misc:expired")} {card.ExpirationMonth}/
                                {card.ExpirationYear}
                            </small>
                        ) : (
                            <small className={styles["card-expiration"]}>
                                {t("misc:expires")} {card.ExpirationMonth}/
                                {card.ExpirationYear}
                            </small>
                        )}
                    </>
                )}
                {!isCompact && !card?.Default && (
                    <>
                        <div className={styles["remove-card"]}>
                            <button
                                className="btn btn-link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeCard(
                                        card.SilverSunnStripeCreditCardId
                                    );
                                }}
                                disabled={removeCardLoading}
                            >
                                {t("buttons:delete")}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
