import React, { useMemo } from "react";
import { CreatorProductVideoPlayerEntitlement } from "@switcherstudio/switcher-api-client";
import { Badge } from "../types";
import GatedIcon from "assets/icons/gated.svg?react";
import styles from "../index.module.scss";

interface PlayerBadgesProps {
    entitlement: CreatorProductVideoPlayerEntitlement;
}

export const PlayerBadges: React.FC<PlayerBadgesProps> = ({ entitlement }) => {
    const playerBadges = useMemo<Badge[]>(() => {
        const badges: Badge[] = [];
        if (entitlement) {
            badges.push({
                slug: entitlement.Product.Name,
                icon: <GatedIcon />
            });
        }
        return badges;
    }, [entitlement]);

    return (
        <>
            {playerBadges.length > 0 && (
                <div
                    className={`badges-container ${styles["badges-container"]}`}
                >
                    {playerBadges.map((badge) => {
                        return (
                            <div
                                className={` ${styles["badge"]} ${
                                    styles[`badge-${badge.slug}`]
                                }`}
                                title={badge.slug}
                                key={badge.slug}
                            >
                                {badge.icon}
                                <p>{badge.slug}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
