import React from "react";
import { useTranslation } from "react-i18next";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";

interface ConnectExternalChannelContainerProps {
    platform: StreamingProvider;
}
export const ConnectExternalChannelContainer: React.FC<
    ConnectExternalChannelContainerProps
> = ({ platform }) => {
    const { t } = useTranslation("platforms");
    return (
        <>
            <div className="alert alert-info" role="alert">
                {t("no-stream-information")}{" "}
                <a href={platform.HelpUrl}>
                    {t("configure-external-channel-message", {
                        platformName: platform.Name
                    })}
                </a>
            </div>
        </>
    );
};
