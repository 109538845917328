import { displayAmount } from "helpers/stripe";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlanType } from ".";
import styles from "../index.module.scss";

export interface FeaturesListProps {
    planType: PlanType;
    amount: number;
}

export const FeaturesList: React.FC<FeaturesListProps> = ({
    planType,
    amount
}) => {
    const { t } = useTranslation("select-plan");

    const header = useMemo<string>(() => {
        switch (planType) {
            case "business":
                return t("business-header");
            case "merchant":
                return t("merchant-header");
            default:
                return "";
        }
    }, [planType, t]);

    const features = useMemo<string[]>(
        () =>
            t(`${planType}-features`, {
                returnObjects: true
            }),
        [planType, t]
    );

    if (planType === "enterprise") {
        return (
            <div className={styles["features-list"]}>
                <p>
                    <strong>{t("enterprise-paragraph1")}</strong>{" "}
                    {t("enterprise-paragraph2")}
                </p>
            </div>
        );
    }

    if (planType === "pass") {
        return (
            <div className={styles["features-list"]}>
                <p>
                    <strong>{t("pass-paragraph1")}</strong>{" "}
                    {t("pass-paragraph2", {
                        replace: { amount: displayAmount(amount) }
                    })}
                </p>
            </div>
        );
    }

    return (
        <div className={styles["features-list"]}>
            <p>{header}</p>
            <ul>
                {features.map((f, key) => (
                    <li className={styles[`${planType}-list-item`]} key={key}>
                        {f}
                    </li>
                ))}
            </ul>
        </div>
    );
};
