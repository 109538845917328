import React from "react";
import { useTranslation } from "react-i18next";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useThirdPartySdk } from "hooks/useThirdPartySdk";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { PlatformImage } from "components/image/PlatformImage";
import styles from "./IframePlatformContainer.module.scss";

interface IframePlatformContainerProps {
    platform: StreamingProvider;
}

export const IframePlatformContainer: React.FC<
    IframePlatformContainerProps
> = ({ platform }) => {
    const { t } = useTranslation("platforms");
    const { isInBrowser } = useSwitcherSdk(true);
    const { ticket } = useSelector((s: RootState) => s.user);

    useThirdPartySdk(isInBrowser, {
        accessToken: ticket?.access_token,
        platformId: platform.Id
    });

    return (
        <>
            {isInBrowser && (
                <div className="row justify-content-center">
                    <div className="col-lg-8 d-flex flex-column align-items-center">
                        <div className={styles["platform-image-container"]}>
                            <PlatformImage platformId={platform?.Id || ""} />
                        </div>
                        <div className="alert alert-info">
                            {t("iframe-platform-message", {
                                platformName: platform?.Name
                            })}
                        </div>
                    </div>
                </div>
            )}
            {!isInBrowser && (
                <iframe
                    title={`${platform.Name}-window`}
                    src={platform.AppViewUrl || ""}
                    frameBorder="0"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        height: "100%",
                        width: "100%"
                    }}
                ></iframe>
            )}
        </>
    );
};
