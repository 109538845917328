import { useState } from "react";
import styles from "./index.module.scss";
import { AddVideos } from "components/add-videos/AddVideos";
import { usePageHeader } from "hooks/usePageHeader";
import { CustomHeaderState } from "store/header/types";

export const VideoLibraryPage = () => {
    const [headerData, setHeaderData] = useState<CustomHeaderState>(
        {} as CustomHeaderState
    );

    usePageHeader(headerData);

    return (
        <div className={styles["video-library-page"]}>
            <AddVideos variant="video-library" setHeaderData={setHeaderData} />
        </div>
    );
};
