import React from "react";
import { LoginInput } from "components/inputs/text-input/LoginInput";
import { IUserLogin } from "store/user/types";
import { useForm, Errors } from "hooks/useForm";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
    /** Handler for login form submission. */
    onSubmit: (payload: IUserLogin) => Promise<void>;
}

/**
 * Login form for the Switcher Studio Dashboard.
 */
export const LoginForm: React.FC<LoginFormProps> = ({
    onSubmit
}: LoginFormProps) => {
    const { t } = useTranslation();
    const { values, errors, isSubmitting, handleChange, handleSubmit } =
        useForm<IUserLogin>(
            {
                username: "",
                password: ""
            },
            async (values: IUserLogin): Promise<void> => await onSubmit(values),
            (values: IUserLogin) => {
                let errors: Errors = {};
                if (!values.username) {
                    errors.username = t("errors:username-required-error");
                } else if (!/\S+@\S+\.\S+/.test(values.username)) {
                    errors.username = t("errors:username-invalid-error");
                }

                if (!values.password) {
                    errors.password = t("errors:password-required-error");
                }

                return errors;
            }
        );

    return (
        <>
            <form onSubmit={handleSubmit}>
                <LoginInput
                    label={t("login:email-address")}
                    type="email"
                    id="username"
                    value={values.username}
                    error={errors.username}
                    onChange={handleChange}
                />
                <LoginInput
                    label={t("login:password")}
                    type="password"
                    id="password"
                    value={values.password}
                    error={errors.password}
                    onChange={handleChange}
                />
                {errors.api && (
                    <div className="alert alert-danger" role="alert">
                        {t(
                            "errors:" +
                                (errors.apiJSON?.error
                                    ? errors.apiJSON.error
                                    : "incorrect-login")
                        )}
                    </div>
                )}
                <button
                    type="submit"
                    className="btn btn-white md-btn btn-block mb-4"
                    disabled={isSubmitting}
                >
                    {t("page-titles:login").toUpperCase()}
                </button>
            </form>
        </>
    );
};
