import React from "react";
import styles from "./AssetCardDelete.module.scss";
import { Asset } from "@switcherstudio/switcher-api-client";
import { AssetCard } from "views/page-content/cloud/AssetCard";
import TrashIcon from "assets/icons/trash.svg?react";
import { useTranslation } from "react-i18next";

interface AssetCardDeleteProps {
    asset: Asset;
    onClick: (asset: Asset) => void;
    selected?: boolean;
    selectMode?: boolean;
    size?: string;
}

export const AssetCardDelete: React.FC<AssetCardDeleteProps> = (
    props: AssetCardDeleteProps
) => {
    const { t } = useTranslation();

    return (
        <div className={styles["delete-card"]}>
            <div
                className={styles["delete-icon"]}
                onClick={() => props.onClick(props.asset)}
            >
                <TrashIcon />
            </div>
            <div className={styles["delete-asset"]}>
                <AssetCard {...props} />
            </div>
            <div
                className={styles["delete-text"]}
                onClick={() => props.onClick(props.asset)}
            >
                {t("buttons:delete")}
            </div>
        </div>
    );
};
