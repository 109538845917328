import React from "react";
import { BannerContentMessage } from "./BannerContentMessage";
import { MessageContentState } from "../types";

interface BannerContentProps {
    messageProps: MessageContentState;
}

export const BannerContent: React.FC<BannerContentProps> = ({
    messageProps
}) => {
    if (messageProps) {
        return (
            <BannerContentMessage
                leftMessageProps={messageProps.leftMessageProps}
                linkProps={messageProps.linkProps}
                rightMessage={messageProps.rightMessage}
            />
        );
    }
};
