import { useMemo } from "react";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
import DragIndicatorIcon from "assets/icons/drag_indicator.svg?react";
import { DisabledVariant } from "../BroadcastDetails";
import { useTranslation } from "react-i18next";

export enum DetailsVariant {
    Vertical = "vertical",
    Horizontal = "horizontal"
}

interface BaseDetailsProps {
    isDraggable?: boolean;
    thumbnail: JSX.Element;
    title: string;
    detailRows: JSX.Element;
    actionItems: JSX.Element;
    variant?: DetailsVariant;
    disabled?: boolean;
    disabledVariant?: DisabledVariant;
    hasToggleRow?: boolean;
}
export const BaseDetails = ({
    isDraggable = false,
    thumbnail,
    title,
    detailRows,
    actionItems,
    variant = DetailsVariant.Horizontal,
    disabled = false,
    disabledVariant = DisabledVariant.Disabled,
    hasToggleRow = false
}: BaseDetailsProps) => {
    const cx = classnames.bind(styles);
    const { t } = useTranslation();

    const details = useMemo(
        () =>
            variant === DetailsVariant.Vertical ? (
                <div className={styles["details-vertical"]}>
                    <div className={styles["detail-lines"]}>
                        <h6>
                            {title !== "" && title !== null
                                ? title
                                : t("players:untitled-collection")}
                        </h6>
                        {detailRows && <>{detailRows}</>}
                    </div>
                    {thumbnail && (
                        <div className={styles["thumbnail"]}>{thumbnail}</div>
                    )}
                </div>
            ) : (
                <div
                    className={`${styles["details"]} ${hasToggleRow ? styles["has-toggle-row"] : ""}`}
                >
                    {thumbnail && (
                        <div className={styles["thumbnail"]}>{thumbnail}</div>
                    )}
                    <div className={styles["detail-lines"]}>
                        <h6>
                            {title !== "" && title !== null
                                ? title
                                : t("players:untitled-collection")}
                        </h6>
                        {detailRows && <>{detailRows}</>}
                    </div>
                </div>
            ),
        [variant, title, detailRows, thumbnail, hasToggleRow, t]
    );

    return (
        <div
            className={cx("item", {
                "item-vertical": variant === DetailsVariant.Vertical,
                "draggable": isDraggable,
                "no-interact":
                    disabled && disabledVariant === DisabledVariant.NoInteract
            })}
        >
            {isDraggable && <DragIndicatorIcon />}
            {details}
            {actionItems && (
                <div className={styles["actions"]}>{actionItems}</div>
            )}
        </div>
    );
};
