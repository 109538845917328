import PlayerIcon from "assets/icons/switcher-player.svg?react";
import styles from "./index.module.scss";

export const PlayerPlaceholder = () => {
    return (
        <div className={`placeholder ${styles["container"]}`}>
            <PlayerIcon />
        </div>
    );
};
