import { useState, useEffect } from "react";
import { client } from "api/client";
import { useIsMountedRef } from "./useIsMountedRef";
import rollbar from "helpers/rollbar";
import { VideoPlayerResponse } from "@switcherstudio/switcher-api-client";

export const useGetProjectsVideoPlayer = (
    projectId: string,
    hasAccess: boolean
): { loading: boolean; projects: VideoPlayerResponse[] } => {
    const isMountedRef = useIsMountedRef();

    const [projects, setProjects] = useState<VideoPlayerResponse[]>();

    useEffect(() => {
        const get = async () => {
            if (hasAccess == null) return;
            try {
                if (hasAccess) {
                    const response = await client
                        .projectsVideoPlayer_GetVideoPlayer(projectId)
                        .then((response) => {
                            return response.map((project) => {
                                project.EmbedCode = project.EmbedCode.replace(
                                    /\s\s+/g,
                                    " "
                                );
                                return project;
                            });
                        });

                    if (isMountedRef.current) {
                        setProjects(() => [...response]);
                    }
                } else {
                    if (isMountedRef.current) {
                        setProjects(() => [
                            {
                                ProjectId: "0",
                                ProjectName: "DEMO",
                                MicrositeUrl:
                                    import.meta.env.VITE_SWITCHER_PLAYER_URL +
                                    "/" +
                                    projectId,
                                EmbedCode:
                                    '<div class="dff402f7-5be0-4890-b831-95c5b63ddb42" data-hostname="' +
                                    import.meta.env.VITE_SWITCHER_PLAYER_URL +
                                    '" data-path="/embed" data-projectid=' +
                                    projectId +
                                    ' data-location="iframe"></div><script src = "' +
                                    import.meta.env.VITE_SWITCHER_PLAYER_URL +
                                    '/embed.js"></script>'
                            }
                        ]);
                    }
                }
            } catch (e) {
                rollbar.error("Error getting projects video player", e);
            }
        };

        get();
    }, [isMountedRef, projectId, hasAccess]);

    return { loading: projects ? false : true, projects };
};
