import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MyTeamPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import {
    mustBeOrgAdmin,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { client } from "api/client";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { openConfirmation } from "store/confirmation/slice";

export const MyTeamPage: React.FC = () => {
    const isMounted = useIsMountedRef();
    useRedirectIfDisallowed(mustBeOrgAdmin);
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const [org] = useState(user.defaultOrg.Organization);
    const [orgInviteLimit] = useState(org.MaximumUsers || 10);
    const [orgUsers, setOrgUsers] = useState([]);
    const [email, setEmail] = useState("");
    const [validInput, setValidInput] = useState(false);
    const { t } = useTranslation();

    const init = useCallback(() => {
        async function _init() {
            const users = await client.organizationUsers_GetOrganizationUsers(
                org.Id
            );
            setOrgUsers(users);
        }

        _init();
    }, [org.Id]);

    useEffect(() => {
        init();
    }, [isMounted, org.Id, init]);

    const handleChange = useCallback(
        (event) => {
            setValidInput(
                event.target.value === "" ? false : event.target.validity.valid
            );
            setEmail(event.target.value);
        },
        [setEmail]
    );

    const handleSubmit = useCallback(
        async ($event) => {
            if ($event) $event.preventDefault(); // prevents page reload on enter
            if (orgUsers.length >= orgInviteLimit || !validInput) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: !validInput
                            ? "errors:invalid-email"
                            : "errors:org-size-limit"
                    })
                );

                return;
            }

            try {
                await client.organizationUsers_PostOrganizationUser(org.Id, {
                    Email: email
                });
                await init();

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:invite-sent",
                        messageOptions: { email }
                    })
                );

                setEmail("");
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:invite-fail"
                    })
                );
            }
        },
        [
            org.Id,
            email,
            dispatch,
            orgInviteLimit,
            orgUsers.length,
            validInput,
            init
        ]
    );

    const handleReset = useCallback(
        async (user) => {
            dispatch(
                openConfirmation({
                    message: "messages:reset-confirmation",
                    messageOptions: {
                        email: user.Email
                    },
                    onSuccess: async () => {
                        try {
                            await client.organizationUsers_PostSendInvite(
                                org.Id,
                                user.Id
                            );

                            dispatch(
                                addNotification({
                                    type: NotificationType.Success,
                                    message: "messages:password-reset",
                                    messageOptions: { email: user.Email }
                                })
                            );
                        } catch (e) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:reset-password-error"
                                })
                            );
                        }
                    }
                })
            );
        },
        [org.Id, dispatch]
    );

    const handleRemove = useCallback(
        async (user) => {
            dispatch(
                openConfirmation({
                    message: "messages:remove-confirmation",
                    messageOptions: {
                        email: user.Email
                    },
                    onSuccess: async () => {
                        try {
                            await client.organizationUsers_DeleteOrganizationUser(
                                org.Id,
                                user.Id
                            );
                            await init();

                            dispatch(
                                addNotification({
                                    type: NotificationType.Success,
                                    message: "messages:user-removed",
                                    messageOptions: { email: user.Email }
                                })
                            );
                        } catch (e) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:remove-user-error",
                                    messageOptions: { email: user.Email }
                                })
                            );
                        }
                    }
                })
            );
        },
        [org.Id, dispatch, init]
    );

    return (
        <div>
            <h4 className={styles["org-name"]}>{org.Name}</h4>
            <section className={styles["header"]}>
                <div className={styles["progress-bar-container"]}>
                    <p>
                        {t("my-team:invites-sent", {
                            amount: `${orgUsers.length} / ${orgInviteLimit}`
                        })}
                    </p>
                    <ProgressBar
                        hideCount
                        maxValue={orgInviteLimit}
                        currentValue={orgUsers.length}
                        variant={"cloud-assets"}
                    />
                </div>
                <div className={styles["invite-container"]}>
                    <form onSubmit={handleSubmit}>
                        <label className="sr-only" htmlFor="email-invite">
                            {t("my-team:email-label")}
                        </label>
                        <input
                            type="email"
                            className={`form-control ${styles["text-input"]}`}
                            id="email-invite"
                            placeholder="example@domain.com"
                            value={email}
                            onChange={handleChange}
                        />
                        <button
                            disabled={
                                orgUsers.length >= orgInviteLimit || !validInput
                            }
                            className="btn btn-primary btn-sm"
                        >
                            {t("buttons:invite")}
                        </button>
                    </form>
                </div>
            </section>

            <ul className={styles["user-list"]}>
                {orgUsers.map((u) => (
                    <li key={u.Id}>
                        <div>{u.Email}</div>
                        <div className={styles["button-group"]}>
                            <button
                                className="btn btn-sm btn-primary"
                                onClick={() => handleReset(u)}
                            >
                                {t("page-titles:reset-password")}
                            </button>
                            {u.Roles.indexOf("OrgAdmin") !== -1 ? null : (
                                <button
                                    className="btn btn-sm.btn-secondary"
                                    onClick={() => handleRemove(u)}
                                >
                                    {t("buttons:remove")}
                                </button>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
