import React from "react";
import styles from "./index.module.scss";
import CheckmarkIcon from "assets/icons/checkmark-simple.svg?react";
import { CheckboxProps } from "../Checkbox";

interface StyledCheckboxOrRadioProps extends Partial<CheckboxProps> {
    type: "checkbox" | "radio";
    checked: boolean;
}

export const StyledCheckboxOrRadio = ({
    id = "checkbox",
    type,
    checked,
    label,
    disabled = false,
    onChange = () => {}
}: StyledCheckboxOrRadioProps) => {
    return (
        <div className={`checkbox-container ${styles["checkbox-container"]}`}>
            {label && (
                <label
                    className={`checkbox-container-label ${styles["checkbox-container-label"]}`}
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            <div
                className={`checkbox-container-input  ${styles["checkbox-container-input"]}`}
            >
                <input
                    id={id}
                    className={styles["checkbox"]}
                    type={type}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                />
                <CheckmarkIcon className={styles["checkbox-icon"]} />
            </div>
        </div>
    );
};
