import React, { createContext } from "react";
import { RouterProvider as ReactRouterProvider } from "react-router-dom";
import BrowserRouter from "router";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "./localization/i18n";
import { bootSegment } from "helpers/analyticsHelpers";
import promiseFinally from "promise.prototype.finally";
import { Client } from "api/client";
import "helpers/polyfills";
import store from "./store/store";
import braze from "helpers/braze";
import { AppWrapper } from "components/AppWrapper";

promiseFinally.shim();
bootSegment();
braze.init();

const switcherClient = new Client({ showLoading: false });
export const SwitcherClientContext = createContext(switcherClient);

// Enables dark theme - https://lukelowrey.com/css-variable-theme-switcher/
// var storedTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
// if (storedTheme)
//     document.documentElement.setAttribute('data-theme', storedTheme)

const persistor = persistStore(store);

const App = () => {
    return (
        <Provider store={store}>
            <SwitcherClientContext.Provider value={switcherClient}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppWrapper>
                        <ReactRouterProvider router={BrowserRouter()} />
                    </AppWrapper>
                </PersistGate>
            </SwitcherClientContext.Provider>
        </Provider>
    );
};

export default App;
