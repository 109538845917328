import React from "react";
import ResetIcon from "assets/icons/reload.svg?react";
import styles from "./VideoLink.module.scss";
import { useTranslation } from "react-i18next";

interface VideoLinkProps {
    value: string | undefined;
    copy: () => Promise<void>;
    reset: () => Promise<void>;
    canCopy: boolean;
}

export const VideoLink: React.FC<VideoLinkProps> = ({
    value,
    reset,
    copy,
    canCopy
}: VideoLinkProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`input-group mb-3 ${styles["video-link"]}`}>
                <div
                    className={`input-group-prepend ${styles["reset-control"]}`}
                >
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={reset}
                    >
                        <ResetIcon />
                    </button>
                </div>
                <input
                    type="text"
                    className={`form-control ${styles["room-url-input"]}`}
                    value={value}
                    readOnly
                />
                {canCopy ? (
                    <div
                        className={`input-group-append ${styles["copy-control"]}`}
                    >
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={copy}
                        >
                            {t("buttons:copy-link").toUpperCase()}
                        </button>
                    </div>
                ) : null}
            </div>
        </>
    );
};
