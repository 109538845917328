import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReauthenticateModal } from "components/modal/ReauthenticateModal";
import styles from "./AccountDeletion.module.scss";
import { trackEvent } from "helpers/analyticsHelpers";

export interface AccountDeletionSurveyViewProps {
    back: () => void;
    requestDeletion: () => Promise<void>;
    onChange?: (value: string) => void;
}

export const AccountDeletionSurveyView: React.FC<
    AccountDeletionSurveyViewProps
> = ({ back, requestDeletion, onChange }) => {
    const { t } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>("");

    const handleFeedback = useCallback(
        (e: string) => {
            setFeedback(e);
            onChange && onChange(!e ? null : e);
        },
        [onChange]
    );

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        trackEvent("Submitted Account Deletion Survey", { feedback });
    };

    return (
        <>
            <div className="form-control">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="deletionSurveyTextInput">
                        {t("delete-account:why-delete-options:text-input")}
                    </label>
                    <br></br>
                    <textarea
                        className={`form-control ${styles["survey-feedback"]}`}
                        id="deletionSurveyTextInput"
                        value={feedback}
                        placeholder={t("delete-account:feedback-placeholder")}
                        onChange={(e) => handleFeedback(e.target.value)}
                        rows={4}
                    />
                    <div className="btns">
                        <button
                            className={`btn btn-grey ${styles["btn"]}`}
                            onClick={back}
                        >
                            {t("buttons:back").toUpperCase()}
                        </button>
                        <button
                            className={`btn btn-warn`}
                            onClick={() => setModalIsOpen(true)}
                        >
                            {t("delete-account:delete-account").toUpperCase()}
                        </button>
                    </div>
                </form>
            </div>
            <div>
                <ReauthenticateModal
                    isOpen={modalIsOpen}
                    setIsOpen={setModalIsOpen}
                    onSuccess={requestDeletion}
                    continueButtonText={t("buttons:deletion-request-confirm")}
                    paragraph={t("delete-account:modal-paragraph")}
                    backButtonText={t("buttons:cancel")}
                />
            </div>
        </>
    );
};
