import { useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { AppDispatch } from "store/store";
import { identify, logout } from "store/user/slice";
import { refreshToken, getUserInfo } from "store/user/thunks";
import { useSwitcherSdk } from "./useSwitcherSdk";
import { resetNotifications } from "store/notification/slice";
import { resetEvents } from "store/events/slice";
import { resetApiCache } from "store/api/slice";

export interface UseRefreshTokenOptions {
    lazyLoad?: boolean;
}

export function useRefreshToken(
    { lazyLoad }: UseRefreshTokenOptions = { lazyLoad: false }
) {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const { isInBrowser } = useSwitcherSdk(true);
    const dispatch = useDispatch<AppDispatch>();
    // Get a new refresh token and userInfo

    const getNewRefreshToken = useCallback(async () => {
        try {
            await dispatch(refreshToken());
            await dispatch(getUserInfo());
            dispatch(identify({}));
            setIsAuthenticated(true);
        } catch (err) {
            // if refresh token has expired, logout
            dispatch(resetNotifications());
            dispatch(resetApiCache());
            dispatch(resetEvents());
            dispatch(logout());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isInBrowser) {
            if (!lazyLoad) {
                getNewRefreshToken();
            }
        } else {
            setIsAuthenticated(true);
        }
    }, [getNewRefreshToken, isInBrowser, lazyLoad]);

    return { isAuthenticated, getNewRefreshToken };
}
