import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateHeader, resetHeader } from "store/header/slice";
import { CustomHeaderState } from "store/header/types";

/**
 * usePageHeader is a hook that provides a way to update the page header by dispatching the updateHeader action
 * with the provided headerProps. If no headerProps are provided, the hook will reset the header to its initial state
 * when the component is dismounted.
 *
 * @param {CustomHeaderState} [headerProps] The header properties to update the page header with.
 * @returns {Object} An object with an updatePageHeader function that can be used to update the page header.
 */
export const usePageHeader = (headerProps?: CustomHeaderState) => {
    const dispatch = useDispatch();
    const updatePageHeader = useCallback(
        (args: CustomHeaderState) => {
            dispatch(updateHeader({ ...headerProps, ...args }));
        },
        [dispatch, headerProps]
    );

    useEffect(() => {
        if (headerProps) {
            updatePageHeader(headerProps);
        }
        // reset headers on page dismount
        return () => dispatch(resetHeader()) as unknown as void;
    }, [dispatch, headerProps, updatePageHeader]);

    return { updatePageHeader };
};
