import React, { Dispatch, SetStateAction } from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import {
    Broadcast,
    CloudflareVideo,
    CreatorProductVideoPlayerEntitlement,
    CreatorProductVideoPlayerPlaylistBroadcastEntitlement,
    EntitySummaryResponse,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import { AddVideos } from "components/add-videos/AddVideos";

export interface VideoMultiSelectModalProps {
    isMultiple: boolean;
    allowAdditional?: boolean;
    onSelect: (broadcastIds: string[]) => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    previouslySelectedBroadcastIds: string[];
    buttonText?: string;
    modalTitle?: string;
    onClose?: (params: any) => any;
    showUnpublished?: boolean;
}

export interface BroadcastsHashItem {
    broadcast: Broadcast;
    players?: VideoPlayer[];
    playlistBroadcastEntitlements?: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[];
    playerEntitlements?: CreatorProductVideoPlayerEntitlement[];
    videoResponse: CloudflareVideo;
    subsequentVideoResponses: CloudflareVideo[];
    metrics: EntitySummaryResponse;
    selected: boolean;
    disabled: boolean;
}
export interface BroadcastsHash {
    [key: string]: BroadcastsHashItem;
}

export interface AddVideosModalProps extends VideoMultiSelectModalProps {
    playerId: string;
    isOpen: boolean;
}

export const AddVideosModal = ({
    playerId,
    isMultiple,
    onSelect,
    isOpen,
    setIsOpen,
    previouslySelectedBroadcastIds,
    buttonText,
    showUnpublished = true,
    onClose,
    modalTitle
}: AddVideosModalProps) => {
    const { t } = useTranslation();

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            stylesOverride={{
                padding: "0",
                maxHeight: "unset",
                maxWidth: "700px"
            }}
        >
            <div className={styles["multi-select-container"]}>
                <header className={styles["header"]}>
                    <h5>{modalTitle || t("video-player-page:add-videos")}</h5>
                    <button
                        type="button"
                        className="btn"
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </header>
                <hr />

                <AddVideos
                    variant="add-videos-modal"
                    setIsOpen={setIsOpen}
                    isMultiple={isMultiple}
                    buttonText={buttonText}
                    onSelect={onSelect}
                    playerId={playerId}
                    onClose={onClose}
                    previouslySelectedBroadcastIds={
                        previouslySelectedBroadcastIds
                    }
                    showUnpublished={showUnpublished}
                />
            </div>
        </ModalBase>
    );
};
