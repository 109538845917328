import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentModal } from "store/modal/slice";
import { useState } from "react";
import { Spinner } from "components/spinners/Spinner";
import { RootState } from "store/reducers";

export const BookingModal = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const user = useSelector((state: RootState) => state.user);
    const userEmail = user.userInfo.Email;

    return (
        <div className={styles["booking-modal"]}>
            <ModalBase isOpen onDismiss={() => dispatch(closeCurrentModal())}>
                <div className={styles["container"]}>
                    {loading && <Spinner />}
                    <iframe
                        className={`${styles["frame"]} ${
                            loading && styles["hidden"]
                        }`}
                        title="promo"
                        onLoad={() => setLoading(false)}
                        src={`https://www.switcherstudio.com/meetings/tim-mobley/switcher-discovery-call?embed=true&email=${userEmail}`}
                        width="100%"
                    ></iframe>
                </div>
            </ModalBase>
        </div>
    );
};
