import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { useState, useEffect } from "react";
import { client } from "api/client";
import rollbar from "helpers/rollbar";

export function useGetStripePrice(
    priceId: string | undefined,
    warningMessage?: string
): { plan: StripePriceAnonymous; loading: boolean } {
    const [plan, setPlan] = useState<StripePriceAnonymous>();

    useEffect(() => {
        const run = async () => {
            if (priceId) {
                try {
                    setPlan(await client.stripePrices_GetPriceInfo(priceId));
                } catch (e) {
                    rollbar.warning(
                        "Error getting stripe price",
                        e,
                        warningMessage
                    );
                }
            }
        };

        run();
    }, [priceId, warningMessage]);

    return { plan, loading: !plan };
}
