import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { switcherSdk } from "utils/switcher-sdk";
import { UserState, IUserInfo } from "store/user/types";
import { OrganizationUserDefault } from "@switcherstudio/switcher-api-client";

// Returns false if a redirect has happened, true if not.
// In most cases you can ignore this return value but if
// you get an unmounted component error you may consider
// preventing rendering with the return value
export function useRedirectIfDisallowed(
    accessStatement: (user: UserState) => boolean,
    redirect: string = "/home",
    skip?: boolean
) {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const isInBrowser = switcherSdk.clientId === "Generic";

    useEffect(() => {
        if (skip || !isInBrowser) return;
        if (!accessStatement(user)) navigate(redirect);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, navigate, redirect, isInBrowser, skip]);

    const isDisallowed = !accessStatement(user);
    return isDisallowed;
}

// validators here for easy re-use

// if there is no defaultOrg, user clearly must be allowed to have access
// if there is one and the user isn't the OrgAdmin, they should not
export const mustBeSubscriptionOwner = (user: {
    userInfo?: IUserInfo;
    defaultOrg?: OrganizationUserDefault;
}): boolean =>
    user?.defaultOrg === null ||
    user?.userInfo?.Roles?.indexOf("OrgContributor") === -1;

export const mustBeOrgAdmin = (user: UserState): boolean =>
    user.defaultOrg !== null &&
    user.userInfo?.Roles?.indexOf("OrgAdmin") !== -1;

export const mustBeLoggedIn = (user: UserState): boolean => {
    const accessGranted = user?.ticket?.accessGranted === "true";
    const nowUtc = new Date().getTime();
    const authTokenUtc = new Date(user?.ticket?.[".expires"]).getTime();
    const authTokenValid = authTokenUtc >= nowUtc;

    return accessGranted && authTokenValid;
};
