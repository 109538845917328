import ChevronUp from "assets/icons/chevron-up.svg?react";
import styles from "./index.module.scss";

export interface ChevronIconProps {
    direction?: "up" | "down" | "left" | "right";
}

export const ChevronIcon = ({ direction = "up" }: ChevronIconProps) => (
    <ChevronUp className={`chevron-icon ${styles[direction]}`} />
);
