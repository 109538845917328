import React from "react";
import { useTranslation } from "react-i18next";
import { useGetStripePrices } from "hooks/useGetStripePrices";
import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { WithCouponInput } from "../../types";
import { CouponInput } from "components/inputs/text-input/CouponInput";
import { SelectPlanCardsContainer } from "../../../../../components/cards/SelectPlanCardsContainer";
import { PageSectionBreak } from "components/breaks/PageSectionBreak";

interface SelectPlanStepProps extends WithCouponInput {
    showCouponInput?: boolean;
    onSelectPlanButtonClicked: (plan: StripePriceAnonymous) => void;
}

export const SelectPlanStep: React.FC<SelectPlanStepProps> = ({
    resellerInventoryItem,
    handleCouponCode,
    removeCoupon,
    showCouponInput = true,
    onSelectPlanButtonClicked
}) => {
    const { t } = useTranslation("getting-started");

    const { plans, loading } = useGetStripePrices();

    return (
        <>
            <h2>
                {t("select-plan.title1")} <br />{" "}
                <strong>{t("select-plan.title2")}</strong>
            </h2>

            <PageSectionBreak />

            <SelectPlanCardsContainer
                plans={plans}
                loading={loading}
                resellerInventoryItem={resellerInventoryItem}
                onSelectPlanButtonClicked={onSelectPlanButtonClicked}
                includePasses
            />

            {showCouponInput && (
                <CouponInput
                    label={t("create-account:add-coupon")}
                    couponCode={resellerInventoryItem?.CouponCode}
                    addCoupon={handleCouponCode}
                    removeCoupon={removeCoupon}
                />
            )}
        </>
    );
};
