import React from "react";
import styles from "./index.module.scss";
import ProfileIcon from "assets/icons/username.svg?react";
import EmailIcon from "assets/icons/email.svg?react";
import LockIcon from "assets/icons/password.svg?react";
import { InputType } from ".";

export interface IconLeftProps {
    type: InputType;
}

export const IconLeft: React.FC<IconLeftProps> = ({ type }) => {
    switch (type) {
        case "firstName":
        case "lastName":
            return <ProfileIcon className={styles["profile-icon"]} />;
        case "email":
            return <EmailIcon className={styles["email-icon"]} />;
        case "password":
            return <LockIcon className={styles["lock-icon"]} />;
        default:
            return <></>;
    }
};
