import { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

/** Abstracts clipboard functionality */
export const useClipboard = () => {
    const dispatch = useDispatch();
    const clipboardExists = useMemo(() => !!navigator.clipboard, []);

    /** Copies text to clipboard and dispatches notification on success */
    const copy = useCallback(
        async (content: string, success?: string) => {
            if (clipboardExists) {
                await navigator.clipboard.writeText(content);

                dispatch(
                    addNotification({
                        type: NotificationType.Info,
                        message: success ?? "messages:copy-success"
                    })
                );
            }
        },
        [dispatch, clipboardExists]
    );

    return {
        copy,
        clipboardExists
    };
};
