import { useEffect, useState } from "react";

/**
 * A hook that providers a settable memoized state. Like a reactive useState or a settable useMemo.
 * It's the best thing ever and probably not an anti-pattern.
 * @param updater A callback to update the state
 * @param deps dependencies for the state
 * @returns an array of the value and the setter
 */
export const useSettableMemo = <T,>(
    updater: () => T,
    deps: any[]
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(updater());

    useEffect(() => {
        setValue(updater());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return [value, setValue];
};
