import React from "react";
import { PlatformId } from "../types";
import { ConnectShopifyContainer } from "./connect-shopify/ConnectShopifyContainer";
import { ConnectExternalChannelContainer } from "./connect-external-channel/ConnectExternalChannelContainer";
import { useTranslation } from "react-i18next";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { ConnectChurchStreamingTvContainer } from "./connect-churchstreamingtv/ConnectChurchStreamingTvContainer";

interface ConnectPlatformContainerProps {
    platform: StreamingProvider;
    updateExtraLinkArgs: (args: any) => void;
}

export const ConnectPlatformContainer: React.FC<
    ConnectPlatformContainerProps
> = ({ platform, updateExtraLinkArgs }: ConnectPlatformContainerProps) => {
    const { t } = useTranslation();

    return (
        <>
            {(function () {
                switch (platform.Id) {
                    // oauth integrations
                    case PlatformId.Facebook:
                    case PlatformId.Youtube:
                    case PlatformId.Twitch:
                    case PlatformId.SwitchboardLive:
                        return;
                    case PlatformId.Shopify:
                        return (
                            <ConnectShopifyContainer
                                updateExtraLinkArgs={updateExtraLinkArgs}
                            />
                        );
                    // url-based RTMP integrations
                    case PlatformId.MsStream:
                    case PlatformId.AmazonLive:
                    case PlatformId.AzureMediaServices:
                    case PlatformId.Caast:
                    case PlatformId.Instafeed:
                        return (
                            <ConnectExternalChannelContainer
                                platform={platform}
                            />
                        );
                    // login integrations
                    case PlatformId.ChurchStreamingTv:
                        return (
                            <ConnectChurchStreamingTvContainer
                                platform={platform}
                            />
                        );
                    default:
                        return (
                            <div className="alert alert-info" role="alert">
                                {t("platforms:unsupported-platform")}
                            </div>
                        );
                }
            })()}
        </>
    );
};
