import React from "react";
import { useTranslation } from "react-i18next";
import { PricingModalTypes } from "../../../../components/modal/GatedContentModal";
import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { GatedContentDisabledTooltip } from "components/tooltips/GatedContentDisabledTooltip";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { EmptyState } from "components/empty-state/EmptyState";

interface GatedContentEmptyProps {
    updateModal: (arg0: boolean, arg1: PricingModalTypes) => void;
    isStripeConnected?: boolean;
}

export const GatedContentEmpty = ({
    updateModal,
    isStripeConnected
}: GatedContentEmptyProps) => {
    const { t } = useTranslation();

    const { gatedContentStatus, loading } = useStripeAccountInfo();

    return (
        <div className="row">
            <EmptyState
                title={t("gated-content-page:no-pricing")}
                description={
                    !isStripeConnected &&
                    t("gated-content-page:no-pricing-body")
                }
            >
                <>
                    {isStripeConnected && !loading && (
                        <GatedContentDisabledTooltip
                            gatedContentStatus={gatedContentStatus}
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                            labelOrientation="bottom"
                        >
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() =>
                                    updateModal(true, PricingModalTypes.Create)
                                }
                                disabled={
                                    gatedContentStatus !==
                                    GatedContentStatus.READY
                                }
                            >
                                {t("gated-content-page:create-btn")}
                            </button>
                        </GatedContentDisabledTooltip>
                    )}
                </>
            </EmptyState>
        </div>
    );
};
