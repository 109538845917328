import { setup } from "utils/thirdparty-sdk";
import { useEffect } from "react";

interface sdkSetupOptions {
    platformId: string;
    accessToken: string;
}

export function useThirdPartySdk(
    isInBrowser: boolean,
    options: sdkSetupOptions
) {
    useEffect(() => {
        if (isInBrowser) {
            return;
        }

        // set up third-party event listeners
        const endpoint =
            import.meta.env.VITE_API_URL ||
            "https://silversunnapi-develop.azurewebsites.net";
        const { platformId, accessToken } = options;
        const { apiListener, uiListener } = setup(
            endpoint,
            accessToken,
            platformId
        );

        return () => {
            apiListener.cancel();
            uiListener.cancel();
        };
    }, [isInBrowser, options]);
}
