/** Exposes an object of features available from Switcher Studio based on some global variables */
function sdk_constructor(): SdkObject {
    if (
        window.webkit == null ||
        window.webkit.messageHandlers == null ||
        window.webkit.messageHandlers.switcher == null
    ) {
        // we are running outside the app, probably in a browser
        return sdkForBrowser();
    } else if (window._switcher_mwv == null) {
        // we are running in Switcher Studio 2.x, api 1.0
        // the default dehavior is now to keep the cover until we hide it from js
        window.webkit.messageHandlers.switcher.postMessage({
            action: "keepCover"
        });
        return sdkForSwitcherV2();
    } else {
        // we are running in a MultiWebView
        return sdkForMultiWebView();
    }
}

export const switcherSdk = sdk_constructor();

function sdkForBrowser(): SdkObject {
    return {
        api: {
            version: 100,
            versionString: "1.0",
            versionMajor: 1,
            versionMinor: 0
        },
        auth: {
            accessType: null,
            accessToken: null
        },
        clientId: "Generic",
        dismiss: () => {},
        hideCover: (_, done) => {
            if (typeof done === "function") done();
        },
        setTitle: () => {},
        setLoadingText: () => {},
        openShareActivity: () => {},
        showCover: () => {},
        getIsolatedWebViewReturnUrl: () => "",
        openIsolatedWebView: () => {},
        closeIsolatedWebView: () => {},
        terminateAccountCreation: () => {},
        terminateBCProfileCreation: () => {},
        clearCache: () => {},
        targetAspectRatio: { numer: 16, denom: 9 }
    };
}

function sdkForSwitcherV2(): SdkObject {
    return {
        api: {
            version: 100,
            versionString: "1.0",
            versionMajor: 1,
            versionMinor: 0
        },
        auth: {
            accessType: document.switcherStudioAccessType,
            accessToken: document.switcherStudioAccessToken
        },
        clientId: "Switcher",
        dismiss: function (args) {
            window.webkit.messageHandlers.switcher.postMessage({
                action: "dismiss",
                args: args
            });
        },
        hideCover: function (options, done) {
            window.webkit.messageHandlers.switcher.postMessage({
                action: "hideCover",
                options: options
            });
            if (typeof done === "function") setTimeout(done, 400);
        },
        setTitle: (title) => {
            window.webkit.messageHandlers.switcher.postMessage({
                action: "setTitle",
                title: title
            });
        },
        setLoadingText: (text) => {
            window.webkit.messageHandlers.switcher.postMessage({
                action: "setLoadingText",
                text: text
            });
        },
        openShareActivity: (text, url) => {
            window.webkit.messageHandlers.switcher.postMessage({
                action: "openShareActivity",
                text: text,
                url: url
            });
        },
        showCover: () => {},
        getIsolatedWebViewReturnUrl: () => "",
        openIsolatedWebView: () => {},
        closeIsolatedWebView: () => {},
        terminateAccountCreation: () => {},
        terminateBCProfileCreation: () => {},
        clearCache: () => {
            try {
                window.webkit.messageHandlers.switcher.postMessage({
                    action: "clearCache"
                });
            } catch (err) {}
        },
        targetAspectRatio: { numer: 16, denom: 9 }
    };
}

function sdkForMultiWebView(): SdkObject {
    return {
        api: window._switcher_mwv.api,
        auth: window._switcher_mwv.auth,
        clientId: window._switcher_mwv.clientId,
        dismiss: window._switcher_mwv.dismiss,
        hideCover: window._switcher_mwv.hideCover,
        setTitle: window._switcher_mwv.setTitle,
        setLoadingText: window._switcher_mwv.setLoadingText,
        openShareActivity: window._switcher_mwv.openShareActivity,
        devId: window._switcher_mwv.devId,
        osName: window._switcher_mwv.osName,
        osVersion: window._switcher_mwv.osVersion,
        appVersion: window._switcher_mwv.appVersion,
        appBuild: window._switcher_mwv.appBuild,
        appId: window._switcher_mwv.appId,
        maxBroadcastVideoSize: window._switcher_mwv.maxBroadcastVideoSize,
        showCover: window._switcher_mwv.showCover,
        getIsolatedWebViewReturnUrl:
            window._switcher_mwv.getIsolatedWebViewReturnUrl,
        openIsolatedWebView: window._switcher_mwv.openIsolatedWebView,
        closeIsolatedWebView: window._switcher_mwv.closeIsolatedWebView,
        terminateAccountCreation: function (user, password) {
            window._switcher_mwv.dismiss({
                reason: "AccountCreation",
                user: user,
                password: password
            });
        },
        terminateBCProfileCreation: function (profile) {
            window._switcher_mwv.dismiss({
                reason: "BCProfileCreation",
                profile: profile
            });
        },
        clearCache: function () {
            if (window._switcher_mwv.api.versionMajor >= 3)
                window._switcher_mwv.clearCache();
            else
                try {
                    window.webkit.messageHandlers.switcher.postMessage({
                        action: "clearCache"
                    });
                } catch (err) {}
        },
        executeInteractivityCommand:
            window._switcher_mwv.executeInteractivityCommand,
        targetAspectRatio: window._switcher_mwv.targetAspectRatio
    };
}

/** Types */
export interface SdkObject {
    api: SdkVersion;
    auth: SdkAuth;
    clientId: string;
    dismiss: (args?: object) => void;
    hideCover: (options?: object, done?: () => void) => void;
    setTitle: (title: string) => void;
    setLoadingText: (title: string) => void;
    openShareActivity: (text: string, url?: string) => void;
    clearCache: () => void;
    devId?: any;
    osName?: any;
    osVersion?: any;
    appVersion?: any;
    appBuild?: any;
    appId?: any;
    maxBroadcastVideoSize?: any;
    showCover: () => void;
    getIsolatedWebViewReturnUrl: () => string;
    openIsolatedWebView: (
        url: string,
        x: number | null,
        y: number | null,
        done: (data?: object) => void
    ) => void;
    closeIsolatedWebView: (options?: object, done?: () => void) => void;
    terminateAccountCreation: (user: string, password: string) => void;
    terminateBCProfileCreation: (profile: object) => void;
    executeInteractivityCommand?: any;
    targetAspectRatio: {
        numer: number;
        denom: number;
    };
}

interface SdkVersion {
    version: number;
    versionString: string;
    versionMajor: number;
    versionMinor: number;
}

interface SdkAuth {
    accessType: string | null;
    accessToken: string | null;
    userId?: string;
}

declare global {
    interface Window {
        webkit: any;
        _switcher_mwv: SdkObject;
    }

    interface Document {
        switcherStudioAccessType: string;
        switcherStudioAccessToken: string;
    }
}
