import React from "react";
import { useTranslation } from "react-i18next";

export interface DownloadSidebarStepContentProps {
    isTrialing?: boolean;
}

export const DownloadSidebarStepContent: React.FC<
    DownloadSidebarStepContentProps
> = ({ isTrialing = true }) => {
    const { t } = useTranslation("getting-started");

    return (
        <p>
            <strong>{t("download-step.sidebar-content1")}</strong>{" "}
            {isTrialing
                ? t("download-step:has-trial")
                : t("download-step:no-trial")}{" "}
            {t("download-step.sidebar-content2")}
        </p>
    );
};
