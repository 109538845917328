import { useEffect, useState } from "react";

import { client } from "api/client";
import { ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";

export function useGetResellerInventoryItems(
    resellerId: string,
    inventoryId: string
): ResellerInventoryItem[] {
    const [items, setItems] = useState<ResellerInventoryItem[]>();

    useEffect(() => {
        const getItems = async () => {
            try {
                if (resellerId && inventoryId) {
                    const itemResponse =
                        await client.resellerInventoryItems_GetResellerInventoryItems(
                            resellerId,
                            inventoryId
                        );

                    setItems(
                        itemResponse.sort(
                            (a, b) =>
                                a.Status - b.Status ||
                                new Date(b.UpdatedAt).getTime() -
                                    new Date(a.UpdatedAt).getTime()
                        )
                    );
                }
            } catch (e) {
                rollbar.error("Error getting reseller inventory items", e);
            }
        };
        getItems();
    }, [resellerId, inventoryId]);

    return items;
}
