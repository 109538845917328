import React, { useState, useRef } from "react";
import useClickOutside from "hooks/useClickOutside";
import useKeyPress from "hooks/useKeyPress";
import rollbar from "helpers/rollbar";
import styles from "./index.module.scss";

export const EditableTextDisplay = ({
    id,
    initialValue,
    onSubmit,
    textElement,
    textDisplayClassName,
    type = "text",
    formatDisplayedText = (text) => text,
    formatInputPlaceholder = () => ""
}: {
    id: string;
    initialValue: string;
    textElement?: "h1" | "h2" | "h3" | "h4" | "h5" | "p";
    onSubmit: (val: string) => Promise<void>;
    type?: string;
    textDisplayClassName?: string;
    formatDisplayedText?: (text: string) => string;
    formatInputPlaceholder?: (text: string) => string;
}) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState<string>(
        formatInputPlaceholder(initialValue)
    );
    const [displayValue, setDisplayValue] = useState<string>(initialValue);
    const inputRef = useRef();
    const handleSubmit = async () => {
        if (!inputValue) {
            setShowInput(false);
            return;
        }
        const displayedText = formatDisplayedText(inputValue);
        try {
            await onSubmit(displayedText);
            setShowInput(false);
            setDisplayValue(displayedText);
            setInputValue(formatInputPlaceholder(displayedText));
        } catch (e) {
            rollbar.error("Error submitting editable text display data", e);
            setShowInput(false);
            setDisplayValue(initialValue);
            setInputValue(formatInputPlaceholder(displayedText));
        }
    };

    useClickOutside(inputRef, () => handleSubmit());
    useKeyPress("Enter", () => {
        if (showInput) {
            handleSubmit();
        }
    });

    const TextDisplay = textElement;

    return (
        <>
            {showInput ? (
                <input
                    className={`form-control ${textDisplayClassName}`}
                    id={id}
                    type={type}
                    value={inputValue}
                    onChange={(target) =>
                        setInputValue(target.currentTarget.value)
                    }
                    ref={inputRef}
                    autoFocus
                />
            ) : (
                <TextDisplay
                    onClick={() => setShowInput(true)}
                    className={`form-control ${textDisplayClassName} ${styles["text-display"]}`}
                >
                    {displayValue}
                </TextDisplay>
            )}
        </>
    );
};
