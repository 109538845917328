import React, { useState, useCallback } from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import styles from "./AssetTag.module.scss";
import { useTranslation } from "react-i18next";

const NEW_GROUP_TAG = "add-new-group";

interface AssetTagProps {
    tags: string[];
    selectedTag: string;
    onChange: (value: string) => void;
}

export const AssetTag: React.FC<AssetTagProps> = ({ tags, onChange }) => {
    const [internalSelectedTag, setInternalSelectedTag] = useState<string>("");
    const [internalOtherTag, setInternalOtherTag] = useState<string>("");
    const { t } = useTranslation();

    const handleInternalChange = useCallback(
        (value: string) => {
            setInternalSelectedTag(value);
            onChange(value === NEW_GROUP_TAG ? null : value);
        },
        [onChange]
    );

    const handleInternalOtherChange = useCallback(
        (value: string) => {
            setInternalOtherTag(value);
            onChange(value);
        },
        [onChange]
    );

    return (
        <>
            <div className={`form-group ${styles["metadata-card"]}`}>
                <label htmlFor="tag-select">
                    {t("cloud:production-groups")}
                </label>
                <select
                    name="tag-select"
                    className="form-control"
                    value={internalSelectedTag}
                    onChange={(e) => handleInternalChange(e.target.value)}
                    id="tag-select"
                >
                    <option value="">Main</option>
                    {tags.map((t, i) => {
                        return (
                            <option key={`tag-${i}`} value={t}>
                                {t}
                            </option>
                        );
                    })}
                    <option value={NEW_GROUP_TAG}>
                        {t("cloud:add-production-group")}
                    </option>
                </select>
            </div>
            {internalSelectedTag === NEW_GROUP_TAG && (
                <>
                    <TextInput
                        label={t("cloud:new-production-group")}
                        type="text"
                        id="other-tag"
                        value={internalOtherTag}
                        onChange={(e) =>
                            handleInternalOtherChange(e.target.value)
                        }
                    />
                </>
            )}
        </>
    );
};
