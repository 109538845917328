import {
    ResellerInventoryItem,
    StripePrice,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { calculateDiscountedAmount, displayAmount } from "helpers/stripe";
import { useTranslation } from "react-i18next";
import styles from "../index.module.scss";
import { PlanPeriodOptions } from "components/cards/SelectPlanCardsContainer";
import { PlanType } from ".";

export interface PriceHeaderProps {
    plan: StripePrice | StripePriceAnonymous;
    planPeriod: PlanPeriodOptions;
    hasDiscount: boolean;
    /** Used to distinguish annual/monthly plans from one time passes */
    planType?: PlanType;
    /** Object that contains coupon details */
    resellerInventoryItem?: ResellerInventoryItem;
    isCurrentPlan?: boolean;
}

export const PriceHeader: React.FC<PriceHeaderProps> = ({
    plan,
    planPeriod,
    hasDiscount,
    planType,
    resellerInventoryItem,
    isCurrentPlan
}) => {
    const { t } = useTranslation("getting-started");

    if (!plan?.Amount) {
        return <span></span>;
    }

    if (hasDiscount && !isCurrentPlan) {
        return (
            <div className={styles["discounted"]}>
                <h5 className={`${styles["price"]} ${styles[planType]}`}>
                    $
                    {displayAmount(
                        plan?.Amount /
                            (planType !== "pass" && planPeriod === "year"
                                ? 12
                                : 1)
                    )}
                    {planType !== "pass"
                        ? `/${t("subscription:month-abbreviated")}`
                        : ""}
                </h5>
                <h1 className={`${styles["price"]}`}>
                    <sup>$</sup>
                    <strong>
                        {displayAmount(
                            calculateDiscountedAmount(
                                plan?.Amount,
                                resellerInventoryItem?.ResellerInventory?.Coupon
                            ) /
                                (planType !== "pass" && planPeriod === "year"
                                    ? 12
                                    : 1)
                        )}
                    </strong>
                    {planType !== "pass"
                        ? `/${t("subscription:month-abbreviated")}`
                        : ""}
                </h1>
            </div>
        );
    } else {
        return (
            <h1 className={`${styles["price"]} ${styles[planType]}`}>
                <sup>$</sup>
                <strong>
                    {displayAmount(
                        plan.Amount /
                            (planType !== "pass" && planPeriod === "year"
                                ? 12
                                : 1)
                    )}
                </strong>
                {planType !== "pass"
                    ? `/${t("subscription:month-abbreviated")}`
                    : ""}
            </h1>
        );
    }
};
