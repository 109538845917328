import React, { useCallback, useEffect, useState } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { facebook as fbClient } from "api/facebook/facebook-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import {
    getFacebookUserProfile,
    selectFacebookDestination
} from "store/platforms/thunks";
import { RootState } from "store/reducers";
import { Link } from "react-router-dom";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import InfoIcon from "assets/icons/info.svg?react";
import { FacebookProfile } from "store/platforms/types";
import { PlatformId } from "../../types";
import { usePlatformActions } from "hooks/usePlatformActions";
import { useTranslation } from "react-i18next";
import fbFetch from "api/facebook/facebook-helper";
import { client } from "api/client";
import styles from "./Facebook.module.scss";
import { resetFacebookForm } from "store/platforms/slice";
import { useNavigate } from "react-router-dom";

interface FacebookContainerProps {
    platform: StreamingProvider;
    destinationType?: string;
}

const pills = ["My Timeline", "Page"];

export const FacebookContainer: React.FC<FacebookContainerProps> = ({
    destinationType
}: FacebookContainerProps) => {
    const { t } = useTranslation();
    const isMounted = useIsMountedRef();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [destinations, setDestinations] = useState(undefined);
    const [isPaginated, setIspaginated] = useState(false);
    const [nextPage, setNextPage] = useState(undefined);
    const [defaultDestination, setDefaultDestination] =
        useState<FacebookProfile>();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const { profileInfo } = useSelector(
        (state: RootState) => state.platforms.facebook
    );
    const { unlink } = usePlatformActions(PlatformId.Facebook);

    const getMore = useCallback(async () => {
        const res = await fbFetch(nextPage);
        setDestinations([...destinations, ...res.data]);
        if (res?.getNextPage) {
            setNextPage(res.getNextPage);
            return;
        }
        setIspaginated(false);
    }, [nextPage, destinations]);

    const handlePillSelect = useCallback(
        async (newPill: string) => {
            setDestinations(undefined);

            let options = { edge: "me" };

            switch (newPill) {
                case "My Timeline":
                    options.edge = "me";
                    options["userImage"] = profileInfo?.picture?.data?.url;
                    break;
                case "Page":
                    options.edge = "accounts";
                    break;
                default:
                    break;
            }

            try {
                const results = await fbClient.helpers.getDestinationsByType(
                    options
                );
                if (!!results) setDestinations(results.data);
                if (results?.getNextPage) {
                    setIspaginated(true);
                    setNextPage(results.getNextPage);
                } else {
                    setIspaginated(false);
                }
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:facebook-load-error"
                    })
                );
            }
        },
        [dispatch, profileInfo]
    );

    useEffect(() => {
        async function init() {
            try {
                await dispatch(getFacebookUserProfile());
                const streamDestinations =
                    await client.streamDestinations_GetStreamDestinations(
                        userInfo?.UserId
                    );
                const defaultStreamDestination = streamDestinations?.find(
                    (sd) => sd.IsDefault
                );

                if (defaultStreamDestination) {
                    const { StreamDestinationId, StreamDestinationType, Name } =
                        defaultStreamDestination;
                    const destination =
                        await fbClient.helpers.getDestinationById(
                            StreamDestinationId
                        );
                    setDefaultDestination({
                        ...destination,
                        edge: StreamDestinationType,
                        name: Name,
                        isTestStream:
                            StreamDestinationType === "me" &&
                            Name === "Private test stream"
                    });
                }
            } catch (e) {
                const needsUnlink = e.message.includes(
                    "The session has been invalidated"
                );

                if (needsUnlink) {
                    await unlink();
                    navigate(`/platforms/connect/${PlatformId.Facebook}`);
                }

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: needsUnlink
                            ? e.message
                            : "errors:facebook-load-error"
                    })
                );
            }
        }

        if (isMounted.current) init();
    }, [dispatch, isMounted, unlink, navigate, userInfo]);

    useEffect(() => {
        let initialPill;

        switch (destinationType) {
            case "accounts":
                initialPill = pills[1];
                break;
            default:
                initialPill = pills[0];
                break;
        }

        handlePillSelect(initialPill);
    }, [handlePillSelect, destinationType]);

    function newStreamClick(destination: FacebookProfile) {
        dispatch(selectFacebookDestination(destination));
        dispatch(resetFacebookForm(destination.id));
        const queryParams = `?destinationId=${destination.id}&destinationType=${destination.edge}`;
        navigate(
            `/platforms/stream/facebook${
                destination.edge === "me" ? "" : queryParams
            }`
        );
    }

    return (
        <>
            {defaultDestination && (
                <>
                    <div className="alert alert-info" role="alert">
                        <InfoIcon className={styles["info-icon"]} />{" "}
                        {t("platforms:default-stream-message")}{" "}
                        <Link
                            to={`/platforms/stream/facebook${
                                defaultDestination.edge === "me"
                                    ? ""
                                    : `?destinationId=${defaultDestination.id}&destinationType=${defaultDestination.edge}`
                            }`}
                            onClick={() =>
                                dispatch(
                                    selectFacebookDestination(
                                        defaultDestination
                                    )
                                )
                            }
                        >
                            <strong>{defaultDestination.name}</strong>
                        </Link>
                        .
                    </div>
                </>
            )}
            <DestinationSelector
                destinations={destinations}
                pills={pills}
                imgSrcKey="picture.data.url"
                destinationNameKey="name"
                onDestinationClick={newStreamClick}
                onPillSelect={handlePillSelect}
                isPaginated={isPaginated}
                loadMore={getMore}
            />
        </>
    );
};
