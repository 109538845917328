import React, { useMemo } from "react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { closeCurrentModal } from "store/modal/slice";
const cx = classNames.bind(styles);

export const VideoPlaybackModal = ({ src }: { src: string }) => {
    const dispatch = useDispatch<AppDispatch>();
    const url = useMemo(() => {
        const srcIncludesQuestionMark = src.includes("?");

        return srcIncludesQuestionMark
            ? `${src}&autoplay=true`
            : `${src}?autoplay=true`;
    }, [src]);

    return (
        <ModalBase onDismiss={() => dispatch(closeCurrentModal())} isOpen>
            <div className={cx("video-container")}>
                <iframe
                    title="playback-modal"
                    src={url}
                    style={{ border: "none" }}
                    height="720"
                    width="1280"
                    allowFullScreen
                    allow="autoplay; picture-in-picture"
                ></iframe>
            </div>
        </ModalBase>
    );
};
