import { useCallback, useEffect, useState } from "react";

export const useMediaQuery = ({
    maxWidth,
    minWidth,
    orientation
}: {
    /** max width in pixels */
    maxWidth?: number;
    /** min width in pixels */
    minWidth?: number;
    /** viewport orientation */
    orientation?: "landscape" | "portrait";
}) => {
    const [matchesWidthQuery, setMatchesWidthQuery] = useState<boolean>();
    const [matchesOrientationQuery, setMatchesOrientationQuery] =
        useState<boolean>();

    const setMatchesWidth = useCallback((e: MediaQueryListEvent) => {
        setMatchesWidthQuery(e?.matches);
    }, []);

    const setMatchesOrientation = useCallback((e: MediaQueryListEvent) => {
        setMatchesOrientationQuery(e?.matches);
    }, []);

    useEffect(() => {
        const mediaQueryWidthStr =
            Boolean(maxWidth) || maxWidth === 0
                ? `(max-width: ${maxWidth}px)`
                : `(min-width: ${minWidth}px)`;

        const mediaQueryOrientationStr = `(orientation: ${orientation})`;

        const mediaWidthObj = window.matchMedia(mediaQueryWidthStr);

        const mediaOrientationObj =
            Boolean(orientation) && window.matchMedia(mediaQueryOrientationStr);

        setMatchesWidthQuery(mediaWidthObj.matches);
        setMatchesOrientationQuery(
            mediaOrientationObj ? mediaOrientationObj?.matches : undefined
        );

        if (mediaWidthObj.addEventListener) {
            mediaWidthObj.addEventListener("change", setMatchesWidth, true);
            mediaOrientationObj &&
                mediaOrientationObj.addEventListener(
                    "change",
                    setMatchesOrientation,
                    true
                );
        } else {
            mediaWidthObj.addListener(setMatchesWidth);
            mediaOrientationObj &&
                mediaOrientationObj.addListener(setMatchesOrientation);
        }

        return () => {
            if (mediaWidthObj.removeEventListener) {
                mediaWidthObj.removeEventListener(
                    "change",
                    setMatchesWidth,
                    true
                );
                mediaOrientationObj &&
                    mediaOrientationObj.removeEventListener(
                        "change",
                        setMatchesOrientation,
                        true
                    );
            } else {
                mediaWidthObj.removeListener(setMatchesWidth);
                mediaOrientationObj &&
                    mediaOrientationObj.removeListener(setMatchesOrientation);
            }
        };
    }, [
        maxWidth,
        minWidth,
        setMatchesWidth,
        setMatchesOrientation,
        orientation
    ]);

    return orientation
        ? matchesWidthQuery && matchesOrientationQuery
        : matchesWidthQuery;
};
