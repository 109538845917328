import { useCallback, useState, useEffect, useMemo } from "react";
import { client } from "api/client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import {
    ResellerInventoryItem,
    StripePrice,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { couponAppliesToPlan } from "helpers/stripe";
import rollbar from "helpers/rollbar";
import { exists } from "helpers/booleans";

export const useCouponAnonymous = (
    couponCodeFromUrl: string,
    selectedPlan: StripePrice | StripePriceAnonymous,
    onChange?: (resellerInventoryItem: ResellerInventoryItem) => void
) => {
    const dispatch = useDispatch<AppDispatch>();

    const couponCode = useMemo(() => couponCodeFromUrl, [couponCodeFromUrl]);

    const [resellerInventoryItem, setResellerInventoryItem] =
        useState<ResellerInventoryItem>();

    const handleCouponCode = useCallback(
        async (
            code: string,
            plan?: StripePrice,
            showMessage: boolean = true
        ) => {
            if (code !== undefined) {
                try {
                    const { ResellerInventoryItem } =
                        await client.coupons_CheckCouponCode(code);

                    if (
                        plan &&
                        !couponAppliesToPlan(plan, ResellerInventoryItem)
                    ) {
                        throw new Error("Coupon not applicable to plan.");
                    }

                    setResellerInventoryItem(ResellerInventoryItem);
                    onChange && onChange(ResellerInventoryItem);

                    if (
                        showMessage &&
                        !ResellerInventoryItem?.ResellerInventory?.IsSilent
                    ) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Success,
                                message: "coupon-form:successfully-applied",
                                messageOptions: { couponInput: code },
                                fadeMilliseconds: 3000
                            })
                        );
                    }
                } catch (e) {
                    // invalid coupon
                    setResellerInventoryItem(undefined);
                    onChange && onChange(undefined);
                    rollbar.error("Invalid coupon error", e);

                    if (
                        !import.meta.env.VITE_DEFAULT_DTS_COUPON ||
                        code !== import.meta.env.VITE_DEFAULT_DTS_COUPON
                    ) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Danger,
                                message: "errors:invalid-coupon"
                            })
                        );
                    }
                }
            }
        },
        [dispatch, onChange]
    );

    useEffect(() => {
        handleCouponCode(couponCode, selectedPlan);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponCode]);

    const removeCoupon = useCallback(() => {
        dispatch(
            addNotification({
                type: NotificationType.Info,
                message: "coupon-form:removed-coupon",
                messageOptions: {
                    couponInput: resellerInventoryItem.CouponCode
                }
            })
        );
        setResellerInventoryItem({});
    }, [dispatch, resellerInventoryItem?.CouponCode]);

    const showCouponInput = useMemo<boolean>(() => {
        if (!exists(handleCouponCode, removeCoupon)) return false;
        return !resellerInventoryItem?.ResellerInventory?.IsSilent;
    }, [handleCouponCode, removeCoupon, resellerInventoryItem]);

    return {
        handleCouponCode,
        removeCoupon,
        resellerInventoryItem,
        trialDays: resellerInventoryItem?.ResellerInventory?.TrialDays ?? 14,
        showCouponInput
    };
};
