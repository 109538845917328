import React, { useState } from "react";
import styles from "./index.module.scss";
import EnIcon from "assets/icons/us-flag.png";
import EsIcon from "assets/icons/es-flag.png";
import { useTranslation } from "react-i18next";

export const FlagContainer: React.FC = () => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
    };

    return (
        <div className={styles["flag-container"]}>
            <img
                onClick={() => changeLanguage("en")}
                className={
                    selectedLanguage === "en"
                        ? styles["selected-flag"]
                        : styles.flag
                }
                src={EnIcon}
                alt="US Flag"
            />
            <img
                onClick={() => changeLanguage("es")}
                className={
                    selectedLanguage === "es"
                        ? styles["selected-flag"]
                        : styles.flag
                }
                src={EsIcon}
                alt="Spain Flag"
            />
        </div>
    );
};
