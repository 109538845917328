import { redirectTo } from "router/loaders";
import { ConnectPlatformPage } from "views/page-content/platforms/connect-platform/ConnectPlatformPage";
import { AddCustomRTMPChannelPage } from "views/page-content/platforms/platform/custom-rtmp/AddCustomRTMPChannelPage";
import { EditCustomRTMPChannelPage } from "views/page-content/platforms/platform/custom-rtmp/EditCustomRTMPChannelPage";
import { AddExternalChannelSettingsPage } from "views/page-content/platforms/platform/external-channel-settings/AddExternalChannelSettingsPage";
import { PlatformFormPage } from "views/page-content/platforms/platform/forms/PlatformFormPage";
import { IframePlatformPage } from "views/page-content/platforms/platform/IFramePlatformPage";
import { PlatformPage } from "views/page-content/platforms/platform/PlatformPage";
import { SimulcastPlatformDestinationSelector } from "views/page-content/platforms/platform/simulcast/simulcast-destinations/simulcast-destination-selector/SimulcastDestinationSelector";
import { SimulcastDestinations } from "views/page-content/platforms/platform/simulcast/simulcast-destinations/SimulcastDestinations";
import { SimulcastExistingBroadcasts } from "views/page-content/platforms/platform/simulcast/simulcast-existing-broadcasts/SimulcastExistingBroadcasts";
import { SimulcastForm } from "views/page-content/platforms/platform/simulcast/SimulcastForm";
import { PlatformsPage } from "views/page-content/platforms/PlatformsPage";
import { PlatformId } from "views/page-content/platforms/types";

export const PlatformRoutes = [
    {
        path: "platforms",
        element: <PlatformsPage />
    },
    {
        path: "platforms/simulcast",
        element: <SimulcastExistingBroadcasts />
    },
    {
        path: "platforms/simulcast/create",
        element: <SimulcastForm />
    },
    {
        path: "platforms/simulcast/destinations",
        element: <SimulcastDestinations />
    },
    {
        path: "platforms/simulcast/destinations/:platformId/add",
        element: <SimulcastPlatformDestinationSelector />
    },
    {
        path: "platforms/simulcast/destinations/:platformId/connect",
        element: <ConnectPlatformPage />
    },
    {
        path: "platforms/simulcast/destinations/custom/connect",
        element: (
            <AddCustomRTMPChannelPage finalUrl="/platforms/simulcast/destinations/custom/add" />
        )
    },
    {
        path: `platforms/connect/${PlatformId.BoxCast}`,
        loader: redirectTo(`/platforms/${PlatformId.BoxCast}`)
    },
    {
        path: "platforms/connect/:platformId",
        element: <ConnectPlatformPage />
    },
    {
        path: "platforms/custom-rtmp",
        element: <AddCustomRTMPChannelPage />
    },
    {
        path: "platforms/custom-rtmp/:id",
        element: <EditCustomRTMPChannelPage />
    },
    {
        path: "platforms/switcherplayer",
        loader: redirectTo("/platforms/stream/switcherplayer")
    },
    {
        path: `platforms/${PlatformId.BoxCast}`,
        element: <IframePlatformPage platformId={PlatformId.BoxCast} />
    },
    {
        path: "platforms/:platformId",
        element: <PlatformPage />
    },
    {
        path: "platforms/:platformId/channels/add",
        element: <AddExternalChannelSettingsPage />
    },
    {
        path: "platforms/stream/:platformId",
        element: <PlatformFormPage />
    },
    {
        path: "platforms/destination/:platformId",
        element: <PlatformPage />
    },
    {
        path: "providers",
        loader: redirectTo("/platforms")
    },
    {
        path: "providers/:platformId",
        loader: redirectTo("/platforms/:platformId")
    },
    {
        path: "providers/:platformId/channels/add",
        loader: redirectTo("/platforms/:platformId/channels/add")
    },
    {
        path: "settings",
        loader: redirectTo("/platforms/custom-rtmp")
    },
    {
        path: "/editquality/:platformId",
        loader: redirectTo("/platforms/:platformId")
    }
];
