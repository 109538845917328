import React, { useEffect } from "react";
import { useParams } from "hooks/useParams";
import { ShopifyLinkArgs } from "views/page-content/platforms/types";

interface ConnectShopifyContainerProps {
    updateExtraLinkArgs: (args: any) => void;
}
export const ConnectShopifyContainer: React.FC<
    ConnectShopifyContainerProps
> = ({ updateExtraLinkArgs }: ConnectShopifyContainerProps) => {
    const { shop } = useParams();

    useEffect(() => {
        if (shop) {
            const shopifyLinkArgs: ShopifyLinkArgs = {
                shop
            };
            updateExtraLinkArgs(shopifyLinkArgs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop]);

    return <></>;
};
