import React from "react";
import styles from "./index.module.scss";

export const PrimarySecondaryButtonsGroup = ({
    primaryButtonText,
    primaryButtonType,
    onPrimaryButtonClick,
    secondaryButtonText,
    onSecondaryButtonClick,
    buttonsDisabled
}: {
    primaryButtonText: string;
    primaryButtonType: "submit" | "button";
    secondaryButtonText: string;
    onSecondaryButtonClick: () => void;
    onPrimaryButtonClick?: () => void;
    buttonsDisabled?: boolean;
}) => (
    <>
        <button
            className="btn btn-primary btn-block"
            type={primaryButtonType}
            disabled={buttonsDisabled}
            onClick={onPrimaryButtonClick}
        >
            {primaryButtonText}
        </button>
        <button
            className={`btn btn-link mt-2 ${styles["secondary-button"]}`}
            onClick={onSecondaryButtonClick}
            disabled={buttonsDisabled}
        >
            {secondaryButtonText}
        </button>
    </>
);
