// Shorten a string to less than maxLen characters without truncating words.
export function shorten(str: string, maxLen: number, separator = undefined) {
    if (!str || str?.length <= maxLen) return str;
    if (separator)
        return str.slice(0, str.lastIndexOf(separator, maxLen)) + "...";
    return str.slice(0, maxLen) + "...";
}

export function isGuid(node: string) {
    return node.match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
    );
}

export const isNullOrWhitespace = (str: string) => !str || !str.trim();

/*
* Check if a string version (syntax: majorVersion.minorVersion.patch) is greater or equal than the majorVersion and minorVersion in parameter
*/
export function versionGreaterOrEqual(str: string, majorVersion: number, minorVersion: number): boolean {
    const [strMajorVersion, strMinorVersion] = str.split('.').map(Number);
    return strMajorVersion > majorVersion || (strMajorVersion === majorVersion && strMinorVersion >= minorVersion);
}
