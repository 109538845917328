import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Modals } from "store/modal/types";
import { RootState } from "store/reducers";
import { VideoUploadMinimizedModalTab } from "./ModalTabs/VideoUploadMinimizedModalTab";
import styles from "./index.module.scss";
import { HelpTab } from "./HelpTab";

export const MinimizedModalTabManager = () => {
    const { minimizedModals } = useSelector((s: RootState) => s.modal);

    const minimizedModalComponents = useMemo(() => {
        return minimizedModals.map((modal) => {
            switch (modal.type) {
                case Modals.TriggeredVideoUploadModal:
                    return (
                        <VideoUploadMinimizedModalTab sessionId={modal.id} />
                    );
                default:
                    return <></>;
            }
        });
    }, [minimizedModals]);

    const tabs = useMemo(() => {
        return [...minimizedModalComponents, <HelpTab key="help" />];
    }, [minimizedModalComponents]);

    return (
        <div className={styles["minimized-modal-tab-container"]}>
            {tabs.map((component) => component)}
        </div>
    );
};
