import {
    loadStripe,
    PaymentIntent,
    SetupIntent,
    StripeError
} from "@stripe/stripe-js";

import { removeQueryParams } from "helpers/url";
import { useRef, useState } from "react";
import { useRunOnce } from "./useRunOnce";
import { exists } from "helpers/booleans";

export interface RedirectCallbackArguments {
    redirectStatus: string;
}

export interface useBillingRedirectOptions {
    onPaymentIntent?: (
        paymentIntent: PaymentIntent,
        args: RedirectCallbackArguments
    ) => void;
    onSetupIntent?: (
        setupIntent: SetupIntent,
        args: RedirectCallbackArguments
    ) => void;
    onError?: (error: StripeError) => void;
}

export const useBillingRedirect = ({
    onPaymentIntent,
    onSetupIntent,
    onError
}: useBillingRedirectOptions = {}) => {
    const paymentIntent = useRef<PaymentIntent>();
    const setupIntent = useRef<SetupIntent>();
    const [redirectStatus, setRedirectStatus] = useState<string>();

    useRunOnce(async () => {
        try {
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_API_KEY
            );

            const searchParams = new URLSearchParams(window.location.search);
            const paymentIntentClientSecret = searchParams.get(
                "payment_intent_client_secret"
            );
            const setupIntentClientSecret = searchParams.get(
                "setup_intent_client_secret"
            );
            const redirectStatus = searchParams.get("redirect_status");

            setRedirectStatus(redirectStatus);

            if (paymentIntentClientSecret) {
                const { paymentIntent: intent, error } =
                    await stripe.retrievePaymentIntent(
                        paymentIntentClientSecret
                    );

                if (error) throw error;

                if (!intent || exists(paymentIntent.current)) {
                    return;
                }

                paymentIntent.current = intent;
                onPaymentIntent?.(intent, { redirectStatus });
            }

            if (setupIntentClientSecret) {
                const { setupIntent: intent, error } =
                    await stripe.retrieveSetupIntent(setupIntentClientSecret);

                if (error) throw error;

                if (!intent || exists(setupIntent.current)) {
                    return;
                }

                setupIntent.current = intent;
                onSetupIntent?.(intent, { redirectStatus });
            }
        } catch (error) {
            onError?.(error as StripeError);
        }
        removeQueryParams();
    });

    return {
        paymentIntent: paymentIntent.current,
        setupIntent: setupIntent.current,
        redirectStatus
    };
};
