import { AnalyticsDateRangeOptions } from "views/page-content/analytics/AnalyticsPage";

export enum AnalyticsTabs {
    Views = "views",
    Revenue = "revenue",
    Customers = "Customers"
}

export enum CustomizationTabs {
    Display = "display",
    Styles = "styles"
}

export enum CatalogTabs {
    Collections = "collections",
    Settings = "settings",
    Share = "share"
}

export enum CollectionTabs {
    Settings = "settings",
    Share = "share",
    Broadcasts = "broadcasts"
}

export enum AnalyticsViewsCards {
    Views = "views",
    WatchTime = "watchTime",
    UniqueViewers = "uniqueViewers"
}

export enum AnalyticsRevenuesCards {
    Revenue = "revenue",
    Sales = "sales",
    Customers = "customers"
}

export enum GatedContentPageTabs {
    Overview = "overview",
    Pricing = "passes"
}

export interface SidebarDropdownSelection {
    videoCMS: boolean;
    creationTools: boolean;
    accountSettings: boolean;
    help: boolean;
}

export interface ViewInitialState {
    analyticsActiveTab: AnalyticsTabs;
    analyticsViewsCardOrder: AnalyticsViewsCards[];
    analyticsRevenueCardOrder: AnalyticsRevenuesCards[];
    customizationActiveTab: CustomizationTabs;
    gatedContentActiveTab: GatedContentPageTabs;
    sidebarDropdownSelection: SidebarDropdownSelection;
    analyticsDateRangeSelection: AnalyticsDateRangeOptions;
    analyticsCustomDateRangeSelection: AnalyticsCustomDateRangeSelection | null;
}

export interface AnalyticsCustomDateRangeSelection {
    start: string | null;
    end: string | null;
}
