import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "localization/i18n";

/**
 * Returns an object containing the title, subtitle, state and data for the current page.
 * The title and subtitle are determined by the path of the current page, and will be
 * translated if a translation exists. The state contains a single property, subheaderLink,
 * which is undefined. The data contains several properties that are undefined. The
 * function can be used in any react component to get the title and subtitle for the
 * current page, as well as the state and data. The state and data can be used to set the
 * state and data of a page header component.
 * @returns {Object}
 */
export const usePageTitle = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const getPathSegments = (path: string) => {
        const guidPattern =
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        const segments = path
            .split("/")
            .filter((segment) => segment !== "" && !guidPattern.test(segment));
        return segments.join("-");
    };

    const getTitleKey = (path: string) => {
        const key = `page-titles:${getPathSegments(path)}`;
        if (i18n.exists(key, { ns: "page-titles" })) {
            return key;
        }
        const parentPath = path.substring(0, path.lastIndexOf("/"));
        if (parentPath !== path) {
            return getTitleKey(parentPath);
        }
        return null;
    };

    const titleKey = getTitleKey(location.pathname);
    const title = titleKey ? t(titleKey) : null;

    const subTitleKey = `page-titles:subheaders:${getPathSegments(
        location.pathname
    )}`;
    const subTitle = i18n.exists(subTitleKey) ? t(subTitleKey) : null;

    return {
        title,
        subTitle,
        state: {
            subheaderLink: undefined
        },
        data: {
            backNavigation: undefined,
            backNavigationText: undefined,
            badgeConfig: undefined,
            upgradeConfig: undefined
        }
    };
};
