import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { RootState } from "store/reducers";
import { Client, client } from "api/client";
import {
    Brand,
    BrandColor,
    BrandProfileLink,
    BrandProfileLinkSocialPlatformType
} from "@switcherstudio/switcher-api-client";
import { FormCard } from "components/form-card/FormCard";
import PencilIcon from "assets/icons/pencil.svg?react";
import { LogoUploader } from "./LogoUploader";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { BrandProfileForm } from "./BrandProfileForm";
import { UploadedLogo } from "./UploadedLogo";
const NO_LOAD_client = new Client({ showLoading: false });

function makeLinkHash(links: BrandProfileLink[]) {
    const linksHash = {
        facebook: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.Facebook,
            Url: ""
        },
        linkedin: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.LinkedIn,
            Url: ""
        },
        twitch: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.Twitch,
            Url: ""
        },
        youtube: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.YouTube,
            Url: ""
        },
        instagram: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.Instagram,
            Url: ""
        },
        snapchat: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.Snapchat,
            Url: ""
        },
        tiktok: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.TikTok,
            Url: ""
        },
        twitter: {
            SocialPlatformType: BrandProfileLinkSocialPlatformType.Twitter,
            Url: ""
        }
    };

    links.forEach((l) => {
        linksHash[`${l.SocialPlatformType.toLowerCase()}`] = l;
    });

    return linksHash;
}

export const BrandProfilePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { switcherSdk } = useSwitcherSdk(false);
    const user = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(true);
    const [brandProfile, setBrandProfile] = useState<any>({});

    const [brandId, setBrandId] = useState<string>(null);
    const [logos, setLogos] = useState<any>([]);

    // Brand Colors
    const [colors, setColors] = useState<BrandColor[]>([]);
    const [primaryColor, setPrimaryColor] = useState<string>(null);
    const [secondaryColor, setSecondaryColor] = useState<string>(null);
    const [tertiaryColor, setTertiaryColor] = useState<string>(null);

    // Social Profile Links
    const [socialProfileLinks, setSocialProfileLinks] =
        useState<any>(undefined);
    const [facebookLink, setFacebookLink] = useState<string>(null);
    const [linkedinLink, setLinkedinLink] = useState<string>(null);
    const [twitchLink, setTwitchLink] = useState<string>(null);
    const [youtubeLink, setYoutubeLink] = useState<string>(null);
    const [instagramLink, setInstagramLink] = useState<string>(null);
    const [snapchatLink, setSnapchatLink] = useState<string>(null);
    const [tiktokLink, setTiktokLink] = useState<string>(null);
    const [twitterLink, setTwitterLink] = useState<string>(null);

    const [isDefaultForTemplates, setIsDefaultForTemplates] =
        useState<boolean>(false);
    const [enableSwitcherPlayer, setEnableSwitcherPlayer] =
        useState<boolean>(false);

    const refreshLogos = useCallback(
        async (brandId) => {
            if (!user.userInfo.UserId) return;
            const logos = await NO_LOAD_client.brandLogos_GetUserBrandLogos(
                user.userInfo.UserId,
                brandId
            );
            const _logos = logos.filter((a) => (a.Type as any) === "Art");

            setLogos(_logos);

            return _logos;
        },
        [user]
    );

    useEffect(() => {
        const init = async function () {
            try {
                const userId = user.userInfo?.UserId;
                setLoading(true);
                let profile = null;
                const brands = await client.userBrands_GetUserBrands(userId);
                let _brandId = null;

                if (!brands.length) {
                    const brand = await client.userBrands_PostUserBrand(
                        userId,
                        {} as Brand
                    );
                    _brandId = brand.Id;
                    setBrandId(_brandId);
                } else {
                    _brandId = brands[0].Id;
                    setBrandId(_brandId);
                }

                profile = await client.userBrandProfile_GetUserBrandProfile(
                    userId,
                    _brandId
                );
                setBrandProfile(profile);

                setColors(!!profile?.Colors ? profile?.Colors : []);
                setPrimaryColor(profile?.Colors[0]?.Color ?? "#ED5623");
                setSecondaryColor(profile?.Colors[1]?.Color ?? "#0C4F60");
                setTertiaryColor(profile?.Colors[2]?.Color ?? "#1EBDC1");

                const linkHash = makeLinkHash(
                    !!profile?.BrandProfileLinks
                        ? profile?.BrandProfileLinks
                        : []
                );
                setSocialProfileLinks(linkHash);
                setFacebookLink(linkHash.facebook.Url);
                setLinkedinLink(linkHash.linkedin.Url);
                setTwitchLink(linkHash.twitch.Url);
                setYoutubeLink(linkHash.youtube.Url);
                setInstagramLink(linkHash.instagram.Url);
                setSnapchatLink(linkHash.snapchat.Url);
                setTiktokLink(linkHash.tiktok.Url);
                setTwitterLink(linkHash.twitter.Url);

                setLogos(
                    !!profile
                        ? profile?.Assets.filter(
                              (a) => (a.Type as any) === "Art"
                          )
                        : []
                );

                setIsDefaultForTemplates(profile?.Brand?.IsDefaultForTemplates);
                setEnableSwitcherPlayer(profile?.Brand?.EnableSwitcherPlayer);

                setLoading(false);
            } catch (e) {
                rollbar.error("Error getting or setting brand profile data", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        };

        init();
    }, [dispatch, user]);

    const submitAction = useCallback(async () => {
        try {
            const userId = user.userInfo?.UserId;

            // Brand Colors submit action

            // TODO: clean up when we move to more than 3 colors
            let brandColorsResult: BrandColor[] = [
                undefined,
                undefined,
                undefined
            ];

            if (!!colors[0]) {
                if (!!primaryColor) {
                    const result =
                        await client.brandColors_PatchBrandColorForUser(
                            userId,
                            brandId,
                            colors[0].Id,
                            {
                                Color: primaryColor,
                                BrandId: brandId
                            } as BrandColor
                        );
                    brandColorsResult[0] = result;
                } else {
                    await client.brandColors_DeleteBrandColorForUser(
                        userId,
                        brandId,
                        colors[0].Id
                    );
                    brandColorsResult[0] = null;
                }
            } else {
                const result = await client.brandColors_PostBrandColorForUser(
                    userId,
                    brandId,
                    { Color: primaryColor, BrandId: brandId } as BrandColor
                );
                brandColorsResult[0] = result;
            }

            if (!!colors[1]) {
                if (!!secondaryColor) {
                    const result =
                        await client.brandColors_PatchBrandColorForUser(
                            userId,
                            brandId,
                            colors[1].Id,
                            {
                                Color: secondaryColor,
                                BrandId: brandId
                            } as BrandColor
                        );
                    brandColorsResult[1] = result;
                } else {
                    await client.brandColors_DeleteBrandColorForUser(
                        userId,
                        brandId,
                        colors[1].Id
                    );
                    brandColorsResult[0] = null;
                }
            } else {
                const result = await client.brandColors_PostBrandColorForUser(
                    userId,
                    brandId,
                    { Color: secondaryColor, BrandId: brandId } as BrandColor
                );
                brandColorsResult[1] = result;
            }

            if (!!colors[2]) {
                if (!!tertiaryColor) {
                    const result =
                        await client.brandColors_PatchBrandColorForUser(
                            userId,
                            brandId,
                            colors[2].Id,
                            {
                                Color: tertiaryColor,
                                BrandId: brandId
                            } as BrandColor
                        );
                    brandColorsResult[2] = result;
                } else {
                    await client.brandColors_DeleteBrandColorForUser(
                        userId,
                        brandId,
                        colors[2].Id
                    );
                    brandColorsResult[0] = null;
                }
            } else {
                const result = await client.brandColors_PostBrandColorForUser(
                    userId,
                    brandId,
                    { Color: tertiaryColor, BrandId: brandId } as BrandColor
                );
                brandColorsResult[2] = result;
            }

            if (!!brandColorsResult) {
                setColors(brandColorsResult);
                if (!!brandColorsResult[0])
                    setPrimaryColor(brandColorsResult[0].Color);
                if (!!brandColorsResult[1])
                    setSecondaryColor(brandColorsResult[1].Color);
                if (!!brandColorsResult[2])
                    setTertiaryColor(brandColorsResult[2].Color);
            }

            // Social Profile Links submit action
            const socialProfileLinksBody = [
                {
                    ...socialProfileLinks.facebook,
                    Url: facebookLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.linkedin,
                    Url: linkedinLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.twitch,
                    Url: twitchLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.youtube,
                    Url: youtubeLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.instagram,
                    Url: instagramLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.snapchat,
                    Url: snapchatLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.tiktok,
                    Url: tiktokLink,
                    BrandId: brandId
                },
                {
                    ...socialProfileLinks.twitter,
                    Url: twitterLink,
                    BrandId: brandId
                }
            ];

            let socialProfileLinksResult =
                await client.brandProfileLinks_PutUserBrandProfileLinks(
                    userId,
                    brandId,
                    socialProfileLinksBody
                );

            if (!!socialProfileLinksResult) {
                const linkHash = makeLinkHash(socialProfileLinksResult);
                setSocialProfileLinks(linkHash);
                setFacebookLink(linkHash.facebook.Url);
                setLinkedinLink(linkHash.linkedin.Url);
                setTwitchLink(linkHash.twitch.Url);
                setYoutubeLink(linkHash.youtube.Url);
            }

            // save additional settings
            const profileUpdateBody = {
                ...brandProfile.Brand,
                IsDefaultForTemplates: isDefaultForTemplates,
                EnableSwitcherPlayer: enableSwitcherPlayer
            };

            await client.userBrands_PutUserBrand(
                userId,
                brandProfile.Brand.Id,
                profileUpdateBody
            );

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:brand-profile-success"
                })
            );

            switcherSdk.dismiss();
        } catch (e) {
            rollbar.error("Error updating user brand profile", e);

            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: e.message
                })
            );
        }
    }, [
        dispatch,
        primaryColor,
        secondaryColor,
        tertiaryColor,
        user,
        facebookLink,
        linkedinLink,
        twitchLink,
        youtubeLink,
        socialProfileLinks,
        brandId,
        colors,
        brandProfile,
        isDefaultForTemplates,
        enableSwitcherPlayer,
        instagramLink,
        snapchatLink,
        tiktokLink,
        twitterLink,
        switcherSdk
    ]);

    const onBrandColorsChange = (value: string, key: string) => {
        switch (key) {
            case "primaryColor":
                setPrimaryColor(value);
                break;
            case "secondaryColor":
                setSecondaryColor(value);
                break;
            case "tertiaryColor":
                setTertiaryColor(value);
                break;
        }
    };

    const onSocialProfileLinksChange = (newVal: string, key: string) => {
        switch (key) {
            case "facebookLink":
                setFacebookLink(newVal);
                break;
            case "linkedinLink":
                setLinkedinLink(newVal);
                break;
            case "twitchLink":
                setTwitchLink(newVal);
                break;
            case "youtubeLink":
                setYoutubeLink(newVal);
                break;
            case "instagramLink":
                setInstagramLink(newVal);
                break;
            case "snapchatLink":
                setSnapchatLink(newVal);
                break;
            case "tiktokLink":
                setTiktokLink(newVal);
                break;
            case "twitterLink":
                setTwitterLink(newVal);
                break;
        }
    };

    return (
        <>
            {!loading && (
                <FormCard icon={<PencilIcon />}>
                    <div className="row">
                        <div className="col-sm-12">
                            {logos.length === 0 && (
                                <LogoUploader
                                    afterSave={(results) => setLogos(results)}
                                    brandId={brandId}
                                />
                            )}
                            {logos.length > 0 && (
                                <UploadedLogo
                                    logos={logos}
                                    refreshLogos={() => refreshLogos(brandId)}
                                    brandId={brandId}
                                />
                            )}
                        </div>
                    </div>
                    <br />
                    <BrandProfileForm
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        tertiaryColor={tertiaryColor}
                        facebookLink={facebookLink}
                        linkedinLink={linkedinLink}
                        twitchLink={twitchLink}
                        youtubeLink={youtubeLink}
                        instagramLink={instagramLink}
                        snapchatLink={snapchatLink}
                        tiktokLink={tiktokLink}
                        twitterLink={twitterLink}
                        submit={submitAction}
                        onBrandColorsChange={onBrandColorsChange}
                        onSocialProfileLinksChange={onSocialProfileLinksChange}
                        socialProfileLinks={socialProfileLinks}
                        isDefaultForTemplates={isDefaultForTemplates}
                        setIsDefaultForTemplates={setIsDefaultForTemplates}
                        enableSwitcherPlayer={enableSwitcherPlayer}
                        setEnableSwitcherPlayer={setEnableSwitcherPlayer}
                        loading={loading}
                    />
                </FormCard>
            )}
        </>
    );
};
