export const embeddedDisplayMap = {
    [0]: "DefaultThumbnail",
    [1]: "Carousel",
    [2]: "Grid"
};

export const defaultInteractiveTabMap = {
    [0]: "About",
    [1]: "Links",
    [2]: "Products",
    [3]: "Cart",
    [4]: "VoD",
    [5]: "Subscription"
};

export const idleStateMap = {
    [0]: "None",
    [1]: "NextEvent",
    [2]: "PreviousLive",
    [3]: "SelectVideo",
    [4]: "Image"
};
