import React from "react";
import styles from "./index.module.scss";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";
import { EnabledInterfaceProps } from "../types";
import { useTranslation } from "react-i18next";

export const EnabledIndicator: React.FC<EnabledInterfaceProps> = ({
    enabled,
    enabledText,
    disabledText
}: EnabledInterfaceProps) => {
    const { t } = useTranslation("widgets");

    return (
        <>
            <div className={styles["enabled-indicator"]}>
                {enabled ? (
                    <CheckmarkIcon className={styles["check-icon"]} />
                ) : null}
                <div
                    className={`${!enabled ? styles["disabled-text"] : ""} ${
                        styles[""]
                    }`}
                >
                    {enabled
                        ? (enabledText ?? t("enabled"))
                        : (disabledText ?? t("disabled"))}
                </div>
            </div>
        </>
    );
};
