import { useTranslation } from "react-i18next";
import { useGetStripeAccounts } from "hooks/useGetStripeAccounts";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import mpstyles from "./index.module.scss";

export const ActiveSubscribersTable = ({
    creatorProductId
}: {
    creatorProductId: string;
}) => {
    const { t } = useTranslation();

    const { accounts } = useGetStripeAccounts();
    const stripeAccountId = accounts?.[0]?.Id;

    const { data } = useSwitcherClient(
        (client) =>
            client.creatorProducts_GetProductActiveSubscriberCountByPrice,
        { args: [stripeAccountId, creatorProductId] }
    );

    const priceSubs = data?.PriceSubscriptions ?? [];

    const { monthly, annual } = priceSubs.reduce(
        (acc, { Interval, Subscriptions }) => {
            if (Interval === "month") {
                acc.monthly += Subscriptions;
            } else if (Interval === "year") {
                acc.annual += Subscriptions;
            }
            return acc;
        },
        { monthly: 0, annual: 0 }
    );

    if (!stripeAccountId || !creatorProductId) {
        return null;
    }

    return (
        <table className={mpstyles.subsTable}>
            <thead>
                <tr>
                    <th>{t("subscription:active-monthly-subs")}</th>
                    <th>{t("subscription:active-annual-subs")}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{monthly}</td>
                    <td>{annual}</td>
                </tr>
            </tbody>
        </table>
    );
};
