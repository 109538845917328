import React from "react";
import { useForm, Errors } from "hooks/useForm";
import { useTranslation } from "react-i18next";
import { BrandColorsForm } from "./BrandColorsForm";
import { SocialProfileLinksForm } from "./SocialProfileLinksForm";
import { Checkbox } from "components/inputs/checkbox/Checkbox";
import styles from "./BrandProfileStyles.module.scss";

interface BrandProfileFormValues {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    facebookLink: string;
    linkedinLink: string;
    twitchLink: string;
    youtubeLink: string;
    instagramLink: string;
    snapchatLink: string;
    tiktokLink: string;
    twitterLink: string;
}

interface BrandProfileFormProps extends BrandProfileFormValues {
    submit: () => Promise<void>;
    onBrandColorsChange: (value: string, key: string) => void;
    onSocialProfileLinksChange: (value: string, key: string) => void;
    socialProfileLinks: any;
    isDefaultForTemplates: boolean;
    setIsDefaultForTemplates: (bool: boolean) => void;
    enableSwitcherPlayer: boolean;
    setEnableSwitcherPlayer: (bool: boolean) => void;
    loading: boolean;
}
export const BrandProfileForm = (props: BrandProfileFormProps) => {
    const {
        submit,
        onBrandColorsChange,
        onSocialProfileLinksChange,
        socialProfileLinks,
        isDefaultForTemplates,
        setIsDefaultForTemplates,
        loading,
        ...initialState
    } = props;

    const { t } = useTranslation();

    const {
        handleSubmit,
        customHandleChange, // only responsible for setting values internally
        values,
        errors
    } = useForm<BrandProfileFormValues>(
        initialState,
        submit,
        (values) => {
            let errors: Errors = {};
            const hexRegExp = /^#([0-9a-f]{6})$/i;

            if (values.primaryColor && !hexRegExp.test(values.primaryColor)) {
                errors.primaryColor = t("errors:brand-color-primary-error");
            }

            if (
                values.secondaryColor &&
                !hexRegExp.test(values.secondaryColor)
            ) {
                errors.secondaryColor = t("errors:brand-color-secondary-error");
            }

            if (values.tertiaryColor && !hexRegExp.test(values.tertiaryColor)) {
                errors.tertiaryColor = t("errors:brand-color-tertiary-error");
            }
            return errors;
        },
        false
    );

    const handleBrandColorsChange = (value: string, key: string) => {
        onBrandColorsChange(value, key);
        const newValues = { ...values, [key]: value };
        customHandleChange(newValues);
    };

    const handleSocialProfileLinksChange = (value: string, key: string) => {
        onSocialProfileLinksChange(value, key);
        const newValues = { ...values, [key]: value };
        customHandleChange(newValues);
    };

    return (
        <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => (e.key === "Enter" ? handleSubmit(e) : null)}
        >
            <div className="row">
                <div className="col-sm-12">
                    <BrandColorsForm
                        onChange={handleBrandColorsChange}
                        primaryColor={values.primaryColor}
                        secondaryColor={values.secondaryColor}
                        tertiaryColor={values.tertiaryColor}
                        errors={errors}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    {!!socialProfileLinks ? (
                        <SocialProfileLinksForm
                            onChange={handleSocialProfileLinksChange}
                            facebookLink={values.facebookLink}
                            linkedinLink={values.linkedinLink}
                            twitchLink={values.twitchLink}
                            youtubeLink={values.youtubeLink}
                            instagramLink={values.instagramLink}
                            snapchatLink={values.snapchatLink}
                            tiktokLink={values.tiktokLink}
                            twitterLink={values.twitterLink}
                            errors={errors}
                        />
                    ) : null}
                </div>
            </div>

            <h4 className={styles["form-card-title"]}>
                {t("brand-profile:additional-settings")}
            </h4>
            <div className="row">
                <div className="col-sm-12">
                    <Checkbox
                        label={t("brand-profile:default-template")}
                        id="isDefaultForTemplates"
                        checked={!!isDefaultForTemplates}
                        onChange={() =>
                            setIsDefaultForTemplates(!isDefaultForTemplates)
                        }
                    />
                </div>
            </div>

            <button
                type="submit"
                className={`btn btn-primary ${styles["save-btn"]}`}
                disabled={loading}
            >
                {t("buttons:save").toUpperCase()}
            </button>
        </form>
    );
};
