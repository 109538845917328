import { useEffect } from "react";

export const useOnLocationChange = (callback: () => void) => {
    /*
        This custom hook can be used, independent of the implemented
        router, to trigger a callback when the location changes. Because
        different routers use different techniques for local navigation,
        we can't rely on the popstate event alone. Ideally, in the future,
        we will just add an event listener to the window.navigation object;
        unfortunately, that is still an experimental feature in Firefox:

        https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API

        Until this has been implemented across all major browsers, the
        approach below (overwriting the pushState and replaceState methods
        on the global history object), is used for browser compatibility.
    */

    useEffect(() => {
        const handleLocationChange = () => {
            callback();
        };

        // Call the callback when the hook is mounted to capture current route.
        handleLocationChange();

        const oldPushState = history.pushState;
        history.pushState = function pushState() {
            const ret = oldPushState.apply(this, arguments as any);
            window.dispatchEvent(new Event("pushstate"));
            window.dispatchEvent(new Event("locationchange"));
            return ret;
        };

        const oldReplaceState = history.replaceState;
        history.replaceState = function replaceState() {
            const ret = oldReplaceState.apply(this, arguments as any);
            window.dispatchEvent(new Event("replacestate"));
            window.dispatchEvent(new Event("locationchange"));
            return ret;
        };

        window.addEventListener("popstate", handleLocationChange);
        window.addEventListener("locationchange", handleLocationChange);

        // Cleanup the listeners
        return () => {
            window.removeEventListener("popstate", handleLocationChange);
            window.removeEventListener("locationchange", handleLocationChange);
        };
    }, [callback]);
};
