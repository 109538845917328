import React, { useState } from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import styles from "./ExternalChannelSettingsForm.module.scss";

interface ExternalChannelSettingsFormProps {
    onClick: (e: any) => void;
    platformId: string;
    platformName: string;
    imgSrc: string;
    streamName: string;
    streamUrl: string;
    streamKey: string;
    btnText: string;
    qualityHeight?: number;
    showQualitySelector?: boolean;
}

const qualityList = [
    { cdnFormat: "1080p", width: 1920, height: 1080, bitrate: 6000000 },
    { cdnFormat: "720p", width: 1280, height: 720, bitrate: 3000000 },
    { cdnFormat: "480p", width: 960, height: 540, bitrate: 1200000 },
    { cdnFormat: "360p", width: 640, height: 360, bitrate: 800000 },
    { cdnFormat: "240p", width: 480, height: 270, bitrate: 400000 }
];

export const ExternalChannelSettingsForm: React.FC<
    ExternalChannelSettingsFormProps
> = ({
    onClick,
    platformName,
    imgSrc,
    streamName,
    streamUrl,
    streamKey,
    qualityHeight,
    btnText
}) => {
    const [selectedQualityIdx, setSelectedQualityIdx] = useState(
        qualityList.findIndex((q) => q.height === qualityHeight)
    );

    return (
        <>
            <div className={styles["form-card"]}>
                <div className={styles["form-card-body"]}>
                    <div className={styles["form-card-title"]}></div>
                    <div className="row justify-content-center">
                        <div className="col-xs-12">
                            <img
                                alt={`${platformName}-logo`}
                                className="img-responsive center-block"
                                src={imgSrc}
                            />
                        </div>
                    </div>
                    <TextInput
                        readonly={true}
                        id="stream-name"
                        label="Stream Name"
                        type="text"
                        placeholder="Username"
                        value={streamName}
                    />
                    {streamUrl && (
                        <TextInput
                            readonly={true}
                            id="stream-url"
                            label="Stream Url"
                            type="text"
                            value={streamUrl}
                        />
                    )}
                    {streamKey && (
                        <TextInput
                            readonly={true}
                            id="stream-key"
                            label="Stream Key"
                            type="text"
                            value={streamKey}
                        />
                    )}
                    {qualityHeight && (
                        <>
                            <div className="form-group">
                                <label htmlFor="external-setting-quality">
                                    Stream Quality
                                </label>
                                <select
                                    id="external-setting-quality"
                                    className={`form-control`}
                                    value={selectedQualityIdx}
                                    onChange={(e) =>
                                        setSelectedQualityIdx(
                                            parseInt(e.target.value)
                                        )
                                    }
                                >
                                    {qualityList.map(({ cdnFormat }, idx) => (
                                        <option key={idx} value={idx}>
                                            {cdnFormat}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                    <button
                        className="btn btn-primary"
                        onClick={() => onClick(qualityList[selectedQualityIdx])}
                    >
                        {btnText}
                    </button>
                </div>
            </div>
        </>
    );
};
