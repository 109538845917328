import React from "react";
import styles from "./AssetCard.module.scss";
import { Asset } from "@switcherstudio/switcher-api-client";
import switcherLogo from "assets/icons/switcher-placeholder.png";
import { formatName, formatBytes } from "./utils";
import LoadingAnimation from "components/loading/LoadingAnimation";

interface AssetCardProps {
    asset: Asset;
    onClick: (asset: Asset) => void;
    selected?: boolean;
    selectMode?: boolean;
    size?: string;
}

export const AssetCard: React.FC<AssetCardProps> = ({
    asset,
    onClick,
    selectMode,
    selected,
    size
}: AssetCardProps) => {
    return (
        <div
            key={asset.Id}
            className={`${styles["asset-card"]} ${
                selectMode && (asset?.RequiredByAssetIds?.length || 0) > 0
                    ? styles["not-allowed"]
                    : ""
            }`}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick(asset);
            }}
        >
            <div id={"asset-" + asset.Id} className={`${styles["asset"]}`}>
                {((asset.Status as any) !== "Uploaded" ||
                    !asset.ThumbnailUrl) && (
                    <div className={styles["processing"]}>
                        <small>Processing...</small>
                        <LoadingAnimation size={"md"} />
                    </div>
                )}
                <img src={asset.ThumbnailUrl || switcherLogo} alt="asset" />
            </div>
            <div className={styles["asset-card-body"]}>
                <p className={styles["asset-card-body-title"]}>
                    <strong>{formatName(asset.Name || "No Name")}</strong>
                </p>
                {(asset.Type as any) !== "Art" && <small>{asset.Type}</small>}
                {size !== "sm" && <small>{formatBytes(asset.Size || 0)}</small>}
                {size !== "sm" && (
                    <div
                        className={`custom-control custom-checkbox ${
                            styles["asset-checkbox"]
                        } ${selectMode ? styles["visible"] : ""}`}
                    >
                        <input
                            className="custom-control-input"
                            type="checkbox"
                            id={asset.Id + "is-selected"}
                            checked={selected}
                            onChange={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onClick(asset);
                            }}
                            aria-label="..."
                            disabled={
                                (asset?.RequiredByAssetIds?.length || 0) > 0
                            }
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={asset.Id + "is-selected"}
                        ></label>
                    </div>
                )}
            </div>
        </div>
    );
};
