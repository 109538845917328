import React from "react";
import styles from "./LoadingAnimation.module.scss";
import { Spinner } from "components/spinners/Spinner";

interface LoadingAnimationProps {
    size?: string;
}
const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ size }) => {
    // Check feature flag to determine which loading animation to use
    let classes = `loader ${styles["ss-loader"]} ${styles[size] || ""} ${
        styles["ringLoader"]
    }`;

    return (
        <div className={classes}>
            <Spinner />
        </div>
    );
};

export default LoadingAnimation;
