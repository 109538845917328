import {
    CreateSubscriptionResponse,
    UpdateSubscriptionResponse
} from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { trackEvent, trackConversion } from "helpers/analyticsHelpers";

import { useCallback, useMemo } from "react";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useBillingRedirect } from "./useBillingRedirect";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import mean from "lodash/mean";
import { AppDispatch } from "store/store";
import { parseBool } from "helpers/booleans";
import rollbar from "helpers/rollbar";
import { subscription } from "store/subscription/slice";
import { useTranslation } from "react-i18next";

export interface useCheckoutRedirectOptions {
    onSuccess?: (args?: { message?: string }) => void;
    onFailure?: (args?: { message?: string }) => void;
}

export const useCheckoutRedirect = ({
    onSuccess,
    onFailure
}: useCheckoutRedirectOptions) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const { searchParams } = useMemo<URL>(
        () => new URL(window.location.href),
        []
    );

    const trackSubscription = useCallback(async (plan: string) => {
        const plans = await client.metrics_GetPlansLtv();
        let planInfo = plans.filter((p) => p.PlanId === plan)[0];

        if (!planInfo) {
            planInfo = {
                Ltv: Number(
                    mean(
                        plans.filter((p) => (p.Ltv || 0) > 0).map((p) => p.Ltv)
                    ).toFixed(2)
                ),
                PlanId: plan
            };
        }

        // legacy "Subscribed" event - deprecated
        trackEvent(
            "Subscribed",
            {
                currency: "USD",
                predicted_ltv: planInfo.Ltv,
                value: planInfo.Ltv,
                planId: planInfo.PlanId,
                category: "Billing",
                label: planInfo.PlanId
            },
            {
                integrations: {
                    Intercom: false,
                    HelpScout: false
                }
            }
        );

        trackConversion(
            true,
            "SilverSunnDashboard",
            await client.stripePrices_GetPrice(plan)
        );
    }, []);

    const makeSubscription = useCallback(
        async (retryStatus?: string) => {
            const planId = searchParams.get("planId");
            const isNewSubscription = parseBool(
                searchParams.get("isNewSubscription")
            );
            const currentSubscriptionId = searchParams.get(
                "currentSubscriptionId"
            );

            const resellerInventoryItemId = searchParams.get(
                "resellerInventoryItemId"
            );

            let subscriptionResponse:
                | CreateSubscriptionResponse
                | UpdateSubscriptionResponse = null;
            let orgId = userInfo.OrganizationId;
            let newSubId: string;

            if (!retryStatus) {
                const options = {
                    OrganizationId: orgId,
                    ResellerInventoryItemId: resellerInventoryItemId,
                    Plan: planId,
                    Quantity: 1
                };

                try {
                    if (isNewSubscription) {
                        // create new subscription - this is called if the user
                        // has NO subscription, which is possible if their previous
                        // subscription has been canceled and the cancellation date
                        // has passed
                        const res: CreateSubscriptionResponse =
                            await client.userSubscriptions_CreateSubscription(
                                userInfo.UserId,
                                {
                                    ...options,
                                    TrialDays: 0,
                                    Source: "SilverSunnDashboard"
                                }
                            );
                        subscriptionResponse = res;
                        newSubId = res.SubscriptionId;
                    } else {
                        // update user's current subscription
                        subscriptionResponse =
                            await client.userSubscriptions_UpdateSubscription(
                                userInfo.UserId,
                                currentSubscriptionId,
                                options
                            );
                    }

                    await trackSubscription(planId);
                } catch (e) {
                    if (e.status === 402) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Danger,
                                message: t("subscription:update-error")
                            })
                        );
                    }
                }
            }

            if (!subscription) {
                throw new Error(t("errors:subscription-failure"));
            }

            await client.userSubscriptions_ReloadSubscription(
                userInfo.UserId,
                currentSubscriptionId ?? newSubId
            );

            return { newSubId, subscriptionResponse, orgId };
        },
        [searchParams, userInfo, trackSubscription, dispatch, t]
    );

    const makeEntitlement = useCallback(async () => {
        const isTrialing = parseBool(searchParams.get("isTrialing"));
        const currentSubscriptionId = searchParams.get("currentSubscriptionId");
        const invoiceId = searchParams.get("invoiceId");

        const finalizedInvoice = await client.stripeInvoices_GetInvoice(
            invoiceId,
            userInfo.UserId
        );

        if (!finalizedInvoice.paid) {
            throw new Error("Invoice not paid");
        }

        await client.purchaseEntitlements_PostUserPurchaseEntitlement(
            userInfo.UserId,
            finalizedInvoice.id
        );

        if (isTrialing) {
            await client.stripe_DeleteSubscription(
                currentSubscriptionId,
                false
            );
        }
    }, [searchParams, userInfo.UserId]);

    useBillingRedirect({
        onSetupIntent: async ({ id, status, payment_method }) => {
            switch (status) {
                case "succeeded":
                    const isSubscription = parseBool(
                        searchParams.get("isSubscription")
                    );

                    const isNewPaymentMethod = parseBool(
                        searchParams.get("isNewPaymentMethod")
                    );

                    const paymentMethodId =
                        typeof payment_method === "string"
                            ? payment_method
                            : payment_method.id;

                    if (isNewPaymentMethod) {
                        await client.userPaymentMethods_AttachPaymentMethod(
                            userInfo.UserId,
                            paymentMethodId
                        );
                    }

                    // Sets the selected payment method as primary after payment
                    await client.userPaymentMethods_SetPrimaryPaymentMethod(
                        userInfo.UserId,
                        paymentMethodId
                    );

                    if (isSubscription) {
                        await makeSubscription();
                    }

                    // If "isSubscription" is undefined then only setup payment method.
                    onSuccess?.({
                        message:
                            isSubscription !== undefined
                                ? t("subscription:success")
                                : t("messages:payment-add-success")
                    });
                    break;
                case "requires_payment_method":
                    onFailure?.();
                    break;
                case "processing":
                    rollbar.info(`Payment Intent is processing: ${id}`);
                    break;
                default:
                    // Handle any unexpected status
                    rollbar.warning(`Unexpected payment status: ${status}`, {
                        id
                    });
                    break;
            }
        },
        onPaymentIntent: async ({ id, status, payment_method }) => {
            switch (status) {
                case "succeeded":
                    const isSubscription = parseBool(
                        searchParams.get("isSubscription")
                    );

                    await client.userPaymentMethods_SetPrimaryPaymentMethod(
                        userInfo.UserId,
                        typeof payment_method === "string"
                            ? payment_method
                            : payment_method.id
                    );

                    if (isSubscription === false) {
                        await makeEntitlement();
                    }

                    onSuccess?.({
                        message: t("subscription:success")
                    });
                    break;
                case "requires_payment_method":
                    onFailure?.();
                    break;
                case "processing":
                    rollbar.info(`Payment Intent is processing: ${id}`);
                    break;
                default:
                    // Handle any unexpected status
                    rollbar.warning(`Unexpected payment status: ${status}`, {
                        id
                    });
                    break;
            }
        }
    });
};
