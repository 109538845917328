import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useTranslation } from "react-i18next";
import {
    resetYoutubeForm,
    updateGeneralForm,
    updateYoutubeForm,
    updateSelectedStream
} from "store/platforms/slice";
import { Toggle } from "components/inputs/toggle/Toggle";
import { PillSelector } from "components/pill-selector/PillSelector";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { GeneralPlatformForm } from "store/platforms/types";
import { useNavigate } from "react-router-dom";
import { ScheduledOptions } from "../forms/types";
import rollbar from "helpers/rollbar";

interface YoutubeFormProps {
    platform?: StreamingProvider;
    multiForm?: boolean;
    destinationId?: string | number;
}

const privacyPills = ["Private", "Public", "Unlisted"];

export const YoutubeForm: React.FC<
    YoutubeFormProps
> = ({}: YoutubeFormProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const isMounted = useIsMountedRef();
    const navigate = useNavigate();

    const { youtube, general } = useSelector(
        (state: RootState) => state.platforms
    );
    const { youtubeForm } = youtube;
    const { selectedStreams, formState } = general;

    useEffect(() => {
        async function init() {
            try {
                const title = `${t(
                    "platforms:my-event"
                )} ${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
                dispatch(updateSelectedStream({ id: "youtube", stream: null }));
                dispatch(resetYoutubeForm());
                dispatch(
                    updateYoutubeForm({
                        title,
                        selectedPrivacy: {
                            name: privacyPills[1],
                            value: privacyPills[1].toLowerCase()
                        }
                    })
                );
            } catch (e) {
                rollbar.error("Error loading youtube platform form", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("platforms:youtube-load-fail")
                    })
                );
                navigate("/platforms/youtube", { replace: true });
            }
        }

        if (isMounted.current) init();
    }, [isMounted, dispatch, navigate, t]);

    useEffect(() => {
        async function setInfoFromStream() {
            if (
                formState === ScheduledOptions.Existing &&
                selectedStreams?.youtube?.snippet
            ) {
                const update: Partial<GeneralPlatformForm> = {
                    title: selectedStreams?.youtube?.snippet?.title,
                    description: selectedStreams?.youtube?.snippet?.description
                };

                if (selectedStreams?.youtube?.snippet?.scheduledStartTime) {
                    update.scheduledStartTime = new Date(
                        selectedStreams?.youtube?.snippet?.scheduledStartTime
                    );
                }

                dispatch(updateGeneralForm(update));
            }
        }

        setInfoFromStream();
    }, [dispatch, formState, selectedStreams]);

    const onToggle = useCallback(
        (key, value) => {
            dispatch(updateYoutubeForm({ [key]: value }));
        },
        [dispatch]
    );

    const onPrivacyChange = useCallback(
        (privacy) => {
            const selectedPrivacy = {
                name: privacy,
                value: privacy.toLowerCase()
            };
            dispatch(updateYoutubeForm({ selectedPrivacy }));
        },
        [dispatch]
    );

    return (
        <>
            {general?.formState !== ScheduledOptions.Existing && (
                <>
                    <div className="form-group">
                        <Toggle
                            label={t("platforms:for-kids")}
                            on={youtube?.youtubeForm.isForKids}
                            onToggle={() => {
                                onToggle(
                                    "isForKids",
                                    !youtube?.youtubeForm.isForKids
                                );
                            }}
                        />
                        {youtube?.youtubeForm.isForKids && (
                            <small>{t("platforms:for-kids-description")}</small>
                        )}
                    </div>
                    <div className="form-group">
                        <Toggle
                            label={t("platforms:allow-embedding")}
                            on={youtubeForm.allowEmbedding}
                            onToggle={() => {
                                onToggle(
                                    "allowEmbedding",
                                    !youtubeForm.allowEmbedding
                                );
                            }}
                        />
                        <small>{t("platforms:embed-restriction")}</small>
                    </div>
                    <div className="form-group">
                        <label>{t("platforms:event-privacy")}</label>
                        <PillSelector
                            pills={privacyPills}
                            selectedPill={youtubeForm.selectedPrivacy.name}
                            selectPill={onPrivacyChange}
                        />
                    </div>
                </>
            )}
        </>
    );
};
