import React, { useEffect } from "react";
import { client } from "api/client";
import rollbar from "../../helpers/rollbar";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/notification/slice";
import { NotificationType } from "../../store/notification/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "store/store";
import { useParams } from "hooks/useParams";

export const ConfirmEmailPage: React.FC = () => {
    const { UserId, Code } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    useEffect(() => {
        async function fetch() {
            try {
                await client.account_ConfirmEmail({
                    UserId: UserId,
                    Code: Code
                });
                navigate("/getting-started");
            } catch (e) {
                rollbar.error("Error confirming email", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:confirm-email-error")
                    })
                );
            }
        }
        fetch();
    }, [t, dispatch, Code, UserId, navigate]);

    return <></>;
};
