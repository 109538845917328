import i18n from "i18next";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationState, AddNotificationPayload } from "./types";

const initialState = {
    notifications: [],
    lastId: 0
} as NotificationState;

export const notification = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        addNotification: (
            state,
            { payload }: PayloadAction<AddNotificationPayload>
        ) => {
            // Translate the click text
            if (payload.hasOwnProperty("clickText"))
                payload.clickText = i18n.t(payload.clickText);

            // If the message is not allowed to be duplicated, check for duplicates
            if (payload?.messageOptions?.allowDuplicates === false) {
                const messageAlreadyExists = state.notifications.find(
                    (n) => n.message === payload.message
                );
                if (messageAlreadyExists) return;
            }

            state.notifications.push({ ...payload, id: ++state.lastId });
        },
        removeNotification: (state, { payload }: PayloadAction<number>) => {
            state.notifications = state.notifications.filter(
                (n) => n.id !== payload
            );
        },
        resetNotifications: () => initialState
    }
});
export const { addNotification, removeNotification, resetNotifications } =
    notification.actions;

export default notification.reducer;
