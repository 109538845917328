import React, { PropsWithChildren } from "react";
import CloseIcon from "assets/icons/close.svg?react";
import styles from "./index.module.scss";
import { exists } from "helpers/booleans";

export interface BannerButtonProps {
    label: string;
    onClick: () => void;
    type:
        | "btn-primary"
        | "btn-outline-primary"
        | "btn-link"
        | "btn-danger"
        | "btn-outline-danger";
    className?: string;
}

export interface BannerProps extends PropsWithChildren {
    header?: string;
    hide?: boolean;
    subheader?: string;
    onClose?: () => void;
    element?: React.ReactNode;
    buttonProps?: BannerButtonProps;
}

export const BannerBaseForProgressTracker: React.FC<BannerProps> = ({
    header,
    subheader,
    hide,
    onClose,
    element,
    buttonProps,
    children
}) =>
    !hide && (
        <div className={styles["banner-container"]}>
            <div className={styles["banner"]}>
                <div>
                    <span>
                        <div className={styles["body"]}>
                            <span className={styles["message"]}>
                                <span className={styles["header-container"]}>
                                    <h4>{header}</h4>
                                    {onClose && (
                                        <button
                                            className={styles["close-btn"]}
                                            onClick={onClose}
                                        >
                                            <CloseIcon />
                                        </button>
                                    )}
                                </span>
                                <span className={styles["subheader-container"]}>
                                    <small>{subheader ? subheader : ""}</small>
                                    {buttonProps && (
                                        <button
                                            className={`btn ${
                                                buttonProps.type
                                            } ${
                                                styles[buttonProps.className] ??
                                                ""
                                            }`}
                                            type="button"
                                            onClick={buttonProps.onClick}
                                        >
                                            {buttonProps.label}
                                        </button>
                                    )}
                                </span>
                            </span>
                        </div>
                    </span>
                    {children}
                </div>
                <span
                    className={`${styles["element-container"]} ${
                        exists(element) ? styles["has-element"] : ""
                    }`}
                >
                    {element}
                </span>
            </div>
        </div>
    );
