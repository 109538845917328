import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { CreateAccountForm, CreateAccountFormProps } from "./CreateAccountForm";

export const CreateAccountStep: React.FC<CreateAccountFormProps> = (props) => {
    const { t } = useTranslation("getting-started");

    return (
        <div className={styles["login-form-container"]}>
            <h4 className={`${styles["title-light"]}`}>
                {t("personal-details.title1")}{" "}
                <strong>{t("personal-details.title2")}</strong>
            </h4>
            <CreateAccountForm {...props} />
        </div>
    );
};
