import { DateValue } from "@visx/mock-data/lib/generators/genDateValue";
import { AnalyticsUnitTypes } from "views/page-content/analytics/types";
import { useMemo } from "react";
import { formatTimeDuration } from "helpers/time";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { displayAmount } from "helpers/stripe";

export interface AnalyticsLineGraphTooltipProps {
    tooltipData: DateValue;
    unit?: AnalyticsUnitTypes;
}

export const AnalyticsLineGraphTooltip = ({
    tooltipData,
    unit
}: AnalyticsLineGraphTooltipProps) => {
    const { i18n } = useTranslation();

    const value = useMemo(() => {
        switch (unit) {
            case "number":
                return Intl.NumberFormat(i18n.language).format(
                    tooltipData.value
                );
            case "time":
                return formatTimeDuration(tooltipData.value, "m:ss");
            case "dollars":
                return displayAmount(tooltipData.value, {
                    locale: i18n.language,
                    signed: true,
                    roundUp: false
                });
        }
    }, [unit, tooltipData.value, i18n.language]);

    return (
        <div className={styles["tooltip"]}>
            <p>{value}</p>
        </div>
    );
};
