import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionState } from "./types";

export const subscription = createSlice({
    name: "subscription",
    initialState: {
        lastAttemptedInvoice: undefined
    } as SubscriptionState,
    reducers: {
        setLastAttemptedInvoice: (
            state: SubscriptionState,
            { payload }: PayloadAction<any>
        ) => {
            state.lastAttemptedInvoice = payload;
        }
    }
});

export const { setLastAttemptedInvoice } = subscription.actions;

export default subscription.reducer;
