import {
    Broadcast,
    CreatorProductEntitlement,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import styles from "../index.module.scss";
import ShopIcon from "assets/icons/product-tag.svg?react";
import PlaylistIcon from "assets/icons/switcher-player-rev.svg?react";
import CardIcon from "assets/icons/ccBrands/genericCard.svg?react";
import HiddenIcon from "assets/icons/hidden.svg?react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import React from "react";
import { Badge } from "../types";
import { exists } from "helpers/booleans";
import { useClaimCheck } from "hooks/useClaimCheck";
interface VideoBadgesProps {
    broadcast?: Broadcast;
    players?: VideoPlayer[] | undefined | null;
    entitlements?: CreatorProductEntitlement[] | undefined | null;
    // if true, badges show count for each type of entitlement (e.g. "1 One-Time Pass"),
    // if false, badges display each individual entitlement
    groupEntitlements?: boolean;
    shownEntitlements?: "one-time-passes" | "subscriptions" | "all" | "none";
}

export const VideoBadges: React.FC<VideoBadgesProps> = ({
    broadcast,
    players,
    entitlements,
    groupEntitlements = false,
    shownEntitlements = "none"
}: VideoBadgesProps) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const videoBadges = useMemo<Badge[]>(() => {
        const isInPlaylist = players?.length > 0;
        const isShoppable = broadcast?.EnableLiveShopping;
        const isHidden = broadcast && !broadcast.ShowInCatalog;
        const hasEntitlement = entitlements?.length > 0;
        const badges: Badge[] = [];
        if (isShoppable) {
            badges.push({
                slug: t("video-library:badges:shop"),
                icon: <ShopIcon />
            });
        }
        if (isInPlaylist) {
            badges.push({
                slug:
                    players?.length === 1
                        ? `1 ${hasCatalogClaim ? t("video-library:badges:collection") : t("video-library:badges:playlist")}`
                        : `${players?.length ?? 0} ${hasCatalogClaim ? t("video-library:badges:collections") : t("video-library:badges:playlists")}`,
                icon: <PlaylistIcon />
            });
        }
        if (isHidden) {
            badges.push({
                slug: t("video-library:badges:hidden"),
                icon: <HiddenIcon />
            });
        }
        if (hasEntitlement) {
            if (groupEntitlements) {
                const { oneTimePassesCount, subscriptionsCount } =
                    entitlements.reduce(
                        (memo, currentEntitlement) => {
                            const isRecurring = exists(
                                currentEntitlement?.Product?.Prices?.[0]
                                    ?.RecurringInterval
                            );
                            return {
                                oneTimePassesCount: isRecurring
                                    ? memo.oneTimePassesCount
                                    : memo.oneTimePassesCount + 1,
                                subscriptionsCount: isRecurring
                                    ? memo.subscriptionsCount + 1
                                    : memo.subscriptionsCount
                            };
                        },
                        { oneTimePassesCount: 0, subscriptionsCount: 0 }
                    );

                if (
                    oneTimePassesCount > 0 &&
                    ["one-time-passes", "all"].includes(shownEntitlements)
                ) {
                    badges.push({
                        slug: t("video-badges:one-time-passes", {
                            count: oneTimePassesCount
                        }),
                        icon: <CardIcon />
                    });
                }

                if (
                    subscriptionsCount > 0 &&
                    ["subscriptions", "all"].includes(shownEntitlements)
                ) {
                    badges.push({
                        slug: t("video-badges:subscriptions", {
                            count: subscriptionsCount
                        }),
                        icon: <CardIcon />
                    });
                }
            } else {
                entitlements.forEach((e) => {
                    const entitlementType = exists(
                        e?.Product?.Prices?.[0]?.RecurringInterval
                    )
                        ? "subscription"
                        : "one-time-pass";
                    const entitlementTypeIsShown =
                        (entitlementType === "subscription" &&
                            ["subscriptions", "all"].includes(
                                shownEntitlements
                            )) ||
                        (entitlementType === "one-time-pass" &&
                            ["one-time-passes", "all"].includes(
                                shownEntitlements
                            ));
                    if (entitlementTypeIsShown) {
                        badges.push({
                            slug: `${e?.Product?.Name}`,
                            icon: <CardIcon />
                        });
                    }
                });
            }
        }
        return badges;
    }, [
        players?.length,
        broadcast,
        entitlements,
        t,
        hasCatalogClaim,
        groupEntitlements,
        shownEntitlements
    ]);

    return (
        <>
            {videoBadges.length > 0 && (
                <div className={`${styles["badges-container"]}`}>
                    {videoBadges.map((badge) => {
                        return (
                            <div
                                className={`${
                                    styles["badge"]
                                } ${styles[`badge-${badge.slug}`]}`}
                                key={badge.slug}
                            >
                                {badge.icon}
                                <p>{badge.slug}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
