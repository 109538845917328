export interface SlugifyOptions {
    toLowerCase?: boolean;
}

export function slugify(
    str: string,
    { toLowerCase = true }: SlugifyOptions = {}
) {
    const out = String(str)
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .replace(/[^a-zA-Z0-9 -]/g, "") // remove non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens

    return toLowerCase ? out.toLowerCase() : out;
}
