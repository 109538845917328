import { Table } from "components/tables/Table";
import { AnalyticTabProps } from "./ReorderCardAction/types";
import { displayDate } from "helpers/time";
import i18n from "localization/i18n";
import { ExportAction } from "./ExportAction";
import { useAnalyticsExport } from "hooks/useAnalyticsExport";
import {
    CustomerRedemptionsSummaryResponse,
    CustomerRedemptionSummary,
    CustomerRedemptionSummaryRedemptionType
} from "@switcherstudio/switcher-api-client";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
const cx = classnames.bind(styles);

export interface CustomersTabProps
    extends AnalyticTabProps<CustomerRedemptionsSummaryResponse> {}

export const CustomersTab = ({
    metrics,
    metricsLoading
}: CustomersTabProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const CustomerRedemptionSummaryRedemptionTypeMap = useMemo<{
        [key in CustomerRedemptionSummaryRedemptionType]: string;
    }>(
        () => ({
            [CustomerRedemptionSummaryRedemptionType._0]: t(
                "analytics-page:type-subscription"
            ),
            [CustomerRedemptionSummaryRedemptionType._1]: t(
                "analytics-page:type-purchase"
            ),
            [CustomerRedemptionSummaryRedemptionType._2]: t(
                "analytics-page:type-email"
            ),
            [CustomerRedemptionSummaryRedemptionType._3]: t(
                "analytics-page:type-password"
            )
        }),
        [t]
    );

    const exportCSV = useAnalyticsExport(metrics?.CustomerRedemptions, {
        onEmpty: () =>
            dispatch(
                addNotification({
                    message: t("analytics-page:empty-dataset-error"),
                    type: NotificationType.Danger
                })
            ),
        onSuccess: () =>
            dispatch(
                addNotification({
                    message: t("analytics-page:export-success"),
                    type: NotificationType.Success
                })
            )
    });

    const mapper = useCallback(
        (redemption: CustomerRedemptionSummary) => ({
            Email: redemption.Email ?? "Unknown",
            Source: redemption.Source ?? "Unknown",
            Type:
                CustomerRedemptionSummaryRedemptionTypeMap[
                    redemption.RedemptionType
                ] ?? "Unknown",
            RedemptionDate: new Date(redemption.RedemptionDate) ?? "Unknown"
        }),
        [CustomerRedemptionSummaryRedemptionTypeMap]
    );

    const redemptions = useMemo(
        () => metrics?.CustomerRedemptions?.map(mapper) ?? [],
        [mapper, metrics]
    );

    return (
        <>
            <div className={cx("actions")}>
                <ExportAction
                    key="export"
                    onClick={() =>
                        exportCSV(
                            mapper,
                            `Switcher-Email-Analytics-${displayDate(
                                new Date(),
                                i18n.language,
                                "YYYY-MM-DD"
                            )}.csv`
                        )
                    }
                    disabled={metricsLoading || redemptions.length === 0}
                />
            </div>
            <Table
                columns={{
                    "Email": {
                        label: "Email",
                        sort: (a, b, direction) => {
                            return direction === "ascending"
                                ? a.localeCompare(b)
                                : b.localeCompare(a);
                        }
                    },
                    "Source": {
                        label: "Source",
                        sort: (a, b, direction) => {
                            return direction === "ascending"
                                ? a.localeCompare(b)
                                : b.localeCompare(a);
                        }
                    },
                    "Type": {
                        label: "Type",
                        sort: (a, b, direction) => {
                            return direction === "ascending"
                                ? a.localeCompare(b)
                                : b.localeCompare(a);
                        }
                    },
                    "RedemptionDate": {
                        label: "Date Collected",
                        sort: (a: Date, b: Date, direction) => {
                            return (
                                Math.sign(
                                    (a?.getTime() ?? 0) - (b?.getTime() ?? 0)
                                ) * (direction === "ascending" ? 1 : -1)
                            );
                        },
                        formatter: (date) =>
                            displayDate(
                                new Date(date),
                                i18n.language,
                                "MM/DD/YY"
                            )
                    }
                }}
                points={redemptions}
                sortedBy="RedemptionDate"
                loading={metricsLoading}
            />
        </>
    );
};
