import { useEffect, useState } from "react";

import { client } from "api/client";
import { UserState } from "store/user/types";
import { Reseller } from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";

export function useGetResellerByUser(user: UserState): Reseller {
    const [reseller, setReseller] = useState<Reseller>();

    useEffect(() => {
        const getReseller = async () => {
            try {
                if (user.userInfo.ResellerId) {
                    setReseller(
                        await client.resellers_GetReseller(
                            user.userInfo.ResellerId
                        )
                    );
                }
            } catch (e) {
                rollbar.error("Error getting reseller", e);
            }
        };
        getReseller();
    }, [user]);

    return reseller;
}
