import React, { useState, useCallback, useMemo } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { areMatchingDates } from "helpers/time";
import dayjs from "dayjs";

interface DateTimePickerProps {
    id: string;
    value?: Date | string;
    error?: string;
    dateOnly?: boolean;
    onChange(
        date: Date | [Date, Date] | null,
        event: React.SyntheticEvent<any, Event> | undefined
    ): void;
    datePickerProps?: Partial<ReactDatePickerProps>;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
    id,
    value,
    error = "",
    dateOnly,
    onChange,
    datePickerProps
}: DateTimePickerProps) => {
    const currentDate = useMemo<Date>(() => new Date(), []);
    const twentyMinutesFromNow = useMemo<Date>(
        () => dayjs(currentDate).add(20, "minute").toDate(),
        [currentDate]
    );
    const startingTime = useMemo(() => {
        if (!value) return currentDate;
        if (typeof value === "string") return new Date(value);
        return value;
    }, [currentDate, value]);

    const getMinTime = useCallback(() => {
        const startValueMinutes = startingTime.getMinutes();
        const nextValidStartMinutes = Math.ceil(startValueMinutes / 5) * 5;

        if (nextValidStartMinutes < 60) {
            if (startValueMinutes % 5 === 0) return startingTime;
            return dayjs(startingTime)
                .add(nextValidStartMinutes, "minute")
                .toDate();
        }

        return new Date(
            startingTime.getFullYear(),
            startingTime.getMonth(),
            startingTime.getDate(),
            startingTime.getHours() + 1,
            0
        );
    }, [startingTime]);

    const [minTime, setMinTime] = useState<Date>(twentyMinutesFromNow);
    const [maxTime, setMaxTime] = useState<Date>(
        new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            23,
            59
        )
    );

    const handleChange = useCallback(
        (selectedDate: Date, e: React.SyntheticEvent) => {
            const firstMinuteOfSelectedDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                0,
                0
            );
            const lastMinuteOfSelectedDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                23,
                59
            );
            const currentTimeOnSelectedDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                currentDate.getHours(),
                currentDate.getMinutes()
            );

            // reset the available times in the time picker for new date before invoking callback
            if (areMatchingDates(currentDate, selectedDate)) {
                setMinTime(twentyMinutesFromNow);
            } else if (
                datePickerProps.maxDate &&
                areMatchingDates(selectedDate, datePickerProps.maxDate)
            ) {
                setMinTime(firstMinuteOfSelectedDate);
                setMaxTime(currentTimeOnSelectedDate);
            } else {
                setMinTime(firstMinuteOfSelectedDate);
                setMaxTime(lastMinuteOfSelectedDate);
            }

            // invoke the onChange callback passed down as a prop
            onChange(selectedDate, e);
        },
        [currentDate, datePickerProps.maxDate, onChange, twentyMinutesFromNow]
    );

    return (
        <>
            <div className="form-group datetime-picker">
                <DatePicker
                    selected={getMinTime()}
                    dateFormat={`MM/dd/yyyy ${dateOnly ? "" : "h:mm a"}`}
                    onChange={handleChange}
                    showTimeSelect={!dateOnly}
                    timeIntervals={5}
                    minTime={minTime}
                    maxTime={maxTime}
                    minDate={currentDate}
                    customInput={
                        <input
                            id={id}
                            title={id}
                            type="text"
                            className="form-control"
                            value={getMinTime()?.toString()}
                        />
                    }
                    {...datePickerProps}
                />
                <small
                    id={`${id}-help`}
                    aria-describedby={id}
                    className="form-text text-danger"
                >
                    {error}
                </small>
            </div>
        </>
    );
};
