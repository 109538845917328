import React, { useMemo } from "react";

interface ElementCheckboxProps {
    label: JSX.Element;
    id: string;
    checked: boolean | undefined;
    onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
    small?: string;
    disabled?: boolean;
    customClass?: string;
    customLabelClass?: string;
}

/**
 * A Checkbox that takes an JSX.Element for label
 */
export const ElementCheckbox: React.FC<ElementCheckboxProps> = ({
    label,
    id,
    checked,
    onChange,
    small,
    disabled,
    customClass,
    customLabelClass
}) => {
    const isDisabled = useMemo<boolean>(
        () => !onChange || !!disabled,
        [onChange, disabled]
    );

    return (
        <div className={customClass}>
            <input
                type="checkbox"
                className="form-check-input"
                id={id}
                checked={checked || false}
                onChange={onChange}
                disabled={isDisabled}
                readOnly={isDisabled}
                onKeyDown={(e) => {
                    if (e.key === "Enter") onChange();
                }}
            />
            <label htmlFor={id} className={customLabelClass}>
                {label}
            </label>
            <small
                id={`${id}-help`}
                aria-describedby={id}
                className="form-text text-muted"
            >
                {small}
            </small>
        </div>
    );
};
