import React from "react";
import { DragAndDrop, DragAndDropProps } from "./DragAndDrop";
import styles from "../../index.module.scss";

/** Styles wrapper for the Drag and Drop component when it is inside the upload modal */
export const DragAndDropStepV2 = ({
    sessionId,
    initialUppyInstance
}: DragAndDropProps) => {
    return (
        <div className={`${styles["upload-step-1"]}`}>
            <div className={`${styles["upload-step-body"]}`}>
                <DragAndDrop
                    initialUppyInstance={initialUppyInstance}
                    sessionId={sessionId}
                />
            </div>
        </div>
    );
};
