import { useEffect, useRef } from "react";

export interface UseOnPageChangeOptions {
    /** If set to true, callback will be triggered on every page change. If not, the callback will only trigger if tab is changed. */
    exactMatch?: boolean;
}

export const useOnPageChange = (
    onPageChange: () => void,
    { exactMatch }: UseOnPageChangeOptions = { exactMatch: false }
) => {
    const lastTab = useRef<string>();

    useEffect(
        () => {
            if (exactMatch) {
                onPageChange();
            } else {
                const tab = window.location.pathname.split("/")[1];
                if (tab !== lastTab.current) {
                    onPageChange();
                    lastTab.current = tab;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [exactMatch, window.history.state]
    );
};
