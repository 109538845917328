import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import {
    Broadcast,
    CreatorProductEntitlement
} from "@switcherstudio/switcher-api-client";
import { BroadcastDetailsProps } from "../entity-details/BroadcastDetails";
import { AttentionModal } from "components/modal/AttentionModal";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { getResultsFromPromiseAllSettled } from "helpers/arrays";
import { DownloadProps, useVideoDownload } from "hooks/useVideoDownload";

import DownloadIcon from "assets/icons/download.svg?react";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import {
    ActionsBarOptions,
    SearchSortOptions
} from "components/generic-multiselect/types";
import { useAddVideosActionsProps } from "./types";

const useAddVideosActions = (props: useAddVideosActionsProps) => {
    const { refetchVideos, isVideoLibrary } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleDownloads } = useVideoDownload();
    const { dispatchApiRequest: deleteBroadcast } = useSwitcherClient(
        (client) => client.broadcasts_DeleteBroadcastCloudflareVideos,
        { requestImmediately: false }
    );

    const deleteSingleBroadcast = useCallback(
        async (broadcast: Broadcast, refetch?: boolean) => {
            try {
                await deleteBroadcast([broadcast.Id]);
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:video-delete-success"
                    })
                );
                if (refetch) {
                    await refetchVideos();
                }
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:videos-deleted_one", { count: 1 })
                    })
                );
            }
        },
        [deleteBroadcast, dispatch, refetchVideos, t]
    );

    const deleteManyBroadcasts = useCallback(
        async (broadcastDetailsProps: BroadcastDetailsProps[]) => {
            const promisesArray = broadcastDetailsProps.map((broadcastItem) =>
                deleteBroadcast([broadcastItem?.broadcast?.Id])
            );

            return Promise.allSettled(promisesArray).then(async (results) => {
                const { fulfilled, rejected } =
                    getResultsFromPromiseAllSettled<Broadcast>(results);

                if (rejected?.length) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("errors:videos-deleted_other", {
                                count: rejected.length
                            })
                        })
                    );
                }

                if (fulfilled?.length) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t(
                                "messages:videos-deleted-success_other",
                                {
                                    count: fulfilled.length
                                }
                            )
                        })
                    );

                    await refetchVideos();
                }
            });
        },
        [deleteBroadcast, dispatch, refetchVideos, t]
    );

    const closeAttentionModal = useCallback(() => {
        dispatch(closeCurrentModal());
    }, [dispatch]);

    const onAttentionModalContinue = useCallback(
        async (callback: (...args: any) => any) => {
            callback();
            closeAttentionModal();
        },
        [closeAttentionModal]
    );

    const handleDispatchAttentionModal = useCallback(
        (
            hasEntitlements: boolean = false,
            multiDelete: boolean = false,
            broadcast: Broadcast = null,
            broadcastDetailsProps: BroadcastDetailsProps[] = null
        ) => {
            const deleteConfirmationText = hasEntitlements
                ? t("video-library:delete-confirmation-entitlements")
                : t("video-library:delete-confirmation");
            dispatch(
                setActiveModal({
                    id: Modals.AttentionModal,
                    type: Modals.AttentionModal,
                    component: (
                        <AttentionModal
                            isOpen={true}
                            setIsOpen={closeAttentionModal}
                            handleCancel={closeAttentionModal}
                            hasContinueButton={true}
                            handleContinue={() =>
                                multiDelete
                                    ? onAttentionModalContinue(() =>
                                          deleteManyBroadcasts(
                                              broadcastDetailsProps
                                          )
                                      )
                                    : onAttentionModalContinue(() =>
                                          deleteSingleBroadcast(broadcast, true)
                                      )
                            }
                            continueText={t("buttons:delete")}
                        >
                            <>
                                <div className={styles["delete-confirmation"]}>
                                    {deleteConfirmationText}
                                </div>
                            </>
                        </AttentionModal>
                    )
                })
            );
        },
        [
            t,
            dispatch,
            closeAttentionModal,
            deleteManyBroadcasts,
            onAttentionModalContinue,
            deleteSingleBroadcast
        ]
    );

    const handleDelete = useCallback(
        async (
            broadcast: Broadcast,
            entitlements: CreatorProductEntitlement[]
        ) => {
            if (!entitlements?.length) {
                handleDispatchAttentionModal(false, false, broadcast, null);
            } else {
                handleDispatchAttentionModal(true, false, broadcast, null);
            }
        },
        [handleDispatchAttentionModal]
    );

    const handleDeleteMany = useCallback(
        async (broadcastDetailsProps: BroadcastDetailsProps[]) => {
            if (!broadcastDetailsProps?.length) return;
            let showDefaultDeleteConfirmation = true;
            for (let i = 0; i < broadcastDetailsProps.length; i++) {
                const { entitlements } = broadcastDetailsProps[i] ?? {};
                if (entitlements?.length) {
                    showDefaultDeleteConfirmation = false;
                    break;
                }
            }

            if (showDefaultDeleteConfirmation) {
                handleDispatchAttentionModal(
                    false,
                    true,
                    null,
                    broadcastDetailsProps
                );
            } else {
                handleDispatchAttentionModal(
                    true,
                    true,
                    null,
                    broadcastDetailsProps
                );
            }
        },
        [handleDispatchAttentionModal]
    );

    const handleDownloadMany = useCallback(
        async (broadcastDetailsProps: BroadcastDetailsProps[]) => {
            handleDownloads(
                broadcastDetailsProps
                    .filter((bdp) => !!bdp.video)
                    .map((bdp) => {
                        return {
                            broadcastId: bdp.broadcast.Id,
                            videoId: bdp.video.uid,
                            title: bdp.broadcast.Title
                        } as DownloadProps;
                    })
            );
        },
        [handleDownloads]
    );

    const actionsBarOptions = useMemo(() => {
        const options: ActionsBarOptions<BroadcastDetailsProps> = {
            showActions: isVideoLibrary,
            actions: [
                {
                    text: t("buttons:download"),
                    onClick: handleDownloadMany,
                    buttonType: "outline",
                    MobileIcon: DownloadIcon
                },
                {
                    text: t("buttons:delete"),
                    onClick: handleDeleteMany,
                    buttonType: "outline",
                    shouldDeselectOnClick: false,
                    MobileIcon: TrashIcon
                }
            ]
        };
        return options;
    }, [handleDeleteMany, handleDownloadMany, t, isVideoLibrary]);

    const searchSortOptions: SearchSortOptions = useMemo(
        () => ({
            showSearchSort: true,
            implementationType: "broadcast-search-sort",
            location: isVideoLibrary ? "video-library" : "player-playlist"
        }),
        [isVideoLibrary]
    );

    return {
        handleDelete,
        actionsBarOptions,
        searchSortOptions
    };
};

export { useAddVideosActions };
