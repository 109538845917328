import React, { useMemo, useRef } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { AspectRatio } from "views/page-content/cloud/upload/types";
import { Button } from "components/buttons/Button";

export interface ThumbnailUploaderProps {
    /** Called when the thumbnail file changes */
    setThumbnailFile: (thumbnailFile: File) => void;
    /** The thumbnail image url */
    thumbnailImageURL: string;
    setThumbnailImageURL: (thumbnailImageURL: string) => void;
    /** Provides the player's orientation for additional context.Currently indicates the recommend resolution for the thumbnail */
    orientation?: AspectRatio | undefined;
    /** Called when the thumbnail image is removed */
    onRemove?: () => void;
    additionalMessage?: string;
}

export const ThumbnailUploader: React.FC<ThumbnailUploaderProps> = ({
    setThumbnailFile,
    thumbnailImageURL,
    setThumbnailImageURL,
    orientation,
    onRemove,
    additionalMessage
}) => {
    const { t } = useTranslation();
    const inputElement = useRef<HTMLInputElement>(null);
    const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setThumbnailFile(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataUrl = e.target?.result as string;
                setThumbnailImageURL(dataUrl);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleRemoveImage = () => {
        setThumbnailFile(null);
        setThumbnailImageURL(null);
        onRemove && onRemove();
        if (inputElement.current) {
            inputElement.current.value = "";
        }
    };

    const helperText = useMemo(() => {
        switch (orientation) {
            case AspectRatio.horizontal:
                return t("inputs:thumbnail-upload:recommended-size-horizontal");
            case AspectRatio.vertical:
                return t("inputs:thumbnail-upload:recommended-size-vertical");
            default:
                return t("inputs:thumbnail-upload:recommended-size");
        }
    }, [t, orientation]);

    return (
        <div className={styles["thumbnail-uploader"]}>
            {thumbnailImageURL && (
                <div className={styles["thumbnail-preview-container"]}>
                    <div className={styles["thumbnail-preview"]}>
                        <img
                            className={styles["thumbnail-img"]}
                            src={thumbnailImageURL}
                            alt="Uploaded Thumbnail"
                        />
                    </div>
                    <Button type="remove" onClick={handleRemoveImage}>
                        {t("buttons:remove")}
                    </Button>
                </div>
            )}
            <div className={styles["thumbnail-button-container"]}>
                <label
                    className={`btn btn-primary ${styles["thumbnail-upload-button"]}`}
                    htmlFor="fileInput"
                    tabIndex={0}
                    role="button"
                >
                    {thumbnailImageURL
                        ? t("buttons:change")
                        : t("buttons:upload")}
                    <input
                        id="fileInput"
                        alt="input for video thumbnail upload"
                        ref={inputElement}
                        type="file"
                        name="thumbnail-upload"
                        accept="image/jpeg, image/png"
                        onChange={handleAddImage}
                        style={{ display: "none" }}
                    />
                </label>
            </div>
            <small>
                {helperText}
                {additionalMessage && (
                    <>
                        <br />
                        {additionalMessage}
                    </>
                )}
            </small>
        </div>
    );
};
