import { StripeAccount } from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useSwitcherClient } from "./useSwitcherClient";

export function useGetStripeAccounts(): {
    accounts: StripeAccount[];
    loading: boolean;
} {
    const userInfo = useSelector((state: RootState) => state.user.userInfo);

    const { data, loading } = useSwitcherClient(
        (client) => client.stripeConnect_GetStripeAccount,
        {
            requestImmediately: true,
            args: [userInfo?.UserId],
            onError: (e) => {
                rollbar.warning("Error getting stripe account", e);
            }
        }
    );

    return { accounts: data?.accounts ?? [], loading };
}
