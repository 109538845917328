import {
    FileItem,
    FileWithMetadata,
    AssetType,
    AspectRatio,
    AspectRatioObject,
    AspectRatioMap
} from "../types";
import { AssetPutOptions, Asset } from "@switcherstudio/switcher-api-client";
import { getFilesWithMetadata } from "./metadata-helpers";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import rollbar from "helpers/rollbar";

export const ASSET_TYPE_MAP = {
    [AssetType.image]: ["image/jpeg", "image/png"],
    [AssetType.video]: ["video/mp4", "video/quicktime"],
    [AssetType.audio]: [
        "audio/caf",
        "audio/m4a",
        "audio/mpeg",
        "audio/wav",
        "audio/x-waf",
        "audio/x-m4a",
        "audio/x-wav"
    ]
};

export const ASPECT_RATIO_MAP: AspectRatioMap = {
    [AspectRatio.horizontal]: {
        Width: 16,
        Height: 9
    },
    [AspectRatio.vertical]: {
        Width: 9,
        Height: 16
    }
};

export function getAspectRatioString(ratios: Array<AspectRatioObject>) {
    if (ratios === null) return [];

    const keys = Object.keys(ASPECT_RATIO_MAP);
    const ratioStrings: string[] = [];

    for (let i = 0; i < ratios.length; i++) {
        const ratio = ratios[i];

        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const ratioObject = ASPECT_RATIO_MAP[key];

            if (
                ratioObject.Height === ratio.Height &&
                ratioObject.Width === ratio.Width
            ) {
                ratioStrings.push(key);
            }
        }
    }

    return ratioStrings;
}

export function getAssetType(type: string) {
    const normalized = type.toLowerCase();

    if (ASSET_TYPE_MAP[AssetType.image].indexOf(normalized) !== -1)
        return AssetType.image;
    if (ASSET_TYPE_MAP[AssetType.video].indexOf(normalized) !== -1)
        return AssetType.video;
    if (ASSET_TYPE_MAP[AssetType.audio].indexOf(normalized) !== -1)
        return AssetType.audio;

    return null;
}

export function fileReaderAsync(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(file);
    });
}

export async function assemblePutOptions(
    files: FileItem[],
    bypassMMArt: boolean = false
): Promise<{
    assetPutOptions: AssetPutOptions[];
    filesWithMetadata: FileWithMetadata[];
}> {
    const assetPutOptions = [];

    const filesWithMetadata = await getFilesWithMetadata(files);

    for (let f of filesWithMetadata) {
        const file = f.file;
        const { mimeType, type } = determineFileType(file);
        const metadataExtension =
            type === "Video" || type === "Audio" ? ".mmsrc" : ".mmart";
        const isAudioOnlyFile =
            type === "Audio" ||
            (type === "Video" && f.fileProps.audioOnly === true);
        const Tags = !f.fileProps.tag ? [] : [f.fileProps.tag];
        assetPutOptions.push({
            Id: f.mid,
            Name: file.name,
            Type: type,
            MimeType: mimeType,
            Meta: {},
            Tags,
            AspectRatios: null
        });

        if (!bypassMMArt) {
            assetPutOptions.push({
                Id: f.imageMid,
                Name:
                    file.name.substring(0, file.name.lastIndexOf(".")) +
                    metadataExtension,
                Type: "Art",
                MimeType: f.metadata.type,
                Meta: {
                    ...(isAudioOnlyFile && { PlayedSourceHasVideo: false })
                },
                Tags,
                AspectRatios: !isAudioOnlyFile ? f.aspectRatios : null,
                RequiresAssetIds: [f.mid]
            });
        }
    }

    return { filesWithMetadata, assetPutOptions };
}

export async function uploadAssetToStorage(
    candidate: FileWithMetadata,
    asset: Asset
) {
    try {
        const _candidate: FileWithMetadata = {
            ...candidate,
            aspectRatios: null
        };
        const azureUrlWithSasToken = asset.Url || "";
        const blobServiceClient = new BlockBlobClient(
            azureUrlWithSasToken,
            new AnonymousCredential()
        );
        const target =
            _candidate.mid === asset.Id ? _candidate.file : _candidate.metadata;
        const uploadBlockBlobOptions = {
            blobHTTPHeaders: { blobContentType: asset.MimeType }
        };

        const uploadResponse = await blobServiceClient.uploadData(
            target,
            uploadBlockBlobOptions
        );
        if (uploadResponse.errorCode) throw new Error(uploadResponse.errorCode);
    } catch (err) {
        rollbar.error("Error uploading asset to storage", err);
        console.error(err);

        if (err.code === "REQUEST_SEND_ERROR") {
            throw new Error("Error uploading asset to storage");
        }
    }
}

function determineFileType(file: any): {
    mimeType: string;
    type: string;
} {
    if (!file) {
        return { mimeType: "", type: "" };
    }

    if (!file.type) {
        const index = file.name.lastIndexOf(".");
        const fileExt = file.name.slice(index, file.name.length);

        if (fileExt === ".mmart") {
            return {
                mimeType: "application/vnd.switcher-mmart",
                type: "Art"
            };
        }
    }

    const assetType = getAssetType(file.type);
    let fileType = { mimeType: "", type: "" };

    switch (assetType) {
        case AssetType.image:
            fileType = {
                mimeType: file.type,
                type: "Image"
            };
            break;
        case AssetType.video:
            fileType = {
                mimeType: file.type,
                type: "Video"
            };
            break;
        case AssetType.audio:
            fileType = {
                mimeType: file.type,
                type: "Audio"
            };
            break;
    }

    return fileType;
}

export const isAudioOrVideo = (assetType: AssetType) =>
    assetType === AssetType.audio || assetType === AssetType.video;
