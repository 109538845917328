import React, { useState, useCallback, useMemo } from "react";
import InfoIcon from "assets/icons/info.svg?react";
import { useUserStatus } from "hooks/useUserStatus";
import { useMatchPathCallback } from "hooks/useMatchPathCallback";
import { useSubscriptionStatusBannerMessageProps } from "./hooks/useSubscriptionStatusBannerMessageProps";
import { Banner } from "../../Banner";
import { useHasPlanRole } from "hooks/useHasPlanRole";

export const SubscriptionStatusBanner: React.FC = () => {
    const [hide, setHide] = useState(false);
    const isGrowUser = useHasPlanRole("Grow");

    const close = useCallback(() => {
        setHide(true);
    }, []);

    const {
        userStatusIsActive,
        userStatusIsTrialing,
        userPlanIsTrial,
        userIsPaused,
        userHasNoSubscription
    } = useUserStatus();

    useMatchPathCallback(/subscription\/subscribe\/[a-zA-Z0-9]+/g, close);

    const messageContentState = useSubscriptionStatusBannerMessageProps(close);

    const showSubBanner = useMemo<boolean>(
        () =>
            (!userStatusIsActive && !userStatusIsTrialing) ||
            (userStatusIsTrialing && !isGrowUser) ||
            userPlanIsTrial ||
            userHasNoSubscription ||
            userIsPaused,
        [
            userHasNoSubscription,
            userIsPaused,
            userPlanIsTrial,
            userStatusIsActive,
            userStatusIsTrialing,
            isGrowUser
        ]
    );

    return (
        showSubBanner && (
            <Banner
                bannerContentMessage={messageContentState}
                icon={<InfoIcon />}
                cssClass="subscription-status"
                hide={hide}
                setHide={close}
                canClose={!userHasNoSubscription}
            />
        )
    );
};
