import { createAsyncThunk } from "@reduxjs/toolkit";
import { createUrl } from "helpers/url";
import { RootState } from "store/reducers";
const baseUrl = import.meta.env.VITE_USER_EVENTS_URL;

export const getEvents = createAsyncThunk(
    "events/getEvents",
    async (args, { getState }) => {
        const {
            user: {
                userInfo,
                ticket: { access_token }
            }
        } = getState() as RootState;

        const authHeader = new Headers({
            Authorization: `bearer ${access_token}`
        });

        const response = await fetch(
            createUrl(baseUrl, {
                pathname: userInfo?.UserId
            }),
            { headers: authHeader }
        );

        return await response.json();
    }
);

export const postEvents = createAsyncThunk(
    "events/postEvent",
    async (events: { [key: string]: any }, { getState }) => {
        const {
            user: {
                userInfo,
                ticket: { access_token }
            }
        } = getState() as RootState;

        const authHeader = new Headers({
            Authorization: `bearer ${access_token}`
        });

        const response = await fetch(
            createUrl(baseUrl, {
                pathname: userInfo?.UserId
            }),
            {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(events)
            }
        );

        return await response.json();
    }
);
