import { createAsyncThunk } from "@reduxjs/toolkit";
import { addToProcessingOrQueued, moveToReady } from "./slice";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { VideoUploadStatus } from "./types";
import { UploadProps } from "hooks/useVideoUpload";
import { VideoPlayerCloudflareResponse } from "@switcherstudio/switcher-api-client";
import { Client } from "api/client";

const NO_LOAD_client = new Client({ showLoading: false });

const UPLOAD_POLLING_INTERVAL = 10000;

export const startPollingUpload = createAsyncThunk<
    void,
    UploadProps,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("uploads/startPollingUpload", async (uploadProps, { dispatch }) => {
    dispatch(addToProcessingOrQueued(uploadProps));
    dispatch(pollPendingUploads());
});

export const pollPendingUploads = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("uploads/pollPendingUploads", async (_, { getState, dispatch }) => {
    let { processingOrQueued } = getState().uploads;

    if (processingOrQueued.length > 0) {
        /** pass in only the processing videos for continued polling*/
        const responses =
            await NO_LOAD_client.cloudRecordings_GetVideosForUserV2(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                processingOrQueued.map((item) => item.broadcastId)
            );

        responses.forEach((response: VideoPlayerCloudflareResponse) => {
            const video = response?.videos[0];
            const broadcastId = response?.broadcast?.Id;
            try {
                if (video.status.state === VideoUploadStatus.Success) {
                    dispatch(moveToReady({ broadcastId }));
                }
            } catch (error) {}
        });

        setTimeout(
            () => dispatch(pollPendingUploads()),
            UPLOAD_POLLING_INTERVAL
        );
    }
});
