import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/inputs/text-input/TextInput";
import {
    BroadcastWebLink,
    VideoPlayerWebLink
} from "@switcherstudio/switcher-api-client";
import { Toggle } from "components/inputs/toggle/Toggle";
import DragIndicatorIcon from "assets/icons/drag_indicator.svg?react";
import { Errors } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";
import { Button } from "components/buttons/Button";

interface WebLinkCardProps {
    link: VideoPlayerWebLink | BroadcastWebLink;
    onLinkChange?: (values: VideoPlayerWebLink | BroadcastWebLink) => void;
    deleteLink?: () => void;
    errors: Errors;
    index: number;
}

export const WebLinkCard = ({
    link,
    onLinkChange,
    deleteLink,
    errors,
    index
}: WebLinkCardProps) => {
    const { t } = useTranslation("video-player-page");
    const [editedLink, setEditedLink] = useState<
        VideoPlayerWebLink | BroadcastWebLink
    >(link);

    const [title, setTitle] = useState<string>(link?.Title);
    const [url, setUrl] = useState<string>(link?.Link);

    const debouncedTitle = useDebounce(title, 500);
    const debouncedUrl = useDebounce(url, 500);

    useEffect(() => {
        setEditedLink((prevLink) => ({ ...prevLink, Title: debouncedTitle }));
    }, [debouncedTitle]);

    useEffect(() => {
        setEditedLink((prevLink) => ({ ...prevLink, Link: debouncedUrl }));
    }, [debouncedUrl]);

    const handleToggle = () => {
        setEditedLink((prevLink) => ({
            ...prevLink,
            PurchaseRequired: !editedLink?.PurchaseRequired
        }));
    };

    useEffect(() => {
        onLinkChange(editedLink);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedLink]);

    /** checks whether the link is a video link or a player/collection link before setting copy */
    const toggleLabel =
        link?.BroadcastId !== null
            ? t("gated-video-links-toggle")
            : t("gated-collection-player-links-toggle");

    return (
        <div className={styles["link-card-container"]}>
            <div className={styles["link-card-inputs"]}>
                <DragIndicatorIcon className={styles["drag-icon"]} />
                <TextInput
                    value={title}
                    id={"link-title"}
                    type="text"
                    label={t("link-name")}
                    className={"link-card-title-input"}
                    onChange={(e) => setTitle(e.target.value)}
                    error={errors[`webLinksTitle_${index}`]}
                />
                <TextInput
                    value={url}
                    id={"link-url"}
                    type="text"
                    label={t("url")}
                    className={"link-card-url-input"}
                    onChange={(e) => setUrl(e.target.value)}
                    error={errors[`webLinks_${index}`]}
                />
                <Button onClick={deleteLink} type="icon">
                    <TrashIcon className={styles["link-card-delete-icon"]} />
                </Button>
            </div>
            <div className={styles["link-card-toggle"]}>
                <Toggle
                    on={editedLink?.PurchaseRequired}
                    onToggle={handleToggle}
                    label={toggleLabel}
                    className={styles["link-card-toggle-label"]}
                    reverseLayout
                />
            </div>
            <hr />
        </div>
    );
};
