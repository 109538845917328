import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useMemo } from "react";

/**
 * A hook to check if the current user has a specific claim
 * @param claim The claim to check
 * @param override Override check if true. Use in development only
 * @returns True is use has claim, false if not
 */
export const useClaimCheck = (claim: string, override: boolean = false) => {
    const { userInfo } = useSelector((s: RootState) => s.user);
    const hasClaim = useMemo<boolean>(() => {
        const claims = userInfo?.FeatureClaims;
        return !!claims && claims.includes(claim);
    }, [claim, userInfo?.FeatureClaims]);

    const shouldOverride = useMemo(
        () => override && import.meta.env.DEV,
        [override]
    );

    return shouldOverride ? true : hasClaim;
};
