import {
    AssetType,
    Thumbnail,
    FileWithMetadata,
    FileItem,
    XMPMeta,
    AspectRatioObject,
    FileProps,
    AssetLayering
} from "../types";
import { v4 as uuidv4 } from "uuid";
import { getAssetType } from "./helpers";
import get from "lodash/get";
import { trackEvent } from "helpers/analyticsHelpers";

export async function getFilesWithMetadata(
    files: FileItem[]
): Promise<FileWithMetadata[]> {
    return Promise.all(files.map(assignFileMetadata));
}

export function generateMetadata(
    assetLayering: AssetLayering,
    assetType: AssetType,
    fileMid: string,
    metadataMid: string,
    thumbnail: Thumbnail,
    props: FileProps,
    targetAspectRatio: AspectRatioObject
) {
    if (assetType === AssetType.video && !props.audioOnly) {
        return generateVideoOrAudioMetadata(
            fileMid,
            metadataMid,
            thumbnail,
            true,
            props,
            targetAspectRatio
        );
    }

    if (
        assetType === AssetType.audio ||
        (assetType === AssetType.video && props.audioOnly)
    ) {
        return generateVideoOrAudioMetadata(
            fileMid,
            metadataMid,
            thumbnail,
            false,
            props,
            targetAspectRatio
        );
    }

    if (assetLayering === AssetLayering.Overlay) {
        return generateOverlayMetadata(
            fileMid,
            metadataMid,
            thumbnail,
            targetAspectRatio
        );
    }

    return generateImageMetadata(
        fileMid,
        metadataMid,
        thumbnail,
        targetAspectRatio
    );
}

async function assignFileMetadata(f: FileItem): Promise<FileWithMetadata> {
    const fileMid = await getOrCreateMid(f.file);
    const metadataMid = uuidv4();
    const assetType = f.assetType ?? getAssetType(f.file.type);
    const targetAspectRatio =
        assetType !== AssetType.audio
            ? get<AspectRatioObject>(f.aspectRatios as any, "0", {
                  Width: 16,
                  Height: 9
              })
            : null;

    return {
        file: f.file,
        metadata: generateMetadata(
            f.assetLayering ?? AssetLayering.FullScreen,
            assetType,
            fileMid,
            metadataMid,
            f.thumbnail,
            f.fileProps,
            targetAspectRatio
        ),
        mid: fileMid,
        imageMid: metadataMid,
        fileProps: f.fileProps,
        aspectRatios: f.aspectRatios
    };
}

function generateImageMetadata(
    fileMid: string,
    metadataMid: string,
    thumbnail: Thumbnail,
    targetAspectRatio: AspectRatioObject
) {
    const template = `<?xml version="1.0" encoding="UTF-8" ?>
<mmart>
  <mid value="${metadataMid}" />
  <dep url="mid:${fileMid}" />
  <target-aspect-ratio value="${targetAspectRatio.Width}:${
        targetAspectRatio.Height
    }" />
  <rank value="1n"/>
  <artwork name="image">
      <props>
          <key>image</key>
          <image src="mid:${fileMid}" />
      </props>
  </artwork>
  <thumbnail format="png" width="${thumbnail.width}" height="${
        thumbnail.height
    }" rendering="composed">${thumbnail.dataURL.split(",", 2)[1]}</thumbnail>
</mmart>`;
    return new Blob([template], { type: "application/vnd.switcher-mmart" });
}

function generateOverlayMetadata(
    fileMid: string,
    metadataMid: string,
    thumbnail: Thumbnail,
    targetAspectRatio: AspectRatioObject
) {
    const y = thumbnail.art.y;
    const height = thumbnail.art.height;
    const template = `<?xml version="1.0" encoding="UTF-8" ?>
<mmart>
  <mid value="${metadataMid}" />
  <dep url="mid:${fileMid}" />
  <target-aspect-ratio value="${targetAspectRatio.Width}:${
        targetAspectRatio.Height
    }" />
  <rank value="1n"/>
  <artwork name="logo">
      <props>
          <key>y</key>
          <real>${y}</real>
          <key>height</key>
          <real>${height}</real>
          <key>image</key>
          <image src="mid:${fileMid}" />
      </props>
  </artwork>
  <thumbnail format="png" width="${thumbnail.width}" height="${
        thumbnail.height
    }" rendering="composed">${thumbnail.dataURL.split(",", 2)[1]}</thumbnail>
</mmart>`;
    return new Blob([template], { type: "application/vnd.switcher-mmart" });
}

function generateVideoOrAudioMetadata(
    fileMid: string,
    metadataMid: string,
    thumbnail: Thumbnail,
    configureVideo: boolean,
    props: FileProps,
    targetAspectRatio: AspectRatioObject
) {
    const assetTypeSpecificTags = configureVideo
        ? `<target-aspect-ratio value="${targetAspectRatio.Width}:${
              targetAspectRatio.Height
          }" />
       <mconf audio="yes" video="yes"${
           props.enableAudio ? " " : ' pref-mute="true" '
       }${props.endOnBlack ? "" : 'end-frame="last"'}/>`
        : `<tag value="${props.thumbnailTag}"/>
     <mconf audio="yes" video="no"/><mconf audio="yes" video="no"/>`;

    const template = `<?xml version="1.0" encoding="UTF-8" ?>
<mmsrc type="played-asset">
<mid value="${metadataMid}" />
<dep url="mid:${fileMid}"/>
${assetTypeSpecificTags}
<thumbnail format="jpeg" width="${thumbnail.width}" height="${
        thumbnail.height
    }" rendering="uncomposed">${thumbnail.dataURL.split(",", 2)[1]}</thumbnail>
<asset src="mid:${fileMid}"/>
<rank value="1025n"/>
<artwork name="video"></artwork>
</mmsrc>
`;
    return new Blob([template], { type: "application/vnd.switcher-mmsrc" });
}

async function getOrCreateMid(file: File): Promise<string> {
    const { extractMetadata } = await import("@switcherstudio/swimeta-ts");

    const existingMetadata = await extractMetadata(file, file.type);
    if (existingMetadata?.type === "qt") {
        const mid = get(
            existingMetadata,
            ["data", "com.recolive.media-id"],
            null
        );
        return mid !== null ? mid : uuidv4();
    } else if (existingMetadata?.type === "caf") {
        const mid = get(existingMetadata, ["data", "RecoLive Media ID"], null);
        return mid !== null ? mid : uuidv4();
    } else if (existingMetadata?.type === "xmp") {
        const { default: X2JS } = await import("x2js");
        const x2js = new X2JS();
        const data = (existingMetadata?.data || "") as string;
        const json = x2js.xml2js<XMPMeta>(data);

        if (json) {
            const mid =
                json?.xmpmeta?.RDF?.Description?.["_swi:MediaID"] ||
                json?.xmpmeta?.RDF?.Description?.MediaID?.__text;
            if (mid) {
                trackEvent("_swi:MediaID was found on existing xmp metadata", {
                    xmpMetadata: existingMetadata
                });
            }

            return mid || uuidv4();
        }
    }

    return uuidv4();
}
