import React from "react";
import { PlatformId } from "views/page-content/platforms/types";

interface PlatformImageProps {
    platformId: string;
    logoUrl?: string;
}

const storageUrl =
    "https://switcherstudio.blob.core.windows.net/staticcontent/images/streamingservices/";
export const PlatformImage: React.FC<PlatformImageProps> = ({
    platformId,
    logoUrl
}: PlatformImageProps) => {
    return (
        <>
            {(function () {
                if (logoUrl) {
                    return <img src={logoUrl} alt={platformId + "-image"} />;
                }

                switch (platformId) {
                    case PlatformId.Facebook:
                        return (
                            <img
                                src={storageUrl + "facebooklive.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.Youtube:
                        return (
                            <img
                                src={storageUrl + "youtube.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.Twitch:
                        return (
                            <img
                                src={storageUrl + "twitch.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.MsStream:
                        return (
                            <img
                                src={storageUrl + "microsoftstream.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.BoxCast:
                        return (
                            <img
                                src={storageUrl + "boxcast.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.ChurchStreamingTv:
                        return (
                            <img
                                src={storageUrl + "churchstreaming.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.Caast:
                        return (
                            <img
                                src={storageUrl + "caast_logo_resized.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    case PlatformId.Instafeed:
                        return (
                            <img
                                src={storageUrl + "instafeed.png"}
                                alt={platformId + "-image"}
                            />
                        );
                    default:
                        return null;
                }
            })()}
        </>
    );
};
