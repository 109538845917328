import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
import { ModalPayload, Modals } from "store/modal/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { destroyModal, expandModal } from "store/modal/slice";
import CloseIcon from "assets/icons/close.svg?react";
import { exists } from "helpers/booleans";
import { useResetUploadSession } from "components/video-upload/hooks/useResetUploadSession";
const cx = classnames.bind(styles);

interface MinimizedModalTabProps {
    statusText: string;
    statusPercent: number;
    successText: string;
    currentModal: ModalPayload;
}

export const MinimizedModalTab = ({
    currentModal,
    statusText,
    statusPercent,
    successText
}: MinimizedModalTabProps) => {
    const { resetUploadSession } = useResetUploadSession();
    const { minimizedModals } = useSelector((s: RootState) => s.modal);
    const dispatch = useDispatch<AppDispatch>();
    const isMinimized = useMemo(
        () => exists(minimizedModals.find((mm) => mm.id === currentModal.id)),
        [currentModal, minimizedModals]
    );
    const handleExpand = useCallback(() => {
        dispatch(expandModal({ id: currentModal.id }));
    }, [currentModal, dispatch]);

    const handleDestroy = useCallback(() => {
        dispatch(destroyModal({ id: currentModal.id }));
        if (currentModal.type === Modals.TriggeredVideoUploadModal) {
            resetUploadSession({ sessionId: currentModal.id });
        }
    }, [currentModal.id, currentModal.type, dispatch, resetUploadSession]);

    const isFinished = useMemo(() => statusPercent === 100, [statusPercent]);

    return (
        isMinimized && (
            <div className={cx("minimized-modal-tab")}>
                {!isFinished ? (
                    <button
                        className={cx("btn", "expand-button")}
                        onClick={handleExpand}
                    >
                        <small className={cx("status-text")}>
                            {statusText}
                        </small>
                        <small className={cx("status-percent")}>
                            {`${statusPercent}%`}
                        </small>
                    </button>
                ) : (
                    <>
                        <button
                            className={cx("btn", "expand-button")}
                            onClick={handleExpand}
                        >
                            <small
                                className={cx("status-text", "success-text")}
                            >
                                {successText}
                            </small>
                        </button>

                        <button
                            className={cx("btn", "close-button")}
                            onClick={handleDestroy}
                        >
                            <CloseIcon className={cx("close-icon")} />
                        </button>
                    </>
                )}
            </div>
        )
    );
};
