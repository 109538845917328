import React from "react";
import { Toggle } from "components/inputs/toggle/Toggle";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface PricingToggleProps {
    toggle: boolean;
    onToggle: () => void;
}

export const PricingToggle: React.FC<PricingToggleProps> = ({
    toggle,
    onToggle
}: PricingToggleProps) => {
    const { t } = useTranslation();
    return (
        <>
            <span className={styles["pricing-toggler"]}>
                <span
                    className={!toggle ? styles["selected-pricing"] : ""}
                    onClick={onToggle}
                >
                    {t("subscription:monthly")}
                </span>
                <Toggle on={toggle} onToggle={onToggle} isOnOn={true} />
                <span
                    className={toggle ? styles["selected-pricing"] : ""}
                    onClick={onToggle}
                >
                    {t("subscription:annual")}
                    <span className={styles["save-label"]}>
                        {t("subscription:save-up-to")} <strong>25%</strong>
                    </span>
                </span>
            </span>
        </>
    );
};
