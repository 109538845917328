import React, { PropsWithChildren } from "react";
import styles from "./index.module.scss";

interface DetailRowProps extends PropsWithChildren {
    variant: "info-row" | "badge-row" | "toggle-row";
}

export const DetailRow = ({
    variant = "info-row",
    children
}: DetailRowProps) => {
    return <div className={`${variant} ${styles[variant]}`}>{children}</div>;
};
