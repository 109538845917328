import { useEffect, useState } from "react";
import getInstance, { HelpScout, HelpScoutCommand } from "helpers/helpscout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { mustBeLoggedIn } from "./useRedirectIfDisallowed";
import { setIsBooted } from "store/supportSystem/slice";

export const useHelpScout = (
    boot: boolean = false
): ((
    command: HelpScoutCommand,
    options?: any,
    additionalOptions?: any
) => void) => {
    const dispatch = useDispatch();
    const enabled = import.meta.env.VITE_SUPPORT_SYSTEM === "helpscout";

    const [helpScout] = useState<HelpScout>(getInstance());
    const user = useSelector((s: RootState) => s.user);
    const isAuthenticated = mustBeLoggedIn(user);

    useEffect(() => {
        if (!enabled) return;

        if (isAuthenticated) {
            if (!boot) {
                return;
            }
            // user changed, restart session
            helpScout.boot();

            dispatch(setIsBooted(true));

            helpScout.command("on", "ready", () => {
                helpScout.command("identify", {
                    name: `${user.userInfo?.FirstName} ${user.userInfo?.LastName}`,
                    email: user.userInfo?.Email,
                    signature: user.userInfo?.HelpScoutSignature
                });
            });
        }
    }, [enabled, isAuthenticated, helpScout, boot, user.userInfo, dispatch]);

    if (!enabled) return null;
    return helpScout.command;
};

export default useHelpScout;
