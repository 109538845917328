import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { ProgressBarProps } from "../ProgressBar";

export interface OnboardingProgressBarProps extends ProgressBarProps {
    minValue: number;
}

export const OnboardingProgressBar: React.FC<OnboardingProgressBarProps> = ({
    minValue,
    maxValue,
    currentValue
}: OnboardingProgressBarProps) => {
    const percentage = useMemo(
        () => ((currentValue - minValue) / (maxValue - minValue)) * 100,
        [currentValue, maxValue, minValue]
    );

    return (
        <div className={styles["progress-container"]}>
            <div className={styles["progress-bar"]}>
                <div
                    className={`${styles["progress-background"]} ${styles["steps"]}`}
                >
                    {" "}
                </div>
                <div
                    className={styles["progress-foreground"]}
                    style={{ width: percentage + "%" }}
                />
            </div>
            <div className={styles["progress-text"]}>
                {currentValue - minValue || 0} of {maxValue - minValue || 0}
            </div>
        </div>
    );
};
