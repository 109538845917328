import React, { useEffect, useMemo, useState } from "react";
import { TutorialList } from "components/tutorial-videos/TutorialList";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { SubscriptionWidget } from "components/widgets/SubscriptionWidget";
import { CloudAssetsWidget } from "components/widgets/CloudAssetsWidget";
import { VideoUsageWidget } from "components/widgets/VideoUsageWidget";
import { VideoLibraryWidget } from "components/widgets/VideoLibraryWidget";
import { CatalogWidget } from "components/widgets/CatalogWidget";
import { isMobile } from "utils/js-utils";
import { useTranslation } from "react-i18next";
import { EntitlementWidget } from "components/widgets/EntitlementWidget";
import { StripeStatus } from "../platforms/types";
import { useClaimCheck } from "hooks/useClaimCheck";
import { VideoPlayerWidget } from "components/widgets/VideoPlayerWidget";
import { ReferralParticipantWidget } from "components/widgets/ReferralParticipantWidget";
import { ShopifyLinkedWidget } from "components/widgets/ShopifyLinkedWidget";
import { AnalyticsWidget } from "components/widgets/AnalyticsWidget";
import { usePageHeader } from "hooks/usePageHeader";
import { Row } from "../../../components/layout/Row";
import { PlayerActivationTrackerBanner } from "components/banners/progress-tracker-banners/PlayerActivationTrackerBanner";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useHasAccess } from "hooks/useHasAccess";
import { useCatalogData } from "hooks/useCatalogData";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { postEvents } from "store/events/thunks";

// will poll constantly if inside component but this works for single load
const _isMobile = isMobile();

export const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const { isInBrowser } = useSwitcherSdk();
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const { events } = useSelector((state: RootState) => state.events);

    const [hasActiveSub, setHasActiveSub] = useState<boolean>(false);
    const [hasCloudAssets, setHasCloudAssets] = useState(false);
    const [hasVideoAccess, setHasVideoAccess] = useState(false);
    const hasVideoPlayerAccess = useClaimCheck("videoplayer");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");
    const hasShopifyAccess = useClaimCheck("shopify");
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    const hasAppClaim = useClaimCheck("app");
    const hasAccess = useHasAccess();
    const userHasFirstName: boolean = user?.userInfo?.FirstName !== "";

    const {
        hasCatalogClaim,
        loading,
        catalogData,
        embedCode: catalogEmbedCode,
        shareLink
    } = useCatalogData({ projectId: user?.userInfo?.ProjectId });

    // Get User Entitlements
    const { data: entitlements } = useSwitcherClient(
        (client) => client.userEntitlements_getActiveUserEntitlements,
        {
            requestImmediately: true,
            hideLoading: true,
            args: [user.userInfo.UserId]
        }
    );

    // Fetch usage data
    const { data: usage, loading: videoLibraryDataLoading } = useSwitcherClient(
        (client) => client.cloudRecordings_GetUsage,
        {
            requestImmediately: true,
            hideLoading: true,
            onSuccess: (data) => {
                dispatch(
                    postEvents({
                        "added-video-to-library": data?.TotalRecordings > 0
                    })
                );
            }
        }
    );

    // Stripe Customer
    const { data: stripeCustomer } = useSwitcherClient(
        (client) => client.stripe_GetCustomer,
        {
            requestImmediately: true,
            hideLoading: true,
            onSuccess: (data) => {
                const hasActiveSub = data?.StripeSubscriptions.some(
                    (s) =>
                        s.Status === StripeStatus.Active &&
                        s.PauseCollection !== null
                );
                setHasActiveSub(hasActiveSub);
            }
        }
    );

    // Assets usage
    const { data: assetsUsage, loading: assetsUsageLoading } =
        useSwitcherClient(
            (client) => client.userAssetsUsage_GetUserAssetsUsage,
            {
                requestImmediately: true,
                hideLoading: true,
                args: [user.userInfo.UserId]
            }
        );

    // Twilio usage
    const { data: twilioUsage, loading: twilioUsageLoading } =
        useSwitcherClient((client) => client.twilio_GetRoomUsage, {
            requestImmediately: hasAccess,
            hideLoading: true,
            args: [user.userInfo.UserId]
        });

    // Shopify store credentials
    const { data: shopifyStoreCredentials, loading: storeCredentialsLoading } =
        useSwitcherClient((client) => client.shopify_GetCredentials, {
            requestImmediately: true,
            hideLoading: true,
            args: []
        });

    usePageHeader({
        title: userHasFirstName
            ? t("home:welcome", { name: user?.userInfo?.FirstName })
            : t("home:nameless-welcome")
    });

    useEffect(() => {
        if (user.userInfo !== undefined && user.userInfo.UserId !== undefined) {
            setHasCloudAssets(
                user.userInfo.FeatureClaims?.indexOf("cloudassets") !== -1
            );
            setHasVideoAccess(
                user.userInfo.FeatureClaims?.indexOf("videochat") !== -1
            );
        }
    }, [user.userInfo]);

    const shouldShowActivationTracker = useMemo(() => {
        return isInBrowser && !videoPlayerDisabled && hasGatedContentAccess;
    }, [isInBrowser, videoPlayerDisabled, hasGatedContentAccess]);

    return (
        <>
            {shouldShowActivationTracker && (
                <PlayerActivationTrackerBanner
                    hasCatalogClaim={hasCatalogClaim}
                    catalogEmbedCode={catalogEmbedCode}
                    videoLibraryDataLoading={videoLibraryDataLoading}
                />
            )}

            {!videoPlayerDisabled && hasVideoPlayerAccess && (
                <Row header={t("home:manage-content")}>
                    <VideoLibraryWidget
                        loading={videoLibraryDataLoading}
                        cloudUsage={usage}
                    />
                    {hasCatalogClaim ? (
                        <CatalogWidget
                            enabled={hasCatalogClaim}
                            loading={loading}
                            catalogId={catalogData?.Details?.Id}
                            shareLink={shareLink}
                        />
                    ) : (
                        <VideoPlayerWidget
                            enabled={hasVideoPlayerAccess}
                            hasActiveSub={hasActiveSub}
                        />
                    )}
                    {events?.["added-video-to-library"] && <AnalyticsWidget />}
                </Row>
            )}
            {hasAppClaim && (
                <Row header={t("home:creation-tools")}>
                    <CloudAssetsWidget
                        enabled={hasCloudAssets}
                        assets={assetsUsage ?? {}}
                        loading={assetsUsageLoading}
                    />
                    <VideoUsageWidget
                        userId={user.userInfo?.UserId}
                        usage={twilioUsage ?? {}}
                        enabled={hasVideoAccess}
                        loading={twilioUsageLoading}
                    />
                </Row>
            )}
            <Row header={t("home:account-overview")}>
                {!hasActiveSub && entitlements?.length ? (
                    <EntitlementWidget entitlements={entitlements} />
                ) : (
                    <SubscriptionWidget customer={stripeCustomer ?? {}} />
                )}
                {import.meta.env.VITE_REFERRAL_ENABLE_REFERRALS === "true" &&
                    !user.userInfo?.Roles?.includes("Affiliate") && (
                        <ReferralParticipantWidget />
                    )}
                {hasShopifyAccess && (
                    <ShopifyLinkedWidget
                        loading={storeCredentialsLoading}
                        enabled={shopifyStoreCredentials?.length > 0}
                        storeCredentials={shopifyStoreCredentials ?? []}
                    />
                )}
            </Row>

            {hasAppClaim && (
                <TutorialList isMobile={_isMobile} limit={5} offset={3} />
            )}
        </>
    );
};
