import { Spinner } from "components/spinners/Spinner";
import React, { MouseEventHandler, useMemo } from "react";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

export type ButtonVariant =
    | "primary"
    | "secondary"
    | "badge"
    | "danger"
    | "category-badge"
    | "primary-outline"
    | "outline"
    | "action"
    | "text"
    | "icon"
    | "link"
    | "link-primary"
    | "box"
    | "badge-secondary"
    | "remove";

export interface SwitcherButtonProps {
    children: JSX.Element | JSX.Element[] | string | number;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: ButtonVariant;
    disabled?: boolean;
    isSubmit?: boolean;
    isActive?: boolean;
    iconRight?: React.ReactNode;
    iconLeft?: React.ReactNode;
    value?: string;
    title?: string;
    tag?: "a" | "button";
    iconSize?: "small" | "medium";
    href?: string;
    rel?: string;
    target?: string;
    showSpinner?: boolean;
    noHover?: boolean;
}

export const Button = ({
    children,
    onClick,
    type = "primary",
    disabled = false,
    isSubmit = false,
    isActive = false,
    iconRight,
    iconLeft,
    value,
    title,
    tag = "button",
    iconSize = "medium",
    href,
    rel,
    target,
    showSpinner = false,
    noHover = false
}: SwitcherButtonProps) => {
    /** The bootstrap classes here should get taken out over time as we hone in on our own button styles */
    const buttonClass = useMemo(() => {
        switch (type) {
            case "primary":
                return "btn-primary";
            case "secondary":
                return "btn-secondary";
            case "danger":
                return "btn-danger";
            default:
                return styles[type];
        }
    }, [type]);

    const className = useMemo(() => {
        return `btn ${styles["button"]} ${buttonClass} ${styles[iconSize]} ${
            isActive ? styles["active"] : ""
        } ${showSpinner ? styles["spinning"] : ""}`;
    }, [buttonClass, iconSize, isActive, showSpinner]);

    const contents = useMemo(() => {
        return (
            <>
                <Spinner />
                <>
                    {iconLeft && (
                        <div className={styles["icon-left"]}>{iconLeft}</div>
                    )}
                    <span>{children}</span>
                    {iconRight && (
                        <div className={styles["icon-right"]}>{iconRight}</div>
                    )}
                </>
            </>
        );
    }, [iconLeft, iconRight, children]);

    return (
        <>
            {tag === "button" ? (
                <button
                    type={isSubmit ? "submit" : "button"}
                    onClick={onClick}
                    className={cx(className, { noHover })}
                    disabled={disabled}
                    value={value}
                    title={title}
                >
                    {contents}
                </button>
            ) : (
                <a
                    className={className}
                    href={href}
                    rel={rel}
                    title={title}
                    target={target}
                >
                    {contents}
                </a>
            )}
        </>
    );
};
