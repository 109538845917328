import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState
} from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../store/store";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import { WebLinksForm } from "../../forms/WebLinksForm/WebLinksForm";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

import {
    BroadcastWebLink,
    WebLinkRequestType,
    WebLinkRequest
} from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";
import { client } from "api/client";
import { exists } from "helpers/booleans";
import { useClaimCheck } from "hooks/useClaimCheck";

interface WebLinkSelectModalProps {
    broadcastId: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    submitHandlesClose?: boolean;
    links: BroadcastWebLink[];
    onSuccess: (broadcastWeblinks: BroadcastWebLink[]) => void;
}

export const WebLinkSelectModal = ({
    broadcastId,
    isOpen,
    setIsOpen,
    links: initialLinks,
    onSuccess
}: WebLinkSelectModalProps) => {
    const [links, setLinks] = useState<BroadcastWebLink[]>(initialLinks);
    const [isValid, setIsValid] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const hasCatalogClaim = useClaimCheck("catalog");
    useEffect(() => {
        if (initialLinks) {
            const sortedInitialLinks = [...initialLinks].sort(
                (a, b) => a.Sequence - b.Sequence
            );
            setLinks(sortedInitialLinks);
        }
    }, [initialLinks]);

    const submitAction = useCallback(async () => {
        const sequencedLinks = links.map((link, index) => ({
            ...link,
            Sequence: index
        }));
        if (exists(broadcastId)) {
            try {
                const webLinkRequest: WebLinkRequest = {
                    Id: broadcastId,
                    webLinks: sequencedLinks,
                    Type: WebLinkRequestType.Broadcast
                };
                await client.webLink_Post(webLinkRequest);
            } catch (e) {
                rollbar.error(e?.message);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        }

        onSuccess(sequencedLinks);
    }, [broadcastId, dispatch, links, onSuccess]);

    const resetAndClose = () => {
        setLinks(initialLinks);
        setIsOpen(false);
    };

    return (
        <>
            <ModalBase
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header="Links"
                successButton={t("buttons:save")}
                dismissButton={t("buttons:cancel")}
                onSuccess={submitAction}
                successDisabled={!isValid}
                onDismiss={resetAndClose}
                stylesOverride={{
                    padding: "0",
                    maxHeight: "unset",
                    maxWidth: "700px"
                }}
            >
                <div className={styles["modal-container"]}>
                    <div className={styles["modal-description"]}>
                        <h6>
                            {hasCatalogClaim
                                ? t(
                                      "video-player-settings:link-modal-description-catalog"
                                  )
                                : t(
                                      "video-player-settings:link-modal-description"
                                  )}
                        </h6>
                    </div>
                    <div className={styles["links-form"]}>
                        <hr />
                        <WebLinksForm
                            links={links}
                            broadcastId={broadcastId}
                            setLinks={setLinks}
                            setIsValid={setIsValid}
                        />
                    </div>
                </div>
            </ModalBase>
        </>
    );
};
