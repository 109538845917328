import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { VideoUploadSession } from "store/videoUploadSession/types";

export const useGetUploadSession = ({ sessionId }: { sessionId: string }) => {
    const allUploadSessions = useSelector(
        (s: RootState) => s.videoUploadSession.uploadSessions
    );

    const currentUploadSession: VideoUploadSession = useMemo(
        () => allUploadSessions[sessionId] ?? ({} as VideoUploadSession),
        [sessionId, allUploadSessions]
    );

    return currentUploadSession;
};
