import React, { useCallback, useState, useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Select } from "components/inputs/select/Select";
import { DateTimePicker } from "../datepicker/DateTimePicker";
import { BroadcastStatus } from "@switcherstudio/switcher-api-client";
import dayjs from "dayjs";
import { isInFuture } from "helpers/time";

export interface ScheduleVideoVisibilityBarProps {
    broadcastStatus: BroadcastStatus;
    setBroadcastStatus: (status: BroadcastStatus) => void;
    startsAt: string;
    setStartsAt: (date: string) => void;
    liveBroadcast?: boolean;
    isUpload?: boolean;
}

export const ScheduleVideoVisibilityBar = ({
    liveBroadcast = false,
    broadcastStatus,
    setBroadcastStatus,
    startsAt,
    setStartsAt,
    isUpload = true
}: ScheduleVideoVisibilityBarProps) => {
    const { t } = useTranslation("video-upload");

    const [localBroadcastStatus, setLocalBroadcastStatus] =
        useState<string>("published");
    //maps the broadcastStatus Options to the specific broadcast statuses. Status within API commented below:
    const statusMap = {
        [BroadcastStatus._0]: "scheduled", // Created
        [BroadcastStatus._1]: "scheduled", //Ready
        [BroadcastStatus._2]: "scheduled", //Active
        [BroadcastStatus._3]:
            liveBroadcast || isInFuture(startsAt) ? "scheduled" : "published", //Ended
        [BroadcastStatus._5]: "unpublished" //Unpublished
    };

    //the visibility options that are displayed in the drop down
    const localBroadcastStatusOptions = useMemo(() => {
        return [
            ...(!liveBroadcast
                ? [
                      {
                          value: "published",
                          text: isUpload ? t("publish-now") : t("publish")
                      }
                  ]
                : []),
            { value: "scheduled", text: t("schedule") },
            {
                value: "unpublished",
                text: isUpload ? t("save-unpublished") : t("unpublished")
            }
        ];
    }, [liveBroadcast, t, isUpload]);

    useEffect(() => {
        setLocalBroadcastStatus(statusMap[broadcastStatus]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcastStatus, setLocalBroadcastStatus]);

    const handleBroadcastStatus = useCallback(
        (localStatus: string) => {
            if (
                localBroadcastStatusOptions.some(
                    (option) => option.value === localStatus
                )
            ) {
                setLocalBroadcastStatus(localStatus);
                switch (localStatus) {
                    case "published":
                        setStartsAt(null);
                        setBroadcastStatus(BroadcastStatus._3);
                        break;
                    case "scheduled":
                        const date = new Date();
                        date.setMinutes(date.getMinutes() + 5);
                        setStartsAt(date.toUTCString());
                        if (liveBroadcast) {
                            setBroadcastStatus(BroadcastStatus._1);
                        } else {
                            setBroadcastStatus(BroadcastStatus._3);
                        }
                        break;
                    case "unpublished":
                        setStartsAt(null);
                        setBroadcastStatus(BroadcastStatus._5);
                        break;
                    default:
                        break;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setBroadcastStatus, localBroadcastStatusOptions]
    );

    return (
        <div>
            <h6>{t("video-visibility")}</h6>
            <div className={styles["schedule-video-visibility-bar"]}>
                <Select
                    id="video-visibility-status-dropdown"
                    onChange={(event) =>
                        handleBroadcastStatus(event.target.value)
                    }
                    options={localBroadcastStatusOptions}
                    label={t("status")}
                    selected={localBroadcastStatus}
                />
                {localBroadcastStatus === "scheduled" && (
                    <div className={styles["date-time-picker-container"]}>
                        <label className={`${styles["date-time-label"]}`}>
                            {t("date-time")}
                        </label>
                        <DateTimePicker
                            id="video-visibility-date-time"
                            datePickerProps={{
                                selected: startsAt
                                    ? dayjs(startsAt).toDate()
                                    : new Date(),
                                minDate: new Date(),
                                maxDate: null
                            }}
                            error={""}
                            onChange={(date: Date) => {
                                setStartsAt(date.toUTCString());
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
