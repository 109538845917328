import { PropsWithChildren } from "react";
import styles from "./EmptyState.module.scss";

interface EmptyStateProps extends PropsWithChildren {
    title?: string;
    description?: string;
}

export const EmptyState = ({
    title,
    description,
    children
}: EmptyStateProps) => {
    return (
        <div className={styles["empty-state"]}>
            <div>
                <div className={styles["empty-state-content"]}>{title}</div>
                {!!description && (
                    <div className={styles["empty-state-content-descr"]}>
                        {description}
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};
