export const videoFormats = [
    {
        name: "1920 x 1080",
        height: 1080,
        width: 1920
    },
    {
        name: "1280 x 720",
        height: 720,
        width: 1280
    },
    {
        name: "960 x 540",
        height: 540,
        width: 960
    },
    {
        name: "640 x 360",
        height: 360,
        width: 640
    },
    {
        name: "480 x 270",
        height: 270,
        width: 480
    }
];

export const videoBitRates = [
    { value: null, name: "Default" },
    { value: 450000, name: "450 kbps" },
    { value: 500000, name: "500 kbps" },
    { value: 550000, name: "550 kbps" },
    { value: 600000, name: "600 kbps" },
    { value: 650000, name: "650 kbps" },
    { value: 700000, name: "700 kbps" },
    { value: 800000, name: "800 kbps" },
    { value: 900000, name: "900 kbps" },
    { value: 1000000, name: "1000 kbps" },
    { value: 1200000, name: "1200 kbps" },
    { value: 1400000, name: "1400 kbps" },
    { value: 1600000, name: "1600 kbps" },
    { value: 1800000, name: "1800 kbps" },
    { value: 2000000, name: "2000 kbps" },
    { value: 2200000, name: "2200 kbps" },
    { value: 2400000, name: "2400 kbps" },
    { value: 2600000, name: "2600 kbps" },
    { value: 2800000, name: "2800 kbps" },
    { value: 3200000, name: "3200 kbps" },
    { value: 3600000, name: "3600 kbps" },
    { value: 4000000, name: "4000 kbps" },
    { value: 4500000, name: "4500 kbps" },
    { value: 5000000, name: "5000 kbps" },
    { value: 5500000, name: "5500 kbps" },
    { value: 6000000, name: "6000 kbps" }
];

export const audioFormats = [
    { id: "default", channelCount: null, sampleRate: null, name: "Default" },
    {
        id: "mono-44100",
        channelCount: 1,
        sampleRate: 44100,
        name: "Mono @ 44100"
    },
    {
        id: "mono-48000",
        channelCount: 1,
        sampleRate: 48000,
        name: "Mono @ 48000"
    },
    {
        id: "stereo-44100",
        channelCount: 2,
        sampleRate: 44100,
        name: "Stereo @ 44100"
    },
    {
        id: "stereo-48000",
        channelCount: 2,
        sampleRate: 48000,
        name: "Stereo @ 48000"
    }
];

export const audioBitRates = [
    { name: "Default", value: null },
    { name: "64 kbps", value: 64000 },
    { name: "128 kbps", value: 128000 }
];
