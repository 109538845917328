import { DateTimePicker } from "components/inputs/datepicker/DateTimePicker";
import { Select } from "components/inputs/select/Select";
import { ReactDatePickerProps } from "react-datepicker";
import styles from "./index.module.scss";
import { SyntheticEvent, useMemo } from "react";
import { displayDate, setToStartOfDay } from "helpers/time";
import { useTranslation } from "react-i18next";

export interface DateRangeSelectorProps {
    id: string;
    label: string;
    selection: string;
    options: { value: string; text: string }[];
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    customStartDate?: Date | null;
    placeholderStartDate?: Date | string | null;
    onStartDateChange?: (date: Date, event: SyntheticEvent<any, Event>) => void;
    customEndDate?: Date | null;
    placeholderEndDate?: Date | string | null;
    onEndDateChange?: (date: Date, event: SyntheticEvent<any, Event>) => void;
    datePickerProps?: Partial<ReactDatePickerProps<never, undefined>>;
}

export const DateRangeSelector = ({
    id,
    label,
    selection,
    options,
    onChange,
    customStartDate,
    placeholderStartDate,
    onStartDateChange,
    customEndDate,
    placeholderEndDate,
    onEndDateChange,
    datePickerProps = {}
}: DateRangeSelectorProps) => {
    const { t, i18n } = useTranslation();

    const _customStartDate = useMemo<Date>(
        () =>
            !!customStartDate
                ? setToStartOfDay(
                      typeof customStartDate === "string"
                          ? new Date(customStartDate)
                          : customStartDate
                  )
                : null,
        [customStartDate]
    );

    const _customEndDate = useMemo<Date>(
        () =>
            !!customEndDate
                ? setToStartOfDay(
                      typeof customEndDate === "string"
                          ? new Date(customEndDate)
                          : customEndDate
                  )
                : null,
        [customEndDate]
    );

    return (
        <div className={styles["date-range-selector"]}>
            <Select
                id={id}
                label={label}
                options={options}
                selected={selection}
                onChange={onChange}
            ></Select>
            {selection === "custom" && (
                <div className={styles["custom-date-container"]}>
                    <DateTimePicker
                        id={`${id}-start`}
                        value={customStartDate}
                        onChange={onStartDateChange}
                        datePickerProps={{
                            ...datePickerProps,
                            selected: _customStartDate,
                            minDate: null,
                            maxDate: _customEndDate ?? new Date(),
                            placeholderText: displayDate(
                                placeholderStartDate?.toString() ??
                                    new Date().toUTCString(),
                                i18n.language,
                                "MM/DD/YYYY"
                            )
                        }}
                        dateOnly
                    />
                    <p>{t("inputs:to")}</p>
                    <DateTimePicker
                        id={`${id}-end`}
                        value={customEndDate}
                        onChange={onEndDateChange}
                        datePickerProps={{
                            ...datePickerProps,
                            selected: _customEndDate,
                            minDate: _customStartDate,
                            maxDate: new Date(),
                            placeholderText: displayDate(
                                placeholderEndDate?.toString() ??
                                    new Date().toUTCString(),
                                i18n.language,
                                "MM/DD/YYYY"
                            )
                        }}
                        dateOnly
                    />
                </div>
            )}
        </div>
    );
};
