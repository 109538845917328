import React from "react";

export interface CheckboxProps {
    label: string;
    id: string;
    checked: boolean | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    small?: string;
    disabled?: boolean;
    customClass?: string;
    customLabelClass?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
    label,
    id,
    checked,
    onChange,
    small,
    disabled,
    customClass,
    customLabelClass
}: CheckboxProps) => {
    return (
        <>
            <div className={`form-group ${customClass}`}>
                <div className="form-check">
                    <label
                        className={`form-check-label ${customLabelClass}`}
                        htmlFor={id}
                    >
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={id}
                            checked={checked || false}
                            onChange={onChange}
                            disabled={disabled}
                        />
                        {label}
                    </label>
                    <small
                        id={`${id}-help`}
                        aria-describedby={id}
                        className="form-text text-muted"
                    >
                        {small}
                    </small>
                </div>
            </div>
        </>
    );
};
