import React, { useEffect, useState, useCallback } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import {
    VideoPlayerProfile,
    VideoPlayerWebLink,
    WebLinkRequest,
    WebLinkRequestType
} from "@switcherstudio/switcher-api-client";
import { RootState } from "store/reducers";
import { Trans, useTranslation } from "react-i18next";
import { usePageHeader } from "hooks/usePageHeader";
import { Link } from "react-router-dom";
import { WebLinksForm } from "components/forms/WebLinksForm/WebLinksForm";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";
import { useParams } from "hooks/useParams";

export const CollectionLinksContentPage: React.FC = () => {
    const { videoPlayerId } = useParams();
    useCatalogAccessBanner();
    const { t } = useTranslation("video-player-page");
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const projectId = user?.userInfo?.ProjectId;
    const [playerProfile, setPlayerProfile] =
        useState<VideoPlayerProfile>(null);
    const [links, setLinks] = useState<VideoPlayerWebLink[]>(
        playerProfile?.VideoPlayer?.VideoPlayerDonationLinks
    );
    const [readyToSave, setReadyToSave] = useState<boolean>(false);

    const { dispatchApiRequest: postLinks, loading: isSaving } =
        useSwitcherClient((client) => client.webLink_Post, {
            requestImmediately: false,
            hideLoading: true,
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:edit-links-failed"
                    })
                );
            }
        });

    usePageHeader({
        title: t("collection-page:collections-settings-links"),
        subTitle: (
            <Trans
                i18nKey={t("page-titles:subheaders:links")}
                components={{
                    link1: (
                        <Link
                            to={"/video-library"}
                            title="Video Library"
                            className={styles["link"]}
                        />
                    )
                }}
            />
        ),
        autoSave: isSaving,
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("collection-page:collections-settings-links")
        ]
    });

    const { dispatchApiRequest: getVideoPlayerProfile, loading } =
        useSwitcherClient(
            (client) =>
                client.videoPlayerProfile_GetVideoPlayerProfileByVideoPlayerId,
            {
                requestImmediately: false,
                args: [videoPlayerId]
            }
        );

    useEffect(() => {
        const init = async () => {
            let response = await getVideoPlayerProfile();
            if (response == null) {
                response = {
                    VideoPlayer: {}
                } as VideoPlayerProfile;
            }
            setPlayerProfile(response);
            setLinks(response.VideoPlayer?.VideoPlayerDonationLinks ?? []);
        };
        init();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, videoPlayerId, projectId]);

    const updateLinks = useCallback(
        async (request: WebLinkRequest) => {
            if (!readyToSave) return;
            try {
                setReadyToSave(false);
                await postLinks([request]);
                return;
            } catch (e) {
                rollbar.error("Error updating video player links", e);
                return;
            }
        },
        [postLinks, readyToSave]
    );

    // prevent autosaves from component setup
    useEffect(() => {
        setTimeout(() => {
            setReadyToSave(true);
        }, 1000);
    }, [readyToSave]);

    useEffect(() => {
        if (links) {
            // add/change links after debounce
            const request: WebLinkRequest = {
                webLinks: links,
                Type: WebLinkRequestType.VideoPlayer,
                Id: playerProfile?.VideoPlayer?.Id
            };
            updateLinks(request);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links]);

    return (
        <>
            {!loading && playerProfile !== null ? (
                <WebLinksForm
                    links={links}
                    videoPlayerId={videoPlayerId}
                    setLinks={setLinks}
                />
            ) : null}
        </>
    );
};
