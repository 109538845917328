import React from "react";
import styles from "./Stepper.module.scss";

interface StepperProps {
    label: string;
    inc: () => void;
    dec: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: number;
    min: number;
    max: number;
}

export const Stepper: React.FC<StepperProps> = ({
    label,
    inc,
    dec,
    onChange,
    value,
    min,
    max
}) => {
    return (
        <div className={styles["container"]}>
            <label>{label}</label>
            <div className={styles["stepper"]}>
                <button
                    className="btn btn-primary"
                    onClick={dec}
                    disabled={value <= min}
                >
                    -
                </button>
                <input
                    className={`form-control ${styles["text-wrapper"]} ml-2 mr-2`}
                    type="text"
                    id="team-qty"
                    value={value ? value : ""}
                    onChange={onChange}
                />
                <button
                    className="btn btn-primary"
                    onClick={inc}
                    disabled={value >= max}
                >
                    +
                </button>
            </div>
        </div>
    );
};
