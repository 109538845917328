import { PayloadAction, Reducer, createSlice } from "@reduxjs/toolkit";
import { EventState } from "./types";
import { getEvents, postEvents } from "./thunks";

const initialState = { events: undefined };

export const events = createSlice({
    name: "events",
    initialState: initialState,
    reducers: {
        resetEvents: () => initialState
    },
    extraReducers(builder) {
        builder.addCase(
            getEvents.fulfilled,
            (state, { payload }: PayloadAction<EventState>) => {
                state.events = payload;
            }
        );

        builder.addCase(
            postEvents.fulfilled,
            (state, { payload }: PayloadAction<EventState>) => {
                state.events = { ...state.events, ...payload };
            }
        );

        builder.addCase(getEvents.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(postEvents.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export const { resetEvents } = events.actions;

export default events.reducer as Reducer<EventState>;
