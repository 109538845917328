import React, { useCallback } from "react";
import { ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { displayDate } from "helpers/time";
import styles from "./ManageInventoryItemsPage.module.scss";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";

interface ItemCardProps {
    item: ResellerInventoryItem;
    isResellerAdmin?: boolean;
    copyToClipboard: (toBeCopied: string) => void;
}

export const ItemCard: React.FC<ItemCardProps> = ({
    item,
    isResellerAdmin,
    copyToClipboard
}) => {
    const { t, i18n } = useTranslation();

    const parseStatus = useCallback(
        (status: number): string => {
            switch (status) {
                case 0:
                    return t("manage-inventories:available");
                case 1:
                    return t("manage-inventories:sent");
                case 2:
                    return t("manage-inventories:redeemed");
                case 3:
                    return t("manage-inventories:invalidated");
                case 4:
                    return t("manage-inventories:claimed");
                default:
                    return "";
            }
        },
        [t]
    );

    return (
        <div className="card card-body mb-2">
            <h5>
                {parseStatus(item.Status)}{" "}
                <span className={styles["checkmark-icon"]}>
                    {item.Status !== 0 && <CheckmarkIcon />}
                </span>
            </h5>
            <h6 className="text-muted">
                {" "}
                {item.Status !== 0 &&
                    displayDate(item.UpdatedAt, i18n.language)}
            </h6>
            <p className="card-text lead mb-2">{item.CouponCode}</p>
            {isResellerAdmin && (
                <p className={styles["email"]}>{item.RedeemedByUserEmail}</p>
            )}
            <button
                className="btn btn-outline-primary"
                disabled={item.Status !== 0 && !isResellerAdmin ? true : false}
                onClick={() =>
                    copyToClipboard(
                        item.Status === 0
                            ? item.CouponCode
                            : item.RedeemedByUserEmail
                    )
                }
            >
                {item.Status === 0 || !isResellerAdmin
                    ? t("buttons:copy-code")
                    : t("buttons:copy-email")}
            </button>
        </div>
    );
};
