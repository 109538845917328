import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { persistReducer } from "redux-persist";
import rootReducer, { RootState } from "./reducers";

import storage from "redux-persist/lib/storage"; // localStorage

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["preferences", "user", "promotions", "view"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
});

if (import.meta.env.NODE_ENV === "development" && import.meta.hot) {
    import.meta.hot.accept("./reducers", () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const newRootReducer = require("./reducers").default;
        store.replaceReducer(newRootReducer);
    });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
