import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { NotificationContainer } from "components/notification/NotificationContainer";
import { useTranslation } from "react-i18next";
//import HelpIcon from "assets/icons/question-mark.svg?react"; //These imports are used for the Searchbar and Help Icon. which is disabled at the moment
//import { trackEvent } from "../../helpers/analyticsHelpers";
import { PageContentHeader } from "components/page-content-header/PageContentHeader";
import { MinimizedModalTabManager } from "components/minimized-modal-tab/MinimizedModalTabManager";
import { useClaimCheck } from "hooks/useClaimCheck";
import { Outlet } from "react-router-dom";
import { usePageTitle } from "hooks/usePageTitle";

//import { SiteSearchBar } from "components/site-searchbar/SiteSearchBar";

interface PageContentProps {
    showPageHeader: boolean;
}

export const PageContent: React.FC<PageContentProps> = ({ showPageHeader }) => {
    const { t } = useTranslation("page-titles");

    const {
        title,
        subTitle,
        state,
        data: { badgeConfig, upgradeConfig, backNavigation, backNavigationText }
    } = usePageTitle();

    const header = useSelector((s: RootState) => s.header);

    const badgeConfigClaim = useClaimCheck(badgeConfig?.claim);
    const showBadge = useMemo(() => {
        if (badgeConfig == null) return false;
        if (badgeConfig.claim == null) return true;

        const shouldHaveClaim = badgeConfig.claimCheck ?? true;
        return (
            (shouldHaveClaim && badgeConfigClaim) ||
            (!shouldHaveClaim && !badgeConfigClaim)
        );
    }, [badgeConfig, badgeConfigClaim]);

    const upgradeConfigClaim = useClaimCheck(upgradeConfig?.claim);
    const showUpgrade = useMemo(() => {
        if (upgradeConfig == null) return false;
        if (upgradeConfig.claim == null) return true;

        const shouldHaveClaim = upgradeConfig.claimCheck ?? true;
        return (
            (shouldHaveClaim && upgradeConfigClaim) ||
            (!shouldHaveClaim && !upgradeConfigClaim)
        );
    }, [upgradeConfig, upgradeConfigClaim]);

    return (
        <>
            <div className="row">
                <main className="container-fluid">
                    <div>
                        {showPageHeader && title && (
                            <PageContentHeader
                                backNavigation={
                                    header?.backNavigation ?? backNavigation
                                }
                                backNavigationText={
                                    header?.backNavigationText ??
                                    t(backNavigationText)
                                }
                                title={header?.title ?? title}
                                subTitle={header?.subTitle ?? subTitle}
                                subTitleLink={state?.subheaderLink}
                                subTitleLinkText={t(
                                    `page-titles:link:${title}`
                                )}
                                showBadge={showBadge}
                                badgeClass={badgeConfig?.class}
                                badgeLabel={t(
                                    `page-titles:badges:${badgeConfig?.label}`
                                )}
                                showUpgrade={showUpgrade}
                                upgradeLabel={t(
                                    `buttons:${upgradeConfig?.label}`
                                )}
                                customButtons={header?.customButtons}
                                autoSave={header?.autoSave}
                                autoSaveText={header?.autoSaveText}
                                inlineCustomButton={header?.inlineCustomButton}
                                shouldHeaderWrap={header?.shouldHeaderWrap}
                                showBreadcrumbs={header?.showBreadcrumbs}
                                breadcrumbLabels={header?.breadcrumbLabels}
                            />
                        )}

                        <NotificationContainer fixed />
                        <Outlet />
                        <MinimizedModalTabManager />
                    </div>
                </main>
            </div>
        </>
    );
};
