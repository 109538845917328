import React, { useMemo } from "react";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";
import { useNavigate } from "react-router-dom";
import styles from "./SubscriptionChangeSuccessPage.module.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "hooks/useParams";

export const SubscriptionChangeSuccessPage: React.FC = () => {
    const { newPaymentMethod: _newPaymentMethod } = useParams();
    const newPaymentMethod = useMemo(
        () => _newPaymentMethod?.toLowerCase() === "true",
        [_newPaymentMethod]
    );
    const { t } = useTranslation();
    const navigate = useNavigate();
    const back = () => {
        navigate("/");
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-10">
                    <div className={styles["form-card"]}>
                        <span className={styles["icon"]}>
                            <CheckmarkIcon />
                        </span>
                        <div className={styles["form-card-body"]}>
                            <p className="lead">
                                {t("payment-success-page:thank-you")}{" "}
                                <strong className={styles["highlight"]}>
                                    {t("subscription:successful-update")}
                                </strong>
                            </p>
                            {newPaymentMethod && (
                                <p>
                                    {t("subscription:use-next-billing-period")}
                                </p>
                            )}
                            <button
                                type="button"
                                className={`btn btn-primary ${styles["return-home-button"]}`}
                                onClick={back}
                            >
                                <ChevronLeft className="mr-2" />{" "}
                                {t("payment-success-page:return-home")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
