import React from "react";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import { BannerBaseForProgressTracker } from "./BannerBaseForProgressTracker";
// import { TutorialVideoType } from "components/tutorial-videos/video/TutorialVideo/types";
// import { TutorialModal } from "components/tutorial-videos/TutorialModal";
// import { isMobile } from "utils/js-utils";

// const IS_MOBILE = isMobile();

export interface Step {
    stepNumber: number;
    id: string;
    header?: string;
    subheader: string;
    action: string;
    // video: TutorialVideoType,
    onAction: () => void;
}

export interface ProgressTrackerBannerProps {
    onClose: () => void;
    currentStep: Step;
    progressFraction: {
        current: number;
        total: number;
    };
    hide?: boolean;
    header?: string;
    hasCatalogClaim: boolean;
}

export const ProgressTrackerBanner: React.FC<ProgressTrackerBannerProps> = ({
    onClose,
    currentStep,
    progressFraction,
    hide,
    header,
    hasCatalogClaim
}) => {
    // const { header: stepHeader, subheader, action, onAction, video } = currentStep;
    const { header: stepHeader, subheader, action, onAction } = currentStep;

    return (
        <BannerBaseForProgressTracker
            header={hasCatalogClaim ? header : (stepHeader ?? header)}
            subheader={subheader}
            onClose={onClose}
            // element={<TutorialModal video={video} isMobile={IS_MOBILE} />}
            hide={hide}
            buttonProps={{
                label: action,
                onClick: onAction,
                type: "btn-primary"
            }}
        >
            <ProgressBar
                currentValue={progressFraction.current}
                maxValue={progressFraction.total}
                variant="thick"
                isStepped
            />
        </BannerBaseForProgressTracker>
    );
};
