import { useEffect, useRef } from "react";

export const useVideoBlobData = (blob: Blob) => {
    const video = useRef<HTMLVideoElement>(document.createElement("video"));

    useEffect(() => {
        video.current.src = URL.createObjectURL(blob);
    }, [blob]);

    return video.current;
};
