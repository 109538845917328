import React from "react";
import { Asset } from "@switcherstudio/switcher-api-client";
import switcherLogo from "assets/icons/switcher.png";
import { formatBytes, getFileExtension } from "./utils";
import { displayDate } from "helpers/time";
import styles from "./AssetDetailsCard.module.scss";
import { useTranslation } from "react-i18next";
import { EditableTextDisplay } from "components/inputs/text-input/EditableTextDisplay";
import { client } from "api/client";
import store from "store/store";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";

interface AssetDetailsCardProps {
    asset: Asset;
    headerText: string;
}

export const AssetDetailsCard: React.FC<AssetDetailsCardProps> = ({
    asset,
    headerText
}) => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const appendFileExtension = (text: string) => {
        const fileExtension = getFileExtension(asset?.Name);
        return `${text}${fileExtension}`;
    };
    const onAssetNameChange = async (newName: string) => {
        const state = store.getState();
        try {
            await client.userAssets_PutUserAssets(
                state?.user?.userInfo?.UserId,
                [
                    {
                        Id: asset.Id,
                        Meta: asset.Meta,
                        Tags: asset.Tags,
                        MimeType: asset.MimeType,
                        RequiresAssetIds: asset.RequiresAssetIds,
                        AspectRatios: asset.AspectRatios,
                        Name: newName
                    }
                ]
            );
        } catch (e) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "errors:asset-name-error"
                })
            );
            throw e;
        }
    };
    return (
        <>
            {asset && (
                <>
                    <div className={styles["asset-container"]}>
                        <h4>{headerText}</h4>
                        <div className={`${styles["asset-card"]} mb-2`}>
                            <div
                                id={"asset-image-" + asset.Id}
                                className={`${styles["asset-image"]} mb-4`}
                            >
                                <img
                                    src={asset.ThumbnailUrl || switcherLogo}
                                    alt="asset"
                                />
                            </div>
                            <div className="card-body">
                                <EditableTextDisplay
                                    id="asset-name-input"
                                    initialValue={asset?.Name}
                                    onSubmit={onAssetNameChange}
                                    textElement="h5"
                                    textDisplayClassName="card-title"
                                    formatDisplayedText={appendFileExtension}
                                />
                                <p className="card-text text-muted">
                                    {t("switcher-cloud:last-updated")}:{" "}
                                    {displayDate(
                                        asset.UpdatedAt,
                                        i18n.language
                                    )}
                                    <br></br>
                                    {t("switcher-cloud:size")}:{" "}
                                    {formatBytes(asset.Size || 0)}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
