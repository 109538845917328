import {
    CreatorProduct,
    CreatorProductPrice
} from "@switcherstudio/switcher-api-client";
import { useDispatch } from "react-redux";
import { useSwitcherClient } from "./useSwitcherClient";
import { addNotification } from "../store/notification/slice";
import { NotificationType } from "../store/notification/types";
import { useTranslation } from "react-i18next";
import { useGetStripeAccounts } from "./useGetStripeAccounts";
import { useState, useMemo } from "react";

export function useGetCreatorProducts(requestImmediately: boolean = true): {
    products: CreatorProduct[];
    productSubscribers: number[];
    /** soon to be deprecated - remove after all recurring payments changes are finished (ek/06.22.23) */
    prices: CreatorProductPrice[];
    recurringProducts: CreatorProduct[];
    oneTimeProducts: CreatorProduct[];
    getProducts: () => void;
    loading: boolean;
} {
    const { accounts, loading: stripeLoading } = useGetStripeAccounts();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [prices, setPrices] = useState<CreatorProductPrice[]>([]);
    const [productSubscribers, setProductSubscribers] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(
        stripeLoading && requestImmediately
    );

    const { dispatchApiRequest: getProducts, transformedData: products } =
        useSwitcherClient((client) => client.creatorProducts_Get, {
            requestImmediately: accounts?.length > 0 && requestImmediately,
            args: [
                accounts?.length > 0 ? accounts[0].Id : "",
                true,
                true,
                true
            ],
            transformResponseData: async ({ originalResponse: products }) =>
                await prepareProductsAndPricing(
                    products?.Products?.filter((p) => p.IsActive)
                ),
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:product-price-retrieval-error")
                    })
                );
            }
        });

    const { dispatchApiRequest: getSubscriptionCountsCreatorProduct } =
        useSwitcherClient(
            (client) => client.creatorProducts_GetSubscriberCount
        );

    const prepareProductsAndPricing = async (_products: CreatorProduct[]) => {
        const prodSubs = [];
        const productsArr = [];
        //extract prices from products array

        for (let p of _products) {
            const preparePrices = (_prices: CreatorProductPrice[]) => {
                const getMostRecentPrice = (
                    recurringInterval: "year" | "month" | undefined | null
                ) => {
                    return _prices.reduce((memo, currentPrice) => {
                        const priceIsEligible =
                            currentPrice?.RecurringInterval ===
                                recurringInterval && currentPrice?.Active;
                        if (!memo) {
                            // there is no most recent price set yet
                            return priceIsEligible ? currentPrice : memo;
                        } else {
                            // there is a most recent price set as the memo
                            return priceIsEligible &&
                                new Date(currentPrice?.CreatedAt) >
                                    new Date(memo?.CreatedAt)
                                ? currentPrice
                                : memo;
                        }
                    }, null);
                };

                const recurringInterval = _prices?.at(0)?.RecurringInterval;
                const mostRecentPrices: CreatorProductPrice[] = [];

                if (!recurringInterval) {
                    const mostRecentOneTimePrice = getMostRecentPrice(null);
                    if (mostRecentOneTimePrice)
                        mostRecentPrices.push(mostRecentOneTimePrice);
                } else {
                    const mostRecentRecurringMonthlyPrice =
                        getMostRecentPrice("month");
                    const mostRecentRecurringAnnualPrice =
                        getMostRecentPrice("year");
                    if (mostRecentRecurringMonthlyPrice)
                        mostRecentPrices.push(mostRecentRecurringMonthlyPrice);
                    if (mostRecentRecurringAnnualPrice)
                        mostRecentPrices.push(mostRecentRecurringAnnualPrice);
                }

                return mostRecentPrices;
            };
            const productPrices = preparePrices(p.Prices);

            if (p.Prices?.some((price) => price.IsRecurring ?? false)) {
                let subs = await getSubscriptionCountsCreatorProduct([
                    accounts[0].Id,
                    p.Id
                ]);
                prodSubs[p.Id] = subs;
            }

            if (p.IsActive && p.Prices?.length > 0) {
                productsArr.push({
                    ...p,
                    Prices: productPrices
                });
            }
        }

        setProductSubscribers(prodSubs);

        productsArr.sort(function (a, b) {
            return new Date(a.CreatedAt) < new Date(b.CreatedAt) ? 1 : -1;
        });

        const pricesArr = productsArr.reduce((memo, currentProduct) => {
            return [...memo, ...currentProduct.Prices];
        }, []);

        setPrices(pricesArr);

        setLoading(false);
        return productsArr;
    };

    const recurringProducts = useMemo<CreatorProduct[]>(
        () => products?.filter((p) => p.Prices.some((pr) => pr.IsRecurring)),
        [products]
    );
    const oneTimeProducts = useMemo<CreatorProduct[]>(
        () => products?.filter((p) => p.Prices.some((pr) => !pr.IsRecurring)),
        [products]
    );

    return {
        products: products ?? [],
        getProducts,
        productSubscribers,
        prices,
        recurringProducts,
        oneTimeProducts,
        loading
    };
}
