import styles from "../index.module.scss";
import { useMemo } from "react";
import React from "react";
import { Badge } from "../types";
import { Button } from "components/buttons/Button";
import CloseIcon from "assets/icons/close-blue.svg?react";
import { useTranslation } from "react-i18next";
import { shorten } from "helpers/strings";
import { Category } from "@switcherstudio/switcher-api-client";
import { useClaimCheck } from "hooks/useClaimCheck";

interface CategoryBadgeProps {
    categories: Category[];
    handleRemoveCategory: (categoryId: string) => void;
}

export const CategoryBadge: React.FC<CategoryBadgeProps> = ({
    categories,
    handleRemoveCategory
}: CategoryBadgeProps) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const categoryBadges = useMemo<Badge[]>(() => {
        return categories
            ? categories.map((c) => ({ slug: c?.Name, icon: null }))
            : [];
    }, [categories]);

    return (
        <>
            <h6>
                {hasCatalogClaim
                    ? t("video-upload:tags")
                    : t("video-upload:categories")}
            </h6>
            {categoryBadges.length === 0 ? (
                <div className={styles["no-categories-applied"]}>
                    <p>
                        {hasCatalogClaim
                            ? t("video-upload:no-tags-applied")
                            : t("video-upload:no-categories-applied")}
                    </p>
                </div>
            ) : (
                <div className={styles["categories-badges-container"]}>
                    {categoryBadges.map((badge) => {
                        return (
                            <Button
                                onClick={(c) =>
                                    handleRemoveCategory(c.currentTarget.value)
                                }
                                type="category-badge"
                                value={badge.slug}
                                iconRight={
                                    <CloseIcon
                                        className={styles["badges-icon"]}
                                    />
                                }
                                key={badge.slug}
                            >
                                <p>{shorten(badge.slug, 15)}</p>
                            </Button>
                        );
                    })}
                </div>
            )}
        </>
    );
};
