import { useCallback } from "react";
import { useSwitcherClient } from "./useSwitcherClient";
import {
    assemblePutOptions,
    uploadAssetToStorage
} from "views/page-content/cloud/upload/upload-helpers/helpers";
import {
    AspectRatio,
    AssetLayering,
    AssetType,
    FileItem
} from "views/page-content/cloud/upload/types";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import { imageThumbnailAsync } from "views/page-content/cloud/upload/upload-helpers/thumbnail-helpers";
import {
    Asset,
    Broadcast,
    CatalogDetailsResponse,
    PlayerDetailsResponse,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";

export interface UseAssetUploadOptions {
    errorMessage?: string;
}

export const useThumbnailUpload = (
    thumbnailFile: File,
    thumbnailImageURL: string
) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation("video-upload");

    const {
        userInfo: { UserId }
    } = useSelector((s: RootState) => s.user);

    const { dispatchApiRequest: dispatchPutUserAsset } = useSwitcherClient(
        (client) => client.userAssets_PutUserAssets
    );

    const { dispatchApiRequest: dispatchDeleteUserAsset } = useSwitcherClient(
        (client) => client.userAssets_DeleteUserAssets
    );

    const handleUploadThumbnail = useCallback(async (): Promise<Asset> => {
        if (!thumbnailFile) {
            return;
        }
        try {
            const fileItems: FileItem[] = [];

            // optimize initial file image
            const optimized = await imageThumbnailAsync(
                AssetLayering.FullScreen,
                AssetType.image,
                thumbnailImageURL,
                1920,
                AspectRatio.auto,
                70,
                thumbnailFile.type
            );

            // Convert the data URL to a Blob
            const blob = await fetch(optimized.dataURL).then((response) =>
                response.blob()
            );

            // Create a new File object from the Blob
            const optimizedFile = new File([blob], thumbnailFile.name, {
                type: thumbnailFile.type
            });

            // get thumbnail size for image (this can be used in carousels)
            const thumbnail = await imageThumbnailAsync(
                AssetLayering.FullScreen,
                AssetType.image,
                thumbnailImageURL,
                320,
                AspectRatio.horizontal,
                70,
                thumbnailFile.type
            );

            const fileItem = {
                name: optimizedFile.name,
                file: optimizedFile,
                fileProps: {},
                AssetLayering: AssetLayering.FullScreen,
                AssetType: AssetType.image,
                thumbnail
            };
            fileItems.push(fileItem);

            const { filesWithMetadata, assetPutOptions } =
                await assemblePutOptions(fileItems, true);

            const newThumbnailAssets = await dispatchPutUserAsset([
                UserId,
                assetPutOptions
            ]);

            //handle azure blob storage upload
            await Promise.all(
                newThumbnailAssets.map(async (thumbnailAsset) => {
                    const file = filesWithMetadata.find(
                        (f) =>
                            f.imageMid === thumbnailAsset.Id ||
                            f.mid === thumbnailAsset.Id
                    );
                    if (file) {
                        await uploadAssetToStorage(file, thumbnailAsset);
                    }
                })
            );

            const thumbnailImageAssets = newThumbnailAssets.filter(
                (a) => (a.Type as any) === "Image"
            );

            return thumbnailImageAssets?.[0];
        } catch (e) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("video-upload:errors:thumbnail-failed-upload")
                })
            );
        }
    }, [
        thumbnailFile,
        thumbnailImageURL,
        dispatchPutUserAsset,
        UserId,
        dispatch,
        t
    ]);

    const handleDeleteThumbnail = useCallback(
        async (thumbnailAssetId: string | undefined) => {
            try {
                if (!!thumbnailAssetId) {
                    await dispatchDeleteUserAsset([UserId, thumbnailAssetId]);
                }
            } catch (e) {
                rollbar.warning("Error deleting user asset", e);
            }
        },
        [UserId, dispatchDeleteUserAsset]
    );

    const handleUpdateThumbnail = useCallback(
        async (
            entity:
                | Broadcast
                | VideoPlayer
                | PlayerDetailsResponse
                | CatalogDetailsResponse
        ) => {
            if (!entity) return;

            if (thumbnailFile && entity?.ThumbnailAssetId) {
                handleDeleteThumbnail(entity?.ThumbnailAssetId);
            }

            if (thumbnailFile) {
                return handleUploadThumbnail();
            }
        },
        [handleDeleteThumbnail, handleUploadThumbnail, thumbnailFile]
    );

    return {
        handleUpdateThumbnail,
        handleUploadThumbnail,
        handleDeleteThumbnail
    };
};
