import React, { useState, useMemo } from "react";
import styles from "./index.module.scss";
import { IconLeft } from "./IconLeft";
import { IconRight } from "./IconRight";

export type InputType =
    | "firstName"
    | "lastName"
    | "email"
    | "password"
    | "text";

interface CreateAccountInputProps {
    label?: string;
    type: InputType;
    id: string;
    value: string | number | string[] | undefined;
    error?: string;
    readOnly?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const CreateAccountInput: React.FC<CreateAccountInputProps> = ({
    label,
    type,
    id,
    value,
    error,
    readOnly,
    onChange,
    onFocus,
    onBlur
}) => {
    const isPassword = useMemo(() => type === "password", [type]);

    const [isFocused, setIsFocused] = useState(false);
    const [isRevealed, setIsRevealed] = useState<boolean>(!isPassword);

    return (
        <div onFocus={onFocus} onBlur={onBlur}>
            <div
                className={`form-group ${styles["login-input"]} ${
                    readOnly ? styles["readOnly"] : ""
                }`}
            >
                <div className={styles["input-container"]}>
                    <label
                        className={` ${styles["floating-label"]} ${
                            !!value || isFocused || !!readOnly
                                ? styles["has-focus"]
                                : ""
                        }`}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                    <IconLeft type={type} />
                    <input
                        type={isPassword && isRevealed ? "text" : type}
                        className="form-control"
                        id={id}
                        value={value || ""}
                        onChange={onChange}
                        onFocus={() => setIsFocused(readOnly ? false : true)}
                        onBlur={() => setIsFocused(false)}
                        disabled={readOnly}
                        readOnly={readOnly}
                    />
                    <IconRight
                        type={type}
                        isRevealed={isRevealed}
                        onClick={() => setIsRevealed((h) => !h)}
                    />
                </div>
                <small
                    id={`${id}-help`}
                    aria-describedby={id}
                    className="form-text"
                >
                    {error && (
                        <div className="alert alert-danger alert-info mb-0 p-2">
                            {error}
                        </div>
                    )}
                </small>
            </div>
        </div>
    );
};
