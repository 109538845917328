import {
    Link as RouterLink,
    LinkProps as RouterLinkProps
} from "react-router-dom";
import styles from "./index.module.scss";

/**
 * A styled wrapper for react-router-dom <Link> component
 */
export const Link = (props: RouterLinkProps) => (
    <RouterLink {...props} className={styles["link"]}>
        {props.children}
    </RouterLink>
);
