import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    useBeforeUnload as _useBeforeUnload,
    useBlocker
} from "react-router-dom";

/**
 * Custom hook that enables a warning dialog when a user attempts to unload the page,
 * based on conditions specified by the caller.
 *
 * @param {boolean} shouldRun - Determines if the before unload handler should be activated.
 * @param {() => void} handler - A callback function to run when the user attempts to unload the page
 *                               without confirmation. Can be used to perform cleanup tasks or prevent
 *                               default actions.
 * @param {boolean} [confirmOnExit=false] - If true, a confirmation dialog is shown to the user
 *                                          when they try to leave the page.
 *
 * @remarks
 * This hook uses the `useBeforeUnload` from `react-router-dom` to manage the unload behavior,
 * including setting the returnValue for older browsers (although it is deprecated).
 * It leverages a blocker to manage the state when confirmation is needed.
 *
 * The message shown in the confirmation dialog is translated using the `useTranslation` hook
 * from 'react-i18next', allowing for localization of the warning message.
 *
 * @example
 * const MyComponent = () => {
 *     useBeforeUnload(true, () => console.log("Cleanup task"), true);
 *     return <div>My Component</div>;
 * };
 */
export const useBeforeUnload = (
    shouldRun: boolean,
    handler: () => void,
    confirmOnExit: boolean = false
): void => {
    const { t } = useTranslation();

    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (shouldRun) {
                if (confirmOnExit) {
                    event.preventDefault();
                    //NOTE: returnValue is deprectated, keeping it for support on older browsers.
                    event.returnValue = "";
                } else {
                    handler && handler();
                }
            }
        },
        [shouldRun, handler, confirmOnExit]
    );

    _useBeforeUnload(handleBeforeUnload);

    const blocker = useBlocker(shouldRun && confirmOnExit);

    useEffect(() => {
        if (blocker.state === "blocked") {
            const message = t("message:confirm-before-unload");
            const response = window.confirm(message);
            if (response) {
                handler && handler();
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocker.state, handler, t]);
};
