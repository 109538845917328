import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import useClickOutside from "hooks/useClickOutside";
import { useIsMountedRef } from "hooks/useIsMountedRef";

interface SelectionOption {
    name: string;
    description?: string;
    id: string;
}

interface SearchableSelectProps {
    label: string;
    // options: Array<SelectionOption>,
    // selectedOption: SelectionOption,
    placeholder: string;
    onSearch: (...args: any) => any;
    onSelect: (_object: SelectionOption) => any;
    resultRender?: (...args: any) => any;
}

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
    label,
    // options,
    // selectedOption,
    placeholder,
    onSearch,
    onSelect,
    resultRender
}: SearchableSelectProps) => {
    const isMounted = useIsMountedRef();
    const [searchTerm, setSearchTerm] = useState([] as any);
    const [searchResults, setSearchResults] = useState(
        [] as Array<SelectionOption>
    );
    const [isOpen, setIsOpen] = useState(false);
    const modalRef = useRef(null);
    useClickOutside(modalRef, () => {
        setSearchTerm("");
        setIsOpen(false);
    });

    useEffect(() => {
        if (isMounted.current) setSearchResults([]);
    }, [isMounted]);

    async function searchOptions(searchTerm) {
        if (!searchTerm) return setSearchResults([]);

        const result = await onSearch(searchTerm);

        setSearchResults(result.data);

        setIsOpen(true);
    }

    const openOnClick = useCallback(() => {
        if (!isOpen) setIsOpen(true);
        if (!searchTerm) setSearchResults([]);
    }, [isOpen, searchTerm]);

    function onChange(newVal) {
        setSearchTerm(newVal);
        // debounce search for things
        searchOptions(newVal);
    }

    function _onSelect(option) {
        onSelect(option);
        setSearchTerm("");
        setIsOpen(false);
    }

    return (
        <>
            <div className={styles["multi-select"]}>
                <label htmlFor={label.split(" ").join("-")} className="sr-only">
                    {label}
                </label>
                <input
                    type="text"
                    className={`form-control ${styles["text-input"]}`}
                    id={label.split(" ").join("-")}
                    placeholder={placeholder}
                    value={searchTerm}
                    onClick={openOnClick}
                    onChange={(e) => onChange(e.target.value)}
                />
                <div className={styles["results-container"]}>
                    {searchResults.length && isOpen ? (
                        <ul className={styles["result-list"]} ref={modalRef}>
                            {searchResults.map((result, i) => (
                                <li
                                    key={`search-result-${i}`}
                                    onClick={() => _onSelect(result)}
                                >
                                    {!!resultRender
                                        ? resultRender(result)
                                        : result.name}
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    {/* {
            selectedOptions.length > 0
              ? <ul className={styles["selected-list"]}>
                {
                  selectedOptions.map((o, i) =>
                    <li key={`search-result-${o.id ? o.id : i}`} onClick={() => _onSelect(o, true)}>
                      {o.name}
                    </li>
                  )
                }
              </ul>
              : null
          } */}
                </div>
            </div>
        </>
    );
};
