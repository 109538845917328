import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useMatchPathCallback = (match: RegExp, callback: () => void) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (match.test(pathname)) {
            callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match, pathname]);
};
