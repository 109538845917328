import React, { PropsWithChildren } from "react";
import styles from "./index.module.scss";
import { Dropdown } from "react-bootstrap";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";

export interface ContextMenuItem {
    key: string;
    text: string;
    onClick: (...args: any) => any;
}

export interface ContextMenuProps extends PropsWithChildren {
    items: ContextMenuItem[];
    dropDirection?: DropDirection;
}

const CustomToggle = React.forwardRef<HTMLDivElement, any>(
    ({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </div>
    )
);
CustomToggle.displayName = "CustomToggle";

export const ContextMenuWrapper = ({
    items,
    dropDirection,
    children
}: ContextMenuProps) => {
    return (
        <Dropdown drop={dropDirection} align={"end"}>
            <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
                drop
            >
                {children}
            </Dropdown.Toggle>

            <Dropdown.Menu rootCloseEvent="mousedown" flip={false}>
                {items.map((item) => {
                    return (
                        <Dropdown.Item
                            key={item.key}
                            className={styles["context-menu-item"]}
                            onClick={() => {
                                item.onClick && item.onClick();
                            }}
                        >
                            {item.text}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};
