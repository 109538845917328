export const mapBodyToForm = function (body) {
    const fd = new FormData();
    for (const key in body) {
        if (body[key]) fd.append(key, body[key]);
    }
    return fd;
};

/** decodeURIComponent with fallback to empty string */
export const decode = function (str: string): string {
    return decodeURIComponent(str || "");
};

export const validatePassword = (password: string): boolean => {
    if (
        password.match(
            /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*()_+-=]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i
        )
    ) {
        return true;
    } else {
        return false;
    }
};

/** A helper to delay a function
 * (useful for fetching from api directly after a post request to allow api time to save changes)
 * */
export const delay = (
    func: (...args: any) => any,
    delayMilliseconds: number = 250
): void => {
    setTimeout(() => {
        func && func();
    }, delayMilliseconds);
};
