import React, { useCallback, useEffect } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { CategorySearch } from "components/category-search/CategorySearch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { TextInput } from "components/inputs/text-input/TextInput";
import {
    resetTwitchForm,
    updateGeneralForm,
    updateTwitchForm
} from "store/platforms/slice";
import { useTranslation } from "react-i18next";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "../../types";

interface TwitchFormProps {
    platform?: StreamingProvider;
    multiForm?: boolean;
}

export const TwitchForm: React.FC<TwitchFormProps> = ({
    multiForm
}: TwitchFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { twitch } = useSelector((state: RootState) => state.platforms);
    const { twitchForm } = twitch;
    const { init } = usePlatformActions(PlatformId.Twitch);

    useEffect(() => {
        // this effect sets the initial value of the 'title'
        if (multiForm) init();
        dispatch(resetTwitchForm());
        dispatch(
            updateGeneralForm({
                title: twitch.channelInfo.title
            })
        );
    }, [dispatch, twitch.channelInfo.title, init, multiForm]);

    const handleChange = useCallback(
        (value, isGeneral?) => {
            const numValue = parseInt(value, 10);
            if (isGeneral) dispatch(updateGeneralForm({ title: value }));
            else if (!isNaN(numValue))
                dispatch(updateTwitchForm({ delay: numValue }));
        },
        [dispatch]
    );

    function onIngestChange(event) {
        const serverName = event.target.value;
        const ingestServer = twitch.ingestServers.filter(
            (s) => s.name === serverName
        )[0];
        dispatch(updateTwitchForm({ ingestServer }));
    }

    return (
        <>
            <CategorySearch initialSearch={twitch.channelInfo.game_name} />
            {/* Delay is only configurable for Twitch Partners: 
          https://dev.twitch.tv/docs/api/reference#modify-channel-information */}
            {twitch.user?.broadcaster_type === "partner" && (
                <TextInput
                    id="twitch-delay"
                    type="number"
                    value={twitchForm.delay}
                    label={t("platforms:delay")}
                    placeholder={""}
                    onChange={(event) => handleChange(event.target.value)}
                />
            )}
            {!!twitchForm.ingestServer && (
                <>
                    <div className="form-group">
                        <label htmlFor="twitch-ingest-server">
                            {t("platforms:ingest-server")}
                        </label>
                        <select
                            id="twitch-ingest-server"
                            className={`form-control`}
                            onChange={onIngestChange}
                            value={twitchForm.ingestServer.name}
                            aria-describedby="ingest-help"
                        >
                            {twitch.ingestServers.map((s, i) => (
                                <option key={"ingest-" + i} value={s.name}>
                                    {s.name}
                                </option>
                            ))}
                        </select>
                        <small id="ingest-help" className="text-center">
                            {t("misc:visit")}{" "}
                            <a href="https://stream.twitch.tv/ingests/">
                                stream.twitch.tv/ingests
                            </a>{" "}
                            {t("misc:twitch-ingest")}
                        </small>
                    </div>
                </>
            )}
        </>
    );
};
