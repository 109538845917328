import Rollbar from "rollbar";
import { createSlice } from "@reduxjs/toolkit";

// TODO: configure these somewhere (store?) left here temporarily
const globalEnvironment = import.meta.env.VITE_ENV || "local";
const globalAppVersion = import.meta.env.VITE_VERSION || "1.0";

const config = {
    accessToken: "2923981ce7f141e08a0070f69058ca74",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: globalEnvironment === "local" ? false : true,
    environment: globalEnvironment,
    hostSafeList: [
        "switcherstudio.com",
        "stripe.com",
        "stripe.network",
        "cloudflarestream.com"
    ],
    payload: {
        environment: globalEnvironment,
        client: {
            javascript: {
                code_version: globalAppVersion,
                source_map_enabled: true,
                guess_uncaught_frames: true
            }
        }
    },
    ignoredMessages: [
        "Account was found, but email is not yet confirmed.  A confirmation email has been sent.",
        "The user either does not exist or is not confirmed.",
        "The user name or password is incorrect.",
        "Too many failed login attempts. Please try again in a few minutes.",
        '{"error":"invalid_grant","error_description":"The user name or password is incorrect."}',
        "The user either does not exist or is not confirmed.",
        "The user is not enabled for live streaming",
        "The user is blocked from live streaming.",
        "(#200) User does not have sufficient administrative permission",
        "The session has been invalidated",
        "(#200) Subject does not have permission to create live video on this page",
        "(#200) Subject does not have permission to create live video on this group",
        "Load failed",
        "Script error.",
        "Failed to load Stripe.js"
    ]
};

export const rollbar = createSlice({
    name: "rollbar",
    initialState: {
        instance: new Rollbar(config)
    },
    reducers: {
        init: (state) => {
            state.instance = new Rollbar(config);
        },
        configureRollbar: (state, { payload }) => {
            state.instance.configure({
                payload: {
                    person: {
                        id: payload?.UserId
                    }
                }
            });
        }
    }
});
export const { init, configureRollbar } = rollbar.actions;

export default rollbar.reducer;
