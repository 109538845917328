import React, { useEffect, useState, useCallback } from "react";
import { TwilioVideoRoomsUsage } from "@switcherstudio/switcher-api-client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import styles from "./RoomUsage.module.scss";
import { useTranslation } from "react-i18next";

interface RoomUsageProps {
    usage?: TwilioVideoRoomsUsage;
}

dayjs.extend(utc);
export const RoomUsage: React.FC<RoomUsageProps> = ({
    usage
}: RoomUsageProps) => {
    const { t } = useTranslation();
    const [usagePercentage, setUsagePercentage] = useState<number>(0);
    const getProgressBarColor = (usagePercentage: number) => {
        if (usagePercentage > 85) {
            return "bg-danger";
        }

        if (usagePercentage > 70) {
            return "bg-warning";
        }

        return "bg-success";
    };

    const getMinutesText = useCallback(() => {
        if (usage && usage.TotalSeconds && usage.MaxSeconds) {
            const used = Math.ceil(usage.TotalSeconds / 60);
            const max = Math.ceil(usage.MaxSeconds / 60);

            return `${used}/${max} ${t("misc:minutes")}`;
        }
    }, [usage, t]);

    // const getDaysLeftText = () => {
    //   const currentTime = dayjs.utc();
    //   const begOfNextMonth = dayjs
    //     .utc()
    //     .add(1, "month")
    //     .startOf("month");
    //   const daysLeft = begOfNextMonth.diff(currentTime, "day");
    //   return t(daysLeft === 1 ? "misc:day-reset" : "misc:day-reset_plural", { number: daysLeft });
    // };

    useEffect(() => {
        const totalSeconds = usage?.TotalSeconds;
        const maxSeconds = usage?.MaxSeconds;

        if (totalSeconds && maxSeconds) {
            setUsagePercentage(Math.round((totalSeconds / maxSeconds) * 100));
        }
    }, [usage]);

    return (
        <>
            <div className={styles["container"]}>
                <div className={styles["progress-bar-container"]}>
                    <small className="pr-2">{getMinutesText()}</small>
                    <div className="progress">
                        <div
                            className={`progress-bar ${getProgressBarColor(
                                usagePercentage
                            )}`}
                            role="progressbar"
                            style={{ width: `${usagePercentage}%` }}
                        ></div>
                    </div>
                </div>
                {/* <small className="text-center">{getDaysLeftText()}</small> */}
            </div>
        </>
    );
};
