import { useCallback, useMemo, useState } from "react";
import { useClaimCheck } from "hooks/useClaimCheck";
import { usePageHeader } from "hooks/usePageHeader";
import { useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";
import { CollectionGatedContentPageStripeSection } from "./CollectionGatedContentPageStripeSection";
import { useParams } from "hooks/useParams";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import classnames from "classnames/bind";
import { usePutCollection } from "hooks/requests/usePutCollection";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { PasswordGatingToggle } from "components/inputs/toggle/PasswordGatingToggle";
const cx = classnames.bind(styles);

export const CollectionGatedContentPage = () => {
    const dispatch = useDispatch();
    const { videoPlayerId } = useParams();
    useCatalogAccessBanner();
    const { t } = useTranslation();
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    const hasEmailGating = useClaimCheck("gatedcontent.email");
    const hasPasswordGating = useClaimCheck("gatedcontent.password");

    const headerSubtitle = useMemo(() => {
        if (!hasEmailGating && !hasPasswordGating) {
            return t("page-titles:subheaders:gated-content");
        }
        if (hasEmailGating && !hasPasswordGating) {
            return t("page-titles:subheaders:gated-content-email-only");
        }
        if (!hasEmailGating && hasPasswordGating) {
            return t("page-titles:subheaders:gated-content-password-only");
        }
        if (hasEmailGating && hasPasswordGating) {
            return t("page-titles:subheaders:gated-content-full");
        }
    }, [hasEmailGating, hasPasswordGating, t]);

    usePageHeader({
        title: t("collection-page:collections-settings-gated-content"),
        subTitle: headerSubtitle,
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("collection-page:collections-settings-gated-content")
        ]
    });

    const {
        collection,
        onChange,
        update,
        loading,
        unchanged,
        getVideoCollection
    } = usePutCollection(videoPlayerId, {
        onSuccess: () => {
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t("players:messages:gated-options-update-success")
                })
            );
        },
        onError: () => {
            addNotification({
                type: NotificationType.Danger,
                message: t("players:messages:update-failed")
            });
        }
    });

    const [hasPasswordValidationError, setHasPasswordValidationError] =
        useState(false);

    const passwordIsValid = useCallback(() => {
        const { Password, IsPasswordGatingEnabled } = collection?.VideoPlayer;
        const isInvalid =
            IsPasswordGatingEnabled && (!Password || Password.length > 256);

        setHasPasswordValidationError(isInvalid);
        return !isInvalid;
    }, [collection]);

    const collectionHasEntitlements = useMemo<boolean>(
        () =>
            collection?.VideoPlayerEntitlements.ProductEntitlements.length > 0,
        [collection]
    );

    useBeforeUnload(!unchanged, null, true);

    return (
        <>
            {hasGatedContentAccess && (hasEmailGating || hasPasswordGating) && (
                <>
                    <div className={cx("gate-options-form")}>
                        {hasPasswordGating && (
                            <PasswordGatingToggle
                                isEnabled={
                                    collection?.VideoPlayer
                                        ?.IsPasswordGatingEnabled
                                }
                                setIsEnabled={(value) =>
                                    onChange("IsPasswordGatingEnabled", value)
                                }
                                password={collection?.VideoPlayer?.Password}
                                setPassword={(value) =>
                                    onChange("Password", value)
                                }
                                hasEmailOrPurchaseGating={
                                    collectionHasEntitlements ||
                                    collection?.VideoPlayer
                                        ?.IsEmailGatingEnabled
                                }
                                hasValidationError={hasPasswordValidationError}
                            />
                        )}
                        {hasEmailGating && (
                            <Toggle
                                on={
                                    collectionHasEntitlements ||
                                    collection?.VideoPlayer
                                        ?.IsEmailGatingEnabled
                                }
                                label={t("collection-page:toggle-email")}
                                disabled={collectionHasEntitlements || loading}
                                reverseLayout
                                onToggle={() =>
                                    onChange(
                                        "IsEmailGatingEnabled",
                                        !collection?.VideoPlayer
                                            ?.IsEmailGatingEnabled
                                    )
                                }
                            />
                        )}

                        <Button
                            disabled={loading || unchanged}
                            onClick={() => {
                                if (passwordIsValid()) update();
                            }}
                        >
                            {t("buttons:save-changes")}
                        </Button>
                    </div>
                    <hr />
                </>
            )}
            <CollectionGatedContentPageStripeSection
                videoPlayerId={videoPlayerId}
                onReset={getVideoCollection}
            />
        </>
    );
};
