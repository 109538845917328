import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { useState, useEffect } from "react";
import { client } from "api/client";

export function useGetStripePrices(): {
    plans: StripePriceAnonymous[];
    loading: boolean;
} {
    const [plans, setPlans] = useState<StripePriceAnonymous[]>([]);

    useEffect(() => {
        const run = async () => {
            let plans = await client.stripePrices_GetActivePrices();

            const recurring = plans.filter((p) => p.RecurringInterval);
            const passes = plans.filter((p) => !p.RecurringInterval);

            plans = [...recurring, ...passes];

            setPlans(plans);
        };

        run();
    }, []);

    return { plans, loading: !plans };
}
