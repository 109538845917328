import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./types";

const initialState = {
    version: undefined
} as AppState;

/** This slice exists solely to compare the cached
 * version of the web app pages that Switcher keeps
 * against the most recently deployed app version
 * (`import.meta.env.VITE_VERSION`).
 */
export const app = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        setAppVersion: (state, { payload }: PayloadAction<string>) => {
            state.version = payload;
        }
    }
});

export const { setAppVersion } = app.actions;

export default app.reducer;
