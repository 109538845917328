import React, { useState, useCallback } from "react";

import { RootState } from "store/reducers";

import { client } from "api/client";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useIsResellerAdmin } from "hooks/useIsResellerAdmin";
import { useIsInfluencer } from "hooks/useIsInfluencer";
import { useGetResellerByUser } from "hooks/useGetResellerByUser";
import { useGetResellerInventories } from "hooks/useGetResellerInventories";
import { usePlatformActions } from "hooks/usePlatformActions";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";

import { Toggle } from "../../../components/inputs/toggle/Toggle";

import styles from "./ManageInventoriesPage.module.scss";
import { PlatformId } from "../platforms/types";
import { InventoriesGrid } from "./InventoriesGrid";

export const ManageInventoriesPage: React.FC = () => {
    const { t } = useTranslation();
    const { link } = usePlatformActions(PlatformId.StripeConnect);
    const user = useSelector((state: RootState) => state.user);
    const reseller = useGetResellerByUser(user);
    const inventories = useGetResellerInventories(reseller);
    const isResellerAdmin = useIsResellerAdmin(user);
    const isInfluencer = useIsInfluencer(user);

    useRedirectIfDisallowed(() => isResellerAdmin || isInfluencer);

    const [shouldShowExpired, setShouldShowExpired] = useState<boolean>(false);

    const manageAccount = useCallback(async () => {
        try {
            const loginLink = await client.stripeConnect_CreateLoginLink(
                user.userInfo.UserId
            );
            window.open(loginLink.url);
        } catch (e) {
            await link();
        }
    }, [link, user.userInfo.UserId]);

    return (
        <>
            {inventories && (
                <>
                    <h4>{reseller?.Name}</h4>
                    <InventoriesGrid
                        shouldShowExpired={shouldShowExpired}
                        inventories={inventories}
                    />
                    <div className={`${styles["buttons"]}`}>
                        {isInfluencer && (
                            <button
                                className="btn btn-primary"
                                onClick={manageAccount}
                            >
                                {t("manage-inventories:manage-stripe-account")}
                            </button>
                        )}

                        <Toggle
                            label={t("buttons:show-expired")}
                            on={shouldShowExpired}
                            onToggle={() => setShouldShowExpired((sse) => !sse)}
                            condensed={true}
                        />
                    </div>
                </>
            )}
        </>
    );
};
