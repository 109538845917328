import { PayloadAction, Reducer, createSlice } from "@reduxjs/toolkit";
import { DownloadsState } from "./types";
import { DownloadProps } from "hooks/useVideoDownload";

export const downloads = createSlice({
    name: "downloads",
    initialState: {
        pending: [],
        ready: []
    } as DownloadsState,
    reducers: {
        addPending: (state, { payload }: PayloadAction<DownloadProps>) => {
            if (!state.pending.find((dl) => dl.videoId === payload.videoId)) {
                state.pending = [...state.pending, payload];
            }
        },
        removePending: (state, { payload }: PayloadAction<DownloadProps>) => {
            state.pending = state.pending.filter(
                (dl) => dl.videoId !== payload.videoId
            );
        },
        moveToReady: (state, { payload }: PayloadAction<DownloadProps>) => {
            state.pending = state.pending.filter(
                (dl) => dl.videoId !== payload.videoId
            );
            state.ready = [...state.ready, payload];
        }
    }
});

export const { addPending, removePending, moveToReady } = downloads.actions;

export default downloads.reducer as Reducer<DownloadsState>;
