import React, { useEffect, useState } from "react";
import { client } from "api/client";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useTranslation } from "react-i18next";
import {
    PlatformsTable,
    PlatformsTableStreamProvider
} from "components/platforms/PlatformsTable";
import { RTMPChannelsTable } from "components/platforms/PlatformsTable/RTMPChannelsTable";
import { Link } from "react-router-dom";
import styles from "./PlatformsPage.module.scss";
import rollbar from "helpers/rollbar";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useAllPlatformActions } from "hooks/usePlatformActions";
import { AppDispatch } from "store/store";
import { PlatformId } from "./types";

export const PlatformsPage: React.FC = () => {
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch<AppDispatch>();
    const { isInBrowser, switcherSdk } = useSwitcherSdk(true);
    const [providers, setProviders] = useState<PlatformsTableStreamProvider[]>(
        []
    );
    const [customRtmpChannels, setCustomRtmpChannels] = useState<
        SwitcherStreamSetting[]
    >([]);
    const { disconnect, canManage } = useAllPlatformActions();

    useEffect(() => {
        async function run() {
            try {
                const response =
                    await client.streamingProviders_GetStreamingProviders();
                let providers = response.StreamingProviders;

                if (!isInBrowser && switcherSdk?.targetAspectRatio) {
                    // If in the app, filter available platforms by the target aspect ratio
                    const width = switcherSdk?.targetAspectRatio?.numer;
                    const height = switcherSdk?.targetAspectRatio?.denom;
                    providers = providers.filter((p) => {
                        return p.AspectRatios?.some(
                            (ar) => ar.Width === width && ar.Height === height
                        );
                    });
                }

                // Should we allow this provider to be managed?
                providers = providers.map(
                    (p): PlatformsTableStreamProvider => ({
                        ...p,
                        CanManage: canManage(p.Id as PlatformId)
                    })
                );

                const allChannels =
                    await client.switcherStreamSettings_GetAllSwitcherStudioSettings();
                if (isMountedRef.current) {
                    setProviders(providers);
                    setCustomRtmpChannels(
                        allChannels.filter(
                            (a) =>
                                a["user-editable"] && a["is-direct-to-provider"]
                        )
                    );
                }
            } catch (e) {
                rollbar.error("Error resetting platforms", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:reset-error"
                    })
                );
            }
        }

        if (isMountedRef.current) {
            run();
        }
    }, [dispatch, isInBrowser, isMountedRef, switcherSdk, canManage]);

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <PlatformsTable
                        platforms={providers}
                        connectText={t("platforms:connect-button")}
                        detailsText={t("buttons:manage")}
                        disconnectText={t("platforms:disconnect-button")}
                        disconnectAction={(platformId: PlatformId) =>
                            disconnect(platformId, providers, setProviders)
                        }
                    />
                    <div className={styles["custom-rtmp-section-header"]}>
                        <div>
                            <h4>{t("platforms:custom-rtmp-header")}</h4>
                            <small>
                                <p className="mb-2 text-muted">
                                    {t(`platforms:custom-rtmp-subheader`)}
                                </p>
                            </small>
                        </div>

                        <Link
                            className={`btn btn-primary mb-2 ${styles["rtmp-btn"]}`}
                            to={"/platforms/custom-rtmp"}
                        >
                            {t("platforms:custom-rtmp-setup")}
                        </Link>
                    </div>

                    <RTMPChannelsTable
                        channels={customRtmpChannels}
                        detailsText={t("buttons:manage")}
                    />
                </div>
            </div>
        </>
    );
};
