import { useEffect, useRef } from "react";

export function useIsMountedRef() {
    const isMountedRef = useRef<boolean | null>(null);
    useEffect(() => {
        isMountedRef.current = true;

        return function () {
            isMountedRef.current = false;
        };
    });
    return isMountedRef;
}
