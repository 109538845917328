import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./VideoAsset.module.scss";
import commonStyles from "../AssetCard.module.scss";
import { formatBytes } from "../utils";
import { AspectRatio, FileItem, FilePropsKey } from "./types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { Checkbox } from "components/inputs/checkbox/Checkbox";
import { updateFileItem } from "store/cloud/slice";
import { AssetTag } from "components/asset-tag/AssetTag";
import { genericVideoThumbnailAsync } from "./upload-helpers/thumbnail-helpers";
import videofileIcon from "assets/icons/videofile-icon.png";
import { AspectRatioSelector } from "components/aspect-ratio-selector/AspectRatioSelector";
import { ASPECT_RATIO_MAP } from "./upload-helpers/helpers";

interface VideoAssetProps {
    fileItem: FileItem;
    tags: string[];
}

export const VideoAsset: React.FC<VideoAssetProps> = ({ fileItem, tags }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [videoObjectUrl, setVideoObjectUrl] = useState<string>("");
    const [staticThumbnailUrl, setStaticThumbnailUrl] =
        useState<string>(videofileIcon);
    const [duration, setDuration] = useState<number>();
    const [step, setStep] = useState<number>();
    const videoRef = useRef<HTMLVideoElement>(null);
    const [selectedTag, setSelectedTag] = useState<string>("");
    const [selectedAspectRatio, setSelectedAspectRatio] = useState<string>(
        AspectRatio.horizontal
    );

    useEffect(() => {
        if (!duration) {
            genericVideoThumbnailAsync(
                fileItem.name,
                960,
                AspectRatio[selectedAspectRatio]
            ).then((d) => {
                setStaticThumbnailUrl(d.dataURL);
            });
        } else {
            setStaticThumbnailUrl("");
        }
    }, [duration, fileItem.name, selectedAspectRatio]);

    useEffect(() => {
        async function getVideoObjectUrl() {
            const url = URL.createObjectURL(fileItem.file);
            setVideoObjectUrl(url);
        }

        getVideoObjectUrl();
    }, [dispatch, fileItem.file]);

    useEffect(() => {
        if (videoObjectUrl && videoRef.current) {
            videoRef.current.onloadedmetadata = () => {
                if (videoRef.current) {
                    setDuration(videoRef.current.duration);
                    setStep(videoRef.current.duration / 100);
                }
            };

            videoRef.current.load(); // needs to be called explicitly for safari

            dispatch(
                updateFileItem({
                    name: fileItem.name,
                    videoRef: videoRef.current
                })
            );
        }
    }, [dispatch, fileItem.name, videoObjectUrl]);

    const toggle = useCallback(
        (key: FilePropsKey) => {
            dispatch(
                updateFileItem({
                    name: fileItem.name,
                    fileProps: {
                        ...fileItem.fileProps,
                        [key]: !fileItem.fileProps?.[key]
                    }
                })
            );
        },
        [dispatch, fileItem]
    );

    const updateTag = useCallback(
        (tag) => {
            dispatch(
                updateFileItem({
                    ...fileItem,
                    fileProps: {
                        ...fileItem.fileProps,
                        tag
                    }
                })
            );
            setSelectedTag(tag);
        },
        [dispatch, fileItem]
    );

    const updateAspectRatio = useCallback(
        (aspectRatio) => {
            dispatch(
                updateFileItem({
                    ...fileItem,
                    aspectRatios: [ASPECT_RATIO_MAP[aspectRatio]]
                })
            );

            setSelectedAspectRatio(aspectRatio);
        },
        [dispatch, fileItem]
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (videoRef.current) {
            videoRef.current.currentTime = parseInt(e.target.value);
        }
    };

    return (
        <>
            {fileItem && (
                <>
                    <div className={`${styles["asset-card"]} mb-2`}>
                        <div className={`${styles["asset-video"]}`}>
                            {videoObjectUrl && (
                                // TODO: Decide whether or not to mute this
                                <>
                                    <video ref={videoRef} muted={true}>
                                        <source
                                            src={videoObjectUrl}
                                            id={`${fileItem.name}-video-source`}
                                        />
                                    </video>
                                    {videoRef.current && duration && (
                                        <div
                                            className={`${styles["controls-overlay"]} pr-1 pl-1 pb-1`}
                                        >
                                            <label
                                                htmlFor="customRange1"
                                                className="mb-0"
                                            >
                                                <small>
                                                    Thumbnail selection
                                                </small>
                                            </label>
                                            <input
                                                type="range"
                                                className="custom-range"
                                                id="customRange1"
                                                defaultValue={0}
                                                onChange={onChange}
                                                min={0}
                                                max={duration}
                                                step={step}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {!duration && staticThumbnailUrl && (
                                <>
                                    <img
                                        alt="Video Thumbnail"
                                        src={staticThumbnailUrl}
                                    />
                                </>
                            )}
                        </div>
                        <div
                            className={`card-body ${commonStyles["asset-card-body"]}`}
                        >
                            <p
                                className={`${styles["asset-card-title"]} text-center mb-2`}
                            >
                                <strong>{fileItem.file.name}</strong>
                                <br></br>
                                {formatBytes(fileItem.file.size || 0)}
                            </p>
                            <Checkbox
                                label="Enable Audio"
                                id={`${fileItem.file.name}-enable-audio`}
                                checked={fileItem.fileProps?.enableAudio}
                                onChange={() => toggle("enableAudio")}
                                customClass="mb-1"
                            />
                            <Checkbox
                                label="End on Black"
                                id={`${fileItem.file.name}-end-on-black`}
                                checked={fileItem.fileProps?.endOnBlack}
                                onChange={() => toggle("endOnBlack")}
                                customClass="mb-1"
                            />
                            <Checkbox
                                label="Audio Only"
                                id={`${fileItem.file.name}-audio-only`}
                                checked={fileItem.fileProps?.audioOnly}
                                onChange={() => toggle("audioOnly")}
                                customClass="mb-1"
                            />
                            <AssetTag
                                tags={tags}
                                onChange={updateTag}
                                selectedTag={selectedTag}
                            />
                            <AspectRatioSelector
                                onChange={updateAspectRatio}
                                selectedAspectRatio={selectedAspectRatio}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
