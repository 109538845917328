import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { MessageContentState } from "../types";

interface BannerContentMessageProps extends MessageContentState {}

export const BannerContentMessage: React.FC<BannerContentMessageProps> = ({
    leftMessageProps,
    linkProps,
    rightMessage
}) => {
    const { t } = useTranslation();
    return (
        <span
            className={`${styles["message"]} ${
                linkProps.end ? styles["reverse"] : ""
            }`}
        >
            {t(leftMessageProps.key, leftMessageProps.params)} <br></br>
            <small>
                {linkProps && (
                    <Link to={linkProps.href} onClick={linkProps.onClick}>
                        {t(linkProps.text)}
                    </Link>
                )}
                {rightMessage ? t(rightMessage) : ""}
            </small>
        </span>
    );
};
