import React, { useCallback } from "react";
import { MultiSelect, Option, SelectProps } from "react-multi-select-component";

export interface OnSearchReturnType extends Option {
    [index: string]: any;
}

interface MultiSelectWrapperProps extends SelectProps {
    label?: string;
    placeholder: string;
    onSearch?: (filter: string) => Promise<OnSearchReturnType[]>;
}

export const MultiSelectWrapper: React.FC<MultiSelectWrapperProps> = (
    props
) => {
    const { label, placeholder, onSearch } = props;

    const customValueRenderer = useCallback(
        (selected) => {
            return selected.length
                ? selected.map(
                      ({ name }, i) =>
                          ` ${name}${i !== selected.length - 1 ? ", " : ""}`
                  )
                : placeholder;
        },
        [placeholder]
    );

    const filterOptions = useCallback(
        async (_options, filter) => {
            if (!filter) {
                return props.value;
            }
            const results = await onSearch(filter);
            return [...props.value, ...results] || [];
        },
        [onSearch, props.value]
    );

    return (
        <>
            {!!label && <label>{label}</label>}
            <MultiSelect
                {...props}
                valueRenderer={customValueRenderer}
                filterOptions={!!onSearch && filterOptions}
            />
        </>
    );
};
