import React, { useState, useCallback, useMemo } from "react";
import {
    StripePrice,
    ResellerInventoryItem,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { PricingToggle } from "components/inputs/toggle/PricingToggle";
import styles from "./index.module.scss";
import { SelectPlanCard } from "components/cards/SelectPlanCardsContainer/SelectPlanCard";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { ComparePlansLink } from "components/links/ComparePlansLink";

export type PlanPeriodOptions = "month" | "year";

export interface SelectPlanCardsContainerProps {
    plans: Array<StripePrice | StripePriceAnonymous>;
    loading: boolean;
    resellerInventoryItem: ResellerInventoryItem;
    onSelectPlanButtonClicked: (plan: StripePrice) => void;
    showFeatures?: boolean;
    includePasses?: boolean;
}

export const SelectPlanCardsContainer: React.FC<
    SelectPlanCardsContainerProps
> = ({
    plans,
    loading,
    resellerInventoryItem,
    onSelectPlanButtonClicked,
    showFeatures = false,
    includePasses = false
}) => {
    const user = useSelector((state: RootState) => state.user);
    const [planPeriod, setPlanPeriod] = useState<PlanPeriodOptions>("month");
    const planForCoupon = useMemo<StripePrice | undefined>(
        () =>
            plans?.filter(
                (p) =>
                    p.Id === resellerInventoryItem?.ResellerInventory?.PriceId
            )?.[0],
        [plans, resellerInventoryItem]
    );

    const _plans = useMemo(() => {
        if (plans) {
            const pass = plans.find((p) => !p.RecurringInterval);

            const p = plans
                .filter((plan) => plan.RecurringInterval === planPeriod)
                .filter((plan) =>
                    resellerInventoryItem?.ResellerInventory?.PriceId
                        ? resellerInventoryItem.ResellerInventory.PriceId ===
                          plan.Id
                        : true
                );

            p.splice(
                planPeriod === "month"
                    ? 3
                    : 2 /*accounting for lack of annual Merchant plan */,
                0,
                {
                    Id: "",
                    Name: "Enterprise"
                }
            );

            if (includePasses) {
                p.splice(4, 0, pass);
            }

            return p;
        }
    }, [includePasses, planPeriod, plans, resellerInventoryItem]);

    const onPricingToggle = useCallback(() => {
        if (planPeriod === "month") {
            setPlanPeriod("year");
        } else {
            setPlanPeriod("month");
        }
    }, [planPeriod]);

    if (loading) {
        return <p>loading</p>;
    }

    if (planForCoupon) {
        return (
            <div className={styles["cards"]}>
                <SelectPlanCard
                    plan={planForCoupon}
                    planPeriod={planPeriod}
                    resellerInventoryItem={resellerInventoryItem}
                    onSelectPlanButtonClicked={onSelectPlanButtonClicked}
                    showFeatures={showFeatures}
                />
            </div>
        );
    }

    return (
        <div className={styles["container"]}>
            <PricingToggle
                toggle={planPeriod === "year"}
                onToggle={onPricingToggle}
            />
            <div className={styles["cards"]}>
                {_plans?.map((plan, key) => (
                    <SelectPlanCard
                        plan={plan}
                        planPeriod={planPeriod}
                        resellerInventoryItem={resellerInventoryItem}
                        onSelectPlanButtonClicked={onSelectPlanButtonClicked}
                        isCurrentPlan={
                            plan && plan.Id === user?.ticket?.activePlan
                        }
                        showCurrentPlanFlag
                        showFeatures={showFeatures}
                        showBestValueFlag
                        key={key}
                    />
                ))}
            </div>

            <ComparePlansLink />
        </div>
    );
};
