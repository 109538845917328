import React from "react";
import styles from "../index.module.scss";
import { useTranslation } from "react-i18next";
import { VideoPlayerResponse } from "@switcherstudio/switcher-api-client";
import { GatedContentModalCallbacks } from "views/page-content/gated-content/PricingDetails/GatedContentTable";
import { AttentionModalCell } from "./AttentionModalCell";

interface AttentionModalTableProps extends GatedContentModalCallbacks {
    players: VideoPlayerResponse[];
}

export const AttentionModalTable: React.FC<AttentionModalTableProps> = ({
    players,
    updateModal
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={`table-responsive ${styles["attn-table"]}`}>
                <table className={`table ${styles["price-table"]}`}>
                    <thead className={`small`}>
                        <tr>
                            <th>{t("gated-content-page:pass")}</th>
                            <th>{t("gated-content-page:player-init-caps")}</th>
                            <th>{t("gated-content-page:price")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {players?.map((player, key) => (
                            <AttentionModalCell
                                player={player}
                                updateModal={updateModal}
                                key={key}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <hr className={styles["attn-hr"]} />
        </>
    );
};
