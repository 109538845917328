import { PropsWithChildren } from "react";
import { StripeElement } from "./StripeElement";
import { useSetupIntent } from "hooks/useSetupIntent";
import { SetupIntent } from "@stripe/stripe-js";
import { RedirectCallbackArguments } from "hooks/useBillingRedirect";

export interface SetupIntentElementProps extends PropsWithChildren {
    onRedirect?: (
        setupIntent: SetupIntent,
        args: RedirectCallbackArguments
    ) => void;
}

export const SetupIntentElement = ({
    children,
    onRedirect
}: SetupIntentElementProps) => {
    const { setupIntent } = useSetupIntent({
        onRedirect
    });

    if (setupIntent) {
        return (
            <StripeElement
                options={{
                    clientSecret: setupIntent.client_secret
                }}
            >
                {children}
            </StripeElement>
        );
    }
};
