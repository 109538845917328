import { useEffect, useState } from "react";

import { client } from "api/client";
import { Reseller } from "@switcherstudio/switcher-api-client";
import { ResellerInventory } from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";

export function useGetResellerInventories(
    reseller: Reseller
): ResellerInventory[] {
    const [inventories, setInventories] = useState<ResellerInventory[]>();

    useEffect(() => {
        const getInventories = async () => {
            try {
                if (reseller?.Id) {
                    const inventoriesResponse =
                        await client.resellerInventories_GetResellerInventories(
                            reseller.Id
                        );
                    setInventories(
                        inventoriesResponse.sort((a, b) => {
                            if (!a.ValidUntil) {
                                return -1;
                            } else {
                                return (
                                    new Date(b.ValidUntil).getTime() -
                                    new Date(a.ValidUntil).getTime()
                                );
                            }
                        })
                    );
                }
            } catch (e) {
                rollbar.error("Error getting reseller inventories", e);
            }
        };
        getInventories();
    }, [reseller]);

    return inventories;
}
