import store from "store/store";

/**
 * Checks if the current user has a specific claim.
 * DEVNOTE: This function should only be used when outside of a component, such as thunks or or other functional helpers.
 * @param claim - The claim to check for.
 * @returns True if the user has the claim, false otherwise.
 */
export const userHasClaim = function (claim: string) {
    const { user } = store.getState();

    return user?.userInfo?.FeatureClaims?.indexOf(claim) !== -1;
};
