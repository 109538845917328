import React, { useState, useCallback } from "react";
import { CustomRTMPChannelForm } from "./CustomRTMPChannelForm";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { client } from "api/client";

interface AddCustomRTMPChannelPageProps {
    finalUrl?: string;
}
export const AddCustomRTMPChannelPage: React.FC<
    AddCustomRTMPChannelPageProps
> = ({ finalUrl }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [channel, setChannel] = useState<SwitcherStreamSetting>({
        "audio-bit-rate": null,
        "audio-channel-count": null,
        "audio-sample-rate": null,
        "rtmp-stream": "",
        url: "",
        version: "1.0",
        "video-bit-rate": null,
        "video-frame-height": 360,
        "video-frame-width": 640,
        "user-editable": true,
        "channel-name": "My New Channel",
        "is-deleted": false
    });

    const onChange = useCallback((update: { [key: string]: any }) => {
        setChannel((prev) => {
            return {
                ...prev,
                ...update
            };
        });
    }, []);

    const createChannel = useCallback(async () => {
        const regx = /^(rtmp|rtmps):\/\/[^ "]+$/i;
        const isRtmpUrl = regx.test(channel.url);

        if (!isRtmpUrl) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("platforms:invalid-rtmp-error")
                })
            );
            return;
        }

        try {
            await client.switcherStreamSettings_PostSwitcherStreamSetting(
                channel
            );
            navigate(finalUrl || "/platforms");
        } catch (err) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("platforms:channel-settings-error")
                })
            );
        }
    }, [channel, dispatch, navigate, t, finalUrl]);

    return (
        <>
            <CustomRTMPChannelForm
                btnText={t("buttons:save")}
                setting={channel}
                onChange={onChange}
                onPrimaryClick={createChannel}
                showFullHD={true}
            />
        </>
    );
};
