import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    VideoPlayerWebLink,
    BroadcastWebLink
} from "@switcherstudio/switcher-api-client";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable
} from "react-beautiful-dnd";
import { WebLinkCard } from "components/cards/WebLinkCard";
import { Errors } from "hooks/useForm";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { isNullOrWhitespace } from "helpers/strings";

type WebLink = VideoPlayerWebLink | BroadcastWebLink;
interface WebLinksFormProps {
    links: WebLink[];
    setLinks: (links: WebLink[]) => void;
    setIsValid?: (isValid: boolean) => void;
    videoPlayerId?: string;
    broadcastId?: string;
}

export const WebLinksForm = ({
    links,
    setLinks,
    setIsValid,
    videoPlayerId,
    broadcastId
}: WebLinksFormProps) => {
    const { t } = useTranslation("video-player-page");
    const [errors, setErrors] = useState<Errors>({});
    const [localLinks, setLocalLinks] = useState<WebLink[]>(links);

    function validateWebLinks(weblinks: WebLink[]): Errors {
        let errors: Errors = {};
        const urlRegExp =
            /^(?:https?:\/\/)?(?:www\.)?((?:[\w-]+\.)*\w[\w-]{0,61}\w)\.(\w{2,})(?::(\d{1,5}))?(?:(\/|\?)[^\s]*)?$/i;
        weblinks?.forEach((value, index) => {
            /** if the user hasn't entered anything, don't throw error, but don't allow save */
            if (!value.Title && !value.Link) {
                errors[`silentEmptyStringError_${index}`] = "silentError";
                return;
            }
            if (isNullOrWhitespace(value.Title)) {
                errors[`webLinksTitle_${index}`] = t(
                    "errors:brand-profile-title-error"
                );
            }
            if (!value.Link || !urlRegExp.test(value.Link)) {
                errors[`webLinks_${index}`] = t(
                    "errors:brand-profile-url-error"
                );
            }
        });

        return errors;
    }

    useEffect(() => {
        const errors = validateWebLinks(localLinks);
        setErrors(errors);
        const isValid = Object.keys(errors).length === 0;
        setIsValid && setIsValid(isValid);
        if (isValid) {
            setLinks(localLinks);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localLinks]);

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return; // Dragged outside the droppable area

            const updatedLinks = Array.from(localLinks);
            const [reorderedLink] = updatedLinks.splice(result.source.index, 1);
            updatedLinks.splice(result.destination.index, 0, reorderedLink);

            setLocalLinks(updatedLinks);
        },
        [localLinks, setLocalLinks]
    );

    const addNewLink = () => {
        const newLink: WebLink = {
            Id: null,
            VideoPlayerId: videoPlayerId ?? null, // this is for the InteractiveLinksPage
            BroadcastId: broadcastId ?? null, // this is for the LinkSelectModal
            Link: "",
            Title: "",
            PurchaseRequired: false
        };

        const updatedLinks =
            localLinks && localLinks.length > 0
                ? [...localLinks, newLink]
                : [newLink];
        setLocalLinks(updatedLinks);
    };

    const removeLink = (index: number) => {
        setLocalLinks(localLinks?.filter((_, i) => i !== index));
    };

    return (
        <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="link-cards">
                    {(droppableProvided, snapshot) => (
                        <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            {localLinks?.map((link, index) => (
                                <Draggable
                                    draggableId={
                                        link?.Id ?? (index + 1).toString()
                                    }
                                    key={link?.Id ?? (index + 1).toString()}
                                    index={index}
                                >
                                    {(draggableProvided) => (
                                        <div
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            ref={draggableProvided.innerRef}
                                        >
                                            <WebLinkCard
                                                link={link}
                                                deleteLink={() =>
                                                    removeLink(index)
                                                }
                                                onLinkChange={(updatedLink) =>
                                                    setLocalLinks(
                                                        localLinks.map(
                                                            (link, i) =>
                                                                i === index
                                                                    ? updatedLink
                                                                    : link
                                                        )
                                                    )
                                                }
                                                errors={errors}
                                                index={index}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            <div
                                className={
                                    snapshot.isDraggingOver
                                        ? styles["placeholder-div"]
                                        : ""
                                }
                            >
                                {droppableProvided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Button type="action" onClick={addNewLink}>
                {t("add-new")}
            </Button>
            {errors.api && (
                <div className="alert alert-danger" role="alert">
                    {errors?.api}
                </div>
            )}
        </div>
    );
};
