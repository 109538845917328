import React from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useForm, Errors } from "hooks/useForm";
import { EditProfileFormValues, Profile } from "./types";
import { useTranslation } from "react-i18next";
import styles from "./ProfileForms.module.scss";
import ProfileIcon from "assets/icons/username.svg?react";
import { PhoneNumberInput } from "components/inputs/text-input/PhoneNumberInput";
import { tryGetProp } from "utils/js-utils";

interface EditProfileFormProps {
    onSubmit: (payload: EditProfileFormValues) => Promise<void>;
    profile: Profile;
}

export const EditProfileForm: React.FC<EditProfileFormProps> = ({
    onSubmit,
    profile
}: EditProfileFormProps) => {
    const { t } = useTranslation();

    const { values, errors, isSubmitting, handleChange, handleSubmit } =
        useForm<EditProfileFormValues>(
            {
                email: tryGetProp(profile, "Email", ""),
                firstName: tryGetProp(profile, "FirstName", ""),
                lastName: tryGetProp(profile, "LastName", ""),
                phoneNumber: tryGetProp(profile, "PhoneNumber", ""),
                company: tryGetProp(profile, "Company", ""),
                title: tryGetProp(profile, "Title", ""),
                website: tryGetProp(profile, "WebsiteUrl", "")
            },
            onSubmit,
            (values: EditProfileFormValues) => {
                let errors: Errors = {};

                if (!values.email) {
                    errors.email = t("forgot-password:enter-email");
                } else if (!/\S+@\S+\.\S+/.test(values.email)) {
                    errors.email = t("errors:invalid-email");
                }

                return errors;
            },
            false
        );

    return (
        <>
            <div className={styles["form-card"]}>
                <span className={styles["icon"]}>
                    <ProfileIcon />
                </span>
                <div className={styles["form-card-body"]}>
                    <h4 className={styles["form-card-title"]}>
                        {t("page-titles:profile")}
                    </h4>
                    <form onSubmit={handleSubmit}>
                        <TextInput
                            id="email"
                            type="email"
                            label={t("create-account:email-address")}
                            placeholder={t("forgot-password:enter-email")}
                            value={values.email}
                            error={errors.email}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="firstName"
                            type="text"
                            label={t("create-account:first-name")}
                            placeholder={t("profile:first-name-placeholder")}
                            value={values.firstName}
                            error={errors.firstName}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="lastName"
                            type="text"
                            label={t("create-account:last-name")}
                            placeholder={t("profile:last-name-placeholder")}
                            value={values.lastName}
                            error={errors.lastName}
                            onChange={handleChange}
                        />
                        <PhoneNumberInput
                            id="phoneNumber"
                            label={t("create-account:phone-number")}
                            placeholder={t("profile:phone-number-placeholder")}
                            value={values.phoneNumber}
                            error={errors.phoneNumber}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="company"
                            type="text"
                            label={t("profile:company")}
                            placeholder={t("profile:company-placeholder")}
                            value={values.company}
                            error={errors.company}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="title"
                            type="text"
                            label={t("profile:title")}
                            placeholder={t("profile:title-placeholder")}
                            value={values.title}
                            error={errors.title}
                            onChange={handleChange}
                        />
                        <TextInput
                            id="website"
                            type="text"
                            label={t("profile:website")}
                            placeholder={t("profile:website-placeholder")}
                            value={values.website}
                            error={errors.website}
                            onChange={handleChange}
                        />
                        {errors.api && (
                            <div className="alert alert-danger" role="alert">
                                {errors.api}
                            </div>
                        )}
                        <button
                            type="submit"
                            className={`btn btn-primary ${styles["submit-btn"]}`}
                            disabled={isSubmitting}
                        >
                            {t("buttons:update-profile").toUpperCase()}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};
