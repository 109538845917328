import { useCreateBroadcastForUpload } from "components/video-upload/hooks/useCreateBroadcastForUpload";
import { useGetAggregateUploadProgress } from "components/video-upload/hooks/useGetAggregateUploadProgress";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modals } from "store/modal/types";
import { MinimizedModalTab } from "..";

export const VideoUploadMinimizedModalTab = ({
    sessionId
}: {
    sessionId: string;
}) => {
    const { t } = useTranslation();
    const { aggregateUploadPercentage, totalInProgressUploads } =
        useGetAggregateUploadProgress({ sessionId });

    useCreateBroadcastForUpload({ sessionId });

    return (
        <MinimizedModalTab
            currentModal={{
                id: sessionId,
                type: Modals.TriggeredVideoUploadModal
            }}
            statusText={t("video-upload:uploading-status-text", {
                count: totalInProgressUploads
            })}
            successText={t("video-upload:uploads-complete")}
            statusPercent={aggregateUploadPercentage}
        />
    );
};
