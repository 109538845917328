import React, { useMemo, PropsWithChildren } from "react";
import styles from "./index.module.scss";
import { Recording } from "./types";
import { Broadcast } from "@switcherstudio/switcher-api-client";
import CalendarIcon from "assets/icons/date.svg?react";
import { ProviderIcon } from "./ProviderIcon";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { VideoBadges } from "components/badges/VideoBadges";
import { shorten } from "helpers/strings";

interface RecordingCardProps extends PropsWithChildren {
    recording: Recording;
    broadcast?: Broadcast;
    children?: React.ReactNode;
    selected?: boolean;
    duration?: string;
}

export const RecordingCard: React.FC<RecordingCardProps> = ({
    recording,
    broadcast,
    children,
    selected,
    duration
}: RecordingCardProps) => {
    const { t, i18n } = useTranslation();
    const timeSince = useMemo(() => {
        const createdAt = recording.created;
        const locale = i18n.language;
        return dayjs(new Date(createdAt)).locale(locale).fromNow();
    }, [recording.created, i18n.language]);

    return (
        <div
            key={recording.id}
            className={`${styles["recording-card"]} ${
                selected ? styles["selected-card"] : ""
            }`}
        >
            <ProviderIcon selected={selected} provider={recording.provider} />
            <div id={recording.id} className={styles["recording"]}>
                {recording.provider !== "cloud" || recording.readyToStream ? (
                    <iframe
                        title={recording.title}
                        src={recording.embedUrl}
                        scrolling="no"
                        frameBorder={0}
                        // eslint-disable-next-line react/no-unknown-property
                        allowTransparency={true}
                        allowFullScreen={true}
                    ></iframe>
                ) : (
                    <div className={styles["processing"]}>
                        {t("video-upload:video-processing")}
                    </div>
                )}
            </div>
            <div className={styles["recording-card-body"]}>
                <p className={styles["recording-card-body-content"]}>
                    <strong>
                        {shorten(recording.title || t("misc:no-title"), 50)}
                    </strong>
                </p>
                <p
                    className={`${styles["recording-card-body-content"]} ${styles["datetime-subtitle"]}`}
                >
                    <CalendarIcon />
                    {duration
                        ? ` ${timeSince}   •   ${duration}`
                        : ` ${timeSince} `}
                </p>
                <VideoBadges
                    broadcast={broadcast}
                    players={recording?.players}
                />
            </div>
            <div className={styles["button-container"]}>{children}</div>
        </div>
    );
};
