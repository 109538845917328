import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { updateQuality } from "store/platforms/slice";
import { useTranslation } from "react-i18next";
import styles from "./QualitySelector.module.scss";
import { Select } from "components/inputs/select/Select";

export const QualitySelector: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { general } = useSelector((state: RootState) => state.platforms);
    const { generalForm, availableQualities } = general;
    const qualityPills = availableQualities.map((q) => q.name);

    const onChange = useCallback(
        (quality: string) => {
            const _quality = availableQualities.find((q) => q.name === quality);
            dispatch(updateQuality(_quality));
        },
        [availableQualities, dispatch]
    );

    return (
        <div className="form-group">
            <label>{t("platforms:stream-quality")}</label>
            <Select
                id="stream-quality-selector"
                containerClassName={styles["quality-selector-dropdown"]}
                onChange={(q) => onChange(q.target.value)}
                options={qualityPills}
                selected={generalForm?.selectedQuality?.name}
            ></Select>
            <small>
                <p className={`${styles["quality-selector-warning"]}`}>
                    {t("platforms:stream-quality-warning")}
                </p>
            </small>
        </div>
    );
};
