import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import styles from "./index.module.scss";
import useClickOutside from "hooks/useClickOutside";
import { AppDispatch } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { searchTwitchCategories } from "store/platforms/thunks";
import { RootState } from "store/reducers";
import { updateTwitchForm } from "store/platforms/slice";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { TwitchCategory } from "store/platforms/types";

interface CategorySearchProps {
    initialSearch?: string;
}

export const CategorySearch: React.FC<CategorySearchProps> = ({
    initialSearch
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { games, twitchForm } = useSelector(
        (state: RootState) => state.platforms.twitch
    );

    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [skipSearch, setSkipSearch] = useState(true);
    const [gameWasSelcted, setGameWasSelected] = useState(false);
    const modalRef = useRef(null);
    useClickOutside(modalRef, () => setIsOpen(false));
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const selectGame = useCallback(
        (game: TwitchCategory) => {
            dispatch(
                updateTwitchForm({
                    ...twitchForm,
                    selectedGame: game ? game.id : null
                })
            );
            setSearchTerm(!!game ? game.name : "");
            setGameWasSelected(true);
            setIsOpen(false);
        },
        [dispatch, twitchForm]
    );

    useEffect(() => {
        setSearchTerm(initialSearch);
        setSkipSearch(true);
    }, [initialSearch]);

    const clearSearch = useCallback(() => {
        selectGame(null);
    }, [selectGame]);

    const searchCategories = useCallback(
        async (_searchTerm) => {
            await dispatch(searchTwitchCategories(_searchTerm));
        },
        [dispatch]
    );

    useEffect(() => {
        async function search() {
            if (!debouncedSearchTerm) return;
            if (
                debouncedSearchTerm.length >= 2 ||
                debouncedSearchTerm.length === 0
            )
                await searchCategories(debouncedSearchTerm);
            setIsOpen(true);
        }
        if (!gameWasSelcted) search();
        // eslint-disable-next-line
    }, [debouncedSearchTerm, searchCategories]);

    async function handleChange(event) {
        setGameWasSelected(false);
        setSearchTerm(event.target.value);
        setSkipSearch(false);
    }

    return (
        <>
            <div className={styles["search-container"]}>
                <TextInput
                    id="catSearch"
                    type="text"
                    value={searchTerm}
                    label={t("platforms:search-label")}
                    placeholder={t("platforms:search-placeholder")}
                    onChange={handleChange}
                />
                {!!searchTerm ? (
                    <button
                        type="button"
                        className="close"
                        aria-label={t("buttons:close")}
                        onClick={clearSearch}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                ) : null}
            </div>
            {isOpen && !skipSearch ? (
                <div className={styles["results-container"]}>
                    <ul className={styles["result-list"]} ref={modalRef}>
                        {games.map((g, i) => (
                            <li
                                key={"twitch-cat-" + i}
                                onClick={() => selectGame(g)}
                            >
                                <img
                                    src={g.box_art_url}
                                    alt={"box art for " + g.name}
                                />
                                <span>{g.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </>
    );
};
