import { trackEvent } from "./analyticsHelpers";

export const openExternalUrlInNewTab = async (
    url: string,
    description?: string
) => {
    if (description) await trackEvent(description);

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};
