import React from "react";
import styles from "./index.module.scss";
import { TutorialVideo } from "../video/TutorialVideo";
import { TutorialVideoType } from "../video/TutorialVideo/types";

interface TutorialProps {
    video: TutorialVideoType;
    isMobile?: boolean;
}

export const Tutorial: React.FC<TutorialProps> = ({
    video,
    isMobile
}: TutorialProps) => {
    return (
        <div
            key={video.id}
            className={`${styles["tutorial-card"]} ${
                isMobile ? styles["mobile"] : ""
            }`}
        >
            <TutorialVideo video={video} />
            <div className={styles["tutorial-card-body"]}>
                <p className={styles["tutorial-card-title"]}>{video.name}</p>
            </div>
        </div>
    );
};
