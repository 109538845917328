import React, { useCallback, useMemo } from "react";
import { NotificationPopover } from "../NotificationPopover/NotificationPopover";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import {
    removeNotification,
    resetNotifications
} from "store/notification/slice";
import { Notification } from "store/notification/types";
import { useOnPageChange } from "hooks/useOnPageChange";

interface NotificationContainerProps {
    /** A list of notifications. */
    notifications?: Notification[];
    /** A value which determines if notifications should be shown. */
    visible?: boolean;
    /** A value which determines the notification container's position attribute. */
    fixed?: boolean;
    /** A handler function attached to each of the notification popovers' close buttons. */
    close?: (id: number) => void;
    /** A handler to clear notifications. Notifications accessed by state are cleared internally. */
    clear?: () => void;
}

/** A container element for notification popovers. */
export const NotificationContainer: React.FC<NotificationContainerProps> = ({
    notifications = [],
    fixed = false,
    clear,
    close
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const { notifications: notificationState } = useSelector(
        (s: RootState) => s.notification
    );

    const _notifications = useMemo<Notification[]>(
        () => notificationState.concat(notifications),
        [notificationState, notifications]
    );

    const _visible = useMemo<boolean>(
        () => _notifications.length > 0,
        [_notifications]
    );

    const _close = useCallback(
        (id: number) => (close ? close(id) : dispatch(removeNotification(id))),
        [close, dispatch]
    );

    useOnPageChange(() => {
        clear && clear();
        dispatch(resetNotifications());
    });

    return (
        <div
            className={`${styles["notification-container"]} ${
                fixed ? styles["fixed"] : ""
            } ${_visible ? styles["show"] : ""}`}
        >
            {_notifications?.map((n) => {
                return (
                    <div key={n.id} className="col-12">
                        <NotificationPopover notification={n} close={_close} />
                    </div>
                );
            })}
        </div>
    );
};
