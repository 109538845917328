import i18n from "i18next";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfirmationState, Confirmation } from "./types";

export const confirmation = createSlice({
    name: "confirmation",
    initialState: {
        confirmationOpen: false,
        message: ``,
        cancelText: "Cancel",
        confirmText: "OK",
        onSuccess: Function,
        htmlMessage: false
    } as ConfirmationState,
    reducers: {
        openConfirmation: (state, { payload }: PayloadAction<Confirmation>) => {
            state.message =
                payload.rawMessage || payload.htmlMessage
                    ? payload.message
                    : i18n.t(
                          payload.message,
                          payload.messageOptions ? payload.messageOptions : null
                      );
            state.confirmationOpen = true;
            state.cancelText = payload.cancelText ?? state.cancelText;
            state.confirmText = payload.confirmText ?? state.confirmText;
            state.onSuccess = payload.onSuccess;
            state.htmlMessage = payload.htmlMessage;
        },
        closeConfirmation: (state) => {
            state.confirmationOpen = false;
            state.message = "";
        }
    }
});
export const { openConfirmation, closeConfirmation } = confirmation.actions;

export default confirmation.reducer;
