import {
    IChannelUpdateOptions,
    IFetchOptions,
    TwitchApiResponse
} from "./types";
import store from "store/store";
import { setLoading } from "store/loading/slice";
import {
    TwitchChannelInfo,
    TwitchCategory,
    TwitchUser,
    TwitchStreamKey
} from "store/platforms/types";

const twitchClientId = import.meta.env.VITE_TWITCH_CLIENT_ID;
const baseUrl = `${
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net"
}/api/streamingproviders/twitch/proxy`;

function parseFields(options) {
    let fieldsArray: string[] = [];
    for (let i in options.fields) {
        fieldsArray.push(`${i}=${encodeURIComponent(options.fields[i])}`);
    }
    return fieldsArray.join("&");
}

const th = () => (options: IFetchOptions) => {
    const state = store.getState();
    store.dispatch(setLoading(1));
    const version = options.version || "helix";
    const url = `${baseUrl}/${version}/${options.service}${
        options.resource ? "/" + options.resource : ""
    }?${parseFields(options)}`;
    const req = fetch(url, {
        method: options.method || "GET",
        body: JSON.stringify(options.body) || null,
        headers: {
            ...(options.headers || {}),
            "Client-ID": twitchClientId,
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.user.ticket.access_token}`
        }
    })
        .then(async (response) => {
            const body = await handleResponse(response);

            if (response.status >= 400) {
                throw new Error(body?.error?.message);
            }
            return body;
        })
        .catch((e) => {
            throw e;
        })
        .finally(() => store.dispatch(setLoading(-1)));

    return req;
};

async function handleResponse(response) {
    const text = await response.text();
    try {
        let result = null;
        result = text === "" ? null : JSON.parse(text);
        return result;
    } catch (e) {
        throw new Error("Invalid JSON");
    }
}

export class TwitchClient {
    th: <T>(options: IFetchOptions) => Promise<T>;

    constructor() {
        this.th = th();
    }

    getUser(): Promise<TwitchUser> {
        return this.th<TwitchApiResponse<TwitchUser>>({
            service: "users"
        }).then((res) => res.data?.[0]);
    }

    getStreamKey(channelId: string): Promise<TwitchStreamKey> {
        return this.th<TwitchApiResponse<TwitchStreamKey>>({
            service: "streams",
            resource: "key",
            fields: {
                broadcaster_id: channelId
            }
        }).then((res) => res.data?.[0]);
    }

    getChannelInfo(channelId: string): Promise<TwitchChannelInfo> {
        return this.th<TwitchApiResponse<TwitchChannelInfo>>({
            service: "channels",
            fields: {
                broadcaster_id: channelId
            }
        }).then((res) => res.data?.[0]);
    }

    modifyChannelInfo(channelId: string, options: IChannelUpdateOptions) {
        return this.th<void>({
            method: "PATCH",
            service: "channels",
            fields: {
                broadcaster_id: channelId
            },
            body: options
        });
    }

    searchCategories(query: string): Promise<TwitchCategory[]> {
        return this.th<TwitchApiResponse<TwitchCategory>>({
            service: "search",
            resource: "categories",
            fields: {
                query: query
            }
        }).then((res) => res.data);
    }
}

export const twitchClient = new TwitchClient();
