import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState
} from "react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { exists } from "helpers/booleans";
import {
    CatalogRequest,
    CatalogResponse
} from "@switcherstudio/switcher-api-client";
import { ThumbnailUploader } from "components/video-upload/components/thumbnail-uploader";
import { AspectRatio } from "views/page-content/cloud/upload/types";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { useCatalogData } from "hooks/useCatalogData";
import { RootState } from "store/reducers";
import { isNullOrWhitespace } from "helpers/strings";
import { TextInput } from "components/inputs/text-input/TextInput";

export interface EditCatalogModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: () => void;
}

export const EditCatalogModal = ({
    isOpen,
    setIsOpen,
    onSubmit
}: EditCatalogModalProps) => {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const {
        catalogData: catalog,
        updateCatalog,
        loading
    } = useCatalogData({
        projectId: userInfo?.ProjectId
    });
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();

    useEffect(() => {
        if (!!catalog) {
            setName(catalog?.Details?.Title);
            setDescription(catalog?.Details?.Description ?? "");
            setThumbnailImageURL(catalog?.Thumbnail?.Url ?? "");
        }
    }, [catalog]);

    const { t } = useTranslation();

    const [thumbnailFile, setThumbnailFile] = useState<File>();
    const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false);
    const [thumbnailImageURL, setThumbnailImageURL] = useState<string>(
        catalog?.Thumbnail?.Url
    );

    const { handleUpdateThumbnail, handleDeleteThumbnail } = useThumbnailUpload(
        thumbnailFile,
        thumbnailImageURL
    );

    const createCatalogRequest = useCallback(
        async (catalog: CatalogResponse) => {
            const apiObject = {
                Catalog: {
                    ...catalog?.Details,
                    Description:
                        exists(description) && !isNullOrWhitespace(description)
                            ? description
                            : null,
                    Title:
                        exists(name) && !isNullOrWhitespace(name)
                            ? name
                            : "Video Catalog"
                }
            } as unknown as CatalogRequest;

            // Update the thumbnail if it has changed
            if (thumbnailFile) {
                const thumbnail = await handleUpdateThumbnail(catalog?.Details);
                apiObject.Catalog.ThumbnailAssetId = thumbnail?.Id;
            } else if (thumbnailRemoved) {
                handleDeleteThumbnail(catalog?.Details?.ThumbnailAssetId);
                apiObject.Catalog.ThumbnailAssetId = undefined;
            }

            return apiObject;
        },
        [
            name,
            description,
            thumbnailFile,
            handleUpdateThumbnail,
            thumbnailRemoved,
            handleDeleteThumbnail
        ]
    );

    const [nameError, setNameError] = useState<string>();
    const handleSubmit = useCallback(async () => {
        if (!name?.length || isNullOrWhitespace(name)) {
            setNameError(t("catalog-page:name-length-error"));
            return;
        }

        setNameError(undefined);
        const newcatalog = await createCatalogRequest(catalog);

        updateCatalog([catalog?.Details?.Id, newcatalog]).then(onSubmit);
        setIsOpen(false);
    }, [
        name,
        createCatalogRequest,
        t,
        catalog,
        updateCatalog,
        setIsOpen,
        onSubmit
    ]);

    return (
        <ModalBase
            preventDismiss={loading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            stylesOverride={{
                padding: "0",
                height: "auto",
                maxWidth: "700px"
            }}
        >
            <>
                <div className={styles["header"]}>
                    {t("buttons:catalog-details")}

                    <button
                        className={styles["close-btn"]}
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div className={styles["content"]}>
                    <div className={styles["label-row"]}>
                        <label htmlFor="name">{t("switcher-cloud:name")}</label>

                        <small>{`${name?.length ?? 0} / 50`}</small>
                    </div>
                    <TextInput
                        id="name"
                        type="text"
                        value={name}
                        maxLength={50}
                        onChange={(e) => {
                            setName(e?.currentTarget?.value);
                        }}
                        error={nameError}
                    />
                    <div className={styles["label-row"]}>
                        <label htmlFor="description">
                            {t("video-upload:description")}
                        </label>

                        <small>{`${description?.length ?? 0} / 500`}</small>
                    </div>
                    <textarea
                        id="description"
                        className={`${styles["description-input"]} form-control`}
                        value={description}
                        maxLength={500}
                        onChange={(e) =>
                            setDescription(e?.currentTarget?.value)
                        }
                    />
                    <div className={styles["label-row"]}>
                        <label htmlFor="thumbnail-upload">
                            Share Thumbnail
                        </label>
                    </div>
                    <ThumbnailUploader
                        setThumbnailFile={setThumbnailFile}
                        thumbnailImageURL={thumbnailImageURL}
                        setThumbnailImageURL={setThumbnailImageURL}
                        onRemove={() => setThumbnailRemoved(true)}
                        orientation={AspectRatio.horizontal}
                        additionalMessage={t(
                            "inputs:thumbnail-upload:catalog-sharing-thumbnail"
                        )}
                    />
                </div>

                <div className={styles["footer"]}>
                    <button
                        className="btn btn-text"
                        onClick={() => setIsOpen(false)}
                    >
                        {t("buttons:cancel")}
                    </button>

                    <button className="btn btn-primary" onClick={handleSubmit}>
                        {t("buttons:save")}
                    </button>
                </div>
            </>
        </ModalBase>
    );
};
