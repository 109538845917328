import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { GatedContentModalCallbacks } from "views/page-content/gated-content/PricingDetails/GatedContentTable";
import CloseIcon from "assets/icons/close.svg?react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";

interface AttentionModalProps extends GatedContentModalCallbacks {
    isOpen: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    onConfirm?: () => void;
    /** callback function to handle actions following cancelation of modal */
    handleCancel?: () => void;
    handleContinue?: () => void;
    children: React.ReactNode;
    hasContinueButton?: boolean;
    continueText?: string;
}

export const AttentionModal: React.FC<AttentionModalProps> = ({
    isOpen,
    setIsOpen,
    handleCancel,
    handleContinue,
    children,
    hasContinueButton = true,
    continueText
}: AttentionModalProps) => {
    const { t } = useTranslation();

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onDismiss={handleCancel}
            stylesOverride={{
                padding: "0",
                height: "auto",
                maxWidth: "700px"
            }}
        >
            <>
                <div className={`${styles["attn-header"]}`}>
                    <h5>{t("gated-content-page:attention")}</h5>
                    <button
                        type="button"
                        className={`btn ${styles["button"]}`}
                        onClick={handleCancel}
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div className={`${styles["content"]} ${styles["undo-mb"]}`}>
                    {children}
                </div>

                <div className={styles["footer"]}>
                    <button
                        type="button"
                        className={`btn btn-text`}
                        onClick={handleCancel}
                    >
                        {t("buttons:cancel")}
                    </button>
                    {hasContinueButton && (
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleContinue}
                        >
                            {continueText ?? t("buttons:continue")}
                        </button>
                    )}
                </div>
            </>
        </ModalBase>
    );
};
