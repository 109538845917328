import React from "react";
import Uppy from "@uppy/core";
import { StatusBar } from "@uppy/react";
import { MutableRefObject } from "react";
import { UploadedFile } from "store/videoUploadSession/types";
import styles from "../index.module.scss";
import { exists } from "helpers/booleans";

export interface IsUploadingStepProps {
    initialUppy: MutableRefObject<Uppy>;
    files: UploadedFile[];
}

export const IsUploadingStep: React.FC<IsUploadingStepProps> = ({
    initialUppy,
    files
}) => {
    if (!files.length) return <></>;

    return (
        <div className={`${styles["upload-step-3"]}`}>
            <div className={`${styles["upload-step-body"]}`}>
                {files.map((file) => (
                    <div
                        className={`${styles["selected-file-container"]}`}
                        key={file.fileId}
                    >
                        <div className={`${styles["selected-file"]}`}>
                            <div className={`${styles["file-name"]}`}>
                                {file.title}
                            </div>
                            <div className={`${styles["file-size"]}`}>
                                {exists(file?.file?.size)
                                    ? `${Math.round(file.file?.size / 1000 / 1000)} MB`
                                    : ""}
                            </div>
                        </div>
                        <StatusBar
                            id={file.fileId}
                            uppy={file.uppyInstance ?? initialUppy.current}
                            hideUploadButton={true}
                            hideAfterFinish={false}
                            showProgressDetails={true}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
