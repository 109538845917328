import { useMemo } from "react";
import { getUTCDate, setToEndOfDay, setToStartOfDay } from "helpers/time";
import {
    DateRangeSelector,
    DateRangeSelectorProps
} from "components/inputs/select/DateRangeSelector";

/*

This enum is for reference only.
Local enums with select options should be defined 
in the component that uses this hook and may only 
contain a subset of these options.

enum AllPossibleDateRangeOptions {
    SevenDays = "sevenDays",
    ThirtyDays = "thirtyDays",
    MonthToDate = "monthToDate",
    YearToDate = "yearToDate",
    TwelveMonths = "twelveMonths",
    Custom = "custom"
}

*/

export const useDateRangeSelector = ({
    id,
    label,
    selection,
    options,
    onChange,
    onStartDateChange,
    onEndDateChange,
    datePickerProps,
    customStartDate,
    customEndDate
}: DateRangeSelectorProps) => {
    const { start, end } = useMemo(() => {
        const result: {
            start: string | null;
            end: string | null;
        } = {
            start: null,
            end: null
        };

        // Define quick factories for getting fresh dates (since dates are mutable)
        const now = () => getUTCDate();
        const today = () => new Date(setToStartOfDay(now()));

        // Define date/time range markers
        const sevenDaysAgo = new Date(today().setDate(today().getDate() - 7));
        const thirtyDaysAgo = new Date(today().setDate(today().getDate() - 30));
        const thirtyDaysAgoNow = new Date(now().setDate(now().getDate() - 30));
        const startOfMonth = new Date(today().setDate(1));
        const startOfYear = new Date(today().setMonth(0, 1));
        const twelveMonthsAgo = new Date(
            today().setFullYear(today().getFullYear() - 1)
        );

        // Convert to ISO strings
        const sevenDaysAgoISOString = sevenDaysAgo.toISOString();
        const thirtyDaysAgoISOString = thirtyDaysAgo.toISOString();
        const startOfMonthISOString = startOfMonth.toISOString();
        const startOfYearISOString = startOfYear.toISOString();
        const twelveMonthsAgoISOString = twelveMonthsAgo.toISOString();
        const customStartFallbackISOString = thirtyDaysAgoNow.toISOString();
        const customEndFallbackISOString = setToEndOfDay(now()).toISOString();

        // Return the appropriate date range based on the selected time frame
        switch (selection) {
            case "sevenDays":
                result.start = sevenDaysAgoISOString;
                break;
            case "thirtyDays":
                result.start = thirtyDaysAgoISOString;
                break;
            case "monthToDate":
                result.start = startOfMonthISOString;
                break;
            case "yearToDate":
                result.start = startOfYearISOString;
                break;
            case "twelveMonths":
                result.start = twelveMonthsAgoISOString;
                break;
            case "custom":
                result.start =
                    customStartDate.toISOString() ??
                    customStartFallbackISOString;
                result.end =
                    customEndDate.toISOString() ?? customEndFallbackISOString;
        }

        return result;
    }, [customStartDate, customEndDate, selection]);

    const component = (
        <DateRangeSelector
            id={id}
            label={label}
            selection={selection}
            options={options}
            onChange={onChange}
            customStartDate={new Date(start)}
            customEndDate={new Date(end)}
            placeholderStartDate={start}
            placeholderEndDate={end}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            datePickerProps={datePickerProps}
        />
    );
    return { start, end, component };
};
