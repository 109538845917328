import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./types";

export const loading = createSlice({
    name: "loading",
    initialState: {
        loading: 0,
        isFullscreen: false
    } as LoadingState,
    reducers: {
        setLoading: (
            state,
            { payload }: PayloadAction<number | LoadingState>
        ) => {
            if (typeof payload === "number") {
                state.loading += payload;
            } else {
                state.loading += payload.loading;
                state.isFullscreen =
                    payload.isFullscreen && payload.loading > 0;
            }
        }
    }
});
export const { setLoading } = loading.actions;

export default loading.reducer;
