import React from "react";
import styles from "./index.module.scss";

interface TextAreaProps {
    label?: string;
    id: string;
    placeholder?: string;
    rows?: number;
    value: string | number | string[] | undefined;
    error?: string;
    horizontal?: boolean;
    disabled?: boolean;
    maxLength?: number;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({
    label,
    id,
    placeholder,
    rows,
    value,
    horizontal,
    disabled = false,
    maxLength,
    onChange
}: TextAreaProps) => {
    return (
        <>
            <div className={`form-group ${horizontal ? "row" : ""}`}>
                <label
                    className={`${horizontal ? "col-xl-2 col-form-label" : ""}`}
                    htmlFor={id}
                >
                    {label}
                </label>
                <div className={`${horizontal ? "col-xl-10" : ""}`}>
                    <textarea
                        className={`${styles["text-area"]}`}
                        id={id}
                        rows={rows}
                        placeholder={placeholder}
                        disabled={disabled}
                        value={value || ""}
                        onChange={onChange}
                        maxLength={maxLength}
                    ></textarea>
                </div>
            </div>
        </>
    );
};
