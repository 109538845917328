import React, { useState, useEffect, useCallback } from "react";
import { formatBytes } from "../utils";
import { ASPECT_RATIO_MAP, fileReaderAsync } from "./upload-helpers/helpers";
import { imageThumbnailAsync } from "./upload-helpers/thumbnail-helpers";
import { AspectRatio, AssetLayering, AssetType, FileItem } from "./types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { setLoading } from "store/loading/slice";
import { updateFileItem } from "store/cloud/slice";
import styles from "./ImageAsset.module.scss";
import commonStyles from "../AssetCard.module.scss";
import { AssetTag } from "components/asset-tag/AssetTag";
import { AspectRatioSelector } from "components/aspect-ratio-selector/AspectRatioSelector";
import { useTranslation } from "react-i18next";

interface ImageAssetProps {
    fileItem: FileItem;
    tags: string[];
}

const assetLayeringOptions = [
    { value: AssetLayering.FullScreen, text: "cloud:full-screen" },
    { value: AssetLayering.Overlay, text: "cloud:overlay" }
];

export const ImageAsset: React.FC<ImageAssetProps> = ({ fileItem, tags }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [assetLayering, setAssetLayering] = useState<
        AssetLayering | undefined
    >(
        fileItem.file.type == "image/png"
            ? AssetLayering.Overlay
            : AssetLayering.FullScreen
    );
    const [selectedTag, setSelectedTag] = useState<string>("");
    const [selectedAspectRatio, setSelectedAspectRatio] = useState<string>(
        AspectRatio.horizontal
    );
    const { t } = useTranslation();

    useEffect(() => {
        async function getThumbnail() {
            dispatch(setLoading(1));
            const dataStr = await fileReaderAsync(fileItem.file);
            if (dataStr && assetLayering) {
                const thumbnail = await imageThumbnailAsync(
                    assetLayering,
                    AssetType.image,
                    dataStr,
                    960, // target width
                    AspectRatio[selectedAspectRatio]
                );
                dispatch(
                    updateFileItem({
                        name: fileItem.name,
                        thumbnail: thumbnail,
                        assetLayering: assetLayering
                    })
                );
            }
            dispatch(setLoading(-1));
        }
        getThumbnail();
    }, [
        dispatch,
        fileItem.file,
        fileItem.name,
        assetLayering,
        selectedAspectRatio
    ]);

    const updateTag = useCallback(
        (tag) => {
            dispatch(
                updateFileItem({
                    ...fileItem,
                    fileProps: {
                        ...fileItem.fileProps,
                        tag
                    }
                })
            );
            setSelectedTag(tag);
        },
        [dispatch, fileItem]
    );

    const updateAspectRatio = useCallback(
        (aspectRatio) => {
            dispatch(
                updateFileItem({
                    ...fileItem,
                    aspectRatios: [ASPECT_RATIO_MAP[aspectRatio]]
                })
            );

            setSelectedAspectRatio(aspectRatio);
        },
        [dispatch, fileItem]
    );

    return (
        <>
            {fileItem && (
                <>
                    <div className={`${styles["asset-card"]} mb-2`}>
                        <div className={`${styles["asset-image"]}`}>
                            {fileItem.thumbnail && (
                                <img
                                    src={fileItem.thumbnail.dataURL}
                                    alt="Asset"
                                />
                            )}
                        </div>
                        <div
                            className={`card-body ${commonStyles["asset-card-body"]}`}
                        >
                            <p
                                className={`${styles["asset-card-title"]} text-center mb-2`}
                            >
                                <strong>{fileItem.file.name}</strong>
                                <br></br>
                                {formatBytes(fileItem.file.size || 0)}
                            </p>
                            <div className={`form-group mb-0`}>
                                <label>{t("cloud:layering")}</label>
                                {assetLayeringOptions.map((a) => {
                                    return (
                                        <div
                                            key={`${fileItem.file.name}-${a.value}`}
                                            className="form-check"
                                        >
                                            <label
                                                className="form-check-label"
                                                htmlFor={`${fileItem.file.name}-${a.value}`}
                                            >
                                                <input
                                                    className="form-check-input"
                                                    id={`${fileItem.file.name}-${a.value}`}
                                                    type="radio"
                                                    checked={
                                                        assetLayering ===
                                                        a.value
                                                    }
                                                    onChange={() =>
                                                        setAssetLayering(
                                                            a.value
                                                        )
                                                    }
                                                />
                                                <span>{t(a.text)}</span>
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                            <AssetTag
                                tags={tags}
                                onChange={updateTag}
                                selectedTag={selectedTag}
                            />
                            <AspectRatioSelector
                                onChange={updateAspectRatio}
                                selectedAspectRatio={selectedAspectRatio}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
