import React, { PropsWithChildren } from "react";
import styles from "./FormWrapper.module.scss";

interface FormWrapperProps extends PropsWithChildren {
    imageUrl?: string;
    destinationName?: string;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({
    imageUrl,
    destinationName,
    children
}) => {
    return (
        <div className={styles["form-card"]}>
            <div className={styles["form-card-header"]}>
                {imageUrl && <img src={imageUrl} alt="Destination" />}
                <h6>{destinationName || ""}</h6>
            </div>
            <div className={styles["form-card-body"]}>{children}</div>
        </div>
    );
};
