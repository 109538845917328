export const surveyCardOptions = ["embed", "stream", "monetize"] as const;
export type SurveyCardOptionsType = (typeof surveyCardOptions)[number];

export const surveyStreamOptions = [
    "website",
    "facebook",
    "youtube",
    "twitch",
    "other"
] as const;
export type SurveyStreamOptionsType = (typeof surveyStreamOptions)[number];

export const surveyMonetizeOptions = ["on-demand", "subscriptions"] as const;
export type SurveyMonetizeOptionsType = (typeof surveyMonetizeOptions)[number];

export const surveySiteOptions = ["has-site", "no-site"] as const;
export type SurveySiteOptionsType = (typeof surveySiteOptions)[number];

export const surveyVideoOptions = [
    "creating-with-switcher",
    "ready-to-upload"
] as const;
export type SurveyVideoOptionsType = (typeof surveyVideoOptions)[number];

export const surveyProductSalesOptions = ["in-store", "online"] as const;
export type SurveyProductSalesOptionsType =
    (typeof surveyProductSalesOptions)[number];

export interface OnboardingSurveyResults {
    selectedCards: Set<SurveyCardOptionsType>;
    selectedStreamOptions: Set<SurveyStreamOptionsType>;
    selectedMonetizeOptions: Set<SurveyMonetizeOptionsType>;
    selectedVideoOptions: Set<SurveyVideoOptionsType>;
    selectedSiteOptions: Set<SurveySiteOptionsType>;
    selectedProductSalesOptions: Set<SurveyProductSalesOptionsType>;
    siteUrl: string;
    skippedSurveyStep?: number;
}

export type OnboardingSurveyResultsEvent = {
    "options.embed": boolean;
    "options.stream": boolean;
    "options.monetize": boolean;
    "destinations.website": boolean;
    "destinations.facebook": boolean;
    "destinations.youtube": boolean;
    "destinations.twitch": boolean;
    "destinations.other": boolean;
    "monetization.on-demand": boolean;
    "monetization.subscriptions": boolean;
    "products.in-store": boolean;
    "products.online": boolean;
    "site.has-site": boolean;
    "site.url": string;
    "video.creating-with-switcher": boolean;
    "video.ready-to-upload": boolean;
    "skipped-step": boolean;
};
