import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { PlatformInitialState } from "../../types";

export interface PlatformFormProps {
    platform: StreamingProvider;
    destinationId: number | string;
    destinationType: string;
    isTitleVisible: boolean;
    isDescriptionVisible: boolean;
    isSchedulingAvailable: boolean;
    initialState: PlatformInitialState;
}

export enum ScheduledOptions {
    Now = "now",
    Later = "later",
    Existing = "existing"
}
