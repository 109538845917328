import React from "react";
import styles from "./DownloadsStandalone.module.scss";
import Apple from "../../../assets/icons/apple.svg?react";
import Windows from "../../../assets/icons/windows.svg?react";
import { useTranslation } from "react-i18next";
import { FlagContainer } from "components/flag-container/FlagContainer";
import SwitcherCast from "assets/SwitcherCast_new.png";

export const DownloadsStandalone: React.FC = () => {
    const { t } = useTranslation("download-switchercast");
    return (
        <section className={`${styles["download-switchercast"]}`}>
            <div className="row">
                <div className="col">
                    <h1 className={`${styles["title"]}`}>
                        {t("download-switchercast")}
                    </h1>
                    <h4>{t("subtitle")}</h4>
                </div>
            </div>
            <div className={`${styles["content-row"]}`}>
                <div>
                    <img
                        className={`img-fluid ${styles["cast-logo"]}`}
                        src={SwitcherCast}
                        alt="Switcher Cast Logo"
                    />
                </div>
                <div className={`${styles["content-block"]}`}>
                    <p>{t("description-1")}</p>
                    <p>{t("description-2")}</p>
                    <a
                        className={`${styles["switchercast-btn"]}`}
                        href="https://itunes.apple.com/us/app/switcher-cast/id1238109306?mt=12"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Apple className={`${styles["icon"]}`} />{" "}
                        {t("install-macos")}
                    </a>
                    <a
                        className={`${styles["switchercast-btn"]}`}
                        href="https://switcherstudio.blob.core.windows.net/staticcontent/software/SwitcherCastWindows.zip"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Windows className={`${styles["icon"]}`} />{" "}
                        {t("install-windows")}
                    </a>
                </div>
            </div>
            <FlagContainer />
        </section>
    );
};
