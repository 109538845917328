import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import React from "react";
import { PlatformId } from "views/page-content/platforms/types";
import { FacebookForm } from "../facebook/FacebookForm";
import { YoutubeForm } from "../youtube/YouTubeForm";
import { TwitchForm } from "../twitch/TwitchForm";
import { VideoPlayerForm } from "../videoplayer/VideoPlayerForm";

interface FormSectionProps {
    platform: StreamingProvider;
    destinationId?: number | string;
    destinationType?: string;
}

export const FormSection: React.FC<FormSectionProps> = ({
    platform,
    destinationId,
    destinationType
}) => {
    switch (platform.Id) {
        case PlatformId.Facebook:
            return (
                <FacebookForm
                    platform={platform}
                    destinationId={destinationId}
                    destinationType={destinationType}
                />
            );
        case PlatformId.Youtube:
            return (
                <YoutubeForm
                    platform={platform}
                    destinationId={destinationId}
                />
            );
        case PlatformId.Twitch:
            return <TwitchForm platform={platform} />;
        case PlatformId.VideoPlayer:
            return <VideoPlayerForm platform={platform} />;

        default:
            return null;
    }
};
