import { useDispatch } from "react-redux";
import { ModalBase } from "../ModalBase";
import { closeCurrentModal } from "store/modal/slice";
import { useScript } from "hooks/useScript";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

export const ContactSalesModal: React.FC = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const status = useScript("//js.hsforms.net/forms/embed/v2.js");

    const [showClose, setShowClose] = useState<boolean>(false);

    useEffect(() => {
        if (status === "ready") {
            hbspt.forms.create({
                region: "na1",
                portalId: "2974196",
                formId: "1a1061e9-e2a0-48bd-a73f-92395b24a6a6",
                target: "#hubspotForm",
                locale: i18n.resolvedLanguage,
                onFormSubmitted: () => setShowClose(true),
                onFormReady: function ($form) {
                    $form
                        .find('select[name="department"]')
                        .val("Marketing")
                        .change();
                }
            });
        }
    }, [i18n, status]);

    return (
        <ModalBase
            header={t("buttons:contact-sales")}
            dismissButton={showClose ? t("buttons:close") : undefined}
            onDismiss={() => dispatch(closeCurrentModal())}
            isOpen
        >
            <div id="hubspotForm" className={styles["form"]}></div>
        </ModalBase>
    );
};
