import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveBanner } from "store/page/slice";

/** Injects a page-level Banner onto the page when a banner is needed but only for one page */
export const usePageBanner = (component?: JSX.Element) => {
    const dispatch = useDispatch();

    const updatePageBanner = useCallback(
        (updatedComponent: JSX.Element) => {
            dispatch(setActiveBanner(updatedComponent));
        },
        [dispatch]
    );

    useEffect(() => {
        if (component) {
            dispatch(setActiveBanner(component));
        }

        /** Clean up active banner on dismount */
        return () => {
            dispatch(setActiveBanner(null));
        };
    }, [component, dispatch]);

    return {
        updatePageBanner
    };
};
