import { useLandingPageRedirectABTest } from "hooks/useLandingPageRedirectABTest";
import React, { useCallback } from "react";
import { DragAndDrop } from "./components/drag-and-drop/DragAndDrop";
import { useCreateUppy } from "./hooks/useCreateUppy";

interface VideoUploadModalEntryPointProps {
    /** The session id for the video upload session */
    sessionId: string;
    /**
     * Callback to reset the video upload session id.
     * Called as soon as files are added so that drag and drop component
     * can be used for a new session if needed
     */
    setSessionId: (id: string) => void;
    /** A callback invoked after filed is added.
     * Note: use this callback to close any modal this component is embedded in
     * */
    onFileAdded?: () => void;
    /** Callback function called when modal is closed */
    onUploadModalClosed?: (...args: any) => any;
    /** Callback function called when file upload is complete */
    onUploadComplete?: (broadcastId: string) => Promise<any>;
    /** Id of video player to lock the upload to */
    lockedToPlayer?: string;
    allowMultipleUploads?: boolean;
    allowAdditionalUploads?: boolean;
    isVideoLibrary?: boolean;
    showVideoStorageBar?: boolean;
    refreshKey?: number;
}

/**
 * A standalone component that includes a drag and drop uploader component that is pre-configured with its own
 * Uppy instance. This component can be placed anywhere in the page -- the page level, component level, in a drawer or in a modal ---
 * and once files are added to the drag and drop either by dragging and dropping or selecting from file explorer,
 * this component will begin a video upload session in our Redux store and automatically handle opening the upload modal
 * in the correct state to continue the upload form process.
 *
 * Note: This component consumes a session id with which it will associate with any files added to it.
 * While the initialization of the session id must be handled by the parent component to the VideoUploadModalEntryPoint component,
 * the ongoing session management (create/destroy/garbage collection, etc) is handled within this component via the useCreateUppy hook.
 */
export const VideoUploadModalEntryPoint = ({
    sessionId,
    setSessionId,
    onFileAdded,
    onUploadComplete,
    lockedToPlayer,
    allowMultipleUploads,
    allowAdditionalUploads = true,
    isVideoLibrary,
    showVideoStorageBar,
    refreshKey
}: VideoUploadModalEntryPointProps) => {
    const { trackUploadForABTest } = useLandingPageRedirectABTest();
    const onUploadCompleteWithABEvent = useCallback(
        (broadcastId: string) => {
            trackUploadForABTest();
            onUploadComplete && onUploadComplete(broadcastId);
        },
        [onUploadComplete, trackUploadForABTest]
    );

    const { entryPointDragAndDropInstance } = useCreateUppy({
        sessionId,
        setSessionId,
        onFileAdded,
        onUploadComplete: onUploadCompleteWithABEvent,
        lockedToPlayer,
        allowMultipleUploads,
        allowAdditionalUploads,
        location: "entry-point-component"
    });

    return (
        <DragAndDrop
            initialUppyInstance={entryPointDragAndDropInstance}
            isVideoLibrary={isVideoLibrary}
            showVideoStorageBar={showVideoStorageBar}
            sessionId={sessionId}
            refreshKey={refreshKey}
        />
    );
};
