import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import styles from "./index.module.scss";

export interface InlineButtonProps
    extends Omit<
        DetailedHTMLProps<
            ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
        >,
        "className"
    > {
    variant?: "primary" | "secondary" | "tertiary";
}

export const InlineButton = ({
    variant = "primary",
    ...props
}: InlineButtonProps) => {
    return <button className={styles[variant]} {...props} />;
};
