import React from "react";
import styles from "./index.module.scss";
import RevealIcon from "assets/icons/eye.svg?react";
import UnrevealedIcon from "assets/icons/eye-slash.svg?react";
import { InputType } from ".";

export interface IconRightProps {
    type: InputType;
    isRevealed: boolean;
    onClick: () => void;
}

export const IconRight: React.FC<IconRightProps> = ({
    type,
    isRevealed,
    onClick
}) => {
    switch (type) {
        case "firstName":
        case "lastName":
            return <></>;
        case "email":
            return <></>;
        case "password":
            return (
                <div className={styles["reveal-icon"]} onClick={onClick}>
                    {isRevealed ? <RevealIcon /> : <UnrevealedIcon />}
                </div>
            );
        default:
            return <></>;
    }
};
