import React, { useMemo } from "react";
import styles from "./SimulcastDestinationCard.module.scss";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import YoutubeLogo from "assets/YouTube_Logo_2017.svg?react";
import RtmpLogo from "assets/rtmp.svg?react";
import FacebookLogo from "assets/Facebook-Logo.svg?react";
import TwitchLogo from "assets/TwitchWordLogo.svg?react";
import SwitcherLogo from "assets/icons/switcher-horizontal_new_black.svg";
import { DestinationDescriptor } from "../../types";
import { PlatformId } from "views/page-content/platforms/types";
import { useClaimCheck } from "hooks/useClaimCheck";

interface SimulcastDestinationCardProps {
    platform?: StreamingProvider;
    destination?: DestinationDescriptor;
    text?: string;
    isDeletable?: boolean;
    isDisabled?: boolean;
    isInline?: boolean;
    clickHandler: () => void;
}

// this card will be used for both the top level platforms and the selected streams.
// the only difference is the click event and the hover state.

export const SimulcastDestinationCard: React.FC<
    SimulcastDestinationCardProps
> = ({
    platform,
    destination,
    text,
    isDeletable = false,
    isDisabled = false,
    isInline = false,
    clickHandler
}) => {
    const hasCatalogClaim = useClaimCheck("catalog");
    const id = platform ? platform.Id : destination.platform;
    const name = useMemo<string>(() => {
        if (id === PlatformId.VideoPlayer) {
            if (hasCatalogClaim) {
                return "Video Catalog";
            } else {
                return "Switcher Player";
            }
        } else if (id === PlatformId.AmazonLive) {
            return "Amazon Live";
        } else if (id === PlatformId.Caast) {
            return "Caast";
        } else if (id === PlatformId.Instafeed) {
            return "Instafeed";
        } else if (id === PlatformId.MsStream) {
            return "Microsoft Stream";
        }

        return platform
            ? platform.Name
            : destination.platform[0].toUpperCase() +
                  destination.platform.substr(1);
    }, [destination, hasCatalogClaim, platform, id]);

    const icons = useMemo(() => {
        return {
            facebook: () => <FacebookLogo />,
            youtube: () => <YoutubeLogo />,
            twitch: () => <TwitchLogo />,
            switcherplayer: () => (
                <img src={SwitcherLogo} alt="Switcher logo" />
            ),
            custom: () => <RtmpLogo />
        };
    }, []);

    const determineContainerStyles = (
        isDisabled: boolean,
        isInline: boolean
    ) => {
        if (isDisabled) {
            return `${styles["is-greyed"]} ${styles["is-disabled"]}`;
        } else if (isInline) {
            return `${styles["is-inline"]} ${styles["is-disabled"]}`;
        }
        return "";
    };

    return (
        <div
            className={`${styles["destination-card"]} ${
                isDeletable && styles["is-deletable"]
            } ${determineContainerStyles(isDisabled, isInline)}`}
            onClick={clickHandler}
        >
            {isDeletable && (
                <button
                    type="button"
                    className={styles["remove-btn"]}
                    data-dismiss="alert"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            )}
            <span className={styles["icon-container"]}>
                {platform?.LogoUrl || destination?.logoUrl ? (
                    <img
                        alt="platform logo"
                        src={platform?.LogoUrl || destination?.logoUrl}
                    />
                ) : (
                    icons[id]()
                )}
            </span>
            <div className={styles["platform-name"]}>
                <b>{name}</b>
            </div>
            {text && <div>{text}</div>}
        </div>
    );
};
