import React from "react";
import styles from "./index.module.scss";

interface PillSelectorProps {
    pills: string[];
    selectedPill: string;
    selectPill: (pill: string, index?: number) => void;
    vertical?: boolean;
    disabled?: boolean;
}

export const PillSelector: React.FC<PillSelectorProps> = ({
    pills,
    selectedPill,
    selectPill,
    vertical,
    disabled = false
}) => {
    return (
        <div
            className={`${styles["pill-selector"]} ${
                vertical ? styles["vertical"] : ""
            }`}
        >
            {pills.map((p, i) => (
                <button
                    type="button"
                    key={"pill-" + i}
                    onClick={() => selectPill(p, i)}
                    disabled={disabled}
                    className={selectedPill === p ? styles["selected"] : ""}
                >
                    {p}
                </button>
            ))}
        </div>
    );
};
