import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { ModalBase } from "./ModalBase";
import { closeConfirmation } from "store/confirmation/slice";
import { useCallback } from "react";

export interface ConfirmationModalProps {
    isOpen: boolean;
    message: string;
    cancelText: string;
    confirmText: string;
    htmlMessage: boolean;
    onSuccess: (...args: any) => any;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onSuccess,
    message,
    htmlMessage,
    confirmText,
    cancelText
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const _onSuccess = useCallback(() => {
        if (typeof onSuccess === "function") {
            dispatch(closeConfirmation());
            return onSuccess();
        }
    }, [dispatch, onSuccess]);

    function close() {
        dispatch(closeConfirmation());
    }

    return (
        <ModalBase
            isOpen={isOpen}
            onSuccess={_onSuccess}
            onDismiss={close}
            successButton={confirmText}
            dismissButton={cancelText}
        >
            {htmlMessage ? (
                <div dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
                <p>{message}</p>
            )}
        </ModalBase>
    );
};
