import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PromotionsState, SetHasViewedPromoModalPayload } from "./types";

const initialState: PromotionsState = {};

export const promotions = createSlice({
    name: "promotions",
    initialState: initialState,
    reducers: {
        setHasViewedPromoModal: (
            state,
            { payload }: PayloadAction<SetHasViewedPromoModalPayload>
        ) => {
            state[payload.promoName] = {
                ...state[payload.promoName],
                hasViewedModal: payload.hasViewedModal
            };
        }
    }
});

export const { setHasViewedPromoModal } = promotions.actions;

export default promotions.reducer;
