import { isMobile } from "utils/js-utils";
import { useMediaQuery } from "./useMediaQuery";

/** Returns if the viewport matches mobile breakpoints. Does not detect device type */
export const useIsMobile = () => {
    const isLandscapeMobile = useMediaQuery({
        maxWidth: 768,
        orientation: "landscape"
    });
    const isPortraitMobile = useMediaQuery({
        maxWidth: 768,
        orientation: "portrait"
    });

    return {
        isMobile: isMobile() || isLandscapeMobile || isPortraitMobile,
        isLandscapeMobile,
        isPortraitMobile
    };
};
