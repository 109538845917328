import React, { useState, useEffect, useCallback } from "react";
import { RootState } from "store/reducers";
import { useSelector, useDispatch } from "react-redux";
import { client } from "api/client";
import {
    VideoRoom,
    TwilioVideoRoomsUsage
} from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { VideoLink } from "./VideoLink";
import { RoomUsage } from "./RoomUsage";
import ExternalIcon from "assets/icons/external.svg?react";
import { useTranslation } from "react-i18next";
import { DisabledFeature } from "components/disabled-feature/DisabledFeature";

import styles from "./RemoteGuestsPage.module.scss";
import rollbar from "helpers/rollbar";
import { useHasAccess } from "hooks/useHasAccess";
import { UpgradePrompt } from "components/upgrade-prompt/UpgradePrompt";
import { AppDispatch } from "store/store";

export const RemoteGuestsPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const isMountedRef = useIsMountedRef();
    const user = useSelector((s: RootState) => s.user);
    const [room, setRoom] = useState<VideoRoom>();
    const [roomUsage, setRoomUsage] = useState<TwilioVideoRoomsUsage>();
    // there are two similar claims: videochat and videochatbeta, so we need a different check
    const hasAccess = useHasAccess(
        (userInfo) =>
            userInfo.FeatureClaims?.filter((c) => c === "videochat").length !==
            0
    );
    const [clipboardExists] = useState(!!navigator.clipboard);

    const copyRoomUrl = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(room?.Url);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-chat-link"
                })
            );
        }
    }, [dispatch, room, clipboardExists]);

    const resetRoom = useCallback(async () => {
        try {
            const room = await client.twilio_PostRoom(
                user.userInfo?.UserId || "",
                {}
            );
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:remote-guests-reset"
                })
            );
            setRoom(room);
        } catch (e) {
            rollbar.error("Error resetting twilio room", e);
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "errors:reset-error"
                })
            );
        }
    }, [dispatch, user.userInfo]);

    const launchVideoChat = useCallback(() => {
        window.open(room?.Url, "_blank");
    }, [room]);

    useEffect(() => {
        async function getRoom() {
            try {
                const rooms = await client.twilio_GetRooms(
                    user.userInfo?.UserId || ""
                );
                if (isMountedRef.current) {
                    setRoom(rooms[0]);
                }
            } catch (e) {
                rollbar.error("Error finding twilio rooms", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:room-find-error"
                    })
                );
            }
        }

        async function getRoomUsage() {
            const usage = await client.twilio_GetRoomUsage(
                user.userInfo?.UserId || ""
            );
            if (isMountedRef.current) {
                setRoomUsage(usage);
            }
        }

        if (hasAccess) {
            getRoom();
            getRoomUsage();
        }
    }, [dispatch, user.userInfo, isMountedRef, hasAccess]);

    return (
        <>
            {!hasAccess ? (
                <DisabledFeature />
            ) : (
                <>
                    {roomUsage?.TotalSeconds >= roomUsage?.MaxSeconds &&
                    user?.userInfo?.PlanName === "Trial" ? (
                        <UpgradePrompt message={t("remote-guests:trial")} />
                    ) : (
                        <>
                            <div className="row mb-4">
                                <div className="col-lg-6">
                                    <h4>{t("remote-guests:invite-guest")}:</h4>
                                    <VideoLink
                                        canCopy={clipboardExists}
                                        copy={copyRoomUrl}
                                        reset={resetRoom}
                                        value={room?.Url}
                                    />
                                </div>
                                <div className={`col-lg-6 ${styles["how-to"]}`}>
                                    <h4>{t("remote-guests:how-to")}:</h4>
                                    <ol>
                                        <li>{t("remote-guests:how-1")}</li>
                                        <li>{t("remote-guests:how-2")}</li>
                                        <li>
                                            {t("remote-guests:how-3a")}{" "}
                                            <em>
                                                {t("remote-guests:launch-chat")}
                                            </em>
                                            .
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            {user?.userInfo?.PlanName === "Trial" ? (
                                <div className="row mb-4">
                                    <div className="col">
                                        <h4>{t("remote-guests:usage")}:</h4>
                                        <RoomUsage usage={roomUsage} />
                                    </div>
                                </div>
                            ) : null}
                            <div className="row justify-content-center mb-2">
                                <div className="col-lg-8">
                                    <button
                                        className={`btn btn-block btn-primary ${styles["launch-button"]}`}
                                        onClick={launchVideoChat}
                                    >
                                        <span>
                                            {t("remote-guests:launch-chat")}
                                        </span>{" "}
                                        <ExternalIcon />
                                    </button>
                                </div>
                                {user?.userInfo?.PlanName === "Trial" ? (
                                    <div className="row mt-4">
                                        <div className="col">
                                            <p>{t("remote-guests:trial")}</p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};
