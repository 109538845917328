import { useEffect, useState } from "react";
import getInstance, {
    Intercom,
    IntercomCommand,
    IntercomSettings
} from "helpers/intercom";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { mustBeLoggedIn } from "./useRedirectIfDisallowed";

export const useIntercom = (
    boot?: boolean
): ((command: IntercomCommand, options?: any) => void) => {
    const enabled = import.meta.env.VITE_SUPPORT_SYSTEM === "intercom";

    const [intercom, setIntercom] = useState<Intercom>(
        getInstance({ app_id: "doe21lte" })
    );
    const user = useSelector((s: RootState) => s.user);
    const isAuthenticated = mustBeLoggedIn(user);

    useEffect(() => {
        if (!enabled) return;

        if (isAuthenticated) {
            if (!boot) {
                return;
            }

            const settings: IntercomSettings = {
                app_id: "doe21lte",
                user_id: user.userInfo?.UserId,
                email: user.userInfo?.Email
            };

            const isChanged = (attr: string): boolean =>
                undefined !== intercom.settings[attr] &&
                settings[attr] !== intercom.settings[attr];

            if (
                !!intercom.appId &&
                ["app_id", "user_id", "email", "language_override"].some(
                    isChanged
                )
            ) {
                // app_id or user changed, restart session
                intercom.command("shutdown");

                if (settings.app_id) {
                    intercom.boot(settings);
                }
            } else if (
                !!settings.app_id &&
                settings.app_id === intercom.appId
            ) {
                // update current session
                intercom.command("update", settings);
            } else {
                // reset intercom
                setIntercom(getInstance(settings));
            }
        }
    }, [enabled, isAuthenticated, intercom, boot, user.userInfo]);

    if (!enabled) return null;
    return intercom.command;
};

export default useIntercom;
