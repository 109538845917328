import React from "react";
import styles from "./index.module.scss";
import { CreateAccountInput } from "components/inputs/text-input/CreateAccountInput";
import { Select } from "components/inputs/select/Select";
import { useTranslation } from "react-i18next";
import { Errors } from "hooks/useForm";
import { CreateAccountFormValues } from "./CreateAccountForm/useCreateAccountForm";

export interface IndustryDropdownProps {
    values: CreateAccountFormValues;
    errors: Errors;
    handleChange: (event: React.ChangeEvent<any>) => void;
}

export const IndustryDropdown: React.FC<IndustryDropdownProps> = ({
    values,
    errors,
    handleChange
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Select
                label={t("personalize-account:please-select")}
                id="industry"
                selected={values.industry}
                error={errors.industry}
                placeholder={t("getting-started:dropdown-placeholder")}
                containerClassName={styles["dropdown"]}
                labelClassName={styles["floating-label"]}
                onChange={handleChange}
                options={t("personalize-account:options", {
                    returnObjects: true
                })}
            />
            {values.industry === "Other (Please Specify)" && (
                <CreateAccountInput
                    label={t("personalize-account:placeholder")}
                    id="industryCustom"
                    type="text"
                    value={values.industryCustom}
                    error={errors.industryCustom}
                    onChange={handleChange}
                />
            )}
        </>
    );
};
