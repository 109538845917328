import { VideoPlayerThumbnailResponse } from "@switcherstudio/switcher-api-client";
import { PlayerPlaceholder } from "components/placeholders/PlayerPlaceholder";
import { Suspense } from "react";
import { BaseThumbnail } from "../BaseThumbnail";

export const VideoPlayerThumbnail = ({ Url }: VideoPlayerThumbnailResponse) => (
    <BaseThumbnail>
        {!Url ? (
            <PlayerPlaceholder />
        ) : (
            <Suspense fallback={<PlayerPlaceholder />}>
                <img src={Url} alt="Video Player Thumbnail" />
            </Suspense>
        )}
    </BaseThumbnail>
);
