import styles from "./index.module.scss";
import { PropsWithChildren } from "react";

interface BaseThumbnailProps extends PropsWithChildren {}
export const BaseThumbnail = ({ children }: BaseThumbnailProps) => (
    <>
        <div className={`base-thumbnail ${styles["thumbnail"]}`}>
            {children}
        </div>
    </>
);
