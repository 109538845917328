import { useElements, useStripe } from "@stripe/react-stripe-js";

export function useStripeHandlers() {
    const stripe = useStripe();
    const elements = useElements();

    return {
        stripe,
        elements
    };
}
