import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import React, { useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import HelpIcon from "assets/icons/help-filled.svg?react";
import styles from "./index.module.scss";

export interface TooltipProps {
    type: "CTA" | "info";
    body: JSX.Element | string;
    show: boolean;
    label?: string;
    children?: JSX.Element;
    style?: React.CSSProperties;
    variant?: "default" | "high-contrast" | "dark";
    labelOrientation?: "left" | "right" | "top" | "bottom";
    /** How long in milliseconds before the tooltip overlay disappears */
    hide?: number;
}

export const TooltipBaseV2 = ({
    type,
    show,
    body,
    label,
    children = <></>,
    style = {},
    variant = "default",
    labelOrientation = "right",
    hide = 2000
}: TooltipProps) => {
    const { isInBrowser } = useSwitcherSdk(true);

    const ref = useRef(null);
    if (!show) return React.cloneElement(children, { style });

    return (
        <div ref={ref} style={style}>
            <OverlayTrigger
                placement="auto"
                trigger={
                    isInBrowser
                        ? ["focus", type === "CTA" ? "click" : "hover"]
                        : ["focus", "click"]
                }
                rootClose
                delay={{
                    show: 0,
                    hide: hide
                }}
                overlay={(props) => (
                    <Popover id="popover-basic" className="popover" {...props}>
                        <Popover.Body
                            style={{
                                background:
                                    "linear-gradient(0deg, rgba(0, 67, 206, 0.08), rgba(0, 67, 206, 0.08)), #FFFFFF",
                                border: "1px solid rgba(0, 67, 206, 0.24)",
                                borderRadius: "4px",
                                cursor: "default"
                            }}
                        >
                            {body}
                        </Popover.Body>
                    </Popover>
                )}
                container={ref?.current}
            >
                {({ ref, ...triggerHandler }) => (
                    <div
                        className={`${styles["container"]} ${styles[labelOrientation]} ${styles[variant]}`}
                    >
                        <div className={styles["children-container"]}>
                            {children}
                        </div>
                        <div
                            className={styles["label-icon-container"]}
                            {...triggerHandler}
                            ref={ref}
                        >
                            <HelpIcon
                                className={`${styles["help-icon"]}`}
                                fill="#007bff"
                            />
                            <small>{label}</small>
                        </div>
                    </div>
                )}
            </OverlayTrigger>
        </div>
    );
};
