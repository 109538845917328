import React from "react";
import GenericCardIcon from "assets/icons/ccBrands/genericCard.svg?react";
import AmexIcon from "assets/icons/ccBrands/amex.svg?react";
import DinersClubIcon from "assets/icons/ccBrands/diners.svg?react";
import DiscoverIcon from "assets/icons/ccBrands/discover.svg?react";
import JCBIcon from "assets/icons/ccBrands/jcb.svg?react";
import MasterCardIcon from "assets/icons/ccBrands/mastercard.svg?react";
import UnionPayIcon from "assets/icons/ccBrands/unionpay.svg?react";
import VisaIcon from "assets/icons/ccBrands/visa.svg?react";

export const getCreditCardIcon = (brand: string | number) => {
    switch (brand) {
        case "American Express":
            return <AmexIcon />;
        case "Diners Club":
            return <DinersClubIcon />;
        case "Discover":
            return <DiscoverIcon />;
        case "JCB":
            return <JCBIcon />;
        case "MasterCard":
            return <MasterCardIcon />;
        case "UnionPay":
            return <UnionPayIcon />;
        case "Visa":
            return <VisaIcon />;
        default:
            return <GenericCardIcon />;
    }
};
