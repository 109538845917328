export class ChannelSetting {
    "audio-bit-rate": number;
    "audio-channel-count": number;
    "audio-sample-rate": number;
    "video-bit-rate": number;
    "video-frame-height": number;
    "video-frame-width": number;

    constructor(
        width,
        height,
        videoBitRate,
        audioChannels,
        audioBitrate,
        audioSampleRate
    ) {
        this["audio-bit-rate"] = audioBitrate;
        this["audio-channel-count"] = audioChannels;
        this["audio-sample-rate"] = audioSampleRate;
        this["video-bit-rate"] = videoBitRate;
        this["video-frame-height"] = height;
        this["video-frame-width"] = width;
    }
}
