import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export const StudioUpgradeCta: React.FC = () => {
    return (
        <p className={styles.upgradeCta}>
            <Trans
                i18nKey={"misc:studio-plan-upgrade-cta"}
                components={{
                    link1: <Link to={"/subscription?#openModal"} />
                }}
            />
        </p>
    );
};
