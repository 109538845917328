import React from "react";

import { ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { ItemRow } from "./ItemRow";
import { TableColumn } from "./types";

// import SortIcon from "assets/icons/sort-columns.svg?react";
import styles from "./ManageInventoryItemsPage.module.scss";

interface ItemTableProps {
    displayedItems: ResellerInventoryItem[];
    isResellerAdmin?: boolean;
    copyToClipboard: (code: string) => void;
    sortByColumn?(
        column: TableColumn,
        sort: (a: ResellerInventoryItem, b: ResellerInventoryItem) => number
    ): void;
}

export const ItemTable: React.FC<ItemTableProps> = ({
    displayedItems,
    isResellerAdmin,
    copyToClipboard
}) => {
    const { t } = useTranslation();
    return (
        <>
            {displayedItems.length === 0 ? (
                <div className="alert alert-info" role="alert">
                    {t("messages:no-inventory")}
                </div>
            ) : (
                <div className={`table-responsive ${styles["table"]}`}>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}>
                                    {t("misc:status")}
                                    {/* <span
                    className={styles["sort-icon"]} 
                    onClick={() =>
                      sortByColumn(
                        TableColumn.Status,
                        (a, b) =>
                          b.Status - a.Status
                      )
                    }
                  >
                    <SortIcon />
                  </span> */}
                                </th>
                                <th style={{ width: "20%" }}>
                                    {t("manage-inventories:code")}
                                    {/* <span
                    className={styles["sort-icon"]} 
                    onClick={() =>
                      sortByColumn(TableColumn.Code, (a, b) =>
                        a.CouponCode.localeCompare(b.CouponCode)
                      )
                    }
                  >
                    <SortIcon />
                  </span> */}
                                </th>
                                {isResellerAdmin && (
                                    <th style={{ width: "35%" }}>
                                        {t("manage-inventories:redeemed-by")}
                                        {/* <span
                      className={styles["sort-icon"]} 
                      onClick={() =>
                        sortByColumn(TableColumn.RedeemedBy, (a, b) =>
                          a.RedeemedByUserEmail?.localeCompare(
                            b.RedeemedByUserEmail
                          )
                        )
                      }
                    >
                      <SortIcon />
                    </span> */}
                                    </th>
                                )}
                                <th style={{ width: "20%" }}>
                                    {t("manage-inventories:redeemed-on")}
                                    {/* <span
                    className={styles["sort-icon"]} 
                    onClick={() =>
                      sortByColumn(TableColumn.RedeemedOn, (a, b) =>
                        new Date(b.UpdatedAt).getTime() - (a.Status === 0
                          ? 0
                          : new Date(a.UpdatedAt).getTime())
                      )
                    }
                  >
                    <SortIcon />
                  </span> */}
                                </th>
                                <th style={{ width: "20%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedItems.map((item, key) => (
                                <ItemRow
                                    item={item}
                                    isResellerAdmin={isResellerAdmin}
                                    copyToClipboard={copyToClipboard}
                                    key={key}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
