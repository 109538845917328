import { CategoryType } from "@switcherstudio/switcher-api-client";
import { CategoriesAssignment } from "components/inputs/categories-assignment";
import { shorten } from "helpers/strings";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { setCategories } from "store/videoUploadSession/slice";
import { useGetUploadSession } from "../hooks/useGetUploadSession";
import styles from "../index.module.scss";

export const BulkUploadConfirmationStep = ({
    sessionId
}: {
    sessionId: string;
}) => {
    const { files, categories } = useGetUploadSession({ sessionId });
    const filesArray = useMemo(
        () => (!!files ? Object.values(files) : []),
        [files]
    );
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className={`${styles["upload-step-2"]}`}>
            <CategoriesAssignment
                selectedCategories={categories}
                setSelectedCategories={(sc) =>
                    dispatch(setCategories({ sessionId, categories: sc }))
                }
                categoryType={CategoryType._0}
            />
            <div className={styles["bulk-uploads-container"]}>
                {filesArray.map((file) => (
                    <div
                        key={file.file.name}
                        className={`${styles["selected-file"]}`}
                    >
                        <p className={`${styles["file-name"]}`}>
                            <strong>{t("video-upload:file")}:</strong>{" "}
                            {shorten(file.file.name, 20)}
                        </p>
                        <p className={`${styles["file-size"]}`}>{`${Math.round(
                            file.file.size / 1000 / 1000
                        )} MB`}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
