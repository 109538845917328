import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { mustBeSubscriptionOwner } from "./useRedirectIfDisallowed";
import { useMemo } from "react";
import { StripeStatus } from "views/page-content/platforms/types";

export const useUserStatus = () => {
    const { userInfo, defaultOrg } = useSelector((s: RootState) => s.user);

    const userStatusIsActive = useMemo<boolean>(
        () => userInfo?.Status === StripeStatus.Active,
        [userInfo]
    );

    const userStatusIsTrialing = useMemo<boolean>(
        () => userInfo?.Status === StripeStatus.Trialing,
        [userInfo]
    );

    const userPlanIsTrial = useMemo<boolean>(
        () =>
            userInfo?.PlanName === "Trial" &&
            !userInfo?.FeatureClaims.includes("nowatermark"),
        [userInfo]
    );

    const userHasNoSubscription = useMemo<boolean>(
        () => userInfo.PlanName === "No Subscription",
        [userInfo.PlanName]
    );

    const userIsPaused = useMemo<boolean>(
        () =>
            userInfo.Roles.includes("Paused") &&
            mustBeSubscriptionOwner({
                defaultOrg,
                userInfo
            }),
        [defaultOrg, userInfo]
    );

    return {
        userStatusIsActive,
        userStatusIsTrialing,
        userPlanIsTrial,
        userHasNoSubscription,
        userIsPaused
    };
};
