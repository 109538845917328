import { PropsWithChildren } from "react";

export interface CaseProps<T> extends PropsWithChildren {
    /** The value to match. Not used directly in this component. */
    value: T;
    /** Indicates if this case should be the default. Not used directly in this component. */
    default?: boolean;
}

/**
 * A React-friendly implementation of switch/case logic. This component is tightly coupled with the Switch component, which should be a parent of this component.
 * An example of this in use can be found in `ViewsTab.tsx`.
 */
export const Case = <T,>({ children }: CaseProps<T>) => <>{children}</>;
