import React, { useMemo, useState } from "react";
import {
    ResellerInventoryItem,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import styles from "./index.module.scss";
import {
    formatPlanName,
    displayAmount,
    displaySubText,
    calculateDiscountedAmount,
    DisplayAmountOptions
} from "helpers/stripe";
import { useTranslation } from "react-i18next";
import { format } from "helpers/time";
import dayjs from "dayjs";
import { PlanChangeTooltip } from "components/tooltips/PlanChangeTooltip";
import { exists } from "helpers/booleans";

export interface CreateAccountSidebarStepContentProps {
    plan: StripePriceAnonymous;
    isTrialing: boolean | undefined;
    changePlan: () => void;
    disableChangePlan: boolean;
    resellerInventoryItem?: ResellerInventoryItem;
}

const displayAmountOptions: DisplayAmountOptions = {
    signed: true
};

export const CreateAccountSidebarStepContent: React.FC<
    CreateAccountSidebarStepContentProps
> = ({
    plan,
    isTrialing,
    resellerInventoryItem,
    changePlan,
    disableChangePlan
}) => {
    const { t } = useTranslation();
    const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);

    const originalAmount = useMemo<string>(
        () => displayAmount(plan?.Amount, displayAmountOptions),
        [plan]
    );

    const finalAmount = useMemo<string>(
        () =>
            displayAmount(
                calculateDiscountedAmount(
                    plan?.Amount,
                    resellerInventoryItem?.ResellerInventory?.Coupon
                ),
                displayAmountOptions
            ),
        [plan, resellerInventoryItem]
    );

    const hasDiscount = useMemo<boolean>(
        () => originalAmount !== finalAmount,
        [finalAmount, originalAmount]
    );

    const firstPaymentDue = useMemo<string>(
        () =>
            format(
                dayjs().add(
                    resellerInventoryItem?.ResellerInventory?.TrialDays ?? 14,
                    "day"
                ),
                "M/DD/YYYY"
            ),
        [resellerInventoryItem]
    );

    const showNonTrialPricing = useMemo<boolean>(
        () =>
            !isTrialing ||
            resellerInventoryItem?.ResellerInventory?.TrialDays === 0 ||
            !plan?.RecurringInterval,
        [isTrialing, plan, resellerInventoryItem]
    );

    return (
        <div className={styles["container"]}>
            <PlanChangeTooltip
                display={
                    displayTooltip &&
                    exists(resellerInventoryItem?.ResellerInventory?.Price)
                }
                plan={plan?.Name}
                coupon={resellerInventoryItem?.CouponCode}
            />
            <table>
                <thead>
                    <tr>
                        <th>
                            {formatPlanName(plan?.Name)}{" "}
                            {plan?.IsRecurring && (
                                <p>
                                    {t(
                                        `getting-started:${plan?.RecurringInterval}`
                                    )}{" "}
                                    {t("getting-started:subscription")}
                                </p>
                            )}
                        </th>
                        {!disableChangePlan ? (
                            <th
                                onClick={changePlan}
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLDivElement>
                                ) => event.keyCode === 13 && changePlan()}
                                onMouseEnter={() => setDisplayTooltip(true)}
                                onMouseLeave={() => setDisplayTooltip(false)}
                                tabIndex={0}
                            >
                                {t("buttons:change")}
                            </th>
                        ) : (
                            <th style={{ pointerEvents: "none" }}></th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {!showNonTrialPricing && (
                        <tr>
                            <td>
                                {t("getting-started:total-due")}{" "}
                                {firstPaymentDue ?? ""}
                            </td>
                            {hasDiscount ? (
                                <td>
                                    <s>{originalAmount}</s>{" "}
                                    <strong>{finalAmount}</strong>{" "}
                                    {displaySubText(
                                        plan,
                                        resellerInventoryItem,
                                        t,
                                        {
                                            addAfterTrial: false
                                        }
                                    )}
                                </td>
                            ) : (
                                <td>{originalAmount}</td>
                            )}
                        </tr>
                    )}
                    <tr>
                        <td>
                            {t("getting-started:total-due")}{" "}
                            {t("getting-started:today")}
                        </td>
                        {hasDiscount && showNonTrialPricing ? (
                            <td>
                                <s>{originalAmount}</s>{" "}
                                <strong>{finalAmount}</strong>{" "}
                                {displaySubText(
                                    plan,
                                    resellerInventoryItem,
                                    t,
                                    {
                                        addAfterTrial: false
                                    }
                                )}
                            </td>
                        ) : (
                            // check that the final amount is a number before showing it otherwise show $0
                            <td>
                                {showNonTrialPricing
                                    ? typeof finalAmount === "number"
                                        ? finalAmount
                                        : "$0"
                                    : "$0"}
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
