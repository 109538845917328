import React, { PropsWithChildren } from "react";
import styles from "./BaseLayout.module.scss";
import { NotificationContainer } from "components/notification/NotificationContainer";
import Loading from "components/loading/Loading";
import { FlagContainer } from "components/flag-container/FlagContainer";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import Logo from "assets/icons/switcher-vertical_new.svg?url";
import { Outlet } from "react-router-dom";

const backgroundStyles = styles["background-new"];

interface BaseLayoutProps extends PropsWithChildren {
    hideFlags?: boolean;
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({
    hideFlags = false
}) => {
    useSwitcherSdk(true);

    return (
        <>
            <span className={backgroundStyles}></span>
            <section className={styles["app-container"]}>
                <div className={`${styles.container} pt-4`}>
                    <Loading />
                    <div className={styles.row}>
                        <div className="col-sm-10">
                            <div className="form-group text-center">
                                <img
                                    className={styles.logo}
                                    src={Logo}
                                    alt="Switcher Studio Logo"
                                />
                            </div>
                        </div>
                    </div>
                    <NotificationContainer />
                    <div className={styles.row}>
                        <div className="col-sm-10">
                            <Outlet />
                        </div>
                    </div>
                </div>
                {!hideFlags && <FlagContainer />}
            </section>
        </>
    );
};
