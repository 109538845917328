import {
    CreatorProductVideoPlayerPlaylistBroadcastEntitlement,
    VideoPlayerCloudflareResponse,
    VideoPlayerPlaylistBroadcast,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { exists } from "helpers/booleans";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useSwitcherClient } from "./useSwitcherClient";

export interface PlaylistBroadcastWithEntitlement
    extends VideoPlayerPlaylistBroadcast {
    entitlements: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[];
    cloudflareResponse: VideoPlayerCloudflareResponse;
}

export interface VideoPlayerWithCloudflareResponses
    extends VideoPlayerResponse {
    cloudflareResponses: VideoPlayerCloudflareResponse[];
}

export interface UseCreatorProductEntitlementOptions {
    onAdd?: () => void;
    onDelete?: () => void;
}

export const useCreatorProductEntitlement = (
    videoPlayerId: string,
    { onAdd, onDelete }: UseCreatorProductEntitlementOptions
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        dispatchApiRequest: getVideoPlayerProfile,
        loading: getCollectionProfileLoading
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerProfile_GetVideoPlayerProfileByVideoPlayerId,
        { args: [videoPlayerId, true] }
    );

    const {
        transformedData: videoPlayer,
        dispatchApiRequest: getVideoPlayer,
        loading: getCollectionLoading
    } = useSwitcherClient((client) => client.videoPlayers_GetVideoPlayer, {
        args: [videoPlayerId],
        requestImmediately: true,
        transformResponseData: async ({
            originalResponse
        }): Promise<VideoPlayerWithCloudflareResponses> => {
            const videoPlayerProfile = await getVideoPlayerProfile();

            return {
                ...originalResponse,
                cloudflareResponses:
                    videoPlayerProfile.VideoPlayerCloudflareVideos
            };
        }
    });

    const playlistBroadcastsWithEntitlements: PlaylistBroadcastWithEntitlement[] =
        useMemo(() => {
            const castedEntitlements: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[] =
                videoPlayer?.BroadcastEntitlements?.ProductEntitlements ?? [];
            const entitlementsMap = castedEntitlements?.reduce(
                (memo, currentEntitlement) => {
                    const existingEntry: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[] =
                        memo[
                            currentEntitlement?.VideoPlayerPlaylistBroadcastId
                        ];
                    return {
                        ...memo,
                        [currentEntitlement?.VideoPlayerPlaylistBroadcastId]:
                            exists(existingEntry)
                                ? [...existingEntry, currentEntitlement].sort(
                                      (a, b) =>
                                          new Date(
                                              b.CreatedAt
                                          ).getMilliseconds() -
                                          new Date(
                                              a.CreatedAt
                                          ).getMilliseconds()
                                  )
                                : [currentEntitlement]
                    };
                },
                {} as {
                    [
                        playlistBroadcasts: string
                    ]: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[];
                }
            );

            const playlistBroadcasts =
                videoPlayer?.VideoPlayer?.VideoPlayerPlaylists?.[0]
                    ?.VideoPlayerPlaylistBroadcasts ?? [];
            const cloudflareResponsesMap =
                videoPlayer?.cloudflareResponses?.reduce(
                    (memo, currentCloudflareResponse) => {
                        return {
                            ...memo,
                            [currentCloudflareResponse?.broadcast?.Id]:
                                currentCloudflareResponse
                        };
                    },
                    {} as {
                        [broadcastId: string]: VideoPlayerCloudflareResponse;
                    }
                );

            const mappedPlaylistBroadcasts: PlaylistBroadcastWithEntitlement[] =
                playlistBroadcasts.map((pb) => {
                    return {
                        ...pb,
                        entitlements: entitlementsMap[pb.Id],
                        cloudflareResponse:
                            cloudflareResponsesMap[pb.BroadcastId]
                    };
                });

            return mappedPlaylistBroadcasts;
        }, [videoPlayer]);

    const {
        dispatchApiRequest: addCreatorProductEntitlement,
        loading: addEntitlementLoading
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Create,
        {
            requestImmediately: false,
            onSuccess: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("messages:pass-assigned")
                    })
                );
                onAdd && onAdd();
                getVideoPlayer();
            },
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:pass-assignment")
                    })
                );
            }
        }
    );

    const {
        dispatchApiRequest: deleteCreatorProductEntitlement,
        loading: deleteEntitlementLoading
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Delete,
        {
            requestImmediately: false,
            onSuccess: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("messages:pass-removed")
                    })
                );
                onDelete && onDelete();
                getVideoPlayer();
            },
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:pass-removal")
                    })
                );
            }
        }
    );

    const loading: boolean = useMemo(() => {
        return (
            getCollectionLoading ||
            getCollectionProfileLoading ||
            addEntitlementLoading ||
            deleteEntitlementLoading
        );
    }, [
        getCollectionLoading,
        getCollectionProfileLoading,
        addEntitlementLoading,
        deleteEntitlementLoading
    ]);

    return {
        loading,
        videoPlayer,
        getVideoPlayer,
        playlistBroadcastsWithEntitlements,
        addCreatorProductEntitlement,
        deleteCreatorProductEntitlement
    };
};
