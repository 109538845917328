import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { TooltipBase } from "../TooltipBase";

export interface PlanChangeTooltipProps {
    display: boolean;
    plan: string;
    coupon?: string;
}

export const PlanChangeTooltip: React.FC<PlanChangeTooltipProps> = ({
    display,
    plan,
    coupon
}) => {
    const { t } = useTranslation("getting-started");

    return (
        <TooltipBase display={display} direction="right">
            <div className={styles["container"]}>
                <p>{t("plan-change-tooltip", { coupon, plan })}</p>
            </div>
        </TooltipBase>
    );
};
