import React, { Dispatch, SetStateAction } from "react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

export interface DeleteModalModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onSubmit: () => void;
    titleText: string;
    subMsg: string;
}

export const DeleteModal = ({
    isOpen,
    setIsOpen,
    onSubmit,
    titleText,
    subMsg
}: DeleteModalModalProps) => {
    const { t } = useTranslation();

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            stylesOverride={{
                maxWidth: "500px",
                padding: "0"
            }}
        >
            <div className={styles["delete-modal-content"]}>
                <h5>{titleText}</h5>
                <p>{subMsg}</p>
            </div>

            <div className={styles["delete-modal-buttons-container"]}>
                <button
                    type="button"
                    className="btn btn-text"
                    onClick={() => setIsOpen(false)}
                >
                    {t("buttons:cancel")}
                </button>

                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={onSubmit}
                >
                    {t("buttons:delete")}
                </button>
            </div>
        </ModalBase>
    );
};
