import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Button } from "components/buttons/Button";

export interface CouponPromptProps {
    onClick: () => void;
    variant?: "default" | "cell";
}

export const CouponPrompt: React.FC<CouponPromptProps> = ({
    onClick,
    variant = "default"
}) => {
    const { t } = useTranslation();

    if (variant === "cell") {
        return (
            <div className={styles["cell-prompt"]}>
                <Button type="link" onClick={onClick}>
                    {t("coupon-form:cell-prompt")}
                </Button>
            </div>
        );
    }

    return (
        <span className={styles["prompt"]}>
            <p>{t("coupon-form:prompt")}</p>
            <p onClick={onClick}>{t("coupon-form:cta")}</p>
        </span>
    );
};
