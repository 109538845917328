import {
    useParams as useReactRouterParams,
    useSearchParams as useReactRouterSearchParams
} from "react-router-dom";

export const useParams = () => {
    const routerParams = useReactRouterParams();
    const [routerSearchParams] = useReactRouterSearchParams();

    return { ...Object.fromEntries(routerSearchParams), ...routerParams };
};
