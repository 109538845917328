import React, { useCallback, useEffect, useMemo, useState } from "react";
import commonStyles from "./CommonStyles.module.scss";
import ReferralIcon from "assets/icons/referral.svg?react";
import { ReferralParticipantResponse } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { Widget } from "./Widget";

export const ReferralParticipantWidget: React.FC<
    ReferralParticipantResponse
> = () => {
    const { t } = useTranslation("widgets");
    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((state: RootState) => state.user);

    const [clipboardExists] = useState(!!navigator.clipboard);
    const [shareLink, setShareLink] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getShareLink = async () => {
            try {
                const shareResponse = await fetch(
                    `https://api.partnerstack.com/v1/partnerships/anonymous/switcherinc/${userInfo?.Email}`,
                    {
                        method: "POST"
                    }
                );

                if (shareResponse.ok) {
                    const responseJson = await shareResponse.json();
                    const responseRData = JSON.parse(responseJson.rdata);
                    setShareLink(responseRData.link);
                } else {
                    setShareLink(null);
                }
                setLoading(false);
            } catch (error) {
                setShareLink(null);
                setLoading(false);
            }
        };

        getShareLink();
    }, [userInfo]);

    const copyLink = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(shareLink);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-referral-link"
                })
            );
        }
    }, [dispatch, shareLink, clipboardExists]);

    const widgetProps = useMemo(
        () =>
            shareLink === null
                ? [
                      {
                          title: t("widgets:referral-share-link"),
                          infoText: t("widgets:referral-share-link-error")
                      }
                  ]
                : [
                      {
                          title: t("widgets:referral-share-link"),
                          buttonText: t("buttons:copy").toUpperCase(),
                          infoText: shareLink,
                          onClick: copyLink
                      }
                  ],
        [shareLink, copyLink, t]
    );

    const widgetParagraph = useMemo(
        () =>
            shareLink === null
                ? t("widgets:referral-paragraph-error")
                : t("widgets:new-referrer-paragraph"),
        [shareLink, t]
    );

    if (loading) return;

    return (
        <Widget
            loading={loading}
            icon={<ReferralIcon />}
            title={t("widgets:referral-header")}
            details={widgetProps}
        >
            <p className={commonStyles["paragraph-full"]}>{widgetParagraph}</p>
        </Widget>
    );
};
