import React from "react";

import { ResellerInventory } from "@switcherstudio/switcher-api-client";

import { InventoryCard } from "./InventoryCard";

import styles from "./ManageInventoriesPage.module.scss";

import { isInPast } from "helpers/time";

interface InventoriesGridProps {
    inventories: ResellerInventory[];
    shouldShowExpired: boolean;
}

export const InventoriesGrid: React.FC<InventoriesGridProps> = ({
    shouldShowExpired,
    inventories
}) => {
    return (
        <>
            <div className={styles["reseller-grid"]}>
                {inventories?.map(
                    (inventory: ResellerInventory, key: number) => {
                        if (isInPast(inventory.ValidUntil)) {
                            if (shouldShowExpired) {
                                return (
                                    <InventoryCard
                                        inventory={inventory}
                                        key={key}
                                    />
                                );
                            } else {
                                return <></>;
                            }
                        } else {
                            return (
                                <InventoryCard
                                    inventory={inventory}
                                    key={key}
                                />
                            );
                        }
                    }
                )}
            </div>
        </>
    );
};
