import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useEffect
} from "react";
import { GenericMultiSelectModal } from "../GenericMultiSelectModal";
import { useTranslation } from "react-i18next";
import { PricingDetails } from "./PricingDetails";
import { useGetCreatorProducts } from "../../../hooks/useGetCreatorProducts";
import { ComponentItem } from "components/generic-multiselect/types";
import {
    CreatorProduct,
    CreatorProductEntitlementsBindingModelDiscriminator
} from "@switcherstudio/switcher-api-client";

export interface PricingSelectModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    /** callback function to be called on modal's onSubmit. returns list of selected id (singular) */
    handleSelect: (selection: CreatorProduct) => void;
    /** Filters available options by the type of entitlement that is applicable */
    discriminator: CreatorProductEntitlementsBindingModelDiscriminator;
    products?: CreatorProduct[];
    preventDismiss?: boolean;
    selected?: string;
}

export const PricingSelectModal = ({
    isOpen,
    setIsOpen,
    handleSelect,
    products,
    discriminator,
    preventDismiss = false,
    selected
}: PricingSelectModalProps) => {
    const { products: fetchedProducts, getProducts } =
        useGetCreatorProducts(false);
    const { t } = useTranslation();

    const _products = useMemo(
        () => products ?? fetchedProducts,
        [products, fetchedProducts]
    );

    useEffect(() => {
        if (products || fetchedProducts) return;
        if (isOpen) {
            getProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, fetchedProducts, products]);

    const handlePricingSelect = useCallback(
        (ids: string[]) => {
            handleSelect(
                _products.find((product) => {
                    return ids.some((id) => id === product.Id);
                })
            );
        },
        [handleSelect, _products]
    );

    const byDiscriminator = useCallback(
        (product: CreatorProduct) => {
            switch (discriminator) {
                case CreatorProductEntitlementsBindingModelDiscriminator._0:
                    return product.Prices.some((p) => p.IsRecurring);
                case CreatorProductEntitlementsBindingModelDiscriminator._2:
                    return product.Prices.some((p) => !p.IsRecurring);
            }
        },
        [discriminator]
    );

    const filteredProducts = useMemo(
        () =>
            _products?.filter(byDiscriminator)?.filter(
                (p) =>
                    p.CreatorProductEntitlements.length === 0 ||
                    // @ts-expect-error - Handle multiple types
                    p.CreatorProductEntitlements.some((cpe) => !cpe.CatalogId)
            ),
        [_products, byDiscriminator]
    );

    return (
        <>
            {filteredProducts && (
                <GenericMultiSelectModal
                    isMultiple={false}
                    onSubmit={handlePricingSelect}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    submitHandlesClose={true}
                    previouslySelectedIds={[selected]}
                    allowUnselect={true}
                    titleText={t("gated-content-modal:choose-pricing")}
                    buttonText={t("buttons:save")}
                    allowNoSelection={true}
                    preventDismiss={preventDismiss}
                    showNone={true}
                    items={filteredProducts.map((p) => {
                        return {
                            id: p.Id,
                            component: <PricingDetails item={p} />
                        } as ComponentItem<CreatorProduct>;
                    })}
                />
            )}
        </>
    );
};
