import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import { getTwitchUserAndChannel } from "store/platforms/thunks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { usePlatformActions } from "hooks/usePlatformActions";
import { useTranslation } from "react-i18next";
import { PlatformId } from "views/page-content/platforms/types";
import { DestinationDescriptor } from "../../types";
import { updateGeneralForm } from "store/platforms/slice";

interface TwitchContainerProps {
    disableAll?: boolean;
}

export const SimulcastTwitchDestinationSelector: React.FC<
    TwitchContainerProps
> = ({}: TwitchContainerProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [destinations, setDestinations] = useState([]);
    const { user } = useSelector((s: RootState) => s.platforms.twitch);
    const { unlink } = usePlatformActions(PlatformId.Twitch);
    const { t } = useTranslation("platforms");
    const { generalForm } = useSelector(
        (state: RootState) => state.platforms.general
    );

    useEffect(() => {
        async function getChannelInfo() {
            try {
                await dispatch(getTwitchUserAndChannel());
            } catch (e) {
                await unlink();
                navigate(`/platforms/connect/${PlatformId.Twitch}`);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("token-expired", {
                            platformName: "Twitch"
                        })
                    })
                );
            }
        }

        getChannelInfo();
    }, [dispatch, navigate, unlink, t]);

    useEffect(() => {
        setDestinations([user]);
    }, [user]);

    const onClick = async (destination: any) => {
        if (
            generalForm.selectedSimulcastDestinations.some(
                (d) => d.id === destination.id
            )
        )
            return;

        const destinationDescriptor: DestinationDescriptor = {
            name: destination.display_name,
            id: destination.id,
            platform: PlatformId.Twitch
        };

        dispatch(
            updateGeneralForm({
                selectedSimulcastDestinations: [
                    ...generalForm.selectedSimulcastDestinations,
                    destinationDescriptor
                ]
            })
        );

        navigate(`/platforms/simulcast/destinations`);
    };

    return (
        <>
            <DestinationSelector
                destinations={destinations}
                imgSrcKey="profile_image_url"
                destinationNameKey="display_name"
                onDestinationClick={onClick}
            />
        </>
    );
};
