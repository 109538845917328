import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import DownloadIcon from "assets/icons/download.svg?react";

export interface ExportActionProps {
    onClick: () => void;
    disabled?: boolean;
}

export const ExportAction = ({
    onClick,
    disabled = false
}: ExportActionProps) => {
    const { t } = useTranslation();

    return (
        <span
            className={`${styles["action"]} ${disabled ? styles["disabled"] : ""}`}
            onClick={onClick}
        >
            <p>{t("analytics-page:export")}</p>
            <DownloadIcon />
        </span>
    );
};
