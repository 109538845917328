import React, { useCallback, useState, useEffect } from "react";
import styles from "./index.module.scss";
import UpIcon from "assets/icons/up-arrow.svg?react";

export const ScrollToTop: React.FC = () => {
    const [hideWhenOnTop, setHideWhenOnTop] = useState(true);

    useEffect(() => {
        window.onscroll = () => {
            if (!hideWhenOnTop && window.pageYOffset === 0)
                setHideWhenOnTop(true);
            else if (hideWhenOnTop) setHideWhenOnTop(false);
        };
        return () => (window.onscroll = null);
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <button
                className={`${styles["scroll-top-button"]} ${
                    styles[!hideWhenOnTop ? "visible" : ""]
                }`}
                onClick={scrollToTop}
            >
                <UpIcon />
                <div className="sr-only">Scroll to Top</div>
            </button>
        </>
    );
};
