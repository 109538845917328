import * as braze from "@braze/web-sdk";

const brazeEndpoint = import.meta.env.VITE_BRAZE_API_ENDPOINT;
const brazeKey = import.meta.env.VITE_BRAZE_API_KEY;
// initialize the SDK

const init = () => {
    if (brazeEndpoint && brazeKey) {
        braze.initialize(brazeKey, {
            baseUrl: brazeEndpoint,
            allowUserSuppliedJavascript: true
        });

        braze.automaticallyShowInAppMessages();
    }
};

const identify = (userId: string) => {
    if (userId) {
        braze.changeUser(userId);
    }
};

const open = () => {
    braze.openSession();
};

export default {
    init,
    identify,
    open
};
