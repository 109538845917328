import React, { HTMLInputTypeAttribute } from "react";
import { StyledCheckboxOrRadio } from "../checkbox/StyledCheckboxOrRadio";
import styles from "./index.module.scss";

export interface CheckboxInputProps {
    checkBoxId: string;
    inputId: string;
    checked: boolean;
    onCheckboxChange: () => void;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputType: HTMLInputTypeAttribute;
    inputName: string;
    value: string | number;
    min?: string | number;
    checkboxLabel?: string;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
}

export const CheckboxInput = ({
    checkBoxId,
    inputId,
    checked,
    onCheckboxChange,
    onInputChange,
    inputType,
    value,
    inputName,
    min,
    placeholder,
    error,
    disabled
}: CheckboxInputProps) => {
    return (
        <div
            className={`${styles["field-container"]} ${
                disabled ? styles["disabled"] : ""
            }`}
        >
            <StyledCheckboxOrRadio
                type="checkbox"
                id={checkBoxId}
                checked={checked}
                onChange={onCheckboxChange}
            />
            <div className={styles["input-column"]}>
                <div className={styles["label-row"]}>
                    <label htmlFor={checkBoxId}>{inputName}</label>
                </div>
                <div className={styles["price-row"]}>
                    {inputType === "number" && (
                        <span className={styles["price-currency"]}>US$</span>
                    )}
                    <input
                        id={inputId}
                        name={inputName}
                        type={inputType}
                        min={min}
                        className={`form-control ${styles["checkbox-input"]}`}
                        value={value}
                        placeholder={placeholder}
                        onChange={onInputChange}
                        disabled={!checked}
                        step="1.00"
                    />
                </div>

                {error && <div className={styles["error"]}>{error}</div>}
            </div>
        </div>
    );
};
