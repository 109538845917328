export interface NotificationState {
    notifications: Notification[];
    lastId: number;
}

export interface Notification {
    id: number;
    message: string;
    type: NotificationType;
    messageOptions?: {
        [key: string]: any;
    };
    linkComponents?: {
        key: string;
        to: string;
        title: string;
        onClick?: (...args: any) => any;
    }[];
    fadeMilliseconds?: number;
    clickText?: string;
    clickAction?: (...args: any) => any;
}

export type AddNotificationPayload = Omit<Notification, "id">;

export enum NotificationType {
    Success = "success",
    Info = "info",
    Danger = "danger"
}
