declare let growsumo: any;

const NOT_INITIALIZED_ERROR = "growsumo is not defined";

export default {
    notInitializedError: NOT_INITIALIZED_ERROR,
    registerCustomer: function (
        customerObj: { name; email; customer_key },
        callback?: any
    ) {
        if (!growsumo) throw new Error(NOT_INITIALIZED_ERROR);

        growsumo.data = {
            ...growsumo.data,
            name: customerObj.name,
            email: customerObj.email,
            customer_key: customerObj.customer_key
        };

        growsumo.createSignup((response) => {
            if (!!callback) callback(response);

            // because these values were set on a global object
            growsumo.data = {
                ...growsumo.data,
                name: "",
                email: "",
                customer_key: ""
            };
        });
    },
    getCurrentPartnerKey: function () {
        if (!growsumo) throw new Error(NOT_INITIALIZED_ERROR);
        return growsumo?.data?.partner_key;
    }
};
