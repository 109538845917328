import { StripePrice } from "@switcherstudio/switcher-api-client";
import styles from "./PlanDetails.module.scss";
import { useTranslation } from "react-i18next";
import { displayAmount } from "helpers/stripe";
import { useMemo } from "react";
export interface PlanDetailsProps {
    item: StripePrice;
    percentSavings?: { name: string; percent: number }[];
    disabled?: boolean;
}

export const PlanDetails = ({
    item,
    percentSavings,
    disabled
}: PlanDetailsProps) => {
    const { t } = useTranslation();
    /** if item is 7-day pass and is disabled, replace cost with explanatory copy. */
    const sevenDayCostUnavailable =
        item?.Name === "Switcher Studio 7-Day Pass" && disabled;
    const cost = item?.Amount;
    const costUnavailable = t("subscription:unavailable");
    const savings = percentSavings?.filter((ps) =>
        item?.Name?.includes(ps?.name)
    )?.[0]?.percent;

    const planName = useMemo(() => {
        switch (item?.Name) {
            case "Switcher Studio Monthly":
                return t("subscription:plans:studio-monthly");
            case "Switcher Studio Yearly":
                return t("subscription:plans:studio-annual");
            case "Business Monthly":
                return t("subscription:plans:business-monthly");
            case "Business Yearly":
                return t("subscription:plans:business-annual");
            case "Switcher Studio 7-Day Pass":
                return t("subscription:plans:pass");
            default:
                return item?.Name;
        }
    }, [item?.Name, t]);

    return (
        <div className={styles["plan-details-container"]}>
            <div
                className={`${styles["plan-details"]} ${
                    disabled ? styles["disabled"] : ""
                }`}
            >
                <h6>{planName}</h6>
                <span>
                    {
                        <div className={styles["plan-cost"]}>
                            {!sevenDayCostUnavailable
                                ? `${displayAmount(cost, {
                                      signed: true,
                                      compact: false,
                                      roundUp: false
                                  })}${
                                      item?.IsRecurring
                                          ? item?.RecurringInterval === "month"
                                              ? `/${t(
                                                    "gated-content-page:month"
                                                )}`
                                              : `/${t(
                                                    "gated-content-page:year"
                                                )}`
                                          : ` ${t(
                                                "subscription:one-time-charge"
                                            )}`
                                  } ${
                                      item?.RecurringInterval === "year"
                                          ? `(${Math.round(savings)}% savings)`
                                          : ""
                                  }`
                                : costUnavailable}
                        </div>
                    }
                </span>
            </div>
        </div>
    );
};
