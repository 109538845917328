import EmbedIcon from "assets/icons/embed.svg?react";
import styles from "./index.module.scss";
import { Button } from "components/buttons/Button";
import { useTranslation } from "react-i18next";

import { useEmbedCode } from "hooks/useEmbedCode";

interface CopyEmbedCodeProps {
    /** the catalog or collection Id */
    entityId: string;
    /** whether collection or catalog */
    variant: "catalog" | "collection";
}

const iconColor = {
    backgroundColor: "#0C4F60"
};

export const CopyEmbedCode = ({ entityId, variant }: CopyEmbedCodeProps) => {
    const { t } = useTranslation();
    const isCatalog = variant === "catalog";
    const { copyEmbedCode, embedCode } = useEmbedCode(variant, entityId);

    return (
        <div>
            <div className={`${styles["embed-widget"]}`}>
                <div className={styles["embed-title"]}>
                    <div className={styles["icon"]} style={iconColor}>
                        <EmbedIcon />
                    </div>
                    <div className={`${styles["title-text"]}`}>
                        {t("catalog-page:embed-title")}
                    </div>
                </div>

                <p className={`text-small ${styles["text-color"]}`}>
                    {isCatalog
                        ? t("catalog-page:embed-subhead")
                        : t("collection-page:embed-subhead")}
                </p>

                <div className={styles["embed-footer"]}>
                    <div className={`${styles["embed-button-container"]}`}>
                        <Button
                            type="outline"
                            onClick={copyEmbedCode}
                            title={embedCode}
                        >
                            {t("catalog-page:embed-action-copy")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
