import { exists } from "helpers/booleans";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useSwitcherClient } from "./useSwitcherClient";

interface useGetStripeConnectLinkProps {
    path?: string;
}

export const useGetStripeConnectLink = (
    { path = "/gated-content" }: useGetStripeConnectLinkProps = {
        path: "/gated-content"
    }
) => {
    const { UserId, FeatureClaims } = useSelector(
        (s: RootState) => s.user?.userInfo
    );
    const [isSetup, setIsSetup] = useState<boolean>();
    const [target, setTarget] = useState<string>();
    const [link, setLink] = useState<string>();

    const { dispatchApiRequest: getAccountLink } = useSwitcherClient(
        (client) => client.stripeConnect_GetAccountLink
    );

    useSwitcherClient((client) => client.stripeConnect_GetStripeAccount, {
        requestImmediately: FeatureClaims.some((c) => c === "gatedcontent"),
        args: [UserId],
        onError: (e) => {
            console.log(e);
        },
        onSuccess: async (data) => {
            const accountIsSetUpOrNeedDetails = exists(
                data?.accounts?.[0]?.DetailsSubmitted
            );
            setIsSetup(accountIsSetUpOrNeedDetails);

            if (accountIsSetUpOrNeedDetails) {
                setTarget("_blank");
                setLink("https://dashboard.stripe.com/login");
            } else {
                setLink(
                    (
                        await getAccountLink([
                            UserId,
                            new URL(path, document.baseURI).href
                        ])
                    ).url
                );
            }
        }
    });

    return {
        isSetup,
        target,
        link
    };
};
