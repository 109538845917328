import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export const useHasPlanRole = (
    role: "Studio" | "Business" | "Merchant" | "Grow"
) => {
    const { userInfo } = useSelector((s: RootState) => s.user);

    return userInfo?.Roles?.includes(role);
};
