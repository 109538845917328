import { VideoUploadStatus } from "store/uploads/types";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { startPollingUpload } from "store/uploads/thunks";
import { AppDispatch } from "store/store";

export interface UploadProps {
    broadcastId?: string;
}

/**
 * Monitor the state of processing uploads.
 */
export const useVideoUpload = () => {
    const { processingOrQueued, ready } = useSelector(
        (state: RootState) => state.uploads
    );
    const dispatch = useDispatch<AppDispatch>();

    const pollUpload = useCallback(
        async (uploadProps: UploadProps) => {
            dispatch(startPollingUpload(uploadProps));
        },
        [dispatch]
    );

    const getUploadStatus = useCallback(
        (uploadProps: UploadProps) => {
            const isInProcessing = !!processingOrQueued?.find(
                (ul) => ul?.broadcastId === uploadProps?.broadcastId
            );

            const isInReady = !!ready?.find(
                (ul) => ul?.broadcastId === uploadProps?.broadcastId
            );

            if (isInProcessing) return VideoUploadStatus.Processing;

            if (isInReady) return VideoUploadStatus.Success;
        },
        [processingOrQueued, ready]
    );

    return {
        /** Ping Cloudflare with an API call every ten seconds to get upload status */
        pollUpload,
        /** Initiate polling to ping Cloudflare with an API call every ten seconds to get upload status */
        getUploadStatus,
        /** List of broadcasts that are already uploaded. Broadcasts are added to this state after polling. */
        ready
    };
};
