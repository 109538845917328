import { GraphCard } from "components/cards/GraphCard";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { reorderAnalyticsViewsCardOrder } from "store/view/slice";
import { AnalyticsViewsCards } from "store/view/types";
import { ReorderCardAction } from "./ReorderCardAction";
import { useTranslation } from "react-i18next";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable
} from "react-beautiful-dnd";
import { move } from "helpers/arrays";
import { Switch } from "components/utility/Switch";
import { Case } from "components/utility/Case";
import styles from "./index.module.scss";
import { MetricsSummaryResponse } from "@switcherstudio/switcher-api-client";
import { AnalyticsLineGraph } from "components/graphs/AnalyticsLineGraph";
import { useAnalyticsExport } from "hooks/useAnalyticsExport";
import { ExportAction } from "./ExportAction";
import { displayDate } from "helpers/time";
import { AnalyticTabProps } from "./ReorderCardAction/types";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

export const ViewsTab = ({
    metrics,
    metricsLoading
}: AnalyticTabProps<MetricsSummaryResponse>) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const exportCSV = useAnalyticsExport(metrics?.DateSummary, {
        onEmpty: () =>
            dispatch(
                addNotification({
                    message: t("analytics-page:empty-dataset-error"),
                    type: NotificationType.Danger
                })
            ),
        onSuccess: () =>
            dispatch(
                addNotification({
                    message: t("analytics-page:export-success"),
                    type: NotificationType.Success
                })
            )
    });

    const { analyticsViewsCardOrder } = useSelector(
        (state: RootState) => state.view
    );

    const handleOnDragEnd = useCallback(
        ({ source, destination }: DropResult) => {
            dispatch(
                reorderAnalyticsViewsCardOrder(
                    move(
                        analyticsViewsCardOrder,
                        source.index,
                        destination.index
                    )
                )
            );
        },
        [analyticsViewsCardOrder, dispatch]
    );

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="analytics-views-tab">
                {(droppableProvided) => (
                    <div
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                    >
                        {analyticsViewsCardOrder.map((card, index) => (
                            <Draggable
                                draggableId={card}
                                key={card}
                                index={index}
                            >
                                {(draggableProvided) => (
                                    <div
                                        className={styles["card"]}
                                        {...draggableProvided.draggableProps}
                                        ref={draggableProvided.innerRef}
                                    >
                                        <Switch test={card}>
                                            <Case<AnalyticsViewsCards>
                                                value={
                                                    AnalyticsViewsCards.Views
                                                }
                                            >
                                                <GraphCard
                                                    key={
                                                        AnalyticsViewsCards.Views
                                                    }
                                                    title={
                                                        t(
                                                            "analytics-page:views"
                                                        ) + ":"
                                                    }
                                                    loading={metricsLoading}
                                                    total={metrics?.TotalViews}
                                                    dragHandleProps={
                                                        draggableProvided.dragHandleProps
                                                    }
                                                    actions={[
                                                        <ExportAction
                                                            key="export"
                                                            onClick={() =>
                                                                exportCSV(
                                                                    (d) => {
                                                                        return {
                                                                            WatchDate:
                                                                                d.WatchDate,
                                                                            Views: d.Views
                                                                        };
                                                                    },
                                                                    `Switcher-Views-Analytics-${displayDate(
                                                                        new Date(),
                                                                        i18n.language,
                                                                        "YYYY-MM-DD"
                                                                    )}.csv`
                                                                )
                                                            }
                                                            disabled={
                                                                metricsLoading
                                                            }
                                                        />
                                                    ]}
                                                    handle={
                                                        <ReorderCardAction />
                                                    }
                                                    graph={
                                                        <AnalyticsLineGraph
                                                            loading={
                                                                metricsLoading
                                                            }
                                                            dateSummary={
                                                                metrics?.DateSummary
                                                            }
                                                            property="Views"
                                                            dateProperty="WatchDate"
                                                        />
                                                    }
                                                />
                                            </Case>
                                            <Case<AnalyticsViewsCards>
                                                value={
                                                    AnalyticsViewsCards.WatchTime
                                                }
                                            >
                                                <GraphCard
                                                    key={
                                                        AnalyticsViewsCards.WatchTime
                                                    }
                                                    title={
                                                        t(
                                                            "analytics-page:watchedseconds"
                                                        ) + ":"
                                                    }
                                                    loading={metricsLoading}
                                                    total={
                                                        metrics?.TotalWatchedSeconds
                                                    }
                                                    actions={[
                                                        <ExportAction
                                                            key="export"
                                                            onClick={() =>
                                                                exportCSV(
                                                                    (d) => {
                                                                        return {
                                                                            WatchDate:
                                                                                d.WatchDate,
                                                                            WatchedSeconds:
                                                                                d.WatchedSeconds
                                                                        };
                                                                    },
                                                                    `Switcher-WatchedSeconds-Analytics-${displayDate(
                                                                        new Date(),
                                                                        i18n.language,
                                                                        "YYYY-MM-DD"
                                                                    )}.csv`
                                                                )
                                                            }
                                                            disabled={
                                                                metricsLoading
                                                            }
                                                        />
                                                    ]}
                                                    handle={
                                                        <ReorderCardAction />
                                                    }
                                                    dragHandleProps={
                                                        draggableProvided.dragHandleProps
                                                    }
                                                    graph={
                                                        <AnalyticsLineGraph
                                                            loading={
                                                                metricsLoading
                                                            }
                                                            dateSummary={
                                                                metrics?.DateSummary
                                                            }
                                                            property="WatchedSeconds"
                                                            dateProperty="WatchDate"
                                                            unit="time"
                                                        />
                                                    }
                                                    unit="time"
                                                />
                                            </Case>
                                            <Case<AnalyticsViewsCards>
                                                value={
                                                    AnalyticsViewsCards.UniqueViewers
                                                }
                                            >
                                                <GraphCard
                                                    key={
                                                        AnalyticsViewsCards.UniqueViewers
                                                    }
                                                    title={
                                                        t(
                                                            "analytics-page:unique-viewers"
                                                        ) + ":"
                                                    }
                                                    loading={metricsLoading}
                                                    total={
                                                        metrics?.TotalViewers
                                                    }
                                                    graph={
                                                        <AnalyticsLineGraph
                                                            loading={
                                                                metricsLoading
                                                            }
                                                            dateSummary={
                                                                metrics?.DateSummary
                                                            }
                                                            property="Viewers"
                                                            dateProperty="WatchDate"
                                                        />
                                                    }
                                                    actions={[
                                                        <ExportAction
                                                            key="export"
                                                            onClick={() =>
                                                                exportCSV(
                                                                    (d) => {
                                                                        return {
                                                                            WatchDate:
                                                                                d.WatchDate,
                                                                            Viewers:
                                                                                d.Viewers
                                                                        };
                                                                    },
                                                                    `Switcher-Viewers-Analytics-${displayDate(
                                                                        new Date(),
                                                                        i18n.language,
                                                                        "YYYY-MM-DD"
                                                                    )}.csv`
                                                                )
                                                            }
                                                            disabled={
                                                                metricsLoading
                                                            }
                                                        />
                                                    ]}
                                                    handle={
                                                        <ReorderCardAction />
                                                    }
                                                    dragHandleProps={
                                                        draggableProvided.dragHandleProps
                                                    }
                                                />
                                            </Case>
                                        </Switch>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
