import React, { PropsWithChildren } from "react";
import styles from "./index.module.scss";
import { exists } from "helpers/booleans";
export interface DetailProps extends PropsWithChildren {
    title?: string | JSX.Element;
    infoText?: string;
    buttonText?: string;
    onClick?: () => void;
}

export const Detail: React.FC<DetailProps> = ({
    title,
    infoText,
    children,
    buttonText,
    onClick
}) => {
    return (
        <div
            className={`${styles["detail-container"]} ${
                onClick ? styles["interactable"] : ""
            }`}
            onClick={onClick}
        >
            {title && <h6 className={styles["account-title"]}>{title}</h6>}

            {children ? (
                <div className={styles["account-info-text"]}>{children}</div>
            ) : (
                <span>
                    <p
                        className={
                            exists(title)
                                ? styles["account-info-text"]
                                : styles["account-info-text-bold"]
                        }
                    >
                        {infoText}
                    </p>
                    {buttonText && (
                        <button className={`btn ${styles[`inline-button`]}`}>
                            {buttonText}
                        </button>
                    )}
                </span>
            )}
        </div>
    );
};
