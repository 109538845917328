import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { PaymentFormProps } from "views/page-content/subscription/types";
import { StripeCardElementOptions } from "@stripe/stripe-js";

export const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    iconStyle: "solid",
    classes: {
        base: "custom-card-element",
        invalid: "custom-card-element--invalid",
        focus: ".custom-card-element--focus",
        webkitAutofill: "custom-card-element--webkit-autofill"
    },
    style: {
        base: {
            iconColor: "rgba(237, 86, 35, 0.5)"
        }
    }
};

export const PaymentForm: React.FC<PaymentFormProps> = ({
    label,
    buttonText,
    error,
    busy,
    hideCardElement,
    onChange,
    onSubmit,
    children
}: PaymentFormProps) => {
    return (
        <form onSubmit={onSubmit}>
            {!hideCardElement ? (
                <div className="form-group">
                    <label htmlFor="card-element">{label}</label>
                    <CardElement
                        id="card-element"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={onChange}
                    />
                </div>
            ) : (
                children
            )}
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
            <button type="submit" className="btn btn-primary" disabled={busy}>
                {buttonText}
            </button>
        </form>
    );
};
