import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface PasswordCriteriaProps {
    passwordValidMap: {
        hasMinLength: boolean;
        hasLower: boolean;
        hasUpper: boolean;
        hasDigit: boolean;
        hasSpecialChar: boolean;
    };
}

export const PasswordCriteria: React.FC<PasswordCriteriaProps> = ({
    passwordValidMap
}) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles["password-rules"]}`}>
            <span>{t("create-account:password-req")}</span>
            <ul className={`${styles["password-list"]}`}>
                <li
                    className={
                        passwordValidMap.hasMinLength
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-min")}
                </li>
                <li
                    className={
                        passwordValidMap.hasLower
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-lower")}
                </li>
                <li
                    className={
                        passwordValidMap.hasUpper
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-upper")}
                </li>
                <li
                    className={
                        passwordValidMap.hasDigit
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-digit")}
                </li>
                <li
                    className={
                        passwordValidMap.hasSpecialChar
                            ? `${styles["li-checked"]}`
                            : ""
                    }
                >
                    {t("create-account:password-special")}
                </li>
            </ul>
        </div>
    );
};
