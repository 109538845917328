import { useCallback } from "react";
import {
    OnboardingSurveyResults,
    OnboardingSurveyResultsEvent,
    surveyCardOptions,
    surveyMonetizeOptions,
    surveyStreamOptions,
    surveyVideoOptions,
    surveySiteOptions,
    surveyProductSalesOptions
} from "views/page-content/onboarding/stepContent/SurveyStep/types";
import { postEvents } from "store/events/thunks";
import { trackEvent } from "helpers/analyticsHelpers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { exists } from "helpers/booleans";

const mapSurveyResultsData = (
    surveyResults: OnboardingSurveyResults
): OnboardingSurveyResultsEvent => {
    let out = {};

    surveyCardOptions.forEach(
        (o) => (out[`options.${o}`] = surveyResults.selectedCards?.has(o))
    );

    surveyStreamOptions.forEach(
        (o) =>
            (out[`destinations.${o}`] =
                surveyResults.selectedStreamOptions?.has(o))
    );

    surveyMonetizeOptions.forEach(
        (o) =>
            (out[`monetization.${o}`] =
                surveyResults.selectedMonetizeOptions?.has(o))
    );

    surveyVideoOptions.forEach(
        (o) => (out[`videos.${o}`] = surveyResults.selectedVideoOptions?.has(o))
    );

    surveySiteOptions
        .filter((o) => o === "has-site")
        .forEach(
            (o) =>
                (out[`site.${o}`] = surveyResults.selectedSiteOptions?.has(o))
        );

    surveyProductSalesOptions.forEach(
        (o) =>
            (out[`products.${o}`] =
                surveyResults.selectedProductSalesOptions?.has(o))
    );

    if (exists(surveyResults.siteUrl)) {
        out["site.url"] = surveyResults.siteUrl;
    }

    if (exists(surveyResults.skippedSurveyStep)) {
        out["skipped-step"] = surveyResults.skippedSurveyStep;
    }
    return out as OnboardingSurveyResultsEvent;
};

export const useOnboardingSurveyResults = () => {
    const { events } = useSelector((state: RootState) => state.events);
    const dispatch = useDispatch<AppDispatch>();

    const postSurveyResults = useCallback(
        (data: OnboardingSurveyResults) => {
            const surveyResults = mapSurveyResultsData(data);
            dispatch(
                postEvents({
                    surveyResults
                })
            );
            trackEvent("Onboarding Survey Results", surveyResults);
        },
        [dispatch]
    );

    return {
        results: events?.["surveyResults"] as OnboardingSurveyResultsEvent,
        postSurveyResults
    };
};
