import React, { useState, useCallback, useRef } from "react";
import { trackEvent } from "helpers/analyticsHelpers";
import styles from "./index.module.scss";
import { TutorialVideoType } from "./types";
import { Stream } from "@cloudflare/stream-react";
import Loading from "components/loading/Loading";

interface TutorialVideoProps {
    video: TutorialVideoType;
    /** If you include this at all, the video will autoplay. Including if it's set to false. */
    autoplay?: boolean;
}

export const TutorialVideo: React.FC<TutorialVideoProps> = ({
    video,
    autoplay = false
}) => {
    const iframeContainerRef = useRef(null);
    const [iframeLoaded, setIframeLoaded] = useState<boolean>(autoplay);
    const [canPlay, setCanPlay] = useState<boolean>(false);

    const loadIframe = useCallback(() => {
        if (iframeLoaded) {
            return;
        }
        setIframeLoaded(true);
    }, [iframeLoaded]);

    const extractUrl = useCallback((video: TutorialVideoType) => {
        let splitUrl = video.url.split("/");
        return (
            "https://customer-5g1vs7rwra6vdqdo.cloudflarestream.com/" +
            splitUrl[3] +
            "/iframe?autoplay=true"
        );
    }, []);

    return (
        <div
            ref={iframeContainerRef}
            className={`${styles["tutorial-video"]} ${
                iframeLoaded ? styles["loaded"] : ""
            }`}
            onClick={loadIframe}
        >
            {!autoplay && (
                <img
                    className={styles["thumbnail"]}
                    src={video.thumbnailUrl}
                    alt={video.name}
                />
            )}
            <div className={styles["playbtn"]}></div>
            {iframeLoaded && (
                <>
                    <Loading isLoading={!canPlay} />
                    <Stream
                        controls
                        onCanPlay={() => {
                            setCanPlay(true);
                        }}
                        src={extractUrl(video)}
                        onPlay={() => {
                            trackEvent("Viewed Tutorial Video", {
                                title: video.name
                            });
                        }}
                    />
                </>
            )}
        </div>
    );
};
