import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";
import styles from "./index.module.scss";

export interface CardProps
    extends PropsWithChildren,
        DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    position?: "background" | "foreground";
}

export const Card: React.FC<CardProps> = (props) => (
    <div
        className={`card ${props.className} ${styles["container"]} ${
            styles[props.position]
        }`}
    >
        {props.children}
    </div>
);
