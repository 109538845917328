import React from "react";
import styles from "./BrandProfileStyles.module.scss";
import { Errors } from "hooks/useForm";
import { useTranslation } from "react-i18next";
import { ColorPicker } from "components/inputs/colorpicker/ColorPicker";

interface BrandColorsFormProps {
    onChange: (values: string, key: string) => void;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    errors: Errors;
}

export const BrandColorsForm: React.FC<BrandColorsFormProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <h4 className={styles["form-card-title"]}>
                {t("brand-profile:brand-colors")}
            </h4>
            <ColorPicker
                id="primaryColor"
                label={t("brand-profile:primary-color")}
                value={props.primaryColor}
                error={props.errors.primaryColor}
                onChange={(e) => {
                    props.onChange(e.target.value, "primaryColor");
                }}
                allowText
                variation
            />
            <ColorPicker
                id="secondaryColor"
                label={t("brand-profile:secondary-color")}
                value={props.secondaryColor}
                error={props.errors.secondaryColor}
                onChange={(e) => {
                    props.onChange(e.target.value, "secondaryColor");
                }}
                allowText
                variation
            />
            <ColorPicker
                id="tertiaryColor"
                label={t("brand-profile:tertiary-color")}
                value={props.tertiaryColor}
                error={props.errors.tertiaryColor}
                onChange={(e) => {
                    props.onChange(e.target.value, "tertiaryColor");
                }}
                allowText
                variation
            />
            {props.errors.api && (
                <div className="alert alert-danger" role="alert">
                    {props.errors.api}
                </div>
            )}
        </>
    );
};
