import { StripePrice } from "@switcherstudio/switcher-api-client";
import { CouponApplied } from "components/alerts/CouponApplied";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ConfirmSubscriptionPage.module.scss";

export const PlanSummary = ({
    plan,
    onChangePlan
}: {
    plan: StripePrice;
    onChangePlan: () => void;
}) => {
    const { t } = useTranslation();
    const { claimedCoupon } = useGetLastClaimedCoupon();

    return (
        <div className={styles["plan-summary-details"]}>
            <div className={styles["plan-summary-content"]}>
                <div className={styles["plan-name-description"]}>
                    <h6>{plan?.Name}</h6>
                    <small>{plan?.Product?.Description}</small>
                </div>

                <button className={`btn btn-link`} onClick={onChangePlan}>
                    {t("subscription:change-plan")}
                </button>
            </div>
            {!!claimedCoupon && (
                <CouponApplied coupon={claimedCoupon} hideRemoveButton />
            )}
        </div>
    );
};
