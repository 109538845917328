import { VideoPlayerResponse } from "@switcherstudio/switcher-api-client";
import { useCallback, useState } from "react";
import { useSwitcherClient } from "../useSwitcherClient";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useCombineLoading } from "hooks/useCombineLoading";

export interface usePutCollectionOptions {
    // autosave?: boolean;
    onSuccess?: (collection: VideoPlayerResponse) => void;
    onError?: (collection: VideoPlayerResponse) => void;
}

export const usePutCollection = (
    videoPlayerId: string,
    { onSuccess, onError }: usePutCollectionOptions = {}
) => {
    const user = useSelector((state: RootState) => state.user);

    const [localCollectionResponse, setLocalCollectionResponse] =
        useState<VideoPlayerResponse>(null);

    const [initCollectionResponse, setInitCollectionResponse] =
        useState<VideoPlayerResponse>(null);

    const [unchanged, setUnchanged] = useState<boolean>(true);

    const {
        loading: getVideoCollectionLoading,
        dispatchApiRequest: getVideoCollection
    } = useSwitcherClient((client) => client.videoPlayers_GetVideoPlayer, {
        requestImmediately: true,
        hideLoading: true,
        args: [videoPlayerId],
        onSuccess: (data) => {
            setLocalCollectionResponse(data);
            setInitCollectionResponse(data);
        }
    });

    const reset = useCallback((): void => {
        setUnchanged(true);
        setLocalCollectionResponse(initCollectionResponse);
    }, [initCollectionResponse]);

    const {
        loading: updateCollectionLoading,
        dispatchApiRequest: updateCollection
    } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_PutVideoPlayer,
        {
            hideLoading: true,
            onSuccess: async (data) => {
                onSuccess?.(data);
                await getVideoCollection();
                setUnchanged(true);
            },
            onError: async (data) => {
                onError?.(data);
                reset();
                await getVideoCollection();
            }
        }
    );

    const onChange = <T extends keyof VideoPlayerResponse["VideoPlayer"]>(
        key: T,
        value: VideoPlayerResponse["VideoPlayer"][T]
    ) => {
        setUnchanged(false);
        setLocalCollectionResponse((c) => ({
            ...c,
            VideoPlayer: {
                ...c.VideoPlayer,
                [key]: value === "" ? null : value
            }
        }));
    };

    const update = useCallback(async () => {
        await updateCollection([
            videoPlayerId,
            localCollectionResponse,
            user?.userInfo?.ProjectId
        ]);
    }, [localCollectionResponse, updateCollection, user, videoPlayerId]);

    // Disabled for now for performance costs
    // const unchanged = useMemo(
    //     () => deepEqual(initCollectionResponse, localCollectionResponse),
    //     [initCollectionResponse, localCollectionResponse]
    // );

    const loading = useCombineLoading(
        updateCollectionLoading,
        getVideoCollectionLoading
    );

    // useEffect(() => {
    //     if (autosave && !unchanged) {
    //         update();
    //     }
    // }, [autosave, unchanged, update]);

    return {
        loading,
        saving: updateCollectionLoading,
        unchanged,
        collection: localCollectionResponse,
        getVideoCollection,
        reset,
        update,
        onChange
    };
};
