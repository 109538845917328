import styles from "./index.module.scss";
import DragIndicatorIcon from "assets/icons/drag_indicator.svg?react";

export const ReorderCardAction = () => {
    return (
        <span className={styles["container"]}>
            <DragIndicatorIcon />
        </span>
    );
};
