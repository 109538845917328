import {
    StripeAccount,
    StripeAccountsDetails
} from "@switcherstudio/switcher-api-client";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useClaimCheck } from "./useClaimCheck";
import { useSwitcherClient } from "./useSwitcherClient";

export enum GatedContentStatus {
    FEATURE_DISABLED,
    STRIPE_ACCOUNT_NOT_SETUP,
    STRIPE_ACCOUNT_UNVERIFIED,
    READY
}

export interface StripeAccountInfo {
    expandDetails?: boolean;
    hideLoading?: boolean;
}

export interface UseStripeAccountInfoReturnObject {
    /** Our StripeAccount object */
    account: StripeAccount;
    /** Stripe Account info from Stripe (Stripe.Account redacted) */
    details: StripeAccountsDetails;
    /** Setup status of Stripe account */
    gatedContentStatus: GatedContentStatus;
    /** Boolean to indicate if data is in the process of being retrieved */
    loading: boolean;
}

/**
 * Retrieves relevant information to connected Stripe account.
 */
export const useStripeAccountInfo = (
    { expandDetails, hideLoading }: StripeAccountInfo = { expandDetails: false }
): UseStripeAccountInfoReturnObject => {
    const userId = useSelector((s: RootState) => s.user?.userInfo?.UserId);
    const enablePayPerView = useClaimCheck("gatedcontent");

    // Manage loading state locally, since the client does not request immediately
    // (so its loading defaults to false, causing a flash of the wrong content)
    const [loading, setLoading] = useState<boolean>(true);

    const { dispatchApiRequest: getStripeAccount, data: res } =
        useSwitcherClient((client) => client.stripeConnect_GetStripeAccount, {
            hideLoading,
            onSuccess: () => setLoading(false),
            onError: () => setLoading(false)
        });

    const account = useMemo(() => res?.accounts?.[0], [res]);
    const details = useMemo(() => res?.Details?.[0], [res]);

    useEffect(() => {
        if (userId) {
            getStripeAccount([userId, expandDetails]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandDetails, userId]);

    const gatedContentStatus = useMemo(() => {
        if (!enablePayPerView) return GatedContentStatus.FEATURE_DISABLED;
        if (!account || (!account.DetailsSubmitted && !account.ChargesEnabled))
            return GatedContentStatus.STRIPE_ACCOUNT_NOT_SETUP;
        if (!account.DetailsSubmitted || !account.ChargesEnabled)
            return GatedContentStatus.STRIPE_ACCOUNT_UNVERIFIED;
        return GatedContentStatus.READY;
    }, [enablePayPerView, account]);

    return { account, details, gatedContentStatus, loading };
};
