import React, { useState, useEffect } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { FormWrapper } from "../../forms/FormWrapper";
import styles from "./SimulcastDestinations.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { SimulcastDestinationDisplay } from "./simulcast-destination-display/SimulcastDestinationDisplay";
import { AppDispatch } from "store/store";
import { SimulcastDestinationCard } from "./simulcast-destination-card/SimulcastDestinationCard";
import { PlatformId } from "views/page-content/platforms/types";
import rollbar from "helpers/rollbar";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useSimulcastFork } from "hooks/useSimulcastFork";
import { useClaimCheck } from "hooks/useClaimCheck";

export const SimulcastDestinations: React.FC = () => {
    const [avaliablePlatforms, setAvailablePlatforms] =
        useState<StreamingProvider[]>();
    const { t } = useTranslation();
    const { t: simulcastT } = useSimulcastFork();
    const navigate = useNavigate();

    const isMountedRef = useIsMountedRef();
    const generalForm = useSelector(
        (s: RootState) => s.platforms.general.generalForm
    );
    const user = useSelector((state: RootState) => state.user);
    const userMaxDests =
        user.userInfo?.FeatureLimitClaims?.[
            "cloudflaresimulcasting:maxdestinations"
        ];
    const dispatch = useDispatch<AppDispatch>();
    const [canSimulcast, setCanSimulcast] = useState<boolean>(false);
    const { isInBrowser, switcherSdk } = useSwitcherSdk(true);

    // Are we offering the Video Library as App Hub experience?
    const isVLH = useClaimCheck("vlh");

    useEffect(() => {
        async function run() {
            try {
                const supportedPlatforms = [
                    PlatformId.Facebook,
                    PlatformId.Youtube,
                    PlatformId.Twitch,
                    PlatformId.VideoPlayer,
                    PlatformId.AmazonLive,
                    PlatformId.Instafeed,
                    PlatformId.Caast,
                    PlatformId.MsStream
                ];
                const response =
                    await client.streamingProviders_GetStreamingProviders();
                let providers = response.StreamingProviders;

                if (!isInBrowser && switcherSdk?.targetAspectRatio) {
                    // If in the app, filter available platforms by the target aspect ratio
                    const width = switcherSdk?.targetAspectRatio?.numer;
                    const height = switcherSdk?.targetAspectRatio?.denom;
                    providers = providers.filter((p) => {
                        return p.AspectRatios?.some(
                            (ar) => ar.Width === width && ar.Height === height
                        );
                    });
                }

                const customChannelRes =
                    await client.switcherStreamSettings_GetAllSwitcherStudioSettings();
                const rtmpChannels = customChannelRes.filter(
                    (a) => a["user-editable"]
                );

                if (isMountedRef.current) {
                    const hasLinkedProveders = !!providers.filter(
                        (p) => p.IsLinked && p.Id !== "simulcast"
                    ).length;

                    const hasRtmpChannels = !!rtmpChannels.length;
                    const canCast = hasLinkedProveders || hasRtmpChannels;
                    const supportedProviders = providers.filter((p) =>
                        supportedPlatforms.includes(p.Id as PlatformId)
                    );
                    supportedProviders.push({
                        Id: "custom",
                        Name: "RTMP",
                        IsLinked: !!rtmpChannels.length
                    });
                    setCanSimulcast(canCast);
                    setAvailablePlatforms([
                        ...supportedProviders.sort(
                            (a, b) => Number(b.IsLinked) - Number(a.IsLinked)
                        )
                    ]);
                }
            } catch (e) {
                rollbar.error("Error resetting simulcast destinations", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:reset-error"
                    })
                );
            }
        }

        if (isMountedRef.current) {
            run();
        }
    }, [isMountedRef, dispatch, isInBrowser, switcherSdk]);

    /**
     * A provider should be disabled if it is a single use provider
     * and already added to the destinations, or if VLH is not enabled
     * and the provider is not linked
     * @param p StreamingProvider
     * @returns Boolean
     */
    const isPlatformDisabled = (p: StreamingProvider) => {
        const singleUseProviders: PlatformId[] = [
            PlatformId.VideoPlayer,
            PlatformId.AmazonLive,
            PlatformId.Caast,
            PlatformId.Instafeed,
            PlatformId.MsStream
        ];
        return singleUseProviders.includes(p.Id as PlatformId)
            ? generalForm.selectedSimulcastDestinations.some(
                  (d) => d.platform === p.Id
              )
            : !isVLH && !p.IsLinked;
    };

    // @param platformId: platform to connect
    // @param connect: connect or add
    const platformClickHandler = (platformId: string, connect: boolean) => {
        // TODO: Add qs params back to the url
        const numberOfSelectedDests =
            generalForm.selectedSimulcastDestinations.filter(
                (d) => d.platform !== PlatformId.VideoPlayer
            ).length;
        const isPlayerPlatform = platformId === PlatformId.VideoPlayer;

        if (numberOfSelectedDests === userMaxDests && !isPlayerPlatform) {
            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: `You have reached your maximum number of Multistream destinations ${numberOfSelectedDests} of ${userMaxDests}`
                })
            );
            return;
        }

        navigate(
            `/platforms/simulcast/destinations/${platformId}/${
                connect ? "connect" : "add"
            }`
        );
    };

    const doneClickHandler = () => {
        navigate("/platforms/simulcast/create");
    };

    return (
        // TODO: If there are no linked platforms and no custom RTMP destinations show message.
        <>
            {canSimulcast ? (
                <>
                    <div className={styles["continue-btn-container"]}>
                        <button
                            disabled={
                                !generalForm.selectedSimulcastDestinations
                                    .length
                            }
                            onClick={doneClickHandler}
                            className={`btn btn-primary ${styles["continue-btn"]}`}
                        >
                            continue
                        </button>
                    </div>
                    <SimulcastDestinationDisplay isDark={true} />
                    <FormWrapper>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>
                                    {simulcastT("select-simulcast-destination")}
                                </h2>
                                <small>
                                    <p className="mb-2 text-muted">
                                        {simulcastT(
                                            "select-simulcast-destination-sub"
                                        )}
                                    </p>
                                </small>
                                <div className={styles["platforms-container"]}>
                                    {avaliablePlatforms?.map((ap, i) => (
                                        <SimulcastDestinationCard
                                            platform={ap}
                                            key={i}
                                            isDisabled={isPlatformDisabled(ap)}
                                            clickHandler={() =>
                                                platformClickHandler(
                                                    ap.Id,
                                                    !ap.IsLinked
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                </>
            ) : (
                <>
                    <FormWrapper>
                        <div>
                            <h2>{simulcastT("start-simulcasting")}</h2>
                            <p>{simulcastT("no-socials")}</p>
                            <Link to="/platforms">
                                {t("page-titles:platforms")}
                            </Link>
                        </div>
                    </FormWrapper>
                </>
            )}
        </>
    );
};
