import React from "react";
import AmazonLiveIcon from "assets/icons/amazon.svg?react";
import FacebookIcon from "assets/icons/facebook.svg?react";
import YouTubeIcon from "assets/icons/youtube.svg?react";
import TwitchIcon from "assets/icons/twitch.svg?react";
import MsStreamIcon from "assets/icons/msstream.svg?react";
import GenericPlatformIcon from "assets/icons/platform.svg?react";
import AzureMediaServicesIcon from "assets/icons/azuremediaservices.svg?react";
import VideoPlayerIcon from "assets/icons/switcher-player.svg?react";

import { PlatformId } from "views/page-content/platforms/types";

interface PlatformIconProps {
    platformId: string;
    iconUrl?: string;
}

export const PlatformIcon: React.FC<PlatformIconProps> = ({
    platformId
}: PlatformIconProps) => {
    return (
        <span>
            {(function () {
                switch (platformId) {
                    case PlatformId.AmazonLive:
                        return <AmazonLiveIcon />;
                    case PlatformId.AzureMediaServices:
                        return <AzureMediaServicesIcon />;
                    case PlatformId.Facebook:
                        return <FacebookIcon />;
                    case PlatformId.Youtube:
                        return <YouTubeIcon />;
                    case PlatformId.Twitch:
                        return <TwitchIcon />;
                    case PlatformId.MsStream:
                        return <MsStreamIcon />;
                    case PlatformId.VideoPlayer:
                        return <VideoPlayerIcon />;
                    default:
                        return <GenericPlatformIcon />;
                }
            })()}
        </span>
    );
};
