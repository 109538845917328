import { useEffect } from "react";
import { getZIndex } from "helpers/styles";

export default function useClickOutside(
    ref: React.MutableRefObject<HTMLElement>,
    action
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;

            if (
                ref.current &&
                !ref.current.contains(target) &&
                getZIndex(ref.current) >= getZIndex(target)
            ) {
                action();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, action]);
}
