import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import rollbar from "helpers/rollbar";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
    setUploadingFiles,
    setSuccessfulUploads,
    setFailedUploads,
    addFiles,
    setStreamMediaId,
    setUppyInstances,
    setIsErrored,
    addUploadToPendingBroadcasts,
    setUploadCanceled,
    setOnFileAdded,
    setLockedToPlayer,
    setOnUploadComplete,
    setAllowAdditionalUploads
} from "store/videoUploadSession/slice";
import { postEvents } from "store/events/thunks";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { exists } from "helpers/booleans";
import { UploadedFile } from "store/videoUploadSession/types";
import { useGetUploadSession } from "./useGetUploadSession";
import { v4 as uuidv4 } from "uuid";

const apiBaseUrl =
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net";

/**
 * A hook that handles creating an Uppy instance, including initializing
 * some state for the video upload session and also defining event listeners on the Uppy instance
 * that sync the internal Uppy instance state with our video upload session Redux state.
 *
 * There are currently two places where this hook would be used:
 *  - from the <VideoUploadModalEntryPoint /> component
 *  - from within the the <TriggeredVideoUploadModal /> component
 *
 * To start with, the Uppy library is typically used together as one whole pre-configured UI called the Uppy
 * Dashboard. We aren't using that and are instead using their UI library piecemeal. That has led to some trickiness in implementing
 * our own UI/UX flow while conforming to the standards and limitations of the Uppy components we are using.
 * In particular, the Uppy DragDrop component and the Uppy StatusBar component needs to plug into one and only one
 * unique Uppy instance. If more than one DragDrop or StatusBar component share the same Uppy instance, the UI throws an error.
 * Because of this, we have had to be creative in how we use Uppy instances.
 *
 * Basically, the strategy we have adopted is to use one initial Uppy instance for the DragAndDrop component
 * and then create a unique Uppy instance for each and every file that is added to the original DragAndDrop-assigned
 * Uppy component. This allows us to simultaneously display multiple upload StatusBar components for each upload and
 * display separate upload status tracking per video. The initial Uppy instance is a stand-in instance that has the sole
 * job of receiving the added files and creating the new children Uppy instances per file.
 *
 * Furthermore, when it comes to the initial Uppy instance, we have had to create two initial instances instead of one.
 * One is for the entry point component's DragDrop component and one is for the modal's DragAndDropStep's DragDrop component.
 * The reason being that there will be times that both these Uppy DragDrop components will be rendered to the page at the same time,
 * and they cannot share the same instance.
 *
 * In the hook below, you'll notice that we utilize a bit of recursion to handle creating Uppy instances - both initial instances
 * and file-specific instances, with the creation of file-specific Uppy instances serving as the base case where recursion stops.
 * In line with this approach, the initial instance only listens for the `files-added` event since this is the trigger event
 * for recursing and creating the child instances. The child instances therefore are given all of the functional event listeners
 * that actually handle updating the video upload session state.
 *
 */
export const useCreateUppy = ({
    sessionId,
    setSessionId,
    allowMultipleUploads,
    allowAdditionalUploads,
    lockedToPlayer,
    onFileAdded,
    onUploadComplete,
    location
}: {
    sessionId: string;
    setSessionId: (id: string) => void;
    allowMultipleUploads?: boolean;
    allowAdditionalUploads?: boolean;
    lockedToPlayer?: string;
    onFileAdded?: (...args: any) => any;
    onUploadComplete?: (...args: any) => any;
    location: "entry-point-component" | "modal";
    cloneFromEntryPointInstance?: boolean;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        entryPointDragAndDropInstance,
        modalDragAndDropInstance,
        lockedToPlayer: sessionLockedToPlayer,
        onFileAdded: sessionOnFileAdded,
        onUploadComplete: sessionOnUploadComplete
    } = useGetUploadSession({ sessionId });

    const { ticket } = useSelector((s: RootState) => s.user);

    /** Creates uppy instance with appropriate event handlers and adds uppy instance to redux store */
    const createUppyInstance = useCallback(
        ({ isInitialInstance }: { isInitialInstance: boolean }) => {
            const _uppy = new Uppy({
                debug: true,
                autoProceed: false,
                restrictions: {
                    maxFileSize: 32212254720,
                    maxNumberOfFiles: allowMultipleUploads ? null : 1,
                    minNumberOfFiles: 1,
                    allowedFileTypes: ["video/*"]
                },
                allowMultipleUploadBatches: true
            })
                .use(Tus, {
                    endpoint: `${apiBaseUrl}/api/CloudRecordings/GetUploadUrl`,
                    chunkSize: 150 * 1024 * 1024,
                    removeFingerprintOnSuccess: true,
                    // @ts-expect-error Tus does allow this to be non-async
                    onBeforeRequest: function (req, file) {
                        // Add auth on initial request only
                        if (req.getURL().includes("GetUploadUrl")) {
                            req.setHeader(
                                "Authorization",
                                `Bearer ${ticket?.access_token}`
                            );
                            req.setHeader("file-id", file.id);
                        }
                    },
                    onAfterResponse: function (req, res) {
                        try {
                            // the unique identifier of the uploaded video
                            const body = JSON.parse(res.getBody());
                            const fileId = body?.fileId;
                            const streamId = body?.streamMediaId;

                            if (fileId && streamId) {
                                dispatch(
                                    setStreamMediaId({
                                        sessionId,
                                        fileId,
                                        streamMediaId: streamId
                                    })
                                );
                            }
                        } catch (e) {
                            rollbar.error(
                                "Error getting file id and stream media id from uppy response",
                                e
                            );
                        }
                    }
                })
                .on("files-added", (files) => {
                    // this logic only happens for the user generated file adding
                    // it is skipped for the programmatic file adding done below

                    // since adding files marks the beginning of a session, the session id
                    // is generated and assigned here.
                    if (isInitialInstance) {
                        const addedFiles: UploadedFile[] = files.map((file) => {
                            const fileSpecificUppyInstance = createUppyInstance(
                                {
                                    isInitialInstance: false
                                }
                            );

                            fileSpecificUppyInstance.addFile(file);

                            return {
                                file,
                                title:
                                    file.name.substring(
                                        0,
                                        file.name.lastIndexOf(".")
                                    ) || file.name,
                                addToPlayers: exists(lockedToPlayer)
                                    ? [lockedToPlayer]
                                    : [],
                                uppyInstance: fileSpecificUppyInstance
                            };
                        });

                        dispatch(addFiles({ sessionId, files: addedFiles }));
                        onFileAdded && onFileAdded();
                    }
                })
                .on("upload", (data) => {
                    if (!isInitialInstance) {
                        dispatch(
                            setUploadingFiles({
                                sessionId,
                                uploadingFileIds: data.fileIDs
                            })
                        );
                    }
                    // reset session id
                    setSessionId(uuidv4());
                })
                .on("error", (error) => {
                    if (!isInitialInstance) {
                        rollbar.error("Uppy upload error", error);
                        dispatch(setIsErrored({ sessionId, isErrored: true }));
                    }
                })
                .on("upload-success", (file) => {
                    if (!isInitialInstance) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Success,
                                message: "messages:video-upload-success"
                            })
                        );
                        dispatch(
                            postEvents({ "added-video-to-library": true })
                        );

                        dispatch(
                            setSuccessfulUploads({
                                sessionId,
                                successfullFileIds: [file.id]
                            })
                        );
                        dispatch(
                            addUploadToPendingBroadcasts({
                                sessionId,
                                fileId: file.id
                            })
                        );
                    }
                })
                .on("upload-error", (file, error) => {
                    if (!isInitialInstance) {
                        dispatch(
                            setFailedUploads({
                                sessionId,
                                failedFiles: [
                                    { id: file?.id, error: error?.message }
                                ]
                            })
                        );
                    }
                })
                .on("restriction-failed", () => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:upload-restriction-failed"
                        })
                    );
                })
                .on("file-removed", (file) => {
                    dispatch(setUploadCanceled({ sessionId, fileId: file.id }));
                })
                .on("cancel-all", () => {});

            if (isInitialInstance) {
                // set some state in session
                if (!sessionLockedToPlayer) {
                    dispatch(setLockedToPlayer({ sessionId, lockedToPlayer }));
                }
                if (!sessionOnFileAdded) {
                    dispatch(setOnFileAdded({ sessionId, onFileAdded }));
                }

                if (!sessionOnUploadComplete) {
                    dispatch(
                        setOnUploadComplete({ sessionId, onUploadComplete })
                    );
                }

                dispatch(
                    setAllowAdditionalUploads({
                        sessionId,
                        allowAdditionalUploads
                    })
                );

                dispatch(
                    setUppyInstances(
                        location === "entry-point-component"
                            ? {
                                  sessionId,
                                  entryPointDragAndDropInstance: _uppy
                              }
                            : { sessionId, modalDragAndDropInstance: _uppy }
                    )
                );
            }

            return _uppy;
        },
        [
            allowMultipleUploads,
            allowAdditionalUploads,
            dispatch,
            location,
            lockedToPlayer,
            onFileAdded,
            onUploadComplete,
            sessionId,
            sessionLockedToPlayer,
            sessionOnFileAdded,
            sessionOnUploadComplete,
            setSessionId,
            ticket?.access_token
        ]
    );

    // create initial uppy instance
    useEffect(() => {
        createUppyInstance({ isInitialInstance: true });
    }, [createUppyInstance, location]);

    return { entryPointDragAndDropInstance, modalDragAndDropInstance };
};
