declare global {
    interface Window {
        VWO: any;
        switcher_vwo_variations: any;
    }
}

export function trackABEvent(event: string) {
    window.VWO = window.VWO || [];
    const VWO = window.VWO;
    VWO.event =
        VWO.event ||
        function () {
            VWO.push(["event"].concat([].slice.call(arguments)));
        };
    VWO.event(event);
}

export function runVwoVariations(
    campaignId: string,
    variations: { variationId: string; callback: (...args: any) => any }[],
    defaultAction?: (...args: any) => any
) {
    if (!window.switcher_vwo_variations) {
        console.log("NO VARIATIONS FOUND");
        defaultAction && defaultAction();
        return;
    }
    const appliedVariation = variations.find(({ variationId }) =>
        window.switcher_vwo_variations.includes(`${campaignId}_${variationId}`)
    );
    if (appliedVariation && appliedVariation?.callback) {
        appliedVariation.callback();
    } else if (defaultAction) {
        defaultAction();
    }
}
