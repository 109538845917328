import {
    PromotionBannerDetails,
    PromotionModalDetails
} from "@switcherstudio/switcher-api-client";
import { exists, existsWithValues } from "helpers/booleans";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setHasViewedPromoModal } from "store/promotions/slice";
import { RootState } from "store/reducers";
import { useSwitcherClient } from "./useSwitcherClient";
import dayjs from "dayjs";
import { format } from "helpers/time";

export interface PromoModalProps extends PromotionModalDetails {
    hasViewedModal?: boolean;
}

/**
 * A hook to determine if a user has been given a
 * limited-time promotional access to a specific feature claim
 * above their role/plan's typical level of access
 * and also store and return coupons for current promos
 * */
export const useGetPromotionalClaim = (
    /** If promoName is omitted,
     * this hook will return the first promo the user is eligible for
    out of all current promos */
    promoName?: string
): {
    eligiblePromotionName: string;
    hasPromotionalClaim: boolean;
    eligibleUpgradeCoupon: string;
    promoModalProps: PromoModalProps;
    promoBannerProps: PromotionBannerDetails;
    promoEndDate: Date;
} => {
    const user = useSelector((s: RootState) => s.user);
    const { data: promosData } = useSwitcherClient(
        (client) => client.promotions_GetPromotions,
        {
            requestImmediately: true,
            hideLoading: true
        }
    );

    const currentPromos = useMemo(() => promosData?.Promotions, [promosData]);
    const promotionsState = useSelector((s: RootState) => s.promotions);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const eligiblePromotion = useMemo(() => {
        if (!existsWithValues(currentPromos)) return null;
        const userRoles = user?.userInfo?.Roles;
        const userFeatureClaims = user?.userInfo?.FeatureClaims;
        const targetedPromotion = currentPromos?.find(
            (promo) => promo?.Detail?.Name === promoName
        );

        // if promoName exists and all user details qualify, return promotion
        if (targetedPromotion) {
            return userRoles?.includes(targetedPromotion?.Detail?.Role) &&
                userFeatureClaims?.includes(
                    targetedPromotion?.Detail?.FeatureClaim
                )
                ? targetedPromotion
                : null;
        }

        // else, no promoName specified, let's return the first promo the user is eligible for
        return currentPromos.find(
            (promo) =>
                userRoles?.includes(promo?.Detail?.Role) &&
                userFeatureClaims?.includes(promo?.Detail?.FeatureClaim)
        );
    }, [user, promoName, currentPromos]);

    const { data: stripePrice } = useSwitcherClient(
        (client) => client.userStripePrices_GetStripePrice,
        {
            requestImmediately:
                user?.userInfo?.ActiveProduct?.discriminator ===
                "StripeProduct",
            args: [user?.userInfo?.UserId, user?.userInfo?.PlanId],
            hideLoading: true
        }
    );

    const promoCouponByPeriod = useMemo(() => {
        const coupons = eligiblePromotion?.Coupon;
        if (!eligiblePromotion || !existsWithValues(coupons)) return null;

        const currentPlanPeriod = stripePrice?.RecurringInterval;
        const lockedCouponPeriod = coupons.length === 1;
        const monthlyPlanUpgradeCoupon = coupons.find(
            (c) => c?.IsChargedAnnually === false
        );
        const annualPlanUpgrageCoupon = coupons.find(
            (c) => c?.IsChargedAnnually === true
        );
        if (lockedCouponPeriod) {
            return monthlyPlanUpgradeCoupon ?? annualPlanUpgrageCoupon;
        }

        return currentPlanPeriod === "year"
            ? annualPlanUpgrageCoupon
            : monthlyPlanUpgradeCoupon;
    }, [eligiblePromotion, stripePrice]);

    /** Check to see if eligibile promotion name is in promotion state, if not, update state */
    useEffect(() => {
        if (!promotionsState || !eligiblePromotion || !eligiblePromotion.Detail)
            return;

        if (!promotionsState[eligiblePromotion.Detail.Name]) {
            dispatch(
                setHasViewedPromoModal({
                    promoName: eligiblePromotion.Detail.Name,
                    hasViewedModal: false
                })
            );
        }
    }, [promotionsState, eligiblePromotion, dispatch]);

    const hasViewedModal = useMemo(
        () => promotionsState[eligiblePromotion?.Detail?.Name]?.hasViewedModal,
        [promotionsState, eligiblePromotion]
    );

    const modalProps = useMemo(() => {
        /**
         * Hardcoded copy localization keys for initial promo test.
         * All subsequent promos will store and return localized copy from API endpoint.
         * Remove after initial trial run (ek 2/14/2023)
         */
        const switcherPlayerStudioAccessData: PromotionModalDetails = {
            VideoUrl:
                "https://customer-5g1vs7rwra6vdqdo.cloudflarestream.com/e8365b48b21c3a1dd6d2ba8cd8bfe8f4/iframe?poster=https%3A%2F%2Fcustomer-5g1vs7rwra6vdqdo.cloudflarestream.com%2Fe8365b48b21c3a1dd6d2ba8cd8bfe8f4%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
            Header: t("promotion:studio-switcher-player:modal-header"),
            SubHeader: t("promotion:studio-switcher-player:modal-sub-header"),
            Description: t(
                "promotion:studio-switcher-player:modal-description"
            ),
            DescriptionTwo: t(
                "promotion:studio-switcher-player:modal-description-two",
                {
                    promotionActiveRange:
                        format(
                            dayjs(eligiblePromotion?.Detail?.ActiveAt),
                            "MMMM D"
                        ) +
                        " - " +
                        format(
                            dayjs(eligiblePromotion?.Detail?.ExpireAt),
                            "MMMM D"
                        )
                }
            ),
            ButtonText: t("promotion:studio-switcher-player:modal-button")
        };
        if (eligiblePromotion?.Detail?.Name === "SwitcherPlayerStudioAccess")
            return switcherPlayerStudioAccessData;

        return eligiblePromotion?.Modal;
    }, [eligiblePromotion, t]);

    return {
        eligiblePromotionName: eligiblePromotion?.Detail?.Name,
        hasPromotionalClaim: exists(eligiblePromotion),
        eligibleUpgradeCoupon: promoCouponByPeriod?.PromotionCode,
        promoModalProps: modalProps
            ? {
                  ...modalProps,
                  hasViewedModal
              }
            : {},
        promoBannerProps: eligiblePromotion?.Banner ?? {},
        promoEndDate:
            exists(eligiblePromotion?.Detail?.ExpireAt) &&
            new Date(eligiblePromotion.Detail.ExpireAt)
    };
};
