import { useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import classnames from "classnames/bind";
import { Button } from "components/buttons/Button";
import { BroadcastsSearchSortBar } from "components/inputs/search-sort-bar/BroadcastsSearchSortBar";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "hooks/useIsMobile";
const cx = classnames.bind(styles);
import {
    GenericMultiSelectProps,
    HashItem
} from "components/generic-multiselect/types";
import { ItemsList } from "./ItemsList";
import { VideoPlayerCloudflareResponse } from "@switcherstudio/switcher-api-client";
import { Spinner } from "components/spinners/Spinner";

export function GenericMultiSelect<T, Y>({
    items,
    handleSelect,
    handleSelectAll = () => {},
    showNone = false,
    allowUnselect = true,
    isMultiple = false,
    allowItemClick = true,
    checkBoxLocation = "right",
    searchSortOptions = { showSearchSort: false },
    actionsBarOptions = { showActions: false },
    loading = false,
    selectedIdSet,
    previouslySelectedIds = [],
    displayOrder = items.map((i) => i.id),
    setDisplayOrder,
    visibleItems = items
}: GenericMultiSelectProps<T, Y>) {
    const [hasBeenSorted, setHasBeenSorted] = useState(false);
    const handleSelectLocal = useCallback(
        (id: string) => {
            if (!isMultiple && selectedIdSet.has(id)) return;
            handleSelect && handleSelect(id);
        },
        [handleSelect, isMultiple, selectedIdSet]
    );

    const { isMobile } = useIsMobile();

    const handleAction = useCallback(
        async (
            onClick: (listItemsProps: Y[]) => Promise<any>,
            shouldDeselectOnClick
        ) => {
            const listItemsPropsArray: Y[] = [...selectedIdSet].map((id) => {
                const item = items.find((item) => item.id === id);
                return item?.component.props as Y;
            });

            await onClick(listItemsPropsArray);
            // Clears selection
            if (shouldDeselectOnClick || shouldDeselectOnClick === undefined) {
                handleSelectAll();
            }
        },
        [handleSelectAll, items, selectedIdSet]
    );

    const { t } = useTranslation();

    const searchSortComponent = useMemo(() => {
        if (!searchSortOptions?.showSearchSort) return <></>;
        switch (searchSortOptions?.implementationType) {
            case "broadcast-search-sort":
                return (
                    <BroadcastsSearchSortBar
                        location={searchSortOptions.location}
                        broadcasts={items.map((item) => item.baseObject)}
                        handleSort={(
                            broadcasts:
                                | VideoPlayerCloudflareResponse[]
                                | HashItem<VideoPlayerCloudflareResponse>[]
                        ) => {
                            setDisplayOrder(
                                broadcasts.map((i) => {
                                    return i.broadcast.Id;
                                })
                            );
                            setHasBeenSorted(true);
                        }}
                    />
                );
            // TODO: add support for generic <SearchSortBar /> component here in future!
            default:
                return <></>;
        }
    }, [searchSortOptions, items, setDisplayOrder]);

    const actionsBarComponent = useMemo(() => {
        if (!actionsBarOptions.showActions) return <></>;
        return (
            <div
                className={`${styles["actions-bar"]} select-many-list-display-actions-bar`}
            >
                <Button
                    onClick={() => handleSelectAll(displayOrder)}
                    type="text"
                    disabled={!displayOrder.length}
                >
                    {selectedIdSet.size > 0 && // any selected items
                    displayOrder.some((id) => selectedIdSet.has(id)) // any visible selected items
                        ? t("buttons:deselect-all")
                        : t("buttons:select-all")}
                </Button>
                <div className={styles["action-buttons"]}>
                    {actionsBarOptions.actions.map(
                        ({
                            onClick,
                            text,
                            buttonType,
                            shouldDeselectOnClick,
                            MobileIcon
                        }) => {
                            return (
                                <Button
                                    onClick={() =>
                                        handleAction(
                                            onClick,
                                            shouldDeselectOnClick
                                        )
                                    }
                                    type={isMobile ? "icon" : buttonType}
                                    disabled={!selectedIdSet.size}
                                    key={text}
                                >
                                    {isMobile ? <MobileIcon /> : text}
                                </Button>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }, [
        actionsBarOptions,
        handleAction,
        handleSelectAll,
        isMobile,
        t,
        selectedIdSet,
        displayOrder
    ]);

    const placeholderComponent = useMemo(() => {
        return (
            <div
                className={cx("ms-card", {
                    "checkbox-left": checkBoxLocation === "left",
                    "allow-item-click": allowItemClick
                })}
                onClick={() => allowItemClick && handleSelectLocal(undefined)}
            >
                <div className={cx("ms-card-component")}>
                    <h6 style={{ marginBottom: "0" }}>{`None`}</h6>
                </div>
                <StyledCheckboxOrRadio
                    type="radio"
                    checked={[...selectedIdSet].filter(Boolean).length === 0}
                    onChange={() => handleSelectLocal(undefined)}
                />
            </div>
        );
    }, [allowItemClick, checkBoxLocation, handleSelectLocal, selectedIdSet]);

    // Apply all sorting, filtering, and other logic to get the final items to display
    const finalItems = useMemo(() => {
        // If no items, return empty array
        if (!items || !items?.length) return [];

        // If no display order (or if there is no search/sort functionality), return all items
        if (
            displayOrder === undefined ||
            !searchSortOptions?.showSearchSort ||
            (displayOrder.length === 0 &&
                searchSortComponent.props.broadcasts.length !== 0)
        ) {
            return items;
        }

        // Otherwise, return the items mapped to the displayOrder
        return displayOrder
            ?.filter((id) => !!items.find((i) => i.id === id))
            ?.map((id) => items.find((i) => i.id === id));
    }, [
        items,
        displayOrder,
        searchSortOptions?.showSearchSort,
        searchSortComponent.props.broadcasts
    ]);

    return (
        <div className={styles["ms-container"]}>
            {searchSortComponent}
            {actionsBarComponent}
            {(loading ||
                (searchSortOptions?.showSearchSort && !hasBeenSorted)) && (
                <div className={styles["loading"]}>
                    <Spinner size={128} />
                </div>
            )}
            {((hasBeenSorted &&
                searchSortOptions?.showSearchSort &&
                visibleItems?.length) ||
                (!searchSortOptions?.showSearchSort &&
                    visibleItems?.length)) && (
                <>
                    {showNone && !isMultiple && placeholderComponent}
                    <ItemsList
                        items={finalItems}
                        allowItemClick={allowItemClick}
                        checkBoxLocation={checkBoxLocation}
                        isMultiple={isMultiple}
                        handleSelectLocal={handleSelectLocal}
                        selectedIdSet={selectedIdSet}
                        previouslySelectedIds={previouslySelectedIds}
                        allowUnselect={allowUnselect}
                    />
                </>
            )}
            {((!searchSortOptions?.showSearchSort && !visibleItems?.length) ||
                (searchSortOptions?.showSearchSort &&
                    hasBeenSorted &&
                    !visibleItems?.length)) && (
                <p className="multi-select-empty-state">
                    {searchSortOptions?.implementationType ===
                        "broadcast-search-sort" &&
                        t("video-library:no-videos-match")}
                </p>
            )}
        </div>
    );
}
