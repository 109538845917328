import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import {
    CatalogDetailsResponse,
    VideoPlayerAspectRatio,
    VideoPlayerProfile,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Select } from "components/inputs/select/Select";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useDebounce } from "hooks/useDebounce";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import { Errors } from "hooks/useForm";

declare global {
    interface Window {
        switcherPlayerApp: any;
    }
}

export interface DisplayTabProps {
    handleSettingsChange: (key: string, value: any) => void;
    localVideoPlayerResponse?: VideoPlayerResponse;
    localCatalogResponse?: CatalogDetailsResponse;
    videoPlayerValues?: VideoPlayerProfile;
    errors: Errors;
}

export const DisplayTab: React.FC<DisplayTabProps> = ({
    handleSettingsChange,
    localVideoPlayerResponse,
    localCatalogResponse,
    videoPlayerValues,
    errors
}) => {
    const { t } = useTranslation("customization-page");
    const localResponse = localCatalogResponse
        ? localCatalogResponse
        : localVideoPlayerResponse?.VideoPlayer;
    const values = videoPlayerValues
        ? videoPlayerValues?.VideoPlayer
        : localCatalogResponse;
    const [embedWidth, setEmbedWidth] = useState<string | null>();
    const debouncedMaxEmbedWidth = useDebounce(embedWidth, 500);
    const [maxWidthError, setMaxWidthError] = useState<string | null>();

    useEffect(() => {
        setEmbedWidth(localResponse?.MaxEmbedWidth);
    }, [localResponse?.MaxEmbedWidth]);

    // Handle the debounced max embed width
    useEffect(() => {
        if (!!debouncedMaxEmbedWidth) {
            // this next section allows for a local display of the error message while also forcing the value to be 320 if the user enters lower.
            // This is required as if we rely on the form validation, the error will display and then immediately be removed when we set the value to 320.
            let newMaxWidth = debouncedMaxEmbedWidth;
            if (parseInt(debouncedMaxEmbedWidth) < 320) {
                newMaxWidth = "320";
                setMaxWidthError(t("errors:max-embed-width"));
            }
            if (parseInt(debouncedMaxEmbedWidth) > 320) {
                setMaxWidthError(null);
            }
            setEmbedWidth(newMaxWidth);

            handleSettingsChange("MaxEmbedWidth", newMaxWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedMaxEmbedWidth]);

    /** Only allow users to set these tabs */
    const defaultInteractiveTabOptions = [
        { value: 0, text: t("details") },
        { value: 4, text: t("collection") },
        { value: 2, text: t("shop") }
    ];

    /** Options for collections vs catalog (catalog does not have thumbnail) */
    const embeddedDisplayOptions = localVideoPlayerResponse
        ? [
              { value: 0, text: t("default-thumbnail") },
              { value: 1, text: t("carousel") },
              { value: 2, text: t("grid") }
          ]
        : [
              { value: 1, text: t("carousel") },
              { value: 2, text: t("grid") }
          ];

    return (
        <>
            <div className={styles["form-content"]}>
                <h5 className={styles["sub-header"]}>
                    {t("embedded-display")}
                </h5>
                <hr />
                <Select
                    id="embedded-display"
                    onChange={(e) => {
                        handleSettingsChange(
                            "EmbeddedDisplay",
                            parseInt(e.target.value)
                        );
                    }}
                    options={embeddedDisplayOptions}
                    selected={localResponse?.EmbeddedDisplay}
                    label={t("display")}
                />
                {localResponse?.EmbeddedDisplay !== 0 && (
                    <Toggle
                        on={values?.ShowTitles}
                        onToggle={() =>
                            handleSettingsChange(
                                "ShowTitles",
                                !values?.ShowTitles
                            )
                        }
                        label={t("titles-below-thumbnails")}
                        className={styles["sub-sub-header"]}
                        reverseLayout
                    />
                )}
                <h5 className={styles["sub-header"]}>
                    {t("playback-behavior")}
                </h5>
                <hr />
                <Select
                    id="default-interactive"
                    onChange={(e) => {
                        handleSettingsChange(
                            "DefaultInteractiveTab",
                            parseInt(e.target.value)
                        );
                    }}
                    options={defaultInteractiveTabOptions}
                    selected={localResponse?.DefaultInteractiveTab}
                    label={t("default-interactive-tab")}
                />
                {localVideoPlayerResponse && (
                    <>
                        <h5 className={styles["sub-header"]}>
                            {t("content-orientation")}
                        </h5>
                        <h6 className={styles["sub-sub-header"]}>
                            {t("content-orientation-sub")}
                        </h6>
                        <StyledCheckboxOrRadio
                            checked={
                                localVideoPlayerResponse?.VideoPlayer
                                    ?.AspectRatio ===
                                VideoPlayerAspectRatio.SixteenByNine
                            }
                            type="radio"
                            id="horiz"
                            label={t("orientation-horizontal")}
                            onChange={() =>
                                handleSettingsChange(
                                    "AspectRatio",
                                    VideoPlayerAspectRatio.SixteenByNine
                                )
                            }
                        />
                        <StyledCheckboxOrRadio
                            checked={
                                localVideoPlayerResponse?.VideoPlayer
                                    ?.AspectRatio ===
                                VideoPlayerAspectRatio.NineBySixteen
                            }
                            type="radio"
                            id="vert"
                            label={t("orientation-vertical")}
                            onChange={() =>
                                handleSettingsChange(
                                    "AspectRatio",
                                    VideoPlayerAspectRatio.NineBySixteen
                                )
                            }
                        />
                    </>
                )}
                <hr />
                <h5 className={styles["sub-header"]}>{t("max-embed-width")}</h5>
                <hr />
                <Toggle
                    on={values?.EnableMaxEmbedWidth}
                    onToggle={() =>
                        handleSettingsChange(
                            "EnableMaxEmbedWidth",
                            !values?.EnableMaxEmbedWidth
                        )
                    }
                    label={t("enable-max-width")}
                    className={styles["sub-sub-header"]}
                    reverseLayout
                />
                {localResponse?.EnableMaxEmbedWidth && (
                    <TextInput
                        id="maxEmbedWidth"
                        type="number"
                        min={320}
                        value={embedWidth ?? "1920"}
                        label={t("max-width")}
                        className="form-field-label"
                        onChange={(e) => setEmbedWidth(e.target.value)}
                        error={
                            maxWidthError ??
                            errors?.VideoPlayerSettings?.MaxEmbedWidth
                        }
                    />
                )}
            </div>
        </>
    );
};
