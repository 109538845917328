import { useScript, ScriptAsyncStatus } from "./useScript";
import { useEffect, useCallback, useState } from "react";
import {
    SomApiConfig,
    SomResults,
    SomError,
    SomProgress,
    SomTestStatus,
    SomData
} from "views/page-content/platforms/types";
import { trackEvent } from "helpers/analyticsHelpers";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";

declare global {
    interface Window {
        SomApi: {
            account: string;
            domainName: string;
            config: SomApiConfig;
            onTestCompleted: (results: SomResults) => void;
            onError: (error: SomError) => void;
            onProgress: (progress: SomProgress) => void;
            startTest: () => void;
        };
    }
}

export function useSpeedTest() {
    const scriptStatus = useScript("https://speedof.me/api/api.js");
    const [somTestStatus, setTestStatus] = useState<SomTestStatus>(
        SomTestStatus.Waiting
    );
    const [somData, setSomData] = useState<SomData>({});

    useEffect(() => {
        if (scriptStatus === ScriptAsyncStatus.Ready) {
            window.SomApi.account = import.meta.env.VITE_SOM_API_KEY;
            window.SomApi.domainName =
                import.meta.env.VITE_DOMAIN || "localhost:3000";
            window.SomApi.config.sustainTime = 2;
            window.SomApi.config.progress.enabled = true;
            window.SomApi.config.progress.verbose = false;
            window.SomApi.config.uploadTestEnabled = true;
            window.SomApi.config.testServerEnabled = false;
            window.SomApi.config.userInfoEnabled = false;
            window.SomApi.config.latencyTestEnabled = false;
            window.SomApi.onTestCompleted = (results) => {
                setSomData({ results });
                setTestStatus(SomTestStatus.Completed);

                trackEvent("Speed test completed", {
                    testResult: results,
                    source: "speedofme"
                });
            };
            window.SomApi.onError = (error) => {
                setSomData({ error });
                setTestStatus(SomTestStatus.Error);
            };
            window.SomApi.onProgress = (progress) => {
                setSomData({ progress });
            };
        }
    }, [scriptStatus]);

    const startSomSpeedTest = useCallback(() => {
        if (scriptStatus === ScriptAsyncStatus.Error) {
            setSomData({
                error: {
                    message: "Speed test is currently unavailable."
                }
            });
        }
        if (scriptStatus === ScriptAsyncStatus.Ready) {
            window.SomApi.startTest();
            setTestStatus(SomTestStatus.InProgress);
        }
    }, [scriptStatus]);

    const createChannelSetting = useCallback(
        (results: SomResults, showFullHD: boolean): ChannelSetting => {
            if (results) {
                const { upload } = results;

                if (showFullHD && upload >= 6.75) {
                    return new ChannelSetting(
                        1920,
                        1080,
                        6000000,
                        2,
                        128000,
                        48000
                    );
                }

                if (upload >= 3.66) {
                    return new ChannelSetting(
                        1280,
                        720,
                        showFullHD ? 3200000 : 2000000,
                        2,
                        128000,
                        48000
                    );
                }

                if (upload >= 1.56) {
                    return new ChannelSetting(
                        960,
                        540,
                        1200000,
                        2,
                        128000,
                        48000
                    );
                }

                if (upload >= 0.95) {
                    return new ChannelSetting(
                        640,
                        360,
                        800000,
                        1,
                        64000,
                        48000
                    );
                }

                // Default setting if none match, should not be reached if all cases are covered
                return new ChannelSetting(480, 270, 500000, 1, 64000, 48000);
            }
        },
        []
    );

    return { createChannelSetting, startSomSpeedTest, somData, somTestStatus };
}
