import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { client } from "api/client";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { UserFeature } from "@switcherstudio/switcher-api-client";
import { Toggle } from "components/inputs/toggle/Toggle";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { refreshToken, getUserInfo } from "store/user/thunks";
import styles from "./ExperimentalFeaturesPage.module.scss";
import { useTranslation } from "react-i18next";
import { DisabledFeature } from "components/disabled-feature/DisabledFeature";
import { useHasAccess } from "hooks/useHasAccess";
import {
    mustBeSubscriptionOwner,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { useParams } from "hooks/useParams";

export const ExperimentalFeaturesPage: React.FC = () => {
    useRedirectIfDisallowed(mustBeSubscriptionOwner);
    const { featureId } = useParams();
    const { t } = useTranslation();
    const { userInfo } = useSelector((state: RootState) => state.user);
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch<AppDispatch>();
    const [userFeatures, setUserFeatures] = useState<UserFeature[]>([]);
    const hasAccess = useHasAccess();

    const onToggle = useCallback(
        async (userFeature: UserFeature) => {
            userFeature.IsClaimed =
                userFeature.IsClaimed === undefined
                    ? true
                    : !userFeature.IsClaimed;

            // set state to update feature
            setUserFeatures((prevState) =>
                prevState.map((f) => {
                    return {
                        ...f,
                        IsClaimed:
                            f.Id === userFeature.Id
                                ? userFeature.IsClaimed
                                : f.IsClaimed
                    };
                })
            );

            await client.userFeatures_PutUserFeature(
                userFeature.Id || "",
                userFeature,
                userInfo?.UserId || ""
            );
            await dispatch(refreshToken());
            await dispatch(getUserInfo());

            if (featureId) {
                const userId = userInfo?.UserId;
                const feature = await client.userFeatures_GetUserFeature(
                    featureId,
                    userId
                );
                setUserFeatures([feature]);
            } else {
                const refreshedFeatures =
                    await client.userFeatures_GetUserFeatures(
                        userInfo?.UserId || ""
                    );
                setUserFeatures(refreshedFeatures);
            }

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:toggle-success"
                })
            );
        },
        [dispatch, userInfo, featureId]
    );

    useEffect(() => {
        async function run() {
            if (featureId) {
                const userId = userInfo?.UserId;
                const feature = await client.userFeatures_GetUserFeature(
                    featureId,
                    userId
                );

                setUserFeatures([feature]);
                return;
            }

            const features = await client.userFeatures_GetUserFeatures(
                userInfo?.UserId || ""
            );
            setUserFeatures(features);
        }

        if (hasAccess) run();
    }, [isMountedRef, userInfo, hasAccess, featureId]);

    return !hasAccess ? (
        <DisabledFeature />
    ) : (
        <section className={`${styles["experimental-features"]}`}>
            <div className="row">
                <div className="alert alert-danger">
                    {t("experimental-features:main-description")}
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <table
                            className={`${styles["experimental-features-table"]}`}
                        >
                            <thead>
                                <tr>
                                    <th>{t("misc:description")}</th>
                                    <th>{t("misc:status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userFeatures.map((f) => {
                                    return (
                                        <tr key={f.Id}>
                                            <td>{f.Description}</td>
                                            <td>
                                                <Toggle
                                                    on={
                                                        f.IsClaimed !==
                                                        undefined
                                                            ? f.IsClaimed
                                                            : false
                                                    }
                                                    onToggle={() => {
                                                        onToggle(f);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};
