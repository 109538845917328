import React, { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { Trans, useTranslation } from "react-i18next";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import {
    CreatorProduct,
    CreatorProductEntitlementsBindingModelDiscriminator,
    StreamingProvider,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { useNavigate } from "react-router-dom";
import rollbar from "helpers/rollbar";
import { Toggle } from "components/inputs/toggle/Toggle";
import {
    resetVideoPlayerForm,
    updateVideoPlayerForm
} from "store/platforms/slice";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "../../types";
import { PlayerSelectModal } from "components/modal/PlayerSelectModal";
import { Button } from "react-bootstrap";
import styles from "./VideoPlayerForm.module.scss";
import { PricingSelectModal } from "components/modal/PricingSelectModal";
import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { GatedContentDisabledTooltip } from "components/tooltips/GatedContentDisabledTooltip";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { useClaimCheck } from "hooks/useClaimCheck";
import { Link } from "react-router-dom";
import { closeCurrentModal } from "store/modal/slice";
interface VideoPlayerFormProps {
    platform?: StreamingProvider;
    multiForm?: boolean;
    destinationId?: string | number;
}

export const VideoPlayerForm: React.FC<
    VideoPlayerFormProps
> = ({}: VideoPlayerFormProps) => {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const navigate = useNavigate();

    const hasShopifyAccess = useClaimCheck("shopify");
    const { dispatchApiRequest: shopify_GetCredentials } = useSwitcherClient(
        (client) => client.shopify_GetCredentials
    );
    const [hasShopifyStoreLinked, setHasShopifyStoreLinked] =
        useState<boolean>(false);
    const [playlistSelectModalIsOpen, setPlaylistSelectModalIsOpen] =
        useState<boolean>(false);
    const [pricingSelectModalIsOpen, setPricingSelectModalIsOpen] =
        useState<boolean>(false);

    const [selectedProduct, setSelectedProduct] = useState<CreatorProduct>();
    const { gatedContentStatus } = useStripeAccountInfo();
    const { videoplayer } = useSelector((state: RootState) => state.platforms);
    const addToPlayers = useMemo(
        () =>
            videoplayer?.videoPlayerForm?.playlistIds?.filter(
                (id) => id && id !== "00000000-0000-0000-0000-000000000000"
            ) ?? [],
        [videoplayer?.videoPlayerForm?.playlistIds]
    );
    const initForm = useCallback(
        async (players: VideoPlayerResponse[]) => {
            let firstPlaylistId = null;
            for (let i = 0; i < players.length; i++) {
                const playlistId =
                    players[i]?.VideoPlayer?.VideoPlayerPlaylists?.[0]?.Id;
                if (
                    playlistId &&
                    playlistId !== "00000000-0000-0000-0000-000000000000"
                ) {
                    firstPlaylistId = playlistId;
                    break;
                }
            }

            try {
                let hasShopifyStoreLinked = false;
                if (hasShopifyAccess) {
                    const storeCredentials = await shopify_GetCredentials();
                    hasShopifyStoreLinked = storeCredentials?.length > 0;
                }
                setHasShopifyStoreLinked(hasShopifyStoreLinked);
                dispatch(
                    resetVideoPlayerForm({
                        enableLiveShopping:
                            hasShopifyAccess && hasShopifyStoreLinked,
                        playlistIds: !!firstPlaylistId ? [firstPlaylistId] : []
                    })
                );
            } catch (e) {
                rollbar.error("Error loading switcher player platform form", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("platforms:videoplayer-load-fail")
                    })
                );
                navigate("/platforms", { replace: true });
            }
        },
        [dispatch, hasShopifyAccess, navigate, shopify_GetCredentials, t]
    );

    const { data: players } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_GetVideoPlayers,
        {
            requestImmediately: true,
            args: [userInfo?.ProjectId, true],
            onSuccess: initForm,
            onSuccessLoading: true,
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:player-retrieval-error")
                    })
                );
            }
        }
    );

    const hasSelectedPlayers = useMemo<boolean>(
        () => addToPlayers.length > 0,
        [addToPlayers.length]
    );

    const gatedContentDisabled = useMemo<boolean>(
        () =>
            !hasSelectedPlayers ||
            gatedContentStatus !== GatedContentStatus.READY,
        [gatedContentStatus, hasSelectedPlayers]
    );

    const { link } = usePlatformActions(PlatformId.Shopify);

    const onToggle = useCallback(
        (key, value) => {
            dispatch(updateVideoPlayerForm({ [key]: value }));
        },
        [dispatch]
    );

    const emptyState = (
        <Trans
            i18nKey={t("players-select-modal:empty")}
            components={{
                link1: (
                    <Link
                        to={"/catalog"}
                        title="Video Catalog"
                        className={styles["link"]}
                        onClick={() => dispatch(closeCurrentModal())}
                    />
                )
            }}
        />
    );

    return (
        <>
            <div>
                {
                    <div
                        className={`form-group ${styles["video-player-form"]}`}
                    >
                        {hasCatalogClaim && (
                            <small>{t("platforms:video-library-stream")}</small>
                        )}
                        <div className={styles["select"]}>
                            <h6>
                                {hasCatalogClaim
                                    ? t("video-upload:add-to-collection")
                                    : t("video-upload:add-to-player")}
                            </h6>
                            <Button
                                onClick={() =>
                                    setPlaylistSelectModalIsOpen(true)
                                }
                            >
                                {hasSelectedPlayers
                                    ? hasCatalogClaim
                                        ? t("video-upload:collection-count", {
                                              count: addToPlayers.length,
                                              plural:
                                                  addToPlayers.length === 1
                                                      ? ""
                                                      : t("video-upload:plural")
                                          })
                                        : t("video-upload:player-count", {
                                              count: addToPlayers.length,
                                              plural:
                                                  addToPlayers.length === 1
                                                      ? ""
                                                      : t("video-upload:plural")
                                          })
                                    : hasCatalogClaim
                                    ? t("video-upload:add-to-collections")
                                    : t("video-upload:add-to-playlists")}
                            </Button>
                        </div>
                        {gatedContentStatus !==
                            GatedContentStatus.FEATURE_DISABLED && (
                            <div className={styles["select"]}>
                                <GatedContentDisabledTooltip
                                    gatedContentStatus={gatedContentStatus}
                                    override={!hasSelectedPlayers}
                                    overrideKey="video-player-settings:add-pricing-tooltip"
                                >
                                    <h6
                                        className={
                                            gatedContentDisabled
                                                ? styles["disabled"]
                                                : ""
                                        }
                                    >
                                        {t("video-upload:pricing-toggle")}
                                    </h6>
                                </GatedContentDisabledTooltip>

                                <Button
                                    onClick={() =>
                                        setPricingSelectModalIsOpen(true)
                                    }
                                    disabled={gatedContentDisabled}
                                    className={
                                        selectedProduct
                                            ? styles["has-pricing"]
                                            : ""
                                    }
                                >
                                    {selectedProduct?.Name ??
                                        t("video-upload:add-pricing")}
                                </Button>
                            </div>
                        )}
                        {hasShopifyAccess && (
                            <Toggle
                                label={t(
                                    "platforms:videoplayer-enable-live-shopping"
                                )}
                                on={
                                    hasShopifyStoreLinked &&
                                    videoplayer?.videoPlayerForm
                                        ?.enableLiveShopping
                                }
                                disabled={!hasShopifyStoreLinked}
                                disabledTooltip={{
                                    type: "CTA",
                                    body: (
                                        <span>
                                            {t(
                                                "platforms:live-shopping-disabled-body"
                                            )}{" "}
                                            <a
                                                className={styles["link"]}
                                                href="/platforms/shopify"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    link({
                                                        finalReturnUrl:
                                                            "/platforms/stream/switcherplayer"
                                                    });
                                                }}
                                            >
                                                {t(
                                                    "platforms:live-shopping-disabled-link"
                                                )}
                                            </a>{" "}
                                            {t(
                                                "platforms:live-shopping-disabled-body-end"
                                            )}
                                        </span>
                                    ),
                                    label: t(
                                        "platforms:live-shopping-disabled-title"
                                    ),
                                    show: true
                                }}
                                onToggle={() => {
                                    onToggle(
                                        "enableLiveShopping",
                                        !videoplayer?.videoPlayerForm
                                            .enableLiveShopping
                                    );
                                }}
                                className={`${
                                    styles["enable-live-shopping-toggle"]
                                } ${
                                    !hasShopifyStoreLinked
                                        ? styles["disabled"]
                                        : ""
                                }`}
                            />
                        )}
                    </div>
                }
            </div>

            <PlayerSelectModal
                isOpen={playlistSelectModalIsOpen}
                setIsOpen={setPlaylistSelectModalIsOpen}
                handleSelect={(playlistIds) => {
                    onToggle("playlistIds", playlistIds);
                }}
                previouslySelectedIds={addToPlayers}
                allowUnselect={true}
                allowNoSelection={true}
                returnPlaylistIds={true}
                players={players}
                emptyStateMessage={hasCatalogClaim && emptyState}
            />

            <PricingSelectModal
                isOpen={pricingSelectModalIsOpen}
                setIsOpen={setPricingSelectModalIsOpen}
                handleSelect={(product) => {
                    onToggle("creatorProductId", product?.Id);
                    setSelectedProduct(product);
                }}
                selected={selectedProduct?.Id}
                discriminator={
                    CreatorProductEntitlementsBindingModelDiscriminator._2
                }
            />
        </>
    );
};
