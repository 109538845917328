import React from "react";

import AmexIcon from "assets/icons/ccBrands/amex.svg?react";
import DinersIcon from "assets/icons/ccBrands/diners.svg?react";
import DiscoverIcon from "assets/icons/ccBrands/discover.svg?react";
import JcbIcon from "assets/icons/ccBrands/jcb.svg?react";
import MastercardIcon from "assets/icons/ccBrands/mastercard.svg?react";
import UnionpayIcon from "assets/icons/ccBrands/unionpay.svg?react";
import VisaIcon from "assets/icons/ccBrands/visa.svg?react";
import GenericIcon from "assets/icons/ccBrands/genericCard.svg?react";

import styles from "./PaymentBrandIcon.module.scss";

interface PaymentBrandIconProps {
    brandSlug: string;
}

enum PaymentBrands {
    Amex = "amex",
    Diners = "diners",
    Discover = "discover",
    Jcb = "jcb",
    Mastercard = "mastercard",
    Unionpay = "unionpay",
    Visa = "visa",
    Generic = "generic"
}

export const PaymentBrandIcon: React.FC<PaymentBrandIconProps> = ({
    brandSlug
}: PaymentBrandIconProps) => {
    return (
        <span className={`${styles["icon-wrapper"]} icon-wrapper`}>
            {(function () {
                switch (brandSlug?.toLowerCase()) {
                    case PaymentBrands.Amex:
                        return <AmexIcon title="Amex" />;
                    case PaymentBrands.Diners:
                        return <DinersIcon title="Diners" />;
                    case PaymentBrands.Discover:
                        return <DiscoverIcon title="Discover" />;
                    case PaymentBrands.Jcb:
                        return <JcbIcon title="Jcb" />;
                    case PaymentBrands.Mastercard:
                        return <MastercardIcon title="Mastercard" />;
                    case PaymentBrands.Unionpay:
                        return <UnionpayIcon title="Unionpay" />;
                    case PaymentBrands.Visa:
                        return <VisaIcon title="Visa" />;
                    case PaymentBrands.Generic:
                    default:
                        return <GenericIcon title="Generic" />;
                }
            })()}
        </span>
    );
};
