import React from "react";
import { BaseThumbnail } from "../BaseThumbnail";
import styles from "./index.module.scss";

interface CountdownThumbnailProps {
    timeRemaining: number;
    headerText?: string;
}

const CountdownThumbnail: React.FC<CountdownThumbnailProps> = ({
    timeRemaining,
    headerText
}) => {
    const formatTimeRemaining = (time: number) => {
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const hours = Math.floor(
            (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );

        const days = Math.floor(time / (1000 * 60 * 60 * 24));

        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m`;
        }

        if (hours === 0 && minutes > 0) {
            return `${minutes}m ${seconds}s`;
        }

        if (hours === 0 && minutes === 0) {
            return `${seconds}s`;
        }

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <BaseThumbnail>
            <div className={styles["countdown-timer"]}>
                {headerText && (
                    <span className={styles["countdown-pretext"]}>
                        {headerText}
                    </span>
                )}
                {timeRemaining > 0 && (
                    <span>{formatTimeRemaining(timeRemaining)}</span>
                )}
            </div>
        </BaseThumbnail>
    );
};

export default CountdownThumbnail;
