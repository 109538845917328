import React from "react";
import { Broadcast } from "@switcherstudio/switcher-api-client";
import { format } from "helpers/time";
import styles from "./SimulcastExistingBroadcast.module.scss";
import { useTranslation } from "react-i18next";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import dayjs from "dayjs";
import { useSimulcastFork } from "hooks/useSimulcastFork";

interface SimulcastExistingTableProps {
    existingStreams: Broadcast[];
    onChange: (id: string) => Promise<void>;
    triggerDelete: (id: string) => Promise<void>;
    defaultBroadcastId: string;
}

export const SimulcastExistingTable: React.FC<SimulcastExistingTableProps> = ({
    existingStreams,
    defaultBroadcastId,
    onChange,
    triggerDelete
}) => {
    const { t } = useTranslation();
    const { t: simulcastT } = useSimulcastFork();

    const isDefault = (broadcastId: string) => {
        return broadcastId === defaultBroadcastId;
    };

    return (
        <table className="table table-striped table-sm">
            <tbody>
                {existingStreams.map((s) => {
                    return (
                        <tr
                            key={s.Id + s.Title}
                            className={`${
                                isDefault(s.Id) &&
                                styles["is-default-broadcast"]
                            } table-row`}
                        >
                            <td className={styles["table-data"]}>
                                <b>{s.Title}</b>
                                {s.StartsAt && (
                                    <>
                                        <br />
                                        {format(
                                            dayjs(s.StartsAt),
                                            "M/DD/YYYY h:mma"
                                        )}
                                    </>
                                )}
                            </td>

                            <td
                                className={`${styles["table-data"]} ${styles["table-default-btn-cell"]}`}
                            >
                                <div className={styles["button-container"]}>
                                    <div className="form-group">
                                        <label htmlFor={s.Id}>
                                            {simulcastT("set-as-default")}
                                        </label>
                                        <input
                                            className={styles["checkbox"]}
                                            id={s.Id}
                                            type="checkbox"
                                            checked={isDefault(s.Id)}
                                            onChange={async () =>
                                                onChange(s.Id)
                                            }
                                        />
                                    </div>

                                    <button
                                        className={`btn btn-danger ${styles["delete-btn"]}`}
                                        onClick={async () =>
                                            triggerDelete(s.Id)
                                        }
                                    >
                                        <TrashIcon />
                                        <span className="sr-only">
                                            {t("buttons:delete")}
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
