import { jsonToCsv } from "helpers/download";
import { useCallback } from "react";

export interface UseAnalyticsExportOptions {
    type?: "text/csv";
    onSuccess?: () => void;
    onEmpty?: () => void;
}

export const useAnalyticsExport = <T extends object>(
    data: T[],
    { type = "text/csv", onSuccess, onEmpty }: UseAnalyticsExportOptions = {}
) => {
    return useCallback(
        (mapper: (item: T) => object, filename: string) => {
            let blob: Blob;

            if (!data) {
                onEmpty?.();
            }

            switch (type) {
                case "text/csv":
                    blob = new Blob([jsonToCsv(data.map(mapper))], {
                        type: "text/csv"
                    });
            }

            // Creating an object for downloading url
            const url = window.URL.createObjectURL(blob);

            // Creating an anchor(a) tag of HTML
            const a = document.createElement("a");

            // Passing the blob downloading url
            a.setAttribute("href", url);
            a.setAttribute("download", filename);

            // Performing a download with click
            a.click();

            onSuccess?.();
        },
        [data, onEmpty, onSuccess, type]
    );
};
