import { useTranslation } from "react-i18next";
import { useSupportSystem } from "hooks/useSupportSystem";
import ChatIcon from "assets/icons/chat.svg?react";
import CloseIcon from "assets/icons/close.svg?react";
import styles from "../index.module.scss";
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
const cx = classnames.bind(styles);

export interface HelpTabProps {}

export const HelpTab = ({}: HelpTabProps) => {
    const { t } = useTranslation();
    const { isBooted } = useSelector((s: RootState) => s.supportSystem);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { isSupportAvailable, supportSystemCommand } = useSupportSystem();

    useEffect(() => {
        if (isBooted) {
            supportSystemCommand("on", "open", () => setIsOpen(true));
            supportSystemCommand("on", "close", () => setIsOpen(false));
        }

        return () => {
            supportSystemCommand("off", "open");
            supportSystemCommand("off", "close");
        };
    }, [isBooted, supportSystemCommand]);

    if (!isSupportAvailable) {
        return <></>;
    }

    return (
        <button
            className={cx("minimized-modal-tab", "help-tab")}
            onClick={() => {
                supportSystemCommand("toggle");
            }}
        >
            {!isOpen ? (
                <ChatIcon className={styles["chat-icon"]} />
            ) : (
                <CloseIcon />
            )}
            <p>{t("buttons:tab-help")}</p>
        </button>
    );
};
