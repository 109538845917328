import { displayAmount } from "helpers/stripe";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmountTableProps } from "../types";
import styles from "./AmountTable.module.scss";

export const NewAmountTable: React.FC<AmountTableProps> = ({
    amounts,
    pricing
}: AmountTableProps) => {
    const { t } = useTranslation();
    const displayDollarAmount = (amt: number) =>
        displayAmount(amt, { signed: true, compact: false });

    return (
        <>
            <table className={`table table-sm ${styles["amount-table"]}`}>
                <tbody>
                    <tr>
                        <td>
                            {t("amount-table:total-cost", {
                                period:
                                    pricing === "yearly" ? "annual" : pricing
                            })}
                        </td>
                        <td className="text-right">
                            {displayDollarAmount(amounts.total)}
                        </td>
                    </tr>
                    <tr>
                        <td>{t("amount-table:credit")}</td>
                        <td className="text-right">
                            {displayDollarAmount(amounts.proration)}
                        </td>
                    </tr>
                    <tr>
                        <td>{t("amount-table:discount")}</td>
                        <td className="text-right">
                            -{displayDollarAmount(amounts.discount)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t("amount-table:today-total")}</strong>
                        </td>
                        <td className="text-right">
                            <strong>{displayDollarAmount(amounts.due)}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};
